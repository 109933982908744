import { useCallback, useMemo } from 'react';

import { PaymentOption } from 'modules/billing/types';
import { useGetFeaturesQuery } from 'store/api';
import type { FeaturesModel } from 'store/common/models';

function toPaymentFeatureFlag(paymentOption: PaymentOption, isOrderFlow = false): keyof FeaturesModel | boolean {
  switch (paymentOption) {
    case PaymentOption.ALIPAY:
      return 'isAlipayEnabled';
    case PaymentOption.BLIK:
      return 'isBlikEnabled';
    case PaymentOption.CRYPTO:
      return isOrderFlow ? 'isOrderingCoingateEnabled' : 'isCoingateEnabled';
    case PaymentOption.GIROPAY:
      return 'isGiropayEnabled';
    case PaymentOption.IDEAL:
      return 'isIdealEnabled';
    case PaymentOption.P24:
      return 'isP24Enabled';
    case PaymentOption.PAYPAL:
      return 'isPayPalEnabled';
    case PaymentOption.SOFORT:
      return 'isSofortEnabled';
    case PaymentOption.STRIPE:
    case PaymentOption.WALLET:
      return true;
    default:
      return false;
  }
}

export function useFeatureFlag() {
  const { data, isLoading } = useGetFeaturesQuery();

  const isPaymentMethodEnabled = useCallback(
    (paymentType: PaymentOption, isOrderFlow = false) => {
      const item = toPaymentFeatureFlag(paymentType, isOrderFlow);

      if (typeof item === 'boolean') {
        return item;
      }

      return !!data?.[item];
    },
    [data],
  );

  const isOrderingEnabled = useMemo(() => !!data?.isOrderingEnabled, [data?.isOrderingEnabled]);

  const isBuyingThreadsUpgradesEnabled = useMemo(
    () => !!data?.isBuyingThreadsUpgradesEnabled,
    [data?.isBuyingThreadsUpgradesEnabled],
  );

  const isBuyingUplinkUpgradesEnabled = useMemo(
    () => !!data?.isBuyingUplinkUpgradesEnabled,
    [data?.isBuyingUplinkUpgradesEnabled],
  );

  const isDealsInventoryEnabled = useMemo(() => !!data?.isDealsInventoryEnabled, [data?.isDealsInventoryEnabled]);

  const isIdentityVerificationEnabled = useMemo(
    () => !!data?.isIdentityVerificationEnabled,
    [data?.isIdentityVerificationEnabled],
  );

  const isHttpsProtocolEnabled = useMemo(() => !!data?.isHttpsProtocolEnabled, [data?.isHttpsProtocolEnabled]);

  return useMemo(
    () => ({
      isFeatureFlagsLoading: isLoading,
      isPaymentMethodEnabled,
      isOrderingEnabled,
      isBuyingThreadsUpgradesEnabled,
      isBuyingUplinkUpgradesEnabled,
      isDealsInventoryEnabled,
      isIdentityVerificationEnabled,
      isHttpsProtocolEnabled,
      flags: data,
    }),
    [
      data,
      isBuyingThreadsUpgradesEnabled,
      isBuyingUplinkUpgradesEnabled,
      isDealsInventoryEnabled,
      isHttpsProtocolEnabled,
      isIdentityVerificationEnabled,
      isLoading,
      isOrderingEnabled,
      isPaymentMethodEnabled,
    ],
  );
}
