import { useCallback, useMemo } from 'react';

import dayjs from 'dayjs';
import { Formik, type FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useHideModal } from 'modules/common/Modal';
import { useRefundProxyMutation } from 'store/api';
import type { ProxyOverviewModel } from 'store/proxies/models';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import { RefundForm, type RefundValues } from './RefundForm';

type HandleSubmit = FormikConfig<RefundValues>['onSubmit'];

export type ProxyAdminRefundModalProps = {
  proxy: ProxyOverviewModel;
};

export function ProxyAdminRefundModal({ proxy }: ProxyAdminRefundModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const [refundProxy] = useRefundProxyMutation();

  const initialValues = useMemo<RefundValues>(() => {
    return { isCustomAmount: false, reason: '', refundAmount: 0, refundSince: dayjs().toISOString() };
  }, []);

  const validationSchema = useMemo<Yup.Schema<RefundValues>>(() => {
    return Yup.object().shape({
      reason: Yup.string().required(),
      refundSince: Yup.string().required(),
      isCustomAmount: Yup.bool().required(),
      refundAmount: Yup.number().when('isCustomAmount', (isCustomAmount, field) =>
        isCustomAmount.every(Boolean) ? field.required().min(1) : field,
      ),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ reason, isCustomAmount, refundSince, refundAmount }, { setErrors, setSubmitting }) => {
      try {
        await refundProxy({
          proxyId: proxy.id,
          reason,
          isCustomAmount,
          refundAmount: isCustomAmount ? (refundAmount ?? null) : null,
          refundSince: dayjs(refundSince).toISOString(),
        });

        ToastManager.success(t('proxies.overview.admin.modals.refund.success'));

        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        ToastManager.error(t('proxies.overview.admin.modals.refund.failure'));
      } finally {
        setSubmitting(false);
      }
    },
    [hideModal, proxy.id, refundProxy, t],
  );

  return (
    <Formik<RefundValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <RefundForm />
    </Formik>
  );
}
