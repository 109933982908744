import type {
  CredentialsGeneratorHostname,
  PacketStreamAccessConfig,
  PacketStreamSession,
} from 'modules/proxies/generators/types';
import type { Protocol } from 'store/proxies/types';
import type { Nullable } from 'types';

type CredentialGeneratorOptions = {
  username: string;
  password: string;
  country: string;
  connection: Protocol.HTTP;
  session: PacketStreamSession;
  hostname: CredentialsGeneratorHostname;
  hostnameIp?: Nullable<string>;
};

function generateRandomSession() {
  const length = 8;
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';

  for (let i = length; i > 0; i -= 1) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }

  return result;
}

export class CredentialsGenerator {
  private options: Nullable<CredentialGeneratorOptions> = null;

  setOptions(options: Nullable<CredentialGeneratorOptions>) {
    this.options = options;
  }

  public build(): PacketStreamAccessConfig {
    if (!this.options) throw new Error('Options are not provided');

    const { hostnameIp, username } = this.options;

    const { port, protocol } = this.getProtocolAndPort();
    const password = this.generatePassword();
    const proxyHostname = this.getProxyHostname();

    return {
      type: 'packet-stream',
      ip: hostnameIp,
      protocol,
      username,
      password,
      proxyHostname,
      port,
    };
  }

  private getProtocolAndPort() {
    if (!this.options) throw new Error('Options are not provided');

    const { connection } = this.options;

    return { protocol: connection, port: 31112 };
  }

  private getProxyHostname() {
    if (!this.options) throw new Error('Options are not provided');

    const { hostname, hostnameIp } = this.options;

    const defaultValue = 'proxy.proxy-cheap.com';

    return hostname === 'dns' ? defaultValue : hostnameIp;
  }

  private generatePassword() {
    if (!this.options) throw new Error('Options are not provided');

    const { country, session } = this.options;
    let { password } = this.options;

    if (country !== 'random') {
      password += `_country-${country.replace(/ /g, '')}`;
    }

    if (session === 'sticky') {
      password += `_session-${generateRandomSession()}`;
    }

    return password;
  }
}
