export const paths = {
  index: '/',
  notFound: '/404',
  auth: {
    signIn: '/login',
    mfa: '/login/mfa',
    signUp: '/register',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    logout: '/logout',
    switch: '/switch',
    oAuth: {
      google: 'connect/google',
      github: 'connect/github',
    },
  },
  marketplace: {
    index: '/marketplace',
  },
  order: {
    index: '/order',
    proxy: '/order/proxy',
    details: '/order/details',
    checkout: '/order/checkout',
  },
  account: {
    index: '/account',
    general: '/account/general',
    notifications: '/account/notifications',
    security: '/account/security',
    identityVerification: '/account/id-verification',
    payments: '/account/billing',
  },
  payments: {
    callback: '/payments/callback',
  },
  proxies: {
    index: '/proxies',
    active: '/proxies?preset=active',
    inactive: '/proxies?preset=inactive',
    expiring: '/proxies?preset=expiring',
    replace: '/proxies/replace',
    operation: {
      index: '/proxies/operation',
      id: (operationId: string) => `/proxies/operation/${operationId}`,
    },
    overview: (proxyId: string | number) => `/proxies/${proxyId}`,
    credentialsGenerator: (proxyId: number) => `/proxies/${proxyId}/credentials-generator`,
  },
  referrals: {
    index: '/referrals',
    bank: '/referrals/bank',
  },
  featureRequest: {
    index: '/feature-request',
  },
  reseller: {
    index: '/reseller',
  },
  apiKeys: {
    index: '/api-keys',
  },
  external: {
    asnLookup: 'https://asnlookup.com',
    needHelp: 'https://proxy-cheapcom7036.zendesk.com/hc/en-us/categories/24603913038237-Getting-Started',
    faq: 'https://support.proxy-cheap.com',
    whatIsMyIp: 'https://www.maxmind.com/en/geoip-web-services-demo',
    apiDocumentation: 'https://app.swaggerhub.com/apis-docs/Proxy-Cheap/API',
    termsAndConditions: 'https://www.proxy-cheap.com/terms-and-conditions',
    privacyPolicy: 'https://www.proxy-cheap.com/privacy-policy',
    stripe: 'https://www.stripe.com',
    whmcs: 'https://marketplace.whmcs.com/product/6889-whitelabel-proxy-reseller-module',
    chromeExtension:
      'https://chrome.google.com/webstore/detail/proxy-cheap-proxy-manager/gdpehpfhegefkjelaifkdbppjbhilaom',
    trustpilotReview: 'https://www.trustpilot.com/evaluate/proxy-cheap.com',
    services: {
      'static-residential-ipv4': 'https://proxy-cheapcom7036.zendesk.com/hc/en-us',
      'static-datacenter-ipv4': 'https://proxy-cheapcom7036.zendesk.com/hc/en-us',
      'static-datacenter-ipv6': 'https://proxy-cheapcom7036.zendesk.com/hc/en-us',
      'rotating-residential': 'https://proxy-cheapcom7036.zendesk.com/hc/en-us',
      'rotating-mobile': 'https://proxy-cheapcom7036.zendesk.com/hc/en-us',
      'dedicated-mobile': 'https://proxy-cheapcom7036.zendesk.com/hc/en-us',
      vpn: 'https://proxy-cheapcom7036.zendesk.com/hc/en-us',
    },

    gettingStarted: 'https://proxy-cheapcom7036.zendesk.com/hc/en-us/categories/24603913038237-Getting-Started',
    tutorials: 'https://proxy-cheapcom7036.zendesk.com/hc/en-us/categories/24603912883997-Proxy-Setup-Configuration',
    faqs: 'https://proxy-cheapcom7036.zendesk.com/hc/en-us/categories/23303038105501-Troubleshooting-Support',
    widget: {
      docs: 'https://proxy-cheapcom7036.zendesk.com/hc/en-us/articles/25206969256477-Integrating-and-Customizing-the-Proxy-Cheap-Widget-on-Your-Website',
      example: 'https://codesandbox.io/p/sandbox/xx54q3',
    },
  },
  quiz: {
    index: '/quiz',
  },
};
