import { useMemo } from 'react';

import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Banner } from 'components/Banner';
import { Button } from 'components/Button';
import { useZendesk } from 'components/Zendesk';
import { useSession } from 'modules/accounts/hooks';

type AccountSuspendedBannerProps = {
  horizontalLayout?: boolean;
};

export function AccountSuspendedBanner({ horizontalLayout }: AccountSuspendedBannerProps) {
  const { t } = useTranslation();
  const { session } = useSession();

  const { open: openZendeskChat } = useZendesk();

  const subtitle = useMemo(() => {
    return (
      <>
        <Typography variant="body-2">{t('common:accountSuspended.subtitle')}</Typography>
        {session?.suspension.reason && (
          <Typography variant="body-2" component="p">
            {`${t('common:accountSuspended.reason')}: ${t(`common:suspensionReasons.${session.suspension.reason}`)}`}
          </Typography>
        )}

        {session?.suspension.explanation && (
          <Typography variant="body-2" component="p">
            {t('common:accountSuspended.reasonDetails')}: {session.suspension.explanation}
          </Typography>
        )}
      </>
    );
  }, [session, t]);

  const actions = useMemo(() => {
    return [
      <Button key="contact-support" fullWidth color="error" onClick={() => openZendeskChat()}>
        {t('common:buttons.contactSupport')}
      </Button>,
    ];
  }, [openZendeskChat, t]);

  return (
    <Banner
      title={t('common:accountSuspended.title')}
      subtitle={subtitle}
      actions={actions}
      isVisible
      slotProps={{
        container: { gridTemplateColumns: { xs: '1fr', lg: horizontalLayout ? 'repeat(2, auto)' : '1fr' } },
        content: { spacing: { xs: 1, md: horizontalLayout ? 0.5 : 1 } },
        title: { color: 'var(--mui-palette-error-main)' },
      }}
      sx={{
        '--Card-boxShadow': 'none',
        '--Card-background': 'var(--mui-palette-error-50)',
        '--Card-paddingBlock': { xs: '16px', md: horizontalLayout ? '32px' : '16px' },
        '--Card-paddingInline': { xs: '16px', md: horizontalLayout ? '32px' : '16px' },
      }}
    />
  );
}
