import { alertClasses } from '@mui/material/Alert';
import type { Components, Theme } from '@mui/material/styles';

import { Icon } from 'components/Icon';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  defaultProps: {
    severity: 'error',
    iconMapping: {
      success: <Icon name="check" size="large" color="var(--mui-palette-success-700)" />,
      info: <Icon name="information-circle" size="large" color="var(--mui-palette-info-700)" />,
      error: <Icon name="close-circle" size="large" color="var(--mui-palette-error-500)" />,
      warning: <Icon name="information-circle" size="large" color="var(--mui-palette-warning-700)" />,
    },
  },
  styleOverrides: {
    root: {
      '--Alert-boxShadow': 'none',
      '--Alert-borderRadius': '12px',
      '--Alert-paddingBlock': '16px',
      '--Alert-paddingInline': '16px',

      '--Alert-icon-paddingBlock': '0px',
      '--Alert-icon-paddingInline': '0px',

      '--Alert-message-paddingBlock': '0px',
      '--Alert-message-paddingInline': '0px',
      '--Alert-message-fontSize': '14px',
      '--Alert-message-lineHeight': '20px',
      '--Alert-message-fontWeight': '400',

      boxShadow: 'var(--Alert-boxShadow)',
      borderRadius: 'var(--Alert-borderRadius)',

      paddingBlock: 'var(--Alert-paddingBlock)',
      paddingInline: 'var(--Alert-paddingInline)',

      alignItems: 'center',

      [`&.${alertClasses.colorError}`]: { '--Alert-message-color': 'var(--mui-palette-error-700)' },

      [`&.${alertClasses.colorInfo}`]: { '--Alert-message-color': 'var(--mui-palette-info-700)' },

      [`&.${alertClasses.colorSuccess}`]: { '--Alert-message-color': 'var(--mui-palette-tertiary-700)' },

      [`&.${alertClasses.colorWarning}`]: { '--Alert-message-color': 'var(--mui-palette-warning-700)' },
    },

    icon: {
      paddingBlock: 'var(--Alert-icon-paddingBlock)',
      paddingInline: 'var(--Alert-icon-paddingInline)',
    },

    message: {
      paddingBlock: 'var(--Alert-message-paddingBlock)',
      paddingInline: 'var(--Alert-message-paddingInline)',

      color: 'var(--Alert-message-color)',
      fontSize: 'var(--Alert-message-fontSize)',
      lineHeight: 'var(--Alert-message-lineHeight)',
      fontWeight: 'var(--Alert-message-fontWeight)',
    },
  },
};
