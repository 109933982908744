import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import type { CredentialsGeneratorHostname, NetNutCredentialsGeneratorValues } from 'modules/proxies/generators/types';
import type { HostnameModel } from 'store/proxies/models';
import { type NetNutHostnameRegion, NetworkType, Protocol } from 'store/proxies/types';
import type { Option } from 'types';

import { NETNUT_COUNTRIES, NETNUT_CREDENTIALS_GENERATOR_GROUPED_BY_COUNTRIES_AND_STATES } from './constants';

export function useNetNutOptions(
  values: NetNutCredentialsGeneratorValues,
  networkType?: NetworkType,
  hostnames?: HostnameModel[],
) {
  const { t } = useTranslation();

  const connection = useMemo<Array<Option<Protocol>>>(() => {
    return [
      { label: t(`common:proxyProtocol.${Protocol.HTTP}`), value: Protocol.HTTP },
      { label: t(`common:proxyProtocol.${Protocol.SOCKS5}`), value: Protocol.SOCKS5 },
    ];
  }, [t]);

  const countries = useMemo<Array<Option<string>>>(
    () => [{ label: 'countriesregions:countries.random', value: 'random' }, ...NETNUT_COUNTRIES],
    [],
  );

  const states = useMemo<Array<Option<string>>>(() => {
    return [
      { label: 'countriesregions:countries.random', value: 'random' },
      ...Object.keys(NETNUT_CREDENTIALS_GENERATOR_GROUPED_BY_COUNTRIES_AND_STATES[values.country] ?? {}).map((key) => ({
        label: key,
        value: key,
      })),
    ];
  }, [values.country]);

  const cities = useMemo<Array<Option<string>>>(() => {
    return [
      { label: 'countriesregions:countries.random', value: 'random' },
      ...(NETNUT_CREDENTIALS_GENERATOR_GROUPED_BY_COUNTRIES_AND_STATES?.[values.country]?.[values.state] ?? []).map(
        ({ city }) => ({ label: city, value: city }),
      ),
    ];
  }, [values.country, values.state]);

  const session = useMemo<Array<Option<string>>>(() => {
    const actions = [
      { label: 'common:credentialsGenerator.session.random', value: 'random' },
      {
        label: 'common:credentialsGenerator.session.sid.label',
        subLabel: 'common:credentialsGenerator.session.sid.subLabel',
        value: 'sid',
      },
    ];

    if (networkType !== NetworkType.Mobile) {
      actions.push({
        label: 'common:credentialsGenerator.session.nnid.label',
        subLabel: 'common:credentialsGenerator.session.nnid.subLabel',
        value: 'nnid',
      });
    }

    return actions;
  }, [networkType]);

  const hostname = useMemo<Array<Option<CredentialsGeneratorHostname>>>(() => {
    return [
      { label: 'common:credentialsGenerator.hostname.dns', value: 'dns' },
      { label: 'common:credentialsGenerator.hostname.ip', value: 'ip' },
    ];
  }, []);

  const hostnameRegions = useMemo<Array<Option<NetNutHostnameRegion>>>(() => {
    if (!hostnames) return [];

    return hostnames
      .filter((o) => !!o.region)
      .map(({ region }) => ({
        label: t(`proxies.credentials.generator.regions.${region}`),
        value: region,
      }));
  }, [hostnames, t]);

  return useMemo(() => {
    return { connection, countries, states, cities, session, hostname, hostnameRegions };
  }, [cities, connection, countries, hostname, hostnameRegions, session, states]);
}
