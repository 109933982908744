import { useMemo, useState } from 'react';

import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Input } from 'components/form-partials/base';
import type { ProxyExtensionPriceModel } from 'store/proxies/models';
import type { Nullable } from 'types';
import { CurrencyFormatter } from 'utils/currency';

export type CouponCodeInputValues = {
  coupon: string;
};

type CouponCodeInputProps = {
  isApplied: boolean;
  isApplying: boolean;
  price?: ProxyExtensionPriceModel;
  onApply: (couponCode: Nullable<string>) => void;
};

export function CouponCodeInput({ isApplied, isApplying, price, onApply }: CouponCodeInputProps) {
  const { t } = useTranslation();

  const [couponCode, setCouponCode] = useState('');

  const discount = useMemo(() => {
    return t('order.v2.summary.discount.value', {
      discount: CurrencyFormatter.format(price?.discountAmount ?? 0),
      percentage: price?.discount ?? 0,
    });
  }, [price?.discount, price?.discountAmount, t]);

  return (
    <>
      <Collapse in={!isApplied} unmountOnExit>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Input
            name="coupon"
            size="small"
            fullWidth
            placeholder={t('common:form.couponCode')}
            onChange={(e) => setCouponCode(e.target.value)}
          />

          <Button size="medium" loading={isApplying} onClick={() => onApply(couponCode)}>
            {t('common:buttons.apply')}
          </Button>
        </Stack>
      </Collapse>

      <Collapse in={isApplied} unmountOnExit>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Chip label={couponCode} color="warning" size="small" onDelete={() => onApply(null)} />

          <Typography variant="subtitle">{discount}</Typography>
        </Stack>
      </Collapse>
    </>
  );
}
