/* eslint-disable @typescript-eslint/no-deprecated */
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { inputBaseClasses } from '@mui/material/InputBase';
import type { Components, Theme } from '@mui/material/styles';

import { Icon } from 'components/Icon';

// TODO: Verify commented styles
export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  defaultProps: {
    clearIcon: <Icon name="cross" size="small" />,
    popupIcon: <Icon name="chevron-down" size="small" />,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '--Autocomplete-input-paddingBlock': '0px',
      '--Autocomplete-input-paddingInline': '0px',
      '--Autocomplete-input-gap': '8px',

      '--Autocomplete-hasPopupIcon-paddingRight': '68px',

      '--Autocomplete-endAdornment-right': '16px',
      '--Autocomplete-endAdornment-gap': '4px',

      [`& .${inputBaseClasses.root}`]: {
        paddingBlock: 'var(--Input-paddingBlock)',
        paddingInline: 'var(--Input-paddingInline)',

        [`&.${inputBaseClasses.sizeSmall}`]: {
          paddingBlock: 'var(--Input-paddingBlock)',
          paddingInline: 'var(--Input-paddingInline)',

          [`& .${autocompleteClasses.input}`]: {
            padding: 0,
            height: 'auto',

            [`label[data-shrink=false] + .${inputBaseClasses.formControl} &`]: {
              '&::placeholder': {
                opacity: '1 !important',
              },
            },

            '&:-webkit-autofill': {
              marginInline: 'calc(var(--Input-paddingInline) * -1)',
              paddingInline: 'var(--Input-paddingInline)',
              boxShadow: 'none',
              WebkitTextFillColor: 'var(--mui-palette-text-primary)',
              caretColor: 'var(--mui-palette-common-black)',
            },

            [`&.${inputBaseClasses.inputAdornedEnd}`]: {
              paddingRight: 'var(--Input-adorned-end-padding) !important',
            },

            [`&.${inputBaseClasses.inputAdornedStart}`]: {
              paddingLeft: 'var(--Input-adorned-start-padding)',
            },
          },
        },

        [`& .${autocompleteClasses.input}`]: {
          padding: 0,
          height: 'auto',

          [`label[data-shrink=false] + .${inputBaseClasses.formControl} &`]: {
            '&::placeholder': {
              opacity: '1 !important',
            },
          },

          '&:-webkit-autofill': {
            marginInline: 'calc(var(--Input-paddingInline) * -1)',
            paddingInline: 'var(--Input-paddingInline)',
            boxShadow: 'none',
            WebkitTextFillColor: 'var(--mui-palette-text-primary)',
            caretColor: 'var(--mui-palette-common-black)',
          },

          [`&.${inputBaseClasses.inputAdornedEnd}`]: {
            paddingRight: 'var(--Input-adorned-end-padding) !important',
          },

          [`&.${inputBaseClasses.inputAdornedStart}`]: {
            paddingLeft: 'var(--Input-adorned-start-padding)',
          },
        },

        [`& .${autocompleteClasses.endAdornment}`]: {
          display: 'inline-flex',
          gap: 'var(--Autocomplete-endAdornment-gap)',
          right: 'var(--Autocomplete-endAdornment-right)',
        },

        [`& .${autocompleteClasses.popupIndicator}`]: {
          transition: theme.transitions.create(['transform'], { duration: 300 }),
        },
      },

      [`&.${autocompleteClasses.hasPopupIcon} .${inputBaseClasses.root}`]: {
        paddingRight: 'var(--Autocomplete-hasPopupIcon-paddingRight) !important',
      },

      [`& .${inputBaseClasses.sizeSmall}`]: {
        '--Autocomplete-hasPopupIcon-paddingRight': '56px',
        '--Autocomplete-endAdornment-right': '12px',
      },

      'label + &': { marginTop: 'var(--Autocomplete-input-gap)' },
    }),

    popupIndicator: { padding: 0, margin: 0 },
    clearIndicator: { padding: 0, margin: 0 },

    popper: ({ theme }) => ({
      '--Autocomplete-popper-minWidth': 'auto',

      '--Autocomplete-paper-boxShadow': 'var(--mui-shadows-4)',
      '--Autocomplete-paper-borderRadius': '8px',
      '--Autocomplete-paper-marginBlock': '8px',
      '--Autocomplete-paper-marginInline': '0px',

      '--Autocomplete-listbox-paddingBlock': '4px',
      '--Autocomplete-listbox-paddingInline': '4px',

      '--Autocomplete-option-background': 'transparent',
      '--Autocomplete-option-borderRadius': '4px',
      '--Autocomplete-option-color': 'var(--mui-palette-text-primary)',
      '--Autocomplete-option-gap': '2px',
      '--Autocomplete-option-paddingBlock': '8px',
      '--Autocomplete-option-paddingInline': '8px',

      '--Autocomplete-option-hover-background': 'var(--mui-palette-neutral-50)',
      '--Autocomplete-option-hover-color': 'var(--mui-palette-text-primary)',

      '--Autocomplete-option-selected-background': 'var(--mui-palette-primary-50)',
      '--Autocomplete-option-selected-color': 'var(--mui-palette-text-primary)',

      minWidth: 'var(--Autocomplete-popper-minWidth)',

      [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'var(--Autocomplete-paper-boxShadow)',
        borderRadius: 'var(--Autocomplete-paper-borderRadius)',

        marginBlock: 'var(--Autocomplete-paper-marginBlock)',
        marginInline: 'var(--Autocomplete-paper-marginInline)',

        [`& .${autocompleteClasses.listbox}`]: {
          paddingBlock: 'var(--Autocomplete-listbox-paddingBlock)',
          paddingInline: 'var(--Autocomplete-listbox-paddingInline)',

          [`& .${autocompleteClasses.option}`]: {
            backgroundColor: 'var(--Autocomplete-option-background)',
            color: 'var(--Autocomplete-option-color)',

            borderRadius: 'var(--Autocomplete-option-borderRadius)',
            paddingBlock: 'var(--Autocomplete-option-paddingBlock)',
            paddingInline: 'var(--Autocomplete-option-paddingInline)',

            transition: theme.transitions.create(['background-color', 'color'], { duration: 300 }),

            [`& + .${autocompleteClasses.option}`]: {
              marginTop: 'var(--Autocomplete-option-gap)',
            },

            '&:hover': {
              backgroundColor: 'var(--Autocomplete-option-hover-background)',
              color: 'var(--Autocomplete-option-hover-color)',
            },

            [`&[aria-selected=true], &[aria-selected=true].${autocompleteClasses.focused}`]: {
              backgroundColor: 'var(--Autocomplete-option-selected-background)',
              color: 'var(--Autocomplete-option-selected-color)',
            },
          },
        },
      },
    }),

    noOptions: {
      paddingBlock: 'var(--Autocomplete-option-paddingBlock)',
      paddingInline: 'var(--Autocomplete-option-paddingInline)',
    },
  },
};
