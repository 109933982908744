import { useCallback, useEffect, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'modules/common/Modal';
import { useUplinkSpeedFormatter } from 'modules/proxies/hooks';
import { useChangeUplinkSpeedMutation, useGetChangeUplinkSpeedOptionsQuery } from 'store/api';
import type { Option } from 'types';
import { ToastManager } from 'utils/toast';

import { ChangeUplinkSpeedForm, type ChangeUplinkSpeedValues } from './ChangeUplinkSpeedForm';

type HandleSubmit = FormikConfig<ChangeUplinkSpeedValues>['onSubmit'];

export type ProxyChangeUplinkSpeedModalProps = {
  proxyIds: number[];
};

export function ProxyChangeUplinkSpeedModal({ proxyIds }: ProxyChangeUplinkSpeedModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const prepareUplinkSpeedLabel = useUplinkSpeedFormatter();

  const [changeUplinkSpeed] = useChangeUplinkSpeedMutation();
  const {
    data: uplinkSpeedOptions,
    isFetching: isOptionsLoading,
    refetch,
  } = useGetChangeUplinkSpeedOptionsQuery(proxyIds[0], { skip: proxyIds.length > 1 });

  const initialValues = useMemo<ChangeUplinkSpeedValues>(() => {
    return { uplinkSpeed: uplinkSpeedOptions?.currentUplinkSpeed ?? 100 };
  }, [uplinkSpeedOptions?.currentUplinkSpeed]);

  const options = useMemo<Array<Option<number>>>(() => {
    return uplinkSpeedOptions?.options.map((i) => ({ value: i, label: prepareUplinkSpeedLabel(i) })) ?? [];
  }, [uplinkSpeedOptions?.options, prepareUplinkSpeedLabel]);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ uplinkSpeed }) => {
      try {
        await changeUplinkSpeed({ proxyId: proxyIds[0], uplinkSpeed }).unwrap();

        ToastManager.success(t('proxies.modals.upgradeBandwidthSpeed.success'));
        hideModal();
      } catch {
        // noop
      }
    },
    [changeUplinkSpeed, hideModal, proxyIds, t],
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Formik<ChangeUplinkSpeedValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <ChangeUplinkSpeedForm options={options} isLoading={isOptionsLoading} />
    </Formik>
  );
}
