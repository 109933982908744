import { type SyntheticEvent, useCallback, type ReactNode } from 'react';

import Stack, { type StackProps } from '@mui/material/Stack';
import MuiTabs, { type TabsProps as MuiTabsProps } from '@mui/material/Tabs';

import type { TabOption } from 'types';

type TabsSlotProps = {
  container?: Partial<StackProps>;
};

type TabsProps<K, T extends TabOption<K> = TabOption<K>> = Omit<MuiTabsProps, 'value' | 'onChange'> & {
  /**
   * Set of the tab items
   */
  items: readonly T[];

  /**
   * Value for Tabs component
   */
  value: K;

  /**
   * Default value for the active tab
   */
  defaultValue?: K;

  /**
   * Type of the parameter matching type
   */
  type?: 'pathname' | 'param';

  /**
   * On Change event callback.
   */
  onChange?: (event: SyntheticEvent, value: K) => void;

  /**
   * Render function for tab label
   */
  renderTab: (item: T) => ReactNode;

  /**
   * Slot props
   */
  slotProps?: TabsSlotProps;

  /**
   * Children of the Tabs component
   */
  children?: ReactNode;
};

export function Tabs<K, T extends TabOption<K> = TabOption<K>>({
  items,
  value,
  defaultValue,
  type,
  slotProps,
  children,

  onChange,
  renderTab,
  ...props
}: TabsProps<K, T>) {
  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: unknown) => {
      return onChange?.(event, newValue as K);
    },
    [onChange],
  );

  return (
    <Stack
      direction={{ xs: 'column-reverse', md: 'row' }}
      spacing={{ xs: 2, md: 0 }}
      justifyContent={{ xs: 'initial', md: 'space-between' }}
      alignItems={{ xs: 'initial', md: 'center' }}
      sx={{
        '--Tabs-container-boxShadow': 'var(--mui-shadows-1)',

        boxShadow: 'var(--Tabs-container-boxShadow)',
      }}
      {...slotProps?.container}
    >
      <MuiTabs value={value} sx={{ '--Tabs-boxShadow': 'none' }} onChange={handleChange} {...props}>
        {items.map(renderTab)}
      </MuiTabs>

      {children}
    </Stack>
  );
}
