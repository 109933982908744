import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

import { Icon } from 'components/Icon';
import { useZendesk } from 'components/Zendesk';
import { usePopover } from 'hooks/usePopover';

import { AccountMenu } from './AccountMenu';

export function AccountButton() {
  const { anchorRef, isOpen, handleClose, handleOpen } = usePopover<HTMLButtonElement>();

  const { unreadMessages } = useZendesk();

  return (
    <>
      <IconButton ref={anchorRef} onClick={handleOpen}>
        <Badge overlap="circular" invisible={!unreadMessages}>
          <Icon name="profile-circle" color="var(--mui-palette-neutral-400)" />
        </Badge>
      </IconButton>

      <AccountMenu anchorEl={anchorRef.current} isOpen={isOpen} onClose={handleClose} />
    </>
  );
}
