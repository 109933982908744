import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { ToastManager } from 'utils/toast';
import { copyToClipboard } from 'utils/values';

export function useCopy() {
  const { t } = useTranslation();

  return useCallback(
    async (value?: string) => {
      if (!value) return;

      try {
        await copyToClipboard(value);

        ToastManager.success(t('common:copySuccess'), true);
      } catch {
        /**
         * This block can catch an error that is related to the unsupported Clipboard API by the browser.
         * In case of not supported feature nothing should happen
         */
      }
    },
    [t],
  );
}
