import type { Components, Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  defaultProps: { arrow: true },
  styleOverrides: {
    popper: {
      '--Tooltip-boxShadow': 'none',
      '--Tooltip-backgroundColor': 'var(--mui-palette-neutral-400)',
      '--Tooltip-color': 'var(--mui-palette-neutral-50)',
      '--Tooltip-paddingBlock': '16px',
      '--Tooltip-paddingInline': '16px',
    },

    tooltip: {
      boxShadow: 'var(--Tooltip-boxShadow)',
      backgroundColor: 'var(--Tooltip-backgroundColor)',
      color: 'var(--Tooltip-color)',

      paddingBlock: 'var(--Tooltip-paddingBlock)',
      paddingInline: 'var(--Tooltip-paddingInline)',

      fontSize: 'var(--Tooltip-fontSize, 14px)',
      fontWeight: 'var(--Tooltip-fontWeight, 400)',
      lineHeight: 'var(--Tooltip-lineHeight, 20px)',
    },

    arrow: {
      '&:before': { backgroundColor: 'var(--Tooltip-backgroundColor)' },
    },
  },
};
