import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks/useRouter';
import { useSession } from 'modules/accounts/hooks';
import { useFeatureFlag } from 'modules/common/hooks';
import { useHideModal, useShowModal } from 'modules/common/Modal';
import { paths } from 'paths';
import { VerificationStatus } from 'store/auth/models';

export function useIdentityVerificationStatus() {
  const { t } = useTranslation();
  const router = useRouter();
  const hideModal = useHideModal();
  const showModal = useShowModal();

  const { isIdentityVerificationEnabled } = useFeatureFlag();
  const { session, isSessionLoading, refetch, isUninitialized } = useSession();

  const verificationRequest = useMemo(() => session?.verificationRequest, [session?.verificationRequest]);

  const isVerificationRequired = useMemo(() => {
    if (!verificationRequest) return false;

    return verificationRequest.status === VerificationStatus.REQUIRED;
  }, [verificationRequest]);

  const shouldDisplayIdentityNotification = useMemo(() => {
    return (
      isIdentityVerificationEnabled &&
      (isVerificationRequired || verificationRequest?.status === VerificationStatus.FAILED)
    );
  }, [isIdentityVerificationEnabled, isVerificationRequired, verificationRequest?.status]);

  const shouldDisplayStatus = useMemo(() => {
    if (!verificationRequest) return false;

    return [VerificationStatus.FAILED, VerificationStatus.NOT_VERIFIED, VerificationStatus.PENDING].includes(
      verificationRequest.status,
    );
  }, [verificationRequest]);

  const shouldForceIDVerification = useCallback(() => {
    if (!isVerificationRequired || !isIdentityVerificationEnabled) return false;

    showModal('account.id.verification', {
      title: t('account.modals.idVerification.title'),
      subtitle: t('account.modals.idVerification.subtitle'),
      confirmLabel: t('common:buttons.continueVerification'),
      closeDisabled: true,
      onConfirm: () => {
        router.push(paths.account.identityVerification);
        hideModal();
      },
    });

    return true;
  }, [hideModal, isIdentityVerificationEnabled, isVerificationRequired, router, showModal, t]);

  return useMemo(
    () => ({
      isSessionLoading,
      isVerificationRequired,
      shouldDisplayIdentityNotification,
      shouldDisplayStatus,
      verificationRequest,
      isUninitialized,
      shouldForceIDVerification,
      refetch,
    }),
    [
      isSessionLoading,
      isVerificationRequired,
      shouldDisplayIdentityNotification,
      shouldDisplayStatus,
      verificationRequest,
      isUninitialized,
      shouldForceIDVerification,
      refetch,
    ],
  );
}
