import { createContext, useCallback, useMemo, useState } from 'react';

import { useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeError } from '@stripe/stripe-js';

import { useMounted } from 'hooks/useMounted';
import type { ContextChildren, Nullable } from 'types';
import { createUseContext } from 'utils/context';

import {
  getClientSecret,
  getCreatePaymentMethodArgs,
  getExternalVendorRedirectURL,
  shouldHandlePaymentViaCustomRedirect,
} from './helpers';
import type {
  CreatePaymentMethodFn,
  HandlePaymentFn,
  HandleSetupFn,
  HandleStripeSubmitFn,
  StripeOptions,
} from './types';

type TStripeContext = {
  error: Nullable<StripeError>;

  createPaymentMethod: CreatePaymentMethodFn;
  handlePayment: HandlePaymentFn;
  handleSetup: HandleSetupFn;
  handleSubmit: HandleStripeSubmitFn;
};

const StripeContext = createContext<TStripeContext | undefined>(undefined);

export type StripeProviderProps = StripeOptions & {
  children: ContextChildren<TStripeContext>;
};

export function StripeProvider({ children, ...props }: StripeProviderProps) {
  const stripe = useStripe();
  const elements = useElements();
  const isMounted = useMounted();

  const [error, setError] = useState<Nullable<StripeError>>(null);

  /**
   * Retrieves SetupIntent from Stripe
   *
   * @private
   *
   * @param {string} clientSecret
   * @returns {Promise<Nullable<SetupIntent>>}
   */
  const getSetupIntent = useCallback(
    async (clientSecret: string) => {
      if (!stripe || !isMounted()) return;

      const { setupIntent, error: retrieveSetupIntentError } = await stripe.retrieveSetupIntent(clientSecret);

      if (retrieveSetupIntentError) {
        setError(retrieveSetupIntentError);

        return null;
      }

      return setupIntent ?? null;
    },
    [isMounted, stripe],
  );

  /**
   * Retrieves PaymentIntent from Stripe
   *
   * @private
   *
   * @param {string} clientSecret
   * @returns {Promise<Nullable<PaymentIntent>>}
   */
  const getPaymentIntent = useCallback(
    async (clientSecret: string) => {
      if (!stripe || !isMounted()) return;

      const { paymentIntent, error: retrievePaymentIntentError } = await stripe.retrievePaymentIntent(clientSecret);

      if (retrievePaymentIntentError) {
        setError(retrievePaymentIntentError);

        return null;
      }

      return paymentIntent ?? null;
    },
    [isMounted, stripe],
  );

  /**
   * Retrieves PaymentMethod from Stripe
   *
   * @param {PaymentOptionType} paymentType
   * @returns {PaymentMethod | void}
   */
  const createPaymentMethod = useCallback<CreatePaymentMethodFn>(
    async (paymentType) => {
      if (!stripe || !isMounted()) return;

      if (shouldHandlePaymentViaCustomRedirect(paymentType)) {
        const { error: createPaymentMethodError, paymentMethod } = await stripe.createPaymentMethod(
          getCreatePaymentMethodArgs(paymentType),
        );

        if (!createPaymentMethodError) {
          return paymentMethod;
        }

        return setError(createPaymentMethodError);
      }

      if (!elements) return;

      const { error: createPaymentMethodError, paymentMethod } = await stripe.createPaymentMethod({ elements });

      if (!createPaymentMethodError) {
        return paymentMethod;
      }

      return setError(createPaymentMethodError);
    },
    [elements, isMounted, stripe],
  );

  /**
   * Handles setup flow
   */
  const handleSetup = useCallback<HandleSetupFn>(
    async ({ onSuccess, onFailure }) => {
      if (!stripe || !elements || !isMounted()) return;

      const clientSecret = getClientSecret(props);
      const setupIntent = await getSetupIntent(clientSecret);

      if (setupIntent?.status === 'requires_action') {
        const { error: handleNextActionError } = await stripe.handleNextAction({ clientSecret });

        if (handleNextActionError) {
          setError(handleNextActionError);

          return onFailure?.(handleNextActionError);
        }
      }

      const { error: confirmSetupError } = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: 'if_required',
      });

      if (confirmSetupError) {
        setError(confirmSetupError);

        return onFailure?.(confirmSetupError);
      }

      return onSuccess();
    },
    [elements, getSetupIntent, isMounted, props, stripe],
  );

  /**
   * Handles payment flow
   */
  const handlePayment = useCallback<HandlePaymentFn>(
    async (clientSecret, { onSuccess, onFailure }) => {
      if (!stripe || !isMounted()) return;

      const paymentIntent = await getPaymentIntent(clientSecret);

      if (paymentIntent?.status === 'requires_action' || paymentIntent?.status === 'requires_capture') {
        const externalVendorRedirectURL = getExternalVendorRedirectURL(paymentIntent);

        if (externalVendorRedirectURL) {
          window.open(externalVendorRedirectURL);

          return onSuccess();
        }

        const { error: handleNextActionError } = await stripe.handleNextAction({ clientSecret });

        if (handleNextActionError) {
          setError(handleNextActionError);

          return onFailure?.(handleNextActionError);
        }

        return onSuccess();
      }

      return onSuccess();
    },
    [getPaymentIntent, isMounted, stripe],
  );

  /**
   * Handles form submit
   */
  const handleSubmit = useCallback<HandleStripeSubmitFn>(
    async ({ event, onSuccess, onFailure, onStart }) => {
      event.preventDefault();

      if (!stripe || !elements || !isMounted()) return;

      onStart?.();

      const { error: elementsError } = await elements.submit();

      if (elementsError) {
        setError(elementsError);

        return onFailure?.(elementsError);
      }

      return onSuccess();
    },
    [elements, isMounted, stripe],
  );

  const value = useMemo<TStripeContext>(
    () => ({
      error,

      createPaymentMethod,
      handlePayment,
      handleSetup,
      handleSubmit,
    }),
    [createPaymentMethod, error, handlePayment, handleSetup, handleSubmit],
  );

  return (
    <StripeContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </StripeContext.Provider>
  );
}

export const useStripeContext = createUseContext(StripeContext);
