import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import type { StatusVariant } from './helpers';

type StatusIndicatorProps = Omit<StatusVariant, 'variant'>;

export function StatusIndicator({ color }: StatusIndicatorProps) {
  const { bgcolor, border } = useMemo(() => {
    const styles = {
      bgcolor: `var(--mui-palette-${color}-500)`,
      border: 'none',
    };

    if (color === 'info') {
      styles.bgcolor = 'transparent';
      styles.border = '1px solid var(--mui-palette-divider)';
    }

    return styles;
  }, [color]);

  return (
    <Stack justifyContent="center" alignItems="center">
      <Box bgcolor={bgcolor} width="8px" height="8px" borderRadius="100%" border={border} />
    </Stack>
  );
}
