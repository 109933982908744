import { useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { Outlet } from 'react-router-dom';

import { useCreditCard } from 'modules/accounts/hooks';
import { useFeatureFlag } from 'modules/common/hooks';
import { useOrderState } from 'modules/orders/hooks';

import { OrderDisabled } from './OrderDisabled';
import { OrderNavigation } from './OrderNavigation';
import { SecurePayment } from './SecurePayment';

export function OrderLayout() {
  const { isFeatureFlagsLoading, isOrderingEnabled } = useFeatureFlag();
  const { isCardLoading } = useCreditCard();

  const selectedServiceId = useOrderState((state) => state.selectedServiceId);
  const currentStep = useOrderState((state) => state.step);

  const content = useMemo(() => {
    if (isFeatureFlagsLoading || isCardLoading) {
      return (
        <Stack flex="1 1 auto" height="100%" alignItems="center" justifyContent="center">
          <CircularProgress size={64} />
        </Stack>
      );
    }

    if (!isOrderingEnabled) {
      return <OrderDisabled />;
    }

    return (
      <Stack
        pr={{ xs: 0, xl: selectedServiceId === 'vpn' || currentStep === 'details' ? 0 : 'var(--OrderSidebar2-width)' }}
        sx={(theme) => ({
          '--OrderContent-paddingBlock': 'calc(var(--OrderNavigation-height) + 32px)',
          '--OrderContent-paddingInline': { xs: '16px', lg: '0px' },
          '--OrderContent-padding':
            'var(--OrderContent-paddingBlock) var(--OrderContent-paddingInline) calc(var(--OrderContent-paddingBlock) + 64px) var(--OrderContent-paddingInline)',
          '--OrderContent-maxWidth': '800px',

          transition: theme.transitions.create('padding-right', { duration: 225 }),
        })}
      >
        <Stack
          p="var(--OrderContent-padding)"
          m="var(--OrderContent-margin)"
          width="var(--OrderContent-width)"
          maxWidth="var(--OrderContent-maxWidth)"
        >
          <Stack spacing={8} pb={{ xs: 8, xl: 0 }}>
            <Outlet />

            <SecurePayment />
          </Stack>
        </Stack>
      </Stack>
    );
  }, [currentStep, isCardLoading, isFeatureFlagsLoading, isOrderingEnabled, selectedServiceId]);

  return (
    <Stack
      component="main"
      flex="1 1 auto"
      minHeight="100%"
      bgcolor="var(--mui-palette-background-default)"
      sx={(theme) => ({ transition: theme.transitions.create('all', { duration: 150 }) })}
    >
      <OrderNavigation />

      {content}
    </Stack>
  );
}
