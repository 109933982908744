import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { posthogEvents } from 'constants/posthogEvents';
import { useHideModal } from 'modules/common/Modal';
import { useDisableProxyAutoExtendMutation, useEnableProxyAutoExtendMutation, useGetProxyQuery } from 'store/api';
import { ToastManager } from 'utils/toast';

import { ProxyAutoExtendForm, type ProxyAutoExtendValues } from './ProxyAutoExtendForm';

type HandleSubmit = FormikConfig<ProxyAutoExtendValues>['onSubmit'];

export type ProxyAutoExtendModalProps = {
  proxyId: number;
};

export function ProxyAutoExtendModal({ proxyId }: ProxyAutoExtendModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [enableAutoExtend] = useEnableProxyAutoExtendMutation();
  const [disableAutoExtend] = useDisableProxyAutoExtendMutation();
  const hideModal = useHideModal();

  const { data: proxy, isLoading } = useGetProxyQuery(proxyId);

  const initialValues = useMemo<ProxyAutoExtendValues>(() => {
    return { isAutoExtendEnabled: proxy?.isAutoExtendEnabled ?? false };
  }, [proxy?.isAutoExtendEnabled]);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ isAutoExtendEnabled }) => {
      try {
        if (isAutoExtendEnabled) {
          await enableAutoExtend({ proxyId }).unwrap();
          posthog?.capture(posthogEvents.proxy.autoExtend.enabled.success, { proxyId });

          ToastManager.success(t('proxies.modals.autoExtend.success.enabled'));
        } else {
          await disableAutoExtend(proxyId).unwrap();
          posthog?.capture(posthogEvents.proxy.autoExtend.disabled.success, { proxyId });

          ToastManager.success(t('proxies.modals.autoExtend.success.disabled'));
        }

        hideModal();
      } catch {
        if (isAutoExtendEnabled) {
          posthog?.capture(posthogEvents.proxy.autoExtend.enabled.failed, { proxyId });
        } else {
          posthog?.capture(posthogEvents.proxy.autoExtend.disabled.failed, { proxyId });
        }
      }
    },
    [disableAutoExtend, enableAutoExtend, hideModal, posthog, proxyId, t],
  );

  return (
    <Formik<ProxyAutoExtendValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <ProxyAutoExtendForm isLoading={isLoading} />
    </Formik>
  );
}
