import { useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikAutocomplete } from 'components/form-partials/formik';
import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';
import { MultiUpdatesAccordion } from 'modules/proxies/components';
import { useGetChangeAuthenticationTypeOptionsQuery } from 'store/api';
import { AuthenticationType } from 'store/proxies/types';
import type { Option } from 'types';

import { IPWhitelistForm } from './IPWhitelistForm';

export type BulkChangeAuthenticationMethodValues = {
  type: AuthenticationType;
  ipWhitelist: string[];
};

type BulkChangeAuthenticationMethodFormProps = {
  // TODO: Uncomment me to enable all proxies
  // isAllSelected: boolean;
  proxyIds: number[];
};

export function BulkChangeAuthenticationMethodForm({ proxyIds }: BulkChangeAuthenticationMethodFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { isSubmitting, isValid, values, handleSubmit } = useFormikContext<BulkChangeAuthenticationMethodValues>();

  const { data, isLoading } = useGetChangeAuthenticationTypeOptionsQuery(proxyIds[0]);

  const options = useMemo<Array<Option<AuthenticationType>>>(() => {
    return data?.availableAuthenticationTypes.map((value) => ({ value, label: t(`common:form.${value}`) })) ?? [];
  }, [data?.availableAuthenticationTypes, t]);

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid}>
        {t('common:buttons.change')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.changeAuthMethod.title')}
      subtitle={t('proxies.modals.changeAuthMethod.subtitle')}
      actions={actions}
    >
      <Stack direction="column" spacing={4}>
        {/* TODO: Do not display that when `isAllSelected` */}
        {proxyIds.length > 1 && <MultiUpdatesAccordion proxyIds={proxyIds} />}

        <FormikAutocomplete
          disableClearable
          options={options}
          name="type"
          helperText={
            values.type === AuthenticationType.USERNAME_PASSWORD && !isLoading
              ? t('common:form.helpers.credentials')
              : undefined
          }
        />

        <Collapse in={values.type === AuthenticationType.IP_WHITELIST && !isLoading} unmountOnExit>
          <IPWhitelistForm proxyIds={proxyIds} />
        </Collapse>
      </Stack>
    </ModalContainer>
  );
}
