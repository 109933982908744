import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { ModalContainer } from 'components/modals';
import { Stripe } from 'modules/billing/Stripe';
import { useHideModal } from 'modules/common/Modal';

import { AddCreditCardForm } from './AddCreditCardForm';

export type AddCreditCardModalProps = {
  clientSecret: string;
};

export function AddCreditCardModal({ clientSecret }: AddCreditCardModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const onModalClose = useCallback(
    (_: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick') return;

      return hideModal();
    },
    [hideModal],
  );

  return (
    <Stripe mode="setup" clientSecret={clientSecret}>
      <ModalContainer
        title={t('order.modals.creditCard.title')}
        subtitle={t('order.modals.creditCard.subtitle')}
        disableEscapeKeyDown
        onClose={onModalClose}
      >
        <AddCreditCardForm />
      </ModalContainer>
    </Stripe>
  );
}
