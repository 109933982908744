import { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikInput } from 'components/form-partials/formik';
import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';

export type CouponCodeValues = {
  couponCode: string;
};

export function CouponCodeForm() {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { isSubmitting, isValid, handleSubmit } = useFormikContext<CouponCodeValues>();

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="activate" type="submit" loading={isSubmitting} fullWidth disabled={!isValid}>
        {t('common:buttons.activate')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('order.modals.coupon.title')}
      subtitle={t('order.modals.coupon.subtitle')}
      actions={actions}
    >
      <FormikInput name="couponCode" placeholder={t('common:form.couponCode')} fullWidth />
    </ModalContainer>
  );
}
