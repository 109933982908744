import { useCallback, useMemo, useState } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useSession } from 'modules/accounts/hooks';
import { useHideModal } from 'modules/common/Modal';
import type { ProxyBulkActionModel } from 'modules/proxies/types';
import { useExtendProxyBandwidthMutation } from 'store/api';
import { ToastManager } from 'utils/toast';

import { ExtendBandwidthForm, type ExtendBandwidthValues } from './ExtendBandwidthForm';

type HandleSubmit = FormikConfig<ExtendBandwidthValues>['onSubmit'];

export type ProxyExtendBandwidthModalProps = {
  proxy: ProxyBulkActionModel;
};

export function ProxyExtendBandwidthModal({ proxy }: ProxyExtendBandwidthModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const hideModal = useHideModal();
  const { session } = useSession();
  const [singleItemPrice, setSingleItemPrice] = useState(0);

  const [extendProxyBandwidth] = useExtendProxyBandwidthMutation();

  const initialValues = useMemo<ExtendBandwidthValues>(() => {
    return { bandwidth: 1, couponCode: null };
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ bandwidth, couponCode }) => {
      try {
        await extendProxyBandwidth({ proxyId: proxy.id, bandwidth, couponCode: couponCode ?? undefined }).unwrap();

        GA_EVENTS.proxyBandwidthPurchased({
          userId: session?.userId,
          value: singleItemPrice,
          networkType: proxy.networkType,
          proxy_id: proxy.id,
          bandwidth,
        });

        posthog?.capture(posthogEvents.proxy.extendBandwidth.success, {
          proxyId: proxy.id,
          bandwidth,
          couponCode,
        });

        ToastManager.success(t('proxies.modals.extendBandwidth.success'));

        hideModal();
      } catch {
        posthog?.capture(posthogEvents.proxy.extendBandwidth.failed, { proxyId: proxy.id, bandwidth });
      }
    },
    [extendProxyBandwidth, hideModal, posthog, proxy.id, proxy.networkType, session?.userId, singleItemPrice, t],
  );

  return (
    <Formik<ExtendBandwidthValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <ExtendBandwidthForm proxy={proxy} setPriceForSingle={setSingleItemPrice} />
    </Formik>
  );
}
