import { useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

import { ModalContainer } from 'components/modals';
import { useRouter } from 'hooks/useRouter';
import { useHideModal } from 'modules/common/Modal';
import { paths } from 'paths';
import { useAppDispatch } from 'store';
import { api } from 'store/api';
import { OperationStatus } from 'store/proxies/types';
import type { Nullable } from 'types';
import { PercentageFormatter } from 'utils/currency';
import { ToastManager } from 'utils/toast';

import { useGetOperation } from './hooks';
import type { MultiStepStatusModalType } from './types';

type StatusProps = {
  operationId: Nullable<string>;
  type: MultiStepStatusModalType;
  wasEnabled?: boolean;
};

export function Status({ operationId, type, wasEnabled }: StatusProps) {
  const { t } = useTranslation();
  const { data } = useGetOperation(operationId);
  const { pathname } = useLocation();
  const hideModal = useHideModal();
  const router = useRouter();
  const dispatch = useAppDispatch();

  const percentage = useMemo(() => {
    if (!data) return null;

    const { total, processed } = data;

    return PercentageFormatter.format(processed / total);
  }, [data]);

  const successMessage = useMemo(() => {
    if (type === 'autoExtend') {
      return t(`proxies.modals.${type}.success.${wasEnabled ? 'enabled' : 'disabled'}`);
    }

    return t(`proxies.modals.${type}.success`);
  }, [t, type, wasEnabled]);

  const { title, subtitle } = useMemo(() => {
    if (type === 'autoExtend') {
      return {
        title: t(`proxies.modals.${type}.status.${wasEnabled ? 'enabling' : 'disabling'}.title`),
        subtitle: t(`proxies.modals.${type}.status.${wasEnabled ? 'enabling' : 'disabling'}.subtitle`),
      };
    }

    return {
      title: t(`proxies.modals.${type}.status.title`),
      subtitle: t(`proxies.modals.${type}.status.subtitle`),
    };
  }, [t, type, wasEnabled]);

  const shouldRedirectOnSuccess = useMemo(() => {
    return !!matchPath(`${paths.proxies.operation.index}/:operationId`, pathname);
  }, [pathname]);

  useEffect(() => {
    if (!data || !operationId) return;

    const { status } = data;

    if ([OperationStatus.IN_PROGRESS].includes(status)) return;

    if ([OperationStatus.FAILURE, OperationStatus.PARTIAL_SUCCESS].includes(status)) {
      hideModal();

      return router.push(paths.proxies.operation.id(operationId));
    }

    dispatch(
      api.util.invalidateTags([
        'Proxy',
        'Proxy-Overview',
        'Proxy-Admin-Details',
        'Proxy-Event',
        'Proxy-Changelog',
        'Account-Balance',
      ]),
    );

    ToastManager.success(successMessage);
    hideModal();

    if (shouldRedirectOnSuccess) {
      return router.replace(paths.proxies.index);
    }
  }, [data, dispatch, hideModal, operationId, router, shouldRedirectOnSuccess, successMessage]);

  return (
    <ModalContainer>
      <Stack spacing={4} flex="1 1 auto" alignItems="center" justifyContent="center">
        <Box display="inline-flex" position="relative">
          <CircularProgress size={80} />
          <Stack alignItems="center" justifyContent="center" top={0} left={0} right={0} bottom={0} position="absolute">
            {percentage && (
              <Typography variant="headline-2" component="div" color="var(--mui-palette-primary-main)">
                {percentage}
              </Typography>
            )}
          </Stack>
        </Box>

        <Stack spacing={1} alignItems="center" textAlign="center">
          <Typography variant="headline-2">{title}</Typography>

          <Typography variant="body-1" color="var(--mui-palette-neutral-500)">
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
