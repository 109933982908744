import { useEffect, useMemo, useRef } from 'react';

import { useGetBulkOperationQuery } from 'store/api';
import { OperationStatus } from 'store/proxies/types';
import type { Nullable } from 'types';

export function useGetOperation(operationId: Nullable<string>) {
  const skip = useRef(false);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const query = useGetBulkOperationQuery(operationId!, { pollingInterval: 1_000, skip: skip.current });

  useEffect(() => {
    if (!operationId) {
      skip.current = true;

      return;
    }

    const { data } = query;

    if (!data) return;

    if (![OperationStatus.FAILURE, OperationStatus.PARTIAL_SUCCESS, OperationStatus.SUCCESS].includes(data.status)) {
      return;
    }

    skip.current = true;
  }, [operationId, query]);

  return useMemo(() => query, [query]);
}
