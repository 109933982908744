import { useMemo, type ComponentPropsWithoutRef, type CSSProperties, type ElementType, type ReactNode } from 'react';

import Modal, { type ModalProps } from '@mui/material/Modal';
import Stack, { type StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useHideModal } from 'modules/common/Modal';

type ModalContainerProps<C extends ElementType = 'div'> = Omit<ModalProps, 'title' | 'open' | 'children'> & {
  component?: C;
  containerProps?: ComponentPropsWithoutRef<C>;

  title?: ReactNode;
  subtitle?: ReactNode;
  helperText?: ReactNode;

  maxWidth?: CSSProperties['maxWidth'];

  children?: ReactNode;
  actions?: ReactNode[];
  actionsDirection?: StackProps['direction'];
  footer?: ReactNode;
  scrollable?: boolean;
};

export function ModalContainer<C extends ElementType = 'div'>({
  component,
  containerProps,

  title,
  subtitle,
  helperText,

  maxWidth = 'var(--ModalContainer-maxWidth)',

  children,
  actions,
  actionsDirection = 'row',
  footer,
  scrollable,
  ...props
}: ModalContainerProps<C>) {
  const hideModal = useHideModal();

  const ContainerComponent = useMemo(() => component ?? 'div', [component]);

  return (
    <Modal open onClose={() => hideModal()} {...props}>
      <Stack
        spacing={4}
        component={ContainerComponent}
        width="100%"
        maxWidth={maxWidth}
        maxHeight="var(--ModalContainer-maxHeight)"
        bgcolor="var(--ModalContainer-background)"
        borderRadius="var(--ModalContainer-radius)"
        paddingInline="var(--ModalContainer-paddingInline)"
        paddingBlock="var(--ModalContainer-paddingBlock)"
        boxShadow="var(--ModalContainer-boxShadow)"
        sx={{ ...(!scrollable && { overflowX: 'hidden', overflowY: 'auto' }) }}
        {...containerProps}
      >
        {(title || subtitle) && (
          <Stack spacing={1} alignItems="center" textAlign="center">
            {title && (
              <Typography variant="headline-1" component="p">
                {title}
              </Typography>
            )}

            {subtitle && (
              <Typography variant="body-1" component="p" color="var(--mui-palette-neutral-500)">
                {subtitle}
              </Typography>
            )}

            {helperText}
          </Stack>
        )}

        {children && (
          <Stack
            maxHeight="var(--ModalContent-maxHeight)"
            sx={{ ...(scrollable && { overflowX: 'hidden', overflowY: 'auto' }) }}
          >
            {children}
          </Stack>
        )}

        {(actions || footer) && (
          <Stack spacing={2}>
            {actions && (
              <Stack direction={actionsDirection} spacing={2}>
                {actions.map((actionItem) => actionItem)}
              </Stack>
            )}

            {footer}
          </Stack>
        )}
      </Stack>
    </Modal>
  );
}
