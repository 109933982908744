import { type Context, useContext } from 'react';

import capitalize from 'lodash/capitalize';

import type { Optional } from 'types';

function buildErrorMessage(name: string) {
  return `Cannot use "use${capitalize(name.toLowerCase())}" outside the "<${capitalize(name.toLowerCase())}Provider" />" context.`;
}

export function createUseContext<T>(context: Context<Optional<T>>) {
  return () => {
    const ctx = useContext(context);

    if (ctx === undefined) {
      throw new Error(buildErrorMessage(context.name));
    }

    return ctx;
  };
}
