/* eslint-disable react/no-unescaped-entities */
import { useMemo, useState } from 'react';

import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikInput } from 'components/form-partials/formik';
import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';
import type { EnableSoftwareTwoFactorModel } from 'store/accounts/models';
import type { Nullable } from 'types';

export type SoftwareTwoFactorAuthenticationValues = {
  code: string;
};

type SoftwareTwoFactorAuthenticationFormProps = {
  configuration: Nullable<EnableSoftwareTwoFactorModel>;
  isLoading: boolean;
};

export function SoftwareTwoFactorAuthenticationForm({
  configuration,
  isLoading,
}: SoftwareTwoFactorAuthenticationFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { isSubmitting, isValid, handleSubmit } = useFormikContext<SoftwareTwoFactorAuthenticationValues>();
  const [isCodeVisible, setCodeVisible] = useState(false);

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid}>
        {t('common:buttons.verify')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, t]);

  const footer = useMemo(() => {
    return (
      <Stack alignItems="center" spacing={2}>
        <Typography variant="caption" color="var(--mui-palette-neutral-500)" textAlign="center">
          <Trans i18nKey="account.modals.totp.codeHelper">
            If you can't use the QR code,
            <Link onClick={() => setCodeVisible((prev) => !prev)}>use the following instructions.</Link>
          </Trans>
        </Typography>

        <Collapse in={isCodeVisible} unmountOnExit>
          <Stack spacing={1} sx={{ span: { inlineSize: 300, overflowWrap: 'break-word' } }}>
            <Typography variant="caption" color="var(--mui-palette-neutral-500)">
              {t('account.modals.totp.enterCode')}
            </Typography>

            <Typography variant="caption" color="var(--mui-palette-neutral-500)">
              {!configuration && isLoading ? <Skeleton /> : configuration?.secret}
            </Typography>
          </Stack>
        </Collapse>
      </Stack>
    );
  }, [configuration, isCodeVisible, isLoading, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('account.modals.totp.title')}
      subtitle={t('account.modals.totp.subtitle')}
      actions={actions}
      footer={footer}
    >
      <Stack spacing={4} alignItems="center">
        {!configuration && isLoading ? (
          <Skeleton width={200} height={200} />
        ) : (
          <img src={configuration?.dataUri} alt="qr code" width={200} height={200} />
        )}

        <FormikInput fullWidth name="code" placeholder={t('common:form.enter', { value: t('common:form.code') })} />
      </Stack>
    </ModalContainer>
  );
}
