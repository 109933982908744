import Slide from '@mui/material/Slide';
import type { Components, Theme } from '@mui/material/styles';

export const MuiSnackbar: Components<Theme>['MuiSnackbar'] = {
  defaultProps: {
    TransitionComponent: Slide,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '--Snackbar-background': 'var(--mui-palette-common-white)',
      '--Snackbar-boxShadow': 'var(--mui-shadows-8)',
      '--Snackbar-borderRadius': '16px',
      '--Snackbar-bottom': '32px',
      '--Snackbar-minWidth': '480px',
      '--Snackbar-paddingBlock': '16px',
      '--Snackbar-paddingInline': '24px',
      '--Snackbar-zIndex': 1299,

      background: 'var(--Snackbar-background)',
      boxShadow: 'var(--Snackbar-boxShadow)',
      borderRadius: 'var(--Snackbar-borderRadius)',
      bottom: 'var(--Snackbar-bottom)',

      minWidth: 'var(--Snackbar-minWidth)',

      paddingBlock: 'var(--Snackbar-paddingBlock)',
      paddingInline: 'var(--Snackbar-paddingInline)',

      zIndex: 'var(--Snackbar-zIndex)',

      transition: theme.transitions.create('all', { duration: 225 }),

      [theme.breakpoints.down('md')]: {
        '--Snackbar-bottom': '16px',
        '--Snackbar-minWidth': 'calc(100% - 32px)',
      },
    }),
  },
};
