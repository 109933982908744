import { useCallback, useMemo, useRef, useState } from 'react';

import dayjs from 'dayjs';

import { useInterval } from './useInterval';

type CountdownOptions = {
  delay: number;
  intervalMs?: number;
  countStop?: number;
};

export function useCountdown({ delay, intervalMs = 1000, countStop = 0 }: CountdownOptions) {
  const targetDate = useRef(new Date(dayjs().add(delay, 'seconds').toDate()).getTime());
  const [count, setCount] = useState(targetDate.current - new Date().getTime());

  const [isCountdownRunning, setIsCountRunning] = useState(false);

  const startCountdown = useCallback(() => {
    targetDate.current = new Date(dayjs().add(delay, 'seconds').toDate()).getTime();
    setCount(targetDate.current - new Date().getTime());
    setIsCountRunning(true);
  }, [delay]);

  const stopCountdown = () => setIsCountRunning(false);

  const countdownCallback = useCallback(() => {
    const result = targetDate.current - new Date().getTime();

    if (result <= countStop) {
      return setIsCountRunning(false);
    }

    setCount(result);
  }, [countStop, targetDate]);

  useInterval(countdownCallback, isCountdownRunning ? intervalMs : undefined);

  const seconds = useMemo(() => Math.floor(count / 1000), [count]);

  const isFinished = useMemo(() => seconds === countStop, [seconds, countStop]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => [seconds, isFinished, { startCountdown, stopCountdown }] as const, [seconds]);
}
