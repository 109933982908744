import type { ServiceID } from './types';

export const SERVICE_WEIGHTS: Record<ServiceID, number> = {
  'static-residential-ipv4': 1,
  'static-datacenter-ipv4': 2,
  'static-datacenter-ipv6': 3,
  'rotating-residential': 4,
  'rotating-mobile': 5,
  'dedicated-mobile': 6,
  vpn: 7,
};
