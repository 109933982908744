import { type MouseEvent, forwardRef, useCallback, useMemo, useState } from 'react';

import LoadingButton, {
  type ButtonTypeMap as LoadingButtonTypeMap,
  type ButtonProps as MuiLoadingButtonProps,
} from '@mui/material/Button';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

import { Icon, type IconSize, isIconName, type IconName, type IconProps } from 'components/Icon';
import type { LiteralNodeUnion } from 'types';

type ButtonSlotProps = {
  startIcon?: Partial<IconProps>;
  endIcon?: Partial<IconProps>;
};

export type ButtonProps = Omit<MuiLoadingButtonProps, 'startIcon' | 'endIcon'> & {
  startIcon?: LiteralNodeUnion<IconName>;
  endIcon?: LiteralNodeUnion<IconName>;

  slotProps?: ButtonSlotProps;
  iconOnly?: boolean;

  debounceTime?: number;
};

type ButtonDimensions = {
  iconSize: IconSize;
  padding: string;
};

export const Button: OverridableComponent<LoadingButtonTypeMap<ButtonProps>> = forwardRef<
  HTMLButtonElement,
  ButtonProps
>(
  (
    {
      children,
      endIcon,
      startIcon,
      size = 'large',
      variant = 'contained',
      slotProps,
      onClick,
      iconOnly,
      debounceTime = 500,
      ...props
    },
    ref,
  ) => {
    const [debounceDisable, setDebounceDisable] = useState(false);

    // globaly prevent double clicks
    const onButtonClick = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        if (debounceDisable) return;

        setDebounceDisable(true);
        onClick?.(e);

        setTimeout(() => {
          setDebounceDisable(false);
        }, debounceTime);
      },
      [debounceDisable, debounceTime, onClick],
    );

    const { iconSize, padding } = useMemo<ButtonDimensions>(() => {
      if (size === 'large') return { iconSize: 'large', padding: '12px' };

      return { iconSize: 'small', padding: size === 'medium' ? '10px' : '6px' };
    }, [size]);

    const start = useMemo(() => {
      if (isIconName(startIcon)) {
        return <Icon name={startIcon} size={slotProps?.startIcon?.size ?? iconSize} {...slotProps?.startIcon} />;
      }

      if (startIcon) return startIcon;

      return null;
    }, [iconSize, slotProps?.startIcon, startIcon]);

    const end = useMemo(() => {
      if (isIconName(endIcon)) {
        return <Icon name={endIcon} size={slotProps?.endIcon?.size ?? iconSize} {...slotProps?.endIcon} />;
      }

      if (endIcon) return endIcon;

      return null;
    }, [iconSize, slotProps?.endIcon, endIcon]);

    return (
      <LoadingButton
        ref={ref}
        startIcon={start}
        endIcon={end}
        onClick={onButtonClick}
        size={size}
        variant={variant}
        sx={{
          ...props.sx,
          ...(iconOnly && {
            '--Button-icon-gap': '0px',
            '--Button-paddingBlock': padding,
            '--Button-paddingInline': padding,
          }),
        }}
        {...props}
      >
        {children}
      </LoadingButton>
    );
  },
) as OverridableComponent<LoadingButtonTypeMap<ButtonProps>>;
