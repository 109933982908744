import { useCallback, useMemo, useState } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';

import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useSession } from 'modules/accounts/hooks';
import { useMultiStepStatusModalContext, withMultiStepModalContext } from 'modules/proxies/components';
import type { ProxyBulkActionModel } from 'modules/proxies/types';
import { useBulkExtendProxyPeriodMutation } from 'store/api';

import { BulkExtendPeriodForm, type BulkExtendPeriodValues } from './BulkExtendPeriodForm';

type HandleSubmit = FormikConfig<BulkExtendPeriodValues>['onSubmit'];

export type ProxyBulkExtendPeriodModalProps = {
  // TODO: Uncomment me to enable all proxies
  // isAllSelected: boolean;
  proxies: ProxyBulkActionModel[];
};

function ProxyBulkExtendPeriodModalComponent({ proxies = [] }: ProxyBulkExtendPeriodModalProps) {
  const posthog = usePostHog();
  const { setOperationId, setStep } = useMultiStepStatusModalContext();

  const { session } = useSession();
  const [singleItemPrice, setSingleItemPrice] = useState(0);

  const [bulkExtendProxyPeriod] = useBulkExtendProxyPeriodMutation();

  const initialValues = useMemo<BulkExtendPeriodValues>(() => {
    return { periodInMonths: 1, couponCode: null };
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ periodInMonths, couponCode }) => {
      const proxyIds = proxies.map(({ id }) => id);

      try {
        // TODO: Send an empty array on `isAllSelected`
        const { operationId } = await bulkExtendProxyPeriod({
          ids: proxyIds,
          periodInMonths,
          couponCode: couponCode ?? undefined,
        }).unwrap();

        proxyIds.forEach((proxyId) => {
          const foundProxy = proxies.find(({ id }) => id === proxyId);

          GA_EVENTS.proxyPeriodExtended({
            userId: session?.userId,
            value: singleItemPrice,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            networkType: foundProxy!.networkType,
            proxy_id: proxyId,
            period: periodInMonths,
          });
        });

        posthog?.capture(posthogEvents.proxy.bulk.extendPeriod.success, {
          proxyIds,
          total: proxyIds.length,
          couponCode,
        });

        setOperationId(operationId);
        setStep('status');
      } catch {
        posthog?.capture(posthogEvents.proxy.bulk.extendPeriod.failed, {
          proxyIds,
          total: proxyIds.length,
        });
      }
    },
    [bulkExtendProxyPeriod, posthog, proxies, session?.userId, setOperationId, setStep, singleItemPrice],
  );

  return (
    <Formik<BulkExtendPeriodValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <BulkExtendPeriodForm proxies={proxies} setPriceForSingle={setSingleItemPrice} />
    </Formik>
  );
}

export const ProxyBulkExtendPeriodModal = withMultiStepModalContext<ProxyBulkExtendPeriodModalProps>(
  ProxyBulkExtendPeriodModalComponent,
  'extendPeriod',
);
