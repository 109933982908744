import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { posthogEvents } from 'constants/posthogEvents';
import { useHideModal } from 'modules/common/Modal';
import { useChangeProxyPortMutation } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import { ChangePortForm, type ChangePortValues } from './ChangePortForm';

type HandleSubmit = FormikConfig<ChangePortValues>['onSubmit'];

export type ProxyAdminChangePortModalProps = {
  proxy: ProxyModel;
};

export function ProxyAdminChangePortModal({ proxy }: ProxyAdminChangePortModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const hideModal = useHideModal();

  const [changeProxyPort] = useChangeProxyPortMutation();

  const initialValues = useMemo<ChangePortValues>(() => {
    const { httpPort, httpsPort, socks5Port } = proxy.connection;

    return {
      port: socks5Port || httpsPort || httpPort || undefined,
    };
  }, [proxy]);

  const validationSchema = useMemo<Yup.Schema<ChangePortValues>>(() => {
    return Yup.object().shape({
      port: Yup.number().optional().min(1).max(65535),
      reason: Yup.string().optional(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ port, reason }, { setErrors, setSubmitting }) => {
      try {
        await changeProxyPort({ port: port || undefined, reason, proxyId: proxy.id }).unwrap();

        posthog?.capture(posthogEvents.proxy.port.success, { proxyId: proxy.id, port, reason });

        ToastManager.success(t('proxies.modals.changePort.success'));
        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        posthog?.capture(posthogEvents.proxy.port.failed, { proxyId: proxy.id, port, reason });

        setSubmitting(false);
      }
    },
    [changeProxyPort, hideModal, posthog, proxy.id, t],
  );

  return (
    <Formik<ChangePortValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <ChangePortForm />
    </Formik>
  );
}
