import { useCallback, useEffect, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { posthogEvents } from 'constants/posthogEvents';
import { useHideModal } from 'modules/common/Modal';
import type { ProxyBulkActionModel } from 'modules/proxies/types';
import { useGetChangeProtocolOptionsQuery, useUpdateProxyProtocolMutation } from 'store/api';
import { Protocol } from 'store/proxies/types';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import { ChangeProxyProtocolForm, type ChangeProxyProtocolValues } from './ChangeProxyProtocolForm';

type HandleSubmit = FormikConfig<ChangeProxyProtocolValues>['onSubmit'];

export type ProxyChangeProtocolModalProps = {
  proxy: ProxyBulkActionModel;
};

export function ProxyChangeProtocolModal({ proxy }: ProxyChangeProtocolModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();

  const [updateProtocol] = useUpdateProxyProtocolMutation();
  const { data, refetch, isUninitialized } = useGetChangeProtocolOptionsQuery(proxy.id);

  const hideModal = useHideModal();

  const initialValues = useMemo<ChangeProxyProtocolValues>(() => {
    if (!data) return { protocol: null };

    return { protocol: data.currentType };
  }, [data]);

  const validationSchema = useMemo<Yup.Schema<ChangeProxyProtocolValues>>(() => {
    return Yup.object().shape({
      protocol: Yup.string().oneOf(Object.values(Protocol)).required(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ protocol }, { setErrors, setSubmitting }) => {
      if (!protocol) return;

      try {
        await updateProtocol({ newType: protocol, proxyId: proxy.id }).unwrap();

        posthog?.capture(posthogEvents.proxy.changeProtocol.success, { proxyId: proxy.id, protocol });

        ToastManager.success(t('proxies.modals.changeProtocol.success'));
        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        posthog?.capture(posthogEvents.proxy.changeProtocol.failed, { proxyId: proxy.id, protocol });

        setSubmitting(false);
      }
    },
    [updateProtocol, proxy.id, posthog, t, hideModal],
  );

  useEffect(() => {
    if (isUninitialized) return;

    refetch();
  }, [isUninitialized, refetch]);

  return (
    <Formik<ChangeProxyProtocolValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      validateOnChange
    >
      <ChangeProxyProtocolForm availableTypes={data?.availableTypes} />
    </Formik>
  );
}
