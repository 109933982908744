import { useCallback, useEffect, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { posthogEvents } from 'constants/posthogEvents';
import { useHideModal } from 'modules/common/Modal';
import { withMultiStepModalContext } from 'modules/proxies/components';
import type { ProxyBulkActionModel } from 'modules/proxies/types';
import { useBulkAutoExtendProxyMutation, useGetBulkAutoExtendSettingsQuery } from 'store/api';
import type { BulkAutoExtendProxyPayload } from 'store/proxies/payloads';
import { ToastManager } from 'utils/toast';

import { BulkAutoExtendForm, type BulkAutoExtendValues } from './BulkAutoExtendForm';

type HandleSubmit = FormikConfig<BulkAutoExtendValues>['onSubmit'];

export type ProxyBulkAutoExtendModalProps = {
  // TODO: Uncomment me to enable all proxies
  // isAllSelected: boolean;
  proxies: ProxyBulkActionModel[];
};

function ProxyBulkAutoExtendModalComponent({ proxies }: ProxyBulkAutoExtendModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const posthog = usePostHog();
  // TODO: Uncomment me
  // const { setOperationId, setStep, setWasEnabled } = useMultiStepStatusModalContext();

  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);

  // TODO: Send an empty array on `isAllSelected`
  const { data, isLoading, isUninitialized, refetch } = useGetBulkAutoExtendSettingsQuery(proxyIds);
  const [autoExtendBulk] = useBulkAutoExtendProxyMutation();

  const isBandwidthRequired = useMemo(() => !!data?.isBandwidthRequired, [data]);

  const initialValues = useMemo<BulkAutoExtendValues>(() => {
    return { isEnabled: !!data?.isAutoExtendEnabled, bandwidth: 0 };
  }, [data]);

  const getPayload = useCallback(
    ({ bandwidth, isEnabled }: BulkAutoExtendValues): BulkAutoExtendProxyPayload => {
      if (!isEnabled) {
        // TODO: Send an empty array on `isAllSelected`
        return { isEnabled, ids: proxyIds };
      }

      // TODO: Send an empty array on `isAllSelected`
      return { ids: proxyIds, isEnabled, bandwidth: isBandwidthRequired ? bandwidth : 0 };
    },
    [isBandwidthRequired, proxyIds],
  );

  const onSubmit = useCallback<HandleSubmit>(
    async (values) => {
      try {
        // TODO: Add operationId,
        await autoExtendBulk(getPayload(values)).unwrap();

        posthog?.capture(posthogEvents.proxy.bulk.autoExtend.success, {
          proxyIds,
          isEnabled: values.isEnabled,
          bandwidth: isBandwidthRequired ? values.bandwidth : undefined,
        });

        ToastManager.success(t(`proxies.modals.autoExtend.success.${values.isEnabled ? 'enabled' : 'disabled'}`));

        hideModal();

        // TODO: Add setter for operationId and step, and wasEnabled from context
        // setOperationId(operationId);
        // setWasEnabled(values.isEnabled);
        // setStep('status');
      } catch {
        posthog?.capture(posthogEvents.proxy.bulk.autoExtend.failed, {
          proxyIds,
          isEnabled: values.isEnabled,
          bandwidth: isBandwidthRequired ? values.bandwidth : undefined,
        });
      }
    },
    [autoExtendBulk, getPayload, hideModal, isBandwidthRequired, posthog, proxyIds, t],
  );

  useEffect(() => {
    if (!isUninitialized) {
      refetch();
    }
  }, [isUninitialized, refetch]);

  return (
    <Formik<BulkAutoExtendValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <BulkAutoExtendForm proxyIds={proxyIds} isBandwidthRequired={isBandwidthRequired} isLoading={isLoading} />
    </Formik>
  );
}

export const ProxyBulkAutoExtendModal = withMultiStepModalContext<ProxyBulkAutoExtendModalProps>(
  ProxyBulkAutoExtendModalComponent,
  'autoExtend',
);
