import { useCallback, useMemo, useState } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';

import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useSession } from 'modules/accounts/hooks';
import { useMultiStepStatusModalContext, withMultiStepModalContext } from 'modules/proxies/components';
import type { ProxyBulkActionModel } from 'modules/proxies/types';
import { useBulkExtendProxyBandwidthMutation } from 'store/api';

import { BulkExtendBandwidthForm, type BulkExtendBandwidthValues } from './BulkExtendBandwidthForm';

type HandleSubmit = FormikConfig<BulkExtendBandwidthValues>['onSubmit'];

export type ProxyBulkExtendBandwidthModalProps = {
  // TODO: Uncomment me to enable all proxies
  // isAllSelected: boolean;
  proxies: ProxyBulkActionModel[];
};

function ProxyBulkExtendBandwidthModalComponent({ proxies = [] }: ProxyBulkExtendBandwidthModalProps) {
  const posthog = usePostHog();
  const { setOperationId, setStep } = useMultiStepStatusModalContext();

  const { session } = useSession();
  const [singleItemPrice, setSingleItemPrice] = useState(0);

  const [bulkExtendProxyBandwidth] = useBulkExtendProxyBandwidthMutation();

  const initialValues = useMemo<BulkExtendBandwidthValues>(() => {
    return { bandwidth: 1, couponCode: null };
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ bandwidth, couponCode }) => {
      const proxyIds = proxies.map(({ id }) => id);

      try {
        // TODO: Send an empty array on `isAllSelected`
        const { operationId } = await bulkExtendProxyBandwidth({
          ids: proxyIds,
          trafficInGB: bandwidth,
          couponCode: couponCode ?? undefined,
        }).unwrap();

        proxyIds.forEach((proxyId) => {
          const foundProxy = proxies.find(({ id }) => id === proxyId);

          GA_EVENTS.proxyBandwidthPurchased({
            userId: session?.userId,
            value: singleItemPrice,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            networkType: foundProxy!.networkType,
            proxy_id: proxyId,
            bandwidth,
          });
        });

        posthog?.capture(posthogEvents.proxy.bulk.extendBandwidth.success, {
          proxyIds,
          total: proxyIds.length,
          couponCode,
        });

        setOperationId(operationId);
        setStep('status');
      } catch {
        posthog?.capture(posthogEvents.proxy.bulk.extendBandwidth.failed, {
          proxyIds,
          total: proxyIds.length,
        });
      }
    },
    [bulkExtendProxyBandwidth, posthog, proxies, session?.userId, setOperationId, setStep, singleItemPrice],
  );

  return (
    <Formik<BulkExtendBandwidthValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <BulkExtendBandwidthForm proxies={proxies} setPriceForSingle={setSingleItemPrice} />
    </Formik>
  );
}

export const ProxyBulkExtendBandwidthModal = withMultiStepModalContext<ProxyBulkExtendBandwidthModalProps>(
  ProxyBulkExtendBandwidthModalComponent,
  'extendBandwidth',
);
