import type { OrderSetupValues } from 'modules/orders/types';

export const DEFAULT_SETUP_VALUES: OrderSetupValues = {
  serviceId: 'static-residential-ipv4',
  planId: 'standard',
  country: undefined,
  isp: undefined,
  package: '50',
  period: null,
  quantity: 1,
  bandwidth: 5,
  isAutoExtendEnabled: true,
  autoExtendBandwidth: 10,
  additionalBandwidth: 1,
};
