import { useEffect, useMemo, useState } from 'react';

import snsWebSdk from '@sumsub/websdk';
import { useTranslation } from 'react-i18next';

import { useSession } from 'modules/accounts/hooks';
import { useFeatureFlag } from 'modules/common/hooks';
import { useGetIdentityVerificationTokenQuery } from 'store/api';

export function useIdentityVerification() {
  const { i18n } = useTranslation();

  const [isServiceVisible, setServiceVisible] = useState(false);

  const { isIdentityVerificationEnabled } = useFeatureFlag();
  const { session } = useSession();
  const { data: verification, refetch } = useGetIdentityVerificationTokenQuery(undefined, {
    skip: !isIdentityVerificationEnabled || !isServiceVisible,
  });

  useEffect(() => {
    if (!verification?.token || !session?.email) return;

    const snsWebSdkInstance = snsWebSdk
      .init(verification.token, async () => {
        const { data } = await refetch();

        return data?.token ?? verification.token;
      })
      .withConf({ lang: i18n.language ?? 'en', email: session.email })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .build();

    snsWebSdkInstance.launch('#sumsub-websdk-container');
  }, [i18n.language, refetch, session, verification]);

  return useMemo(() => ({ isServiceVisible, setServiceVisible }), [isServiceVisible]);
}
