import type { ChipProps } from '@mui/material/Chip';

import { ProxyStatus, type ProxyModel } from 'store/proxies/models';
import { ProxySubnetStatus } from 'store/proxies/types';

export type StatusVariant = {
  color: ChipProps['color'];
  variant: ChipProps['variant'];
};

export function getStatusColor(status: ProxyModel['status'] | ProxySubnetStatus): StatusVariant {
  if ([ProxyStatus.EXPIRING_SOON, ProxySubnetStatus.NEW_ORDERS_PAUSED].includes(status)) {
    return { color: 'warning', variant: 'filled' };
  }

  if ([ProxyStatus.ACTIVE, ProxySubnetStatus.ENABLED].includes(status)) {
    return { color: 'success', variant: 'filled' };
  }

  if ([ProxyStatus.EXPIRED, ProxyStatus.CANCELED, ProxySubnetStatus.DISABLED].includes(status)) {
    return { color: 'error', variant: 'filled' };
  }

  // Pending or Initiating
  return { color: 'secondary', variant: 'filled' };
}
