import { type ReactNode, useMemo, useState } from 'react';

import Collapse from '@mui/material/Collapse';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import MuiInput from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput, { type OutlinedInputProps } from '@mui/material/OutlinedInput';

import { Icon, isIconName, type IconName } from 'components/Icon';
import type { LiteralNodeUnion } from 'types';

export type InputProps<Value extends string> = Omit<OutlinedInputProps, 'name' | 'id' | 'error' | 'value'> & {
  name?: string;
  label?: string;

  value?: Value;
  hasError?: boolean;
  error?: string;
  helperText?: ReactNode;

  endIcon?: LiteralNodeUnion<IconName>;
  startIcon?: LiteralNodeUnion<IconName>;

  variant?: 'outlined' | 'standard' | 'filled';
};

export function Input<Value extends string>({
  name,
  label,

  fullWidth,
  disabled,
  size,

  value,
  hasError,
  error,
  helperText,

  endIcon,
  startIcon,

  endAdornment,
  startAdornment,

  variant = 'outlined',
  ...props
}: InputProps<Value>) {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const InputComponent = useMemo(() => {
    if (variant === 'filled') return FilledInput;

    if (variant === 'standard') return MuiInput;

    return OutlinedInput;
  }, [variant]);

  const end = useMemo(() => {
    if (props.type === 'password') {
      return (
        <InputAdornment position="end">
          <IconButton size={size} disabled={disabled} onClick={() => setPasswordVisible((prev) => !prev)}>
            <Icon name={isPasswordVisible ? 'eye-slash' : 'eye'} size="small" />
          </IconButton>
        </InputAdornment>
      );
    }

    if (isIconName(endIcon)) {
      return (
        <InputAdornment position="end">
          <Icon name={endIcon} size="small" />
        </InputAdornment>
      );
    }

    if (endIcon || endAdornment) {
      return <InputAdornment position="end">{endIcon ?? endAdornment}</InputAdornment>;
    }

    return null;
  }, [disabled, endAdornment, endIcon, isPasswordVisible, props.type, size]);

  const start = useMemo(() => {
    if (isIconName(startIcon)) {
      return (
        <InputAdornment position="start">
          <Icon name={startIcon} size="small" />
        </InputAdornment>
      );
    }

    if (startIcon || startAdornment) {
      return <InputAdornment position="start">{startIcon ?? startAdornment}</InputAdornment>;
    }

    return null;
  }, [startAdornment, startIcon]);

  const type = useMemo(() => {
    if (props.type === 'password') {
      return isPasswordVisible ? 'text' : 'password';
    }

    return props.type;
  }, [isPasswordVisible, props.type]);

  const shouldRenderEndAdornment = useMemo(
    () => !!endIcon || !!endAdornment || props.type === 'password',
    [endAdornment, endIcon, props.type],
  );

  const shouldRenderStartAdornment = useMemo(() => !!startIcon || !!startAdornment, [startAdornment, startIcon]);

  return (
    <FormControl error={hasError} fullWidth={fullWidth} disabled={disabled}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}

      <InputComponent
        {...props}
        id={name}
        name={name}
        type={type}
        endAdornment={shouldRenderEndAdornment && end}
        startAdornment={shouldRenderStartAdornment && start}
        aria-labelledby={name}
        value={value}
        error={hasError}
        disabled={disabled}
        fullWidth={fullWidth}
        size={size}
      />

      <Collapse in={hasError || !!helperText} unmountOnExit>
        <FormHelperText error={hasError}>{hasError ? error?.toString() : helperText}</FormHelperText>
      </Collapse>
    </FormControl>
  );
}
