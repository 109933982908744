import { type RefObject, useCallback, useMemo, useRef, useState } from 'react';

import type { Nullable } from 'types';

type PopoverController<T> = {
  anchorRef: RefObject<Nullable<T>>;
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  handleToggle: () => void;
};

export function usePopover<T = HTMLElement>(): PopoverController<T> {
  const anchorRef = useRef<T>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => setIsOpen(true), []);

  const handleClose = useCallback(() => setIsOpen(false), []);

  const handleToggle = useCallback(() => setIsOpen((prev) => !prev), []);

  return useMemo<PopoverController<T>>(() => {
    return { anchorRef, isOpen, handleOpen, handleClose, handleToggle };
  }, [handleClose, handleOpen, handleToggle, isOpen]);
}
