import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { posthogEvents } from 'constants/posthogEvents';
import { useHideModal } from 'modules/common/Modal';
import { useReactivateProxyMutation } from 'store/api';
import { ToastManager } from 'utils/toast';

import { ReactivateProxyForm, type ReactivateProxyValues } from './ReactivateProxyForm';

type HandleSubmit = FormikConfig<ReactivateProxyValues>['onSubmit'];

export type ProxyReactivateModalProps = {
  proxyId: number;
};

export function ProxyReactivateModal({ proxyId }: ProxyReactivateModalProps) {
  const { t } = useTranslation();
  const [reactivateProxy] = useReactivateProxyMutation();
  const hideModal = useHideModal();
  const posthog = usePostHog();

  const initialValues = useMemo<ReactivateProxyValues>(() => {
    return { periodInMonths: 1 };
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ periodInMonths }) => {
      try {
        await reactivateProxy({ proxyId, periodInMonths }).unwrap();

        posthog?.capture(posthogEvents.proxy.reactivated.success, { proxyId, periodInMonths });

        ToastManager.success(t('proxies.modals.reactivate.success'));
        hideModal();
      } catch {
        posthog?.capture(posthogEvents.proxy.reactivated.failed, { proxyId, periodInMonths });
      }
    },
    [hideModal, posthog, proxyId, reactivateProxy, t],
  );

  return (
    <Formik<ReactivateProxyValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <ReactivateProxyForm proxyId={proxyId} />
    </Formik>
  );
}
