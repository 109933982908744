import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { useRouter } from 'hooks/useRouter';
import { useHideModal, useShowModal } from 'modules/common/Modal';

import { useOrderState } from './useOrderState';

export function useShowUnavailableDeals() {
  const { t } = useTranslation();
  const showModal = useShowModal();
  const hideModal = useHideModal();
  const router = useRouter();

  const resetOrder = useOrderState((state) => state.resetOrder);

  return useDebouncedCallback((error: unknown) => {
    if ((error as { errors?: unknown }).errors === 'DEAL_NOT_FOUND') {
      showModal('order.no-deals', {
        title: t('common:order.modals.noDeals.title'),
        subtitle: t('common:order.modals.noDeals.subtitle'),
        closeDisabled: true,
        onConfirm: () => {
          hideModal();
          resetOrder();
          router.back();
        },
        confirmLabel: t('common:buttons.quit'),
      });
    }
  }, 500);
}
