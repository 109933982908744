import type { Language } from 'store/accounts/models';
import type { Nullable } from 'types';

import type { MultiFactorHardwareOptions, MultiFactorProvider, UserRole } from './types';

/**
 * @POST /login-check
 */
export type AuthenticationModel = TokenModel & {
  mfa: Nullable<MultiFactorModel>;
};

/**
 * @GET /captcha
 */
export type CaptchaModel = {
  isEnabled: boolean;
  siteKey: Nullable<string>;
};

/**
 * @POST /login-check
 * @GET /2fa-status
 */
export type MultiFactorModel = {
  providers: MultiFactorProvider[];
  preferredProvider: MultiFactorProvider;
  options: Nullable<MultiFactorHardwareOptions>;
};

/**
 * @GET /session
 */
export type SessionModel = {
  userId: number;
  userIp: Nullable<string>;
  language: Nullable<Language>;
  email: string;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  roles: UserRole[];
  createdAt: string;

  trialCount: number;
  trialLimit: number;

  isActive: boolean;
  isProfileValid: boolean;
  isProfileSkipped: boolean;
  isSuspended: boolean;
  pendingCryptoOrders: number;

  suspension: SuspensionModel;
  verificationRequest: VerificationRequestModel;
};

export type SuspensionModel = {
  reason: Nullable<string>;
  explanation: Nullable<string>;
};

export enum VerificationStatus {
  NOT_VERIFIED = 'NOT_VERIFIED',
  PENDING = 'PENDING',
  REQUIRED = 'REQUIRED',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
}

export type VerificationRequestModel = {
  status: VerificationStatus;
  reason: Nullable<string>;
};

/**
 * @POST /login-check
 * @POST /registration
 */
export type TokenModel = {
  accessToken: string;
  refreshToken: string;
};
