import { ReactComponent as DocumentDownloadIcon } from 'assets/icons/content/document-download.svg';
import { ReactComponent as DocumentTextIcon } from 'assets/icons/content/document-text.svg';
import { ReactComponent as Edit3Icon } from 'assets/icons/content/edit-3.svg';
import { ReactComponent as NoteTextIcon } from 'assets/icons/content/note-text.svg';

export const content = {
  'document-download': DocumentDownloadIcon,
  'document-text': DocumentTextIcon,
  'edit-3': Edit3Icon,
  'note-text': NoteTextIcon,
};
