import { useMemo } from 'react';

import { useAvailableProxyFilters } from './useAvailableProxyFilters';
import { useParsedProxyFilters } from './useParsedProxyFilters';

export type ProxyFilterValues = {
  search: string;
  network?: string[];
  ipVersion?: string;
  connectionType?: string;
  authenticationType?: string;
  status?: string[];
  ispName?: string;
  countryCode?: string;
};

export function useProxyFilters() {
  const parsedSearchParams = useParsedProxyFilters();

  const {
    defaultOption,
    networkTypeOptions,
    ipVersionOptions,
    protocolOptions,
    authenticationTypeOptions,
    statusOptions,
    ISPOptions,
    countryOptions,
  } = useAvailableProxyFilters();

  // defaults to reset to
  const defaultOptions = useMemo<ProxyFilterValues>(
    () => ({
      search: '',
      network: [],
      ipVersion: defaultOption.value,
      connectionType: defaultOption.value,
      authenticationType: defaultOption.value,
      status: [],
      ispName: defaultOption.value,
      countryCode: defaultOption.value,
    }),
    [defaultOption],
  );

  // defaults + search params
  const initialValues: ProxyFilterValues = useMemo(() => {
    const foundProxyTypes = networkTypeOptions
      .filter((option) => parsedSearchParams.proxyType?.some((value) => value === option.value))
      .map(({ value }) => value);

    const foundStatuses = statusOptions
      .filter((option) => parsedSearchParams.status?.some((value) => value === option.value))
      .map(({ value }) => value);

    return {
      search: parsedSearchParams.publicIp ?? parsedSearchParams.name ?? '',
      network: foundProxyTypes.length > 0 ? foundProxyTypes : [],
      ipVersion:
        ipVersionOptions.find(({ value }) => value === parsedSearchParams.ipVersion)?.value || defaultOptions.ipVersion,
      connectionType:
        protocolOptions.find(({ value }) => value === parsedSearchParams.connectionType)?.value ||
        defaultOptions.connectionType,
      authenticationType:
        authenticationTypeOptions.find(({ value }) => value === parsedSearchParams.authenticationType)?.value ||
        defaultOptions.authenticationType,
      status: foundStatuses.length > 0 ? foundStatuses : [],
      ispName: ISPOptions.find(({ value }) => value === parsedSearchParams.ispName)?.value || defaultOptions.ispName,
      countryCode:
        countryOptions.find(({ value }) => value === parsedSearchParams.countryCode)?.value ||
        defaultOptions.countryCode,
    };
  }, [
    networkTypeOptions,
    ipVersionOptions,
    protocolOptions,
    authenticationTypeOptions,
    statusOptions,
    parsedSearchParams,
    ISPOptions,
    countryOptions,
    defaultOptions,
  ]);

  return {
    defaultOptions,
    initialValues,
  };
}
