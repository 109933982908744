import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { posthogEvents } from 'constants/posthogEvents';
import { useHideModal } from 'modules/common/Modal';
import { withMultiStepModalContext } from 'modules/proxies/components';
import type { ProxyBulkActionModel } from 'modules/proxies/types';
import { useBulkChangeProxyAuthenticationTypeMutation } from 'store/api';
import type { UpdateAuthenticationTypePayload } from 'store/proxies/payloads';
import { AuthenticationType } from 'store/proxies/types';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import {
  BulkChangeAuthenticationMethodForm,
  type BulkChangeAuthenticationMethodValues,
} from './BulkChangeAuthenticationMethodForm';

type HandleSubmit = FormikConfig<BulkChangeAuthenticationMethodValues>['onSubmit'];

export type ProxyBulkChangeAuthenticationMethodModalProps = {
  // TODO: Uncomment me to enable all proxies
  // isAllSelected: boolean;
  proxies: ProxyBulkActionModel[];
};

function ProxyBulkChangeAuthenticationMethodModalComponent({ proxies }: ProxyBulkChangeAuthenticationMethodModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const posthog = usePostHog();
  // TODO: Uncomment me
  // const { setOperationId, setStep } = useMultiStepStatusModalContext();

  const [bulkChangeProxyAuthenticationType] = useBulkChangeProxyAuthenticationTypeMutation();

  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);

  const initialValues = useMemo<BulkChangeAuthenticationMethodValues>(() => {
    return { type: AuthenticationType.USERNAME_PASSWORD, ipWhitelist: [''] };
  }, []);

  const validate = useCallback(
    ({ ipWhitelist, type }: BulkChangeAuthenticationMethodValues) => {
      const errors: Record<string, string | string[] | undefined> = {};

      const filteredWhitelist = ipWhitelist.filter((i) => Boolean(i.length));

      if (type === AuthenticationType.IP_WHITELIST && filteredWhitelist.length === 0) {
        errors.globalError = t('errors:minItems', { field: t('common:form.ipWhitelist'), min: 1 }) ?? '';
      }

      return errors;
    },
    [t],
  );

  const mapResponseKeyIntoFormKey = useCallback((key: keyof UpdateAuthenticationTypePayload) => {
    if (key === 'ips') return 'ipWhitelist';
    if (key === 'newAuthType') return 'type';

    throw new Error('Unsupported key');
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ type, ipWhitelist }, { setErrors, setSubmitting }) => {
      try {
        const ips = type === AuthenticationType.IP_WHITELIST ? ipWhitelist.filter((v) => v.length) : [];

        // TODO: Add operationId, and send empty array on `isAllSelected`
        await bulkChangeProxyAuthenticationType({ ids: proxyIds, authType: type, ips }).unwrap();

        posthog?.capture(posthogEvents.proxy.bulk.authenticationType.success, {
          proxyIds,
          total: proxyIds.length,
        });

        // TODO: Add setter for operationId and step from context
        ToastManager.success(t('proxies.modals.changeAuthMethod.success'));
        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error, mapResponseKeyIntoFormKey);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        setSubmitting(false);

        posthog?.capture(posthogEvents.proxy.bulk.authenticationType.failed, {
          proxyIds,
          total: proxyIds.length,
        });
      }
    },
    [bulkChangeProxyAuthenticationType, hideModal, mapResponseKeyIntoFormKey, posthog, proxyIds, t],
  );

  return (
    <Formik<BulkChangeAuthenticationMethodValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      <BulkChangeAuthenticationMethodForm proxyIds={proxyIds} />
    </Formik>
  );
}

export const ProxyBulkChangeAuthenticationMethodModal =
  withMultiStepModalContext<ProxyBulkChangeAuthenticationMethodModalProps>(
    ProxyBulkChangeAuthenticationMethodModalComponent,
    'changeAuthMethod',
  );
