import { type ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import { Button } from 'components/Button';
import { FormikInput } from 'components/form-partials/formik';
import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';
import { BalanceSummary, CouponCodeInput, PriceItem } from 'modules/proxies/components';
import type { ProxyBulkActionModel } from 'modules/proxies/types';
import { useGetProxyExtendBandwidthPriceQuery } from 'store/api';
import type { Nullable } from 'types';
import { ToastManager } from 'utils/toast';

export type ExtendBandwidthValues = {
  bandwidth: number;
  couponCode: Nullable<string>;
};

type ExtendBandwidthFormProps = {
  proxy: ProxyBulkActionModel;
  setPriceForSingle: (price: number) => void;
};

export function ExtendBandwidthForm({ proxy, setPriceForSingle }: ExtendBandwidthFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { isSubmitting, isValid, values, handleSubmit, setFieldValue } = useFormikContext<ExtendBandwidthValues>();

  const { data, isFetching, error } = useGetProxyExtendBandwidthPriceQuery(
    { proxyId: proxy.id, bandwidth: values.bandwidth, couponCode: values.couponCode ?? undefined },
    { refetchOnMountOrArgChange: true },
  );

  const extensionPriceError = useMemo(() => {
    if (!error) return;

    if (error?.message && typeof error.message === 'string') {
      return t(`errors:${error.message}`);
    }

    return t('proxies.modals.extendBandwidth.failure');
  }, [error, t]);

  const priceForSingle = useMemo(() => {
    if (!data) {
      return 0;
    }

    return data.finalPrice;
  }, [data]);

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isFetching}>
        {t('common:buttons.extend')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, t, isFetching]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const parsedValue = parseFloat(e.target.value.trim());

      setFieldValue('bandwidth', Number.isNaN(parsedValue) ? 1 : parsedValue);
    },
    [setFieldValue],
  );

  useEffect(() => {
    setPriceForSingle(priceForSingle);
  }, [priceForSingle, setPriceForSingle]);

  useEffect(() => {
    if (!extensionPriceError) return;

    ToastManager.error(extensionPriceError);
  }, [extensionPriceError]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.extendBandwidth.title')}
      subtitle={t('proxies.modals.extendBandwidth.subtitle')}
      actions={actions}
    >
      <Stack spacing={4}>
        <NumericFormat
          fullWidth
          name="bandwidth"
          suffix=" GB"
          value={values.bandwidth}
          onChange={handleChange}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          customInput={Field}
          component={FormikInput}
        />

        <Stack spacing={2} divider={<Divider />}>
          <BalanceSummary isLoading={isFetching} price={data?.finalPrice} />

          <Stack spacing={2}>
            <PriceItem
              label={t('common:form.price')}
              price={data?.priceNoDiscounts ?? 0}
              isLoading={isFetching}
              slotProps={{ label: { variant: 'body-2' }, price: { variant: 'body-2' } }}
            />

            <CouponCodeInput
              isApplied={!!values.couponCode && !!data && data.discount > 0}
              isApplying={isFetching}
              price={data}
              onApply={(couponCode) => setFieldValue('couponCode', couponCode)}
            />
          </Stack>

          <PriceItem label={t('common:form.total')} price={data?.finalPrice ?? 0} isLoading={isFetching} />
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
