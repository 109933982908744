import { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikInput } from 'components/form-partials/formik';
import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';

export type PasswordValues = {
  password: string;
};

export function PasswordForm() {
  const { t } = useTranslation();
  const { dirty, isSubmitting, isValid, handleSubmit } = useFormikContext<PasswordValues>();
  const hideModal = useHideModal();

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || !dirty}>
        {t('common:buttons.verify')}
      </Button>,
    ];
  }, [dirty, hideModal, isSubmitting, isValid, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit }}
      title={t('account.modals.password.title')}
      subtitle={t('account.modals.password.subtitle')}
      actions={actions}
    >
      <FormikInput
        fullWidth
        type="password"
        placeholder={t('common:form.enter', { value: t('common:form.password') })}
        name="password"
      />
    </ModalContainer>
  );
}
