import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useHideModal } from 'modules/common/Modal';
import { useAddProxyRouteMutation } from 'store/api';
import type { ProxyOverviewModel } from 'store/proxies/models';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import { AddRouteForm, type AddRouteValues } from './AddRouteForm';

type HandleSubmit = FormikConfig<AddRouteValues>['onSubmit'];

export type ProxyAdminAddRouteModalProps = {
  proxy: ProxyOverviewModel;
};

export function ProxyAdminAddRouteModal({ proxy }: ProxyAdminAddRouteModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const [addRoute] = useAddProxyRouteMutation();

  const initialValues = useMemo<AddRouteValues>(() => {
    const { httpPort, httpsPort, socks5Port } = proxy.connection;

    return {
      port: socks5Port || httpsPort || httpPort || undefined,
      subnetId: null,
    };
  }, [proxy.connection]);

  const validationSchema = useMemo<Yup.Schema<AddRouteValues>>(() => {
    return Yup.object().shape({
      port: Yup.number().required().min(1).max(65535),
      subnetId: Yup.string().required(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ port, subnetId }, { setErrors, setSubmitting }) => {
      if (!port || !subnetId) return;

      try {
        await addRoute({ port, proxyId: proxy.id, subnetId }).unwrap();

        ToastManager.success(t('proxies.modals.addRoute.success'));
        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        setSubmitting(false);
      }
    },
    [addRoute, hideModal, proxy.id, t],
  );

  return (
    <Formik<AddRouteValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <AddRouteForm proxyId={proxy.id} />
    </Formik>
  );
}
