import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import * as Yup from 'yup';

import { posthogEvents } from 'constants/posthogEvents';
import { useMounted } from 'hooks/useMounted';
import { useSession } from 'modules/accounts/hooks';
import { useIsNewCustomer } from 'modules/common/hooks';
import { useHideModal } from 'modules/common/Modal';
import { getOrderSetupPostHogEventValues } from 'modules/orders/helpers';
import { useOrderFlow, useOrderState } from 'modules/orders/hooks';
import type { OrderSetupValues } from 'modules/orders/types';
import { useGetOrderServicePriceMutation, useGetProxiesISPsQuery } from 'store/api';
import { toOrderSetupPayload } from 'store/orders/helpers';
import { getValidationErrors } from 'utils/error';

import { CouponCodeForm, type CouponCodeValues } from './CouponCodeForm';

type HandleSubmit = FormikConfig<CouponCodeValues>['onSubmit'];

export type CouponCodeModalProps = {
  currentSettings?: OrderSetupValues;
};

export function CouponCodeModal({ currentSettings }: CouponCodeModalProps) {
  const hideModal = useHideModal();
  const isMounted = useMounted();
  const isNewCustomer = useIsNewCustomer();
  const posthog = usePostHog();

  const { data: isps = [] } = useGetProxiesISPsQuery();
  const { isMarketplaceFlow, isSameSubnetFlow, originProxyId } = useOrderFlow();
  const { session } = useSession();

  const price = useOrderState((state) => state.price);
  const serviceId = useOrderState((state) => state.selectedServiceId);
  const planId = useOrderState((state) => state.selectedPlanId);
  const selectedSetup = useOrderState((state) => state.selectedSetup);
  const setCouponCode = useOrderState((state) => state.setCouponCode);

  const [getOrderPrice] = useGetOrderServicePriceMutation();

  const values = useMemo(() => currentSettings ?? selectedSetup, [currentSettings, selectedSetup]);

  const initialValues = useMemo<CouponCodeValues>(() => {
    return { couponCode: '' };
  }, []);

  const validationSchema = useMemo<Yup.Schema<CouponCodeValues>>(() => {
    return Yup.object().shape({
      couponCode: Yup.string().required(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ couponCode }, { setSubmitting, setErrors }) => {
      if (!serviceId || !values) return;

      try {
        const mappedValues = toOrderSetupPayload({ ...values, couponCode });

        await getOrderPrice(mappedValues).unwrap();

        if (!isMounted()) {
          return;
        }

        setCouponCode(couponCode);

        posthog?.capture(
          posthogEvents.order.v2.couponCode.applied,
          getOrderSetupPostHogEventValues({
            serviceId,
            planId,
            couponCode,
            userId: session?.userId,
            discount: price?.discount ?? 0,
            totalPrice: price?.finalPrice ?? 0,
            unitPrice: price?.unitPrice ?? 0,
            discountAmount: price?.discountAmount ?? 0,
            values: selectedSetup,
            isNewCustomer,
            isMarketplaceFlow,
            isSameSubnetFlow,
            originProxyId,
            isps,
            paymentType: null,
          }),
        );

        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        posthog?.capture(
          posthogEvents.order.v2.couponCode.invalid,
          getOrderSetupPostHogEventValues({
            serviceId,
            planId,
            couponCode,
            userId: session?.userId,
            discount: price?.discount ?? 0,
            totalPrice: price?.finalPrice ?? 0,
            unitPrice: price?.unitPrice ?? 0,
            discountAmount: price?.discountAmount ?? 0,
            values: selectedSetup,
            isNewCustomer,
            isMarketplaceFlow,
            isSameSubnetFlow,
            originProxyId,
            isps,
            paymentType: null,
          }),
        );

        setSubmitting(false);
      }
    },
    [
      getOrderPrice,
      hideModal,
      isMarketplaceFlow,
      isSameSubnetFlow,
      originProxyId,
      isMounted,
      isNewCustomer,
      isps,
      planId,
      posthog,
      price,
      selectedSetup,
      serviceId,
      session,
      setCouponCode,
      values,
    ],
  );

  return (
    <Formik<CouponCodeValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <CouponCodeForm />
    </Formik>
  );
}
