import type { ReactNode } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import type { Nullable } from 'types';

type AuthenticationExampleItemProps = {
  label: string;
  value: Nullable<string | number>;
  actions?: ReactNode[];
};

export function AuthenticationExampleItem({ label, value, actions = [] }: AuthenticationExampleItemProps) {
  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
      <Stack>
        <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
          {label}
        </Typography>

        <Typography variant="body-2">{value}</Typography>
      </Stack>

      {actions.length > 0 && (
        <Stack direction="row" spacing={1} alignItems="center">
          {actions.map((action) => action)}
        </Stack>
      )}
    </Stack>
  );
}
