import { useMemo } from 'react';

import type { CredentialsGeneratorHostname } from 'modules/proxies/generators/types';
import type { Option } from 'types';

import { PACKETSTREAM_CREDENTIALS_GENERATOR_COUNTRIES } from './constants';

export function usePacketStreamOptions() {
  const countries = useMemo<Array<Option<string>>>(() => {
    return [
      { label: 'countriesregions:countries.random', value: 'random' },
      ...PACKETSTREAM_CREDENTIALS_GENERATOR_COUNTRIES,
    ];
  }, []);

  const session = useMemo<Array<Option<string>>>(() => {
    return [
      { label: 'common:credentialsGenerator.session.random', value: 'random' },
      { label: 'common:credentialsGenerator.session.sticky', value: 'sticky' },
    ];
  }, []);

  const hostname = useMemo<Array<Option<CredentialsGeneratorHostname>>>(() => {
    return [
      { label: 'common:credentialsGenerator.hostname.dns', value: 'dns' },
      { label: 'common:credentialsGenerator.hostname.ip', value: 'ip' },
    ];
  }, []);

  return useMemo(() => {
    return { countries, session, hostname };
  }, [countries, hostname, session]);
}
