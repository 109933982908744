import { useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikAutocomplete, FormikInput } from 'components/form-partials/formik';
import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';
import { useGetProxySubnetsQuery } from 'store/api';
import type { Nullable, Option } from 'types';

export type AddRouteValues = {
  port?: number;
  subnetId: Nullable<string>;
};

type AddRouteFormProps = {
  proxyId: number;
};

export function AddRouteForm({ proxyId }: AddRouteFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { handleSubmit, isSubmitting, isValid } = useFormikContext<AddRouteValues>();

  const { data, isLoading } = useGetProxySubnetsQuery(proxyId);

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="add" type="submit" fullWidth loading={isSubmitting} disabled={!isValid}>
        {t('common:buttons.add')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, t]);

  const options = useMemo<Array<Option<string, { countryCode: string }>>>(() => {
    if (!data) return [];

    return data.map(({ id, label, countryCode }) => ({ value: id, label, parameters: { countryCode } }));
  }, [data]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.addRoute.title')}
      actions={actions}
    >
      <Stack spacing={4}>
        {isLoading ? (
          <>
            <Skeleton width="100%" height={48} />

            <Skeleton width="100%" height={48} />
          </>
        ) : (
          <>
            <FormikInput fullWidth name="port" type="number" placeholder={t('common:form.port')} />

            <FormikAutocomplete
              fullWidth
              options={options.slice().sort(({ parameters: a }, { parameters: b }) => {
                if (!a.countryCode || !b.countryCode) return 0;

                return -b.countryCode.localeCompare(a.countryCode);
              })}
              name="subnetId"
              groupBy={(option) => t(`countriesregions:countries.${option.parameters.countryCode}`)}
              placeholder={t('common:form.subnetId')}
            />
          </>
        )}
      </Stack>
    </ModalContainer>
  );
}
