import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { COUNTRIES } from 'constants/countries';
import { useGetProxiesISPsQuery } from 'store/api';

// TODO: Change the hardcoded arrays to enums
export function useAvailableProxyFilters() {
  const { t } = useTranslation();
  const { data: ISPs = [] } = useGetProxiesISPsQuery();

  const ALL_OPTION = useMemo(() => ({ value: '', label: t('common:all') }), [t]);

  return {
    defaultOption: ALL_OPTION,
    networkTypeOptions: useMemo(
      () =>
        ['DATACENTER', 'RESIDENTIAL', 'RESIDENTIAL_STATIC', 'MOBILE'].map((value) => ({
          value,
          label: t(`common:proxy.${value}`),
        })),
      [t],
    ),
    ipVersionOptions: useMemo(
      () =>
        [ALL_OPTION].concat(
          ['IPv4', 'IPv6'].map((value) => ({
            value,
            label: t(`common:ipVersion.${value}`),
          })),
        ),
      [t, ALL_OPTION],
    ),
    protocolOptions: useMemo(
      () =>
        [ALL_OPTION].concat(
          ['HTTP', 'HTTPS', 'SOCKS5'].map((value) => ({
            value,
            label: t(`common:proxyProtocol.${value}`),
          })),
        ),
      [t, ALL_OPTION],
    ),
    authenticationTypeOptions: useMemo(
      () =>
        [ALL_OPTION].concat(
          ['IP_WHITELIST', 'USERNAME_PASSWORD'].map((value) => ({
            value,
            label: t(`common:authenticationType.${value}`),
          })),
        ),
      [t, ALL_OPTION],
    ),
    statusOptions: useMemo(
      () =>
        ['ACTIVE', 'CANCELED', 'EXPIRED', 'PENDING', 'INITIATING'].map((value) => ({
          value,
          label: t(`common:status.${value}`),
        })),
      [t],
    ),
    ISPOptions: useMemo(
      () =>
        [ALL_OPTION].concat(
          ISPs.map(({ name }) => ({
            value: name,
            label: name,
          })),
        ),
      [ALL_OPTION, ISPs],
    ),
    countryOptions: useMemo(
      () =>
        [ALL_OPTION].concat(
          COUNTRIES.map(({ value, label }) => ({
            value,
            label: t(label),
          })),
        ),
      [t, ALL_OPTION],
    ),
    sortByOptions: useMemo(
      () =>
        ['NEWEST', 'LATEST', 'HIGH_BANDWIDTH', 'LOW_BANDWIDTH', 'EXPIRING_SOON', 'EXPIRING_LATE'].map((value) => ({
          value,
          label: t(`proxies.my-proxies.sortBy.${value}`),
        })),
      [t],
    ),
  };
}
