import { useCallback, useMemo, useState } from 'react';

import { autocompleteClasses } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputLabelClasses } from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button, type ButtonProps } from 'components/Button';
import { Card } from 'components/Card';
import { FormikAutocomplete, FormikNumberInput } from 'components/form-partials/formik';
import { Icon } from 'components/Icon';
import { useCopy } from 'hooks/useCopy';
import { AuthenticationExample } from 'modules/proxies/generators/components';
import type { PacketStreamCredentialsGeneratorValues } from 'modules/proxies/generators/types';
import type { HostnameModel } from 'store/proxies/models';

import { PACKETSTREAM_CREDENTIALS_GENERATOR_COUNTRIES } from './constants';
import type { CredentialsGenerator } from './CredentialsGenerator';
import { usePacketStreamOptions } from './usePacketStreamOptions';

type GeneratorFormProps = {
  host?: HostnameModel[];
  generator: CredentialsGenerator;
};

export function GeneratorForm({ host, generator }: GeneratorFormProps) {
  const { t } = useTranslation();
  const { values, handleSubmit } = useFormikContext<PacketStreamCredentialsGeneratorValues>();
  const copy = useCopy();

  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const { countries, session, hostname } = usePacketStreamOptions();

  const buttonProps = useMemo<ButtonProps>(() => {
    return { color: 'secondary', variant: 'outlined', size: 'small', iconOnly: true };
  }, []);

  const getSelectedCountry = useCallback((country: string) => {
    if (country === 'random') return country;

    return PACKETSTREAM_CREDENTIALS_GENERATOR_COUNTRIES.find(({ value }) => value === country)?.label ?? '';
  }, []);

  const { password, port, username, protocol, proxyHostname } = useMemo(() => {
    const { country } = values;

    const hostnameIp = host?.[0].hostnameIp ?? null;

    generator.setOptions({ ...values, country: t(getSelectedCountry(country), { lng: 'en' }), hostnameIp });

    return generator.build();
  }, [generator, getSelectedCountry, host, t, values]);

  const invisiblePassword = useMemo(() => password.replaceAll(/./g, '\u2022'), [password]);

  const command = useMemo(() => {
    return `curl -v -x ${protocol}://${username}:${password}@${proxyHostname}:${port} https://ipv4.icanhazip.com`;
  }, [password, port, protocol, proxyHostname, username]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Stack spacing={2}>
        <Grid
          container
          spacing={4}
          sx={{
            [`& .${autocompleteClasses.root}`]: { '--Autocomplete-input-gap': '4px' },
            [`& .${inputBaseClasses.root}`]: { '--Input-gap': '4px' },
            [`& .${inputLabelClasses.root}`]: { '--FormLabel-fontWeight': 400 },
          }}
        >
          <Grid size={{ xs: 12, md: 6 }}>
            <Stack spacing={2}>
              <Stack spacing={0.5}>
                <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
                  {t('proxies.credentials.generator.connection')}
                </Typography>

                <Typography variant="body-2" lineHeight="36px">
                  {t(`common:proxyProtocol.${values.connection}`)}
                </Typography>
              </Stack>

              <FormikAutocomplete
                name="country"
                options={countries}
                disableClearable
                label={t('proxies.credentials.generator.country')}
                placeholder={t('common:form.select')}
                size="small"
                fullWidth
              />
            </Stack>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <Stack spacing={2}>
              <FormikAutocomplete
                name="session"
                options={session}
                disableClearable
                label={t('proxies.credentials.generator.session')}
                placeholder={t('common:form.select')}
                size="small"
                fullWidth
              />

              <FormikAutocomplete
                name="hostname"
                options={hostname}
                disableClearable
                label={t('proxies.credentials.generator.hostname')}
                placeholder={t('common:form.select')}
                size="small"
                fullWidth
              />
            </Stack>
          </Grid>
        </Grid>

        <Card
          title={t('proxies.overview.command.title')}
          slotProps={{
            container: { spacing: 1 },
            title: { variant: 'body-2', color: 'var(--mui-palette-neutral-500)' },
          }}
          sx={{
            '--Card-boxShadow': 'none',
            '--Card-background': 'var(--mui-palette-neutral-950)',
            '--Card-paddingBlock': '16px',
            '--Card-paddingInline': '16px',
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography
              fontFamily="Courier"
              fontWeight={400}
              fontSize={12}
              lineHeight="16px"
              letterSpacing={0.04}
              color="var(--mui-palette-success-main)"
            >
              {t('common:command', { command, interpolation: { escapeValue: false } })}
            </Typography>

            <IconButton onClick={() => copy(command)}>
              <Icon name="copy" size="small" color="var(--mui-palette-common-white)" />
            </IconButton>
          </Stack>
        </Card>

        <AuthenticationExample>
          <AuthenticationExample.Item
            label={t('proxies.credentials.generator.example.username')}
            value={username}
            actions={[<Button {...buttonProps} key="copy" startIcon="copy" iconOnly onClick={() => copy(username)} />]}
          />

          <AuthenticationExample.Item
            label={t('proxies.credentials.generator.example.password')}
            value={isPasswordVisible ? password : invisiblePassword}
            actions={[
              <Button
                {...buttonProps}
                key="visibility"
                startIcon={isPasswordVisible ? 'eye-slash' : 'eye'}
                debounceTime={1}
                onClick={() => setPasswordVisibility((prev) => !prev)}
              />,
              <Button {...buttonProps} key="copy" startIcon="copy" iconOnly onClick={() => copy(username)} />,
            ]}
          />

          <AuthenticationExample.Item
            label={t('proxies.credentials.generator.example.host')}
            value={proxyHostname ?? null}
            actions={[
              <Button
                {...buttonProps}
                key="copy"
                startIcon="copy"
                iconOnly
                onClick={() => copy(proxyHostname ?? '')}
              />,
            ]}
          />

          <AuthenticationExample.Item
            label={t('proxies.credentials.generator.example.port')}
            value={port}
            actions={[
              <Button
                {...buttonProps}
                key="copy"
                startIcon="copy"
                iconOnly
                onClick={() => copy(port.toString() ?? '')}
              />,
            ]}
          />
        </AuthenticationExample>

        <Grid container alignItems="center">
          <Grid size={{ xs: 12, md: 5 }}>
            <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
              {t('proxies.credentials.generator.count')}
            </Typography>
          </Grid>

          <Grid size={{ xs: 12, md: 7 }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <FormikNumberInput name="count" size="small" fullWidth />

              <Button type="submit" size="medium" fullWidth>
                {t('common:buttons.generateCredentials')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
}
