import type { AppEndpointBuilder } from 'store/types';
import { buildCurrentAppURL } from 'utils/url';

import { toAuthenticationModel, toCaptchaModel, toMultiFactorModel, toSessionModel, toTokenModel } from './helpers';
import type { AuthenticationModel, CaptchaModel, MultiFactorModel, SessionModel, TokenModel } from './models';
import type {
  ForgotPasswordPayload,
  MultiFactorPayload,
  ResetPasswordPayload,
  SignInPayload,
  SignUpPayload,
} from './payloads';
import type { MultiFactorProvider } from './types';

export const authEndpoints = (builder: AppEndpointBuilder) => ({
  // #region Queries
  getCaptcha: builder.query<CaptchaModel, void>({
    query: () => ({
      url: 'captcha',
      method: 'GET',
    }),
    providesTags: ['Captcha'],
    transformResponse: toCaptchaModel,
  }),

  getMultiFactorStatus: builder.query<MultiFactorModel, MultiFactorProvider | void>({
    query: (preferProvider) => ({
      url: buildCurrentAppURL('2fa-status'),
      method: 'GET',
      params: { preferProvider },
    }),
    transformResponse: toMultiFactorModel,
  }),

  getSession: builder.query<SessionModel, void>({
    query: () => ({
      url: 'session',
      method: 'GET',
    }),
    providesTags: ['Session'],
    transformResponse: toSessionModel,
  }),
  // #endregion Queries

  // #region Mutations
  forgotPassword: builder.mutation<void, ForgotPasswordPayload>({
    query: (data) => ({
      url: 'forgot-password-post',
      method: 'POST',
      data,
    }),
  }),

  login: builder.mutation<AuthenticationModel, SignInPayload>({
    query: (data) => ({
      url: buildCurrentAppURL('login-check'),
      method: 'POST',
      data,
    }),
    transformResponse: toAuthenticationModel,
  }),

  logout: builder.mutation<void, void>({
    query: () => ({
      url: 'logout',
      method: 'POST',
    }),
  }),

  multiFactorVerification: builder.mutation<TokenModel, MultiFactorPayload>({
    query: ({ preferProvider, ...data }) => ({
      url: buildCurrentAppURL('2fa-check-api'),
      method: 'POST',
      data,
      params: { preferProvider },
    }),
    transformResponse: toTokenModel,
  }),

  register: builder.mutation<TokenModel, SignUpPayload>({
    query: (data) => ({
      url: buildCurrentAppURL('registration'),
      method: 'POST',
      data,
    }),
    transformResponse: toTokenModel,
  }),

  resetPassword: builder.mutation<void, ResetPasswordPayload>({
    query: ({ code, ...data }) => ({
      url: `reset-password-post/${code}`,
      method: 'POST',
      data,
    }),
  }),
  // #endregion Mutations
});
