import isObject from 'lodash/isObject';

import type { Paginate } from 'types';

export function isPaginatedDTO<T>(value: T | Paginate<T>): value is Paginate<T> {
  return isObject(value) && 'data' in value && 'metadata' in value;
}

export function isEnum<T extends object>(value: T) {
  return (token: unknown): token is T[keyof T] => {
    return Object.values(value).includes(token);
  };
}
