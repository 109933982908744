import { useCallback, useMemo, useState } from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { ModalContainer } from 'components/modals';
import { posthogEvents } from 'constants/posthogEvents';
import { useCopy } from 'hooks/useCopy';
import { useHideModal } from 'modules/common/Modal';
import type { ProxyModel } from 'store/proxies/models';
import type { ProxyCredentialsFormat } from 'store/proxies/types';

type CredentialOption = {
  label: string;
  format: ProxyCredentialsFormat;
};

export type ProxyCopyCredentialsModalProps = {
  proxy: ProxyModel;
};

const OPTIONS: CredentialOption[] = [
  { label: 'IP:PORT', format: 'IP_PORT' },
  { label: 'IP:PORT:USERNAME:PASSWORD', format: 'IP_PORT_USERNAME_PASSWORD' },
  { label: 'USERNAME:PASSWORD@IP:PORT', format: 'USERNAME_PASSWORD_IP_PORT' },
];

export function ProxyCopyCredentialsModal({ proxy }: ProxyCopyCredentialsModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const posthog = usePostHog();
  const copy = useCopy();

  const [isLoading, setLoading] = useState(false);

  const footer = useMemo(
    () => (
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>
    ),
    [hideModal, t],
  );

  const handleClick = useCallback(
    async (format: ProxyCredentialsFormat) => {
      const { connection, authentication } = proxy;
      const { publicIp, lastIp, httpPort, httpsPort, socks5Port } = connection;
      const { username, password } = authentication;

      try {
        setLoading(true);

        const ipPort = `${publicIp ?? lastIp}:${httpPort ?? httpsPort ?? socks5Port}`;
        const usernamePassword = `${username}:${password}`;

        posthog.capture(posthogEvents.proxy.copyCredentials.success, { format });

        if (format === 'IP_PORT') {
          return await copy(ipPort);
        }

        if (format === 'IP_PORT_USERNAME_PASSWORD') {
          return await copy(`${ipPort}:${usernamePassword}`);
        }

        if (format === 'USERNAME_PASSWORD_IP_PORT') {
          return await copy(`${usernamePassword}@${ipPort}`);
        }
      } catch {
        posthog.capture(posthogEvents.proxy.copyCredentials.failed, { format });
      } finally {
        setLoading(false);
      }
    },
    [copy, posthog, proxy],
  );

  return (
    <ModalContainer
      title={t('proxies.modals.copyCredentials.title')}
      subtitle={t('proxies.modals.copyCredentials.subtitle')}
      footer={footer}
    >
      <Stack spacing={2} divider={<Divider />}>
        {OPTIONS.map(({ label, format }) => (
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <Typography variant="body-1">{label}</Typography>

            <Button
              color="secondary"
              variant="outlined"
              size="medium"
              loading={isLoading}
              onClick={() => handleClick(format)}
            >
              {t('common:buttons.copy')}
            </Button>
          </Stack>
        ))}
      </Stack>
    </ModalContainer>
  );
}
