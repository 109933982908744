import { useEffect, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikAutocomplete } from 'components/form-partials/formik';
import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';
import { BalanceSummary, CouponCodeInput, MultiUpdatesAccordion, PriceItem } from 'modules/proxies/components';
import type { ProxyBulkActionModel } from 'modules/proxies/types';
import { useGetBulkProxyExtendPeriodPriceQuery } from 'store/api';
import type { Nullable, Option } from 'types';
import { ToastManager } from 'utils/toast';

export type BulkExtendPeriodValues = {
  periodInMonths: number;
  couponCode: Nullable<string>;
};

type BulkExtendPeriodFormProps = {
  // TODO: Uncomment me to enable all proxies
  // isAllSelected: boolean;
  proxies: ProxyBulkActionModel[];
  setPriceForSingle: (price: number) => void;
};

export function BulkExtendPeriodForm({ proxies = [], setPriceForSingle }: BulkExtendPeriodFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { isSubmitting, isValid, values, handleSubmit, setFieldValue } = useFormikContext<BulkExtendPeriodValues>();

  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);

  const { data, isFetching, error } = useGetBulkProxyExtendPeriodPriceQuery(
    {
      // TODO: Send an empty array on `isAllSelected`
      ids: proxyIds,
      periodInMonths: values.periodInMonths,
      couponCode: values.couponCode ?? undefined,
    },
    { refetchOnMountOrArgChange: true },
  );

  const extensionPriceError = useMemo(() => {
    if (!error) return;

    if (error?.message && typeof error.message === 'string') {
      return t(`errors:${error.message}`);
    }

    return t('proxies.modals.extendPeriod.failure');
  }, [error, t]);

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isFetching}>
        {t('common:buttons.extend')}
      </Button>,
    ];
  }, [hideModal, isFetching, isSubmitting, isValid, t]);

  const priceForSingle = useMemo(() => {
    if (!data) {
      return 0;
    }

    return +(data.finalPrice / proxyIds.length).toFixed(2);
  }, [data, proxyIds.length]);

  const periodOptions = useMemo<Array<Option<number>>>(() => {
    return [1, 2, 3, 6, 12].map((count) => ({ value: count, label: t('common:form.month', { count }) }));
  }, [t]);

  useEffect(() => {
    setPriceForSingle(priceForSingle);
  }, [priceForSingle, setPriceForSingle]);

  useEffect(() => {
    if (!extensionPriceError) return;

    ToastManager.error(extensionPriceError);
  }, [extensionPriceError]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.extendPeriod.title')}
      subtitle={t('proxies.modals.extendPeriod.subtitle')}
      actions={actions}
    >
      <Stack spacing={4}>
        {/* TODO: Do not display that when `isAllSelected` */}
        {proxyIds.length > 1 && <MultiUpdatesAccordion proxyIds={proxyIds} />}

        <FormikAutocomplete
          size="small"
          disableClearable
          options={periodOptions}
          label={t('common:form.timePeriod')}
          name="periodInMonths"
        />

        <Stack spacing={2} divider={<Divider />}>
          <BalanceSummary isLoading={isFetching} price={data?.finalPrice} />

          <Stack spacing={2}>
            <PriceItem
              label={t('common:form.price')}
              price={data?.priceNoDiscounts ?? 0}
              isLoading={isFetching}
              slotProps={{ label: { variant: 'body-2' }, price: { variant: 'body-2' } }}
            />

            <CouponCodeInput
              isApplied={!!values.couponCode && !!data && data.discount > 0}
              isApplying={isFetching}
              price={data}
              onApply={(couponCode) => setFieldValue('couponCode', couponCode)}
            />
          </Stack>

          <PriceItem label={t('common:form.total')} price={data?.finalPrice ?? 0} isLoading={isFetching} />
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
