export function buildCurrentAppURL(path: string) {
  const currentURL = process.env.REACT_APP_CURRENT_APP_URL;

  if (!currentURL) {
    throw new Error('REACT_APP_CURRENT_APP_URL is not set properly');
  }

  return `${currentURL}/${path}`;
}

export function buildGrafanaURL(proxyId: number) {
  return `http://grafana.proxycheap.io/d/ed080196-623c-4421-ab74-4386b3f005d3/abuse-investigations?orgId=1&var-proxyID=${proxyId}&from=now-12h&to=now`;
}
