import { useCallback, useMemo } from 'react';

import type { ProxyOverviewModel } from 'store/proxies/models';
import { IPVersion, Protocol } from 'store/proxies/types';

export function useProxyCurl(proxy?: ProxyOverviewModel) {
  const isIPv6 = useMemo(() => {
    if (!proxy) return false;

    const { connection } = proxy;

    return connection.ipVersion === IPVersion.IPv6;
  }, [proxy]);

  const usernameAndPassword = useMemo(() => {
    if (!proxy) return '';

    const { authentication } = proxy;
    const { username, password } = authentication;

    if (!password && !username) return '';

    return `${username}:${password}@`;
  }, [proxy]);

  const getConnectionForIPv4 = useCallback(
    (ip?: string, port?: number) => {
      if (!proxy) return '';

      const { proxyType, connection } = proxy;
      const { connectIp, socks5Port, httpPort, httpsPort } = connection;

      if (proxyType === Protocol.SOCKS5) {
        return `${ip ?? connectIp}:${port ?? socks5Port}`;
      }

      if (proxyType === Protocol.HTTPS) {
        return `${ip ?? connectIp}:${port ?? httpsPort}`;
      }

      return `${ip ?? connectIp}:${port ?? httpPort}`;
    },
    [proxy],
  );

  const getConnectionForIPv6 = useCallback(() => {
    if (!proxy) return '';

    const { connection } = proxy;
    const { hostnames } = connection;

    if (!hostnames) return '';

    return Object.keys(hostnames)[0];
  }, [proxy]);

  const getIPAndPort = useCallback(
    (ip?: string, port?: number) => {
      if (isIPv6) {
        return getConnectionForIPv6();
      }

      return getConnectionForIPv4(ip, port);
    },
    [getConnectionForIPv4, getConnectionForIPv6, isIPv6],
  );

  const buildCommand = useCallback(
    (ip?: string, port?: number) => {
      if (!proxy) return null;

      const { proxyType } = proxy;

      return `curl -v -x ${proxyType.toLowerCase()}://${usernameAndPassword}${getIPAndPort(ip, port)} https://ipv4.icanhazip.com`;
    },
    [getIPAndPort, proxy, usernameAndPassword],
  );

  const command = useMemo(() => buildCommand(), [buildCommand]);

  return useMemo(() => {
    return { buildCommand, command };
  }, [buildCommand, command]);
}
