import { useCallback, useMemo } from 'react';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { posthogEvents } from 'constants/posthogEvents';
import { useSession } from 'modules/accounts/hooks';
import { useShowModal } from 'modules/common/Modal';
import { useResendEmailVerificationLinkMutation } from 'store/api';
import { ToastManager } from 'utils/toast';

export function useEmailVerification(withPolling = false) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { session, isSessionLoading } = useSession({ ...(withPolling && { pollingInterval: 5_000 }) });

  const showModal = useShowModal();
  const [resendEmailVerificationLink] = useResendEmailVerificationLinkMutation();

  const isEmailVerificationRequired = useMemo(
    () => (isSessionLoading ? false : !session?.isActive),
    [isSessionLoading, session],
  );

  const shouldForceEmailVerification = useCallback(() => {
    if (!isEmailVerificationRequired) return false;

    showModal('account.email.verification');

    return true;
  }, [isEmailVerificationRequired, showModal]);

  const handleResendVerificationEmail = useCallback(async () => {
    try {
      await resendEmailVerificationLink().unwrap();

      posthog?.capture(posthogEvents.email.confirmation.resent, { email: session?.email });

      ToastManager.success(t('account.modals.emailVerification.sent.success'));
    } catch {
      // noop
    }
  }, [posthog, resendEmailVerificationLink, session?.email, t]);

  return useMemo(
    () => ({
      isEmailVerificationRequired,
      session,
      isSessionLoading,
      handleResendVerificationEmail,
      shouldForceEmailVerification,
    }),
    [
      handleResendVerificationEmail,
      isEmailVerificationRequired,
      isSessionLoading,
      session,
      shouldForceEmailVerification,
    ],
  );
}
