import { useCallback, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';
import type { ProxyOverviewModel } from 'store/proxies/models';
import { createBlobLink } from 'utils/values';

type CredentialsFormat = 'IP:PORT' | 'IP:PORT:USERNAME:PASSWORD' | 'USERNAME:PASSWORD@IP:PORT';

type CredentialOption = {
  label: string;
  format: CredentialsFormat;
};

export type ProxyIPv6CredentialsModalProps = {
  proxy?: ProxyOverviewModel;
};

const OPTIONS: CredentialOption[] = [
  { label: 'IP:PORT', format: 'IP:PORT' },
  { label: 'IP:PORT:USERNAME:PASSWORD', format: 'IP:PORT:USERNAME:PASSWORD' },
  { label: 'USERNAME:PASSWORD@IP:PORT', format: 'USERNAME:PASSWORD@IP:PORT' },
];

export function ProxyIPv6CredentialsModal({ proxy }: ProxyIPv6CredentialsModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const footer = useMemo(
    () => (
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>
    ),
    [hideModal, t],
  );

  const data = useMemo(() => {
    if (!proxy) return [];

    const { connection } = proxy;
    const { hostnames } = connection;

    if (!hostnames) return [];

    return Object.entries(hostnames).map(([key, value]) => ({ id: key, connection: key, exitHostname: value }));
  }, [proxy]);

  const formatData = useCallback(
    (format: CredentialsFormat) => {
      if (format === 'IP:PORT') {
        return data.map(({ connection }) => connection).join('\n');
      }

      if (format === 'IP:PORT:USERNAME:PASSWORD') {
        return data
          .map(({ connection }) => `${connection}:${proxy?.authentication.username}:${proxy?.authentication.password}`)
          .join('\n');
      }

      return data
        .map(({ connection }) => `${proxy?.authentication.username}:${proxy?.authentication.password}@${connection}`)
        .join('\n');
    },
    [data, proxy?.authentication.password, proxy?.authentication.username],
  );

  const handleClick = useCallback(
    (format: CredentialsFormat) => {
      return createBlobLink(formatData(format), 'proxy_list');
    },
    [formatData],
  );

  return (
    <ModalContainer
      title={t('proxies.modals.downloadCredentials.title')}
      subtitle={t('proxies.modals.downloadCredentials.subtitle')}
      footer={footer}
    >
      <Stack spacing={2} divider={<Divider />}>
        {OPTIONS.map(({ label, format }) => (
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <Typography variant="body-1">{label}</Typography>

            <Button color="secondary" variant="outlined" size="medium" onClick={() => handleClick(format)}>
              {t('common:buttons.copy')}
            </Button>
          </Stack>
        ))}
      </Stack>
    </ModalContainer>
  );
}
