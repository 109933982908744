import { useMemo } from 'react';

import Stack from '@mui/material/Stack';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikAutocomplete } from 'components/form-partials/formik';
import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';
import { MultiUpdatesAccordion } from 'modules/proxies/components';
import type { Protocol } from 'store/proxies/types';
import type { Nullable, Option } from 'types';

export type BulkChangeProtocolValues = {
  protocol: Nullable<Protocol>;
};

type BulkChangeProtocolFormProps = {
  // TODO: Uncomment me to enable all proxies
  // isAllSelected: boolean;
  proxyIds: number[];
  availableTypes?: Protocol[];
};

export function BulkChangeProtocolForm({ proxyIds, availableTypes = [] }: BulkChangeProtocolFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { dirty, isSubmitting, isValid, handleSubmit } = useFormikContext<BulkChangeProtocolValues>();

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="update" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || !dirty}>
        {t('common:buttons.change')}
      </Button>,
    ];
  }, [dirty, hideModal, isSubmitting, isValid, t]);

  const options = useMemo<Array<Option<Protocol>>>(() => {
    return availableTypes.map((value) => ({ label: value, value }));
  }, [availableTypes]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.changeProtocol.title')}
      subtitle={t('proxies.modals.changeProtocol.subtitle')}
      actions={actions}
    >
      <Stack spacing={4}>
        {/* TODO: Do not display that when `isAllSelected` */}
        {proxyIds.length > 1 && <MultiUpdatesAccordion proxyIds={proxyIds} />}

        <FormikAutocomplete fullWidth name="protocol" disableClearable options={options} />
      </Stack>
    </ModalContainer>
  );
}
