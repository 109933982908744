import { type ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import Badge from '@mui/material/Badge';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

import dayjs from 'dayjs';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { matchPath, Outlet, useLocation, useSearchParams } from 'react-router-dom';

import { CannyProvider } from 'components/Canny/CannyProvider';
import { Icon } from 'components/Icon';
import { useScreenSize } from 'hooks/useScreenSize';
import { useSession } from 'modules/accounts/hooks';
import { useMaintenance } from 'modules/common/hooks';
import { paths } from 'paths';
import type { DashboardMaintenanceWindow } from 'store/common/types';
import { PersistService } from 'utils/persist';

import { MaintenanceStatus } from './MaintenanceStatus';
import { Navigation } from './Navigation';
import { ReplacementBanner } from './ReplacementBanner';
import { Sidebar } from './Sidebar';

type DashboardLayoutProps = {
  children?: ReactNode;
};

const HARDCODED_MESSAGE: DashboardMaintenanceWindow = {
  start: dayjs().toISOString(),
  end: dayjs().add(5, 'days').toISOString(),
  note: 'Due to a database issue, orders and payments made between 2024-12-09, 3:00 AM, and 7:00 PM GMT were not processed. If you placed an order or made a payment during this time, please contact our support team for assistance. We apologize for the inconvenience.',
  replacementNote: null,
};

export function AppLayout({ children }: DashboardLayoutProps) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const { isSuspended } = useSession();
  const { pathname } = useLocation();

  const { width } = useScreenSize();

  const isTopWarningMessageVisible = useFeatureFlagEnabled('top_message_warning');

  const [offset, setOffset] = useState(0);
  const maintenanceRef = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { isProxyReplacementAvailable, maintenance, shouldDisplayBanner } = useMaintenance();

  const bgcolor = useMemo(() => {
    if (matchPath(`${paths.proxies.index}/:id`, pathname) || matchPath(paths.referrals.index, pathname)) {
      return 'var(--mui-palette-background-default)';
    }

    return 'var(--mui-palette-background-paper)';
  }, [pathname]);

  const isMaintenanceVisible = useMemo(() => {
    if (isTopWarningMessageVisible) return true;

    if (!maintenance) return false;

    return !(!maintenance.end || !maintenance.start);
  }, [isTopWarningMessageVisible, maintenance]);

  useEffect(() => {
    setOffset(maintenanceRef.current?.clientHeight ?? 0);
  }, [isMaintenanceVisible, width, isProxyReplacementAvailable]);

  useEffect(() => {
    const returnTo = searchParams.get('returnTo');

    if (returnTo) {
      PersistService.set('redirect.url', returnTo);
    }
  }, [searchParams]);

  return (
    <CannyProvider>
      <Stack position="relative" minHeight="100%">
        <MaintenanceStatus
          ref={maintenanceRef}
          isVisible={shouldDisplayBanner || !!isTopWarningMessageVisible}
          maintenance={maintenance}
          isReplacementAvailable={isProxyReplacementAvailable}
          hardcoded={isTopWarningMessageVisible ? HARDCODED_MESSAGE : undefined}
        />

        <Sidebar offset={offset} isOpen={isSidebarOpen} onClose={() => setSidebarOpen(false)} />

        <Stack
          flex="1 1 auto"
          pl={{ md: 'var(--Sidebar-width)' }}
          sx={(theme) => ({
            '--Content-paddingBlock': `calc(var(--Navigation-height) + ${offset}px)`,
            '--Content-paddingInline': '0px',
            '--Content-padding': `${isMobile && isProxyReplacementAvailable ? 0 : 'var(--Content-paddingBlock)'} var(--Content-paddingInline) var(--Content-paddingBlock) var(--Content-paddingInline)`,

            transition: theme.transitions.create('padding-right', { duration: 225 }),
          })}
        >
          <Navigation offset={offset} />

          <Stack component="main" flex="1 1 auto" bgcolor={bgcolor}>
            {isMobile && isProxyReplacementAvailable && <ReplacementBanner />}

            {children ?? <Outlet />}
          </Stack>
        </Stack>
      </Stack>

      <Fade in={isMobile} timeout={300} unmountOnExit>
        <Fab onClick={() => setSidebarOpen((prev) => !prev)}>
          <Badge invisible={!isSuspended}>
            <Icon name="menu" color="var(--mui-palette-text-primary)" />
          </Badge>
        </Fab>
      </Fade>
    </CannyProvider>
  );
}
