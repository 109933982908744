import { useCallback, useEffect, useState, type ReactNode } from 'react';

import isEqual from 'lodash/isEqual';
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';

import { useRouter } from 'hooks/useRouter';
import { getOrderPath, getOrderStep } from 'modules/orders/helpers';
import { useInitOrderStateFromParams, useOrderFlow, useOrderState } from 'modules/orders/hooks';
import { paths } from 'paths';

type OrderStepGuardProps = {
  children: ReactNode;
};

export function OrderStepGuard({ children }: OrderStepGuardProps) {
  const [checked, setChecked] = useState(false);
  const { pathname } = useLocation();

  const { isInitialized } = useInitOrderStateFromParams();
  const { isValidServiceId, isStateMalformed } = useOrderFlow();
  const navigationType = useNavigationType();
  const router = useRouter();

  const currentStep = useOrderState((state) => state.step);
  const resetOrder = useOrderState((state) => state.resetOrder);
  const setOrderStep = useOrderState((state) => state.setStep);

  const redirectToPath = useCallback(
    (path: string) => {
      if (isEqual(pathname, path)) return;

      return router.replace(path);
    },
    [pathname, router],
  );

  useEffect(() => {
    if (!isInitialized) return;

    if (!isEqual(paths.order.index, pathname) && isStateMalformed) {
      setChecked(true);
      resetOrder();

      return router.replace(paths.order.index);
    }

    if (!isEqual(paths.order.index, pathname) && !isValidServiceId) {
      setChecked(true);
      resetOrder();

      return router.replace(paths.order.index);
    }

    setChecked(true);

    return redirectToPath(getOrderPath(currentStep));
  }, [
    currentStep,
    isInitialized,
    isStateMalformed,
    isValidServiceId,
    pathname,
    redirectToPath,
    resetOrder,
    router,
    setOrderStep,
  ]);

  useEffect(() => {
    if (navigationType !== NavigationType.Pop) return;

    const nextStep = getOrderStep(pathname);

    if (!nextStep) return;

    setOrderStep(nextStep);
  }, [navigationType, pathname, setOrderStep]);

  if (!checked) return null;

  return children;
}
