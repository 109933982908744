import { useCallback, useMemo } from 'react';

import { Experiment } from 'store/common/types';
import type { NetworkType } from 'store/proxies/types';
import type { Nullable } from 'types';

import { useExperiment } from './useExperiment';

export function useBlackFridayExperiment() {
  const { isEnabled, configuration, isExperimentLoading } = useExperiment(Experiment.BlackFriday);

  const isBlackFridayAllowedForUser = useMemo(
    () => isEnabled && !!configuration?.couponCode && !!configuration?.availableFor.length,
    [configuration?.availableFor.length, configuration?.couponCode, isEnabled],
  );

  const shouldAssignBlackFridayCouponCode = useCallback(
    // TODO: Type is forced by OrderProxySetupPage context... Probably needs to be adjusted in the feature
    (networkType: Nullable<NetworkType>) => {
      if (!networkType) return false;

      return !!configuration?.availableFor.includes(networkType);
    },
    [configuration?.availableFor],
  );

  return useMemo(() => {
    return {
      isBlackFridayEnabled: isEnabled,
      isExperimentLoading,
      availableFor: configuration?.availableFor ?? [],
      blackFridayCouponCode: configuration?.couponCode ?? null,
      isBlackFridayAllowedForUser,
      shouldAssignBlackFridayCouponCode,
    };
  }, [
    configuration?.availableFor,
    configuration?.couponCode,
    isBlackFridayAllowedForUser,
    isEnabled,
    isExperimentLoading,
    shouldAssignBlackFridayCouponCode,
  ]);
}
