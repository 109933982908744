import { useMemo } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { posthogEvents } from 'constants/posthogEvents';
import { useRouter } from 'hooks/useRouter';
import { useIdentityVerificationStatus } from 'modules/accounts/hooks';
import { useShowModal } from 'modules/common/Modal';
import { toProxyBulkActionModel } from 'modules/proxies/helpers';
import { paths } from 'paths';
import type { ProxyOverviewModel } from 'store/proxies/models';
import type { Action } from 'types';
import { ToastManager } from 'utils/toast';
import { buildGrafanaURL } from 'utils/url';
import { isAdminUser } from 'utils/user';

import { useProxyActionPermissions } from './useProxyActionPermissions';

export function useProxyActions(
  proxy?: ProxyOverviewModel,
  onOrderSimilarProxyClick?: (fromSameSubnet?: boolean) => void,
) {
  const { t } = useTranslation();
  const { canProxy } = useProxyActionPermissions(proxy);
  const posthog = usePostHog();
  const router = useRouter();
  const showModal = useShowModal();
  const { shouldForceIDVerification } = useIdentityVerificationStatus();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return useMemo<Action[]>(() => {
    if (!proxy) return [];

    const actions: Action[] = [];

    if (canProxy('auto_extend')) {
      actions.push({
        icon: 'refresh',
        label: t('common:form.isAutoExtendEnabled'),
        onClick: (e) => {
          e.stopPropagation();

          posthog.capture(posthogEvents.proxy.actions.autoExtend, { isBulk: false });

          return showModal('proxies.period.auto-extend', { proxyId: proxy.id });
        },
      });
    }

    if (isMobile && canProxy('ordering_subnet') && onOrderSimilarProxyClick) {
      actions.push({
        icon: 'shopping-card',
        label: t('proxies.overview.title.order.subnet.mobileAction'),
        onClick: (e) => {
          e.stopPropagation();

          posthog.capture(posthogEvents.proxy.actions.orderSimilarProxy.subnet, { proxyId: proxy.id });

          return onOrderSimilarProxyClick(true);
        },
      });
    }

    if (isMobile && onOrderSimilarProxyClick) {
      actions.push({
        icon: 'shopping-card',
        label: t('common:buttons.orderSimilarProxy'),
        onClick: (e) => {
          e.stopPropagation();

          posthog.capture(posthogEvents.proxy.actions.orderSimilarProxy.settings, { proxyId: proxy.id });

          return onOrderSimilarProxyClick();
        },
      });
    }

    if (canProxy('reactivate')) {
      actions.push({
        icon: 'refresh',
        label: t('common:buttons.reactivate'),
        onClick: (e) => {
          e.stopPropagation();

          if (shouldForceIDVerification()) {
            ToastManager.error(t('common:idVerification.title'));

            return router.push(paths.account.identityVerification);
          }

          e.preventDefault();

          posthog.capture(posthogEvents.proxy.actions.reactivate);

          return showModal('proxies.reactivate', { proxyId: proxy.id });
        },
      });
    }

    if (canProxy('extend_period')) {
      actions.push({
        icon: 'forward-square',
        label: t('common:buttons.extendProxy'),
        onClick: (e) => {
          e.stopPropagation();

          posthog.capture(posthogEvents.proxy.actions.extendPeriod);

          return showModal('proxies.period.extend', { proxy: toProxyBulkActionModel(proxy) });
        },
      });
    }

    if (canProxy('buy_traffic')) {
      actions.push({
        icon: 'trend-up',
        label: t('common:buttons.extendBandwidth'),
        onClick: (e) => {
          e.stopPropagation();

          posthog.capture(posthogEvents.proxy.actions.extendBandwidth);

          return showModal('proxies.bandwidth.extend', { proxy: toProxyBulkActionModel(proxy) });
        },
      });
    }

    if (canProxy('change_protocol')) {
      actions.push({
        icon: 'document-code',
        label: t('common:buttons.changeProtocol'),
        onClick: (e) => {
          e.stopPropagation();

          posthog.capture(posthogEvents.proxy.actions.changeProtocol);

          return showModal('proxies.protocol.change', { proxy: toProxyBulkActionModel(proxy) });
        },
      });
    }

    if (canProxy('change_authentication_method')) {
      actions.push({
        icon: 'key',
        label: t('common:buttons.changeAuthentication'),
        onClick: (e) => {
          e.stopPropagation();

          posthog.capture(posthogEvents.proxy.actions.changeAuthenticationMethod);

          showModal('proxies.authentication-method.change', { proxy: toProxyBulkActionModel(proxy) });
        },
      });
    }

    if (canProxy('download_credentials')) {
      actions.push({
        icon: 'document-download',
        label: t('common:buttons.downloadCredentials'),
        onClick: (e) => {
          e.stopPropagation();

          posthog.capture(posthogEvents.proxy.actions.downloadCredentials);

          return showModal('proxies.credentials.download', {
            isAllSelected: false,
            proxies: [toProxyBulkActionModel(proxy)],
          });
        },
      });
    }

    if (isAdminUser()) {
      actions.push({
        icon: 'grafana',
        label: t('common:buttons.grafana'),
        onClick: (e) => {
          e.stopPropagation();

          window.open(buildGrafanaURL(proxy.id), '_blank');
        },
      });
    }

    return actions;
  }, [canProxy, isMobile, onOrderSimilarProxyClick, posthog, proxy, router, shouldForceIDVerification, showModal, t]);
}
