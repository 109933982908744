import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import ReactGA from 'react-ga4';

import { api } from 'store/api';
import { parseJWT } from 'utils/jwt';
import { PersistService } from 'utils/persist';

import type { TokenModel } from './models';
import type { AuthState, MultiFactorProvider } from './types';

const { accessToken, refreshToken } = PersistService.getCredentials();

const wasQuizClosed = !!PersistService.get<boolean>('quiz.closed');
const isMFARequired = PersistService.get<boolean>('auth.mfa.required');

const initialState: AuthState = {
  accessToken,
  refreshToken,
  isAuthenticated: !isMFARequired && !!accessToken,

  isMFACompleted: isMFARequired == null ? null : !isMFARequired,
  mfa: null,

  wasQuizClosed,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens: (state, { payload }: PayloadAction<TokenModel>) => {
      const tokenPayload = parseJWT(payload.accessToken);

      PersistService.setCredentials(payload);
      PersistService.set('token-expiry-time', tokenPayload ? tokenPayload.exp : null);

      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;

      // User does not need to go through the MFA process - can be fully authenticated
      state.isAuthenticated = !tokenPayload?.mfa;

      // User needs to go through the MFA process
      if (tokenPayload?.mfa) {
        PersistService.set('auth.mfa.required', true);

        state.isMFACompleted = false;
      }
    },

    setMFAKind: (state, { payload }: PayloadAction<MultiFactorProvider>) => {
      state.isMFACompleted = false;

      state.mfa = state.mfa
        ? { ...state.mfa, preferredProvider: payload }
        : { preferredProvider: payload, providers: [], options: null };
    },

    closeQuiz: (state) => {
      PersistService.set('quiz.closed', true);

      state.wasQuizClosed = true;
    },

    onSignOut: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.mfa = null;
      state.isMFACompleted = null;
      state.isAuthenticated = false;

      PersistService.removeCredentials();
      PersistService.onSignOut();
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getSession.matchFulfilled, (_, { payload }) => {
      if (payload.userId != null) {
        Sentry.setUser({ id: payload.userId.toString() });
      }

      ReactGA.gtag('set', 'user_data', {
        email: payload.email,
        address: {
          first_name: payload?.firstName,
          last_name: payload?.lastName,
        },
      });
    });

    builder.addMatcher(api.endpoints.getMultiFactorStatus.matchFulfilled, (state, { payload }) => {
      PersistService.set('auth.mfa.required', true);

      state.isMFACompleted = false;
      state.mfa = payload;
    });

    builder.addMatcher(api.endpoints.getSession.matchFulfilled, (state, { payload }) => {
      posthog.setPersonProperties({}, { user_id: payload.userId, joined_at: payload.createdAt, email: payload.email });
    });

    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
      const tokenPayload = parseJWT(payload.accessToken);

      PersistService.setCredentials({ accessToken: payload.accessToken, refreshToken: payload.refreshToken });
      PersistService.set('token-expiry-time', tokenPayload ? tokenPayload.exp : null);

      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;

      // User does not need to go through the MFA process - can be fully authenticated
      state.isAuthenticated = !payload.mfa;

      // User needs to go through the MFA process
      if (payload.mfa) {
        PersistService.set('auth.mfa.required', true);

        state.isMFACompleted = false;
        state.mfa = payload.mfa;
      }
    });

    builder.addMatcher(api.endpoints.register.matchFulfilled, (state, { payload }) => {
      const tokenPayload = parseJWT(payload.accessToken);

      PersistService.setCredentials(payload);
      PersistService.set('token-expiry-time', tokenPayload ? tokenPayload.exp : null);

      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.isAuthenticated = true;
    });

    builder.addMatcher(api.endpoints.multiFactorVerification.matchFulfilled, (state, { payload }) => {
      const tokenPayload = parseJWT(payload.accessToken);

      PersistService.setCredentials(payload);
      PersistService.set('token-expiry-time', tokenPayload ? tokenPayload.exp : null);
      PersistService.remove('auth.mfa.required');

      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.isAuthenticated = !!payload.accessToken;
      state.isMFACompleted = true;
    });

    builder.addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.mfa = null;
      state.isMFACompleted = null;
      state.isAuthenticated = false;
      state.wasQuizClosed = false;

      PersistService.remove('quiz.closed');
      PersistService.remove('review.dismissed');
      PersistService.removeCredentials();
      PersistService.remove('auth.mfa.required');
    });
  },
});

export const { setMFAKind, setTokens, closeQuiz, onSignOut } = slice.actions;

export const { reducer: authReducer } = slice;
