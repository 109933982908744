import { type MouseEvent, useCallback } from 'react';

import Stack from '@mui/material/Stack';

import { useTranslation } from 'react-i18next';

import { Button, type ButtonProps } from 'components/Button';
import { useRouter } from 'hooks/useRouter';
import { useIdentityVerificationStatus } from 'modules/accounts/hooks';
import { useShowModal } from 'modules/common/Modal';
import { paths } from 'paths';
import { ToastManager } from 'utils/toast';

type ReactivateButtonProps = Omit<ButtonProps, 'onClick'> & {
  proxyId: number;
};

export function ReactivateButton({ proxyId, size = 'medium', ...props }: ReactivateButtonProps) {
  const { t } = useTranslation();
  const showModal = useShowModal();
  const { shouldForceIDVerification } = useIdentityVerificationStatus();
  const router = useRouter();

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (shouldForceIDVerification()) {
        ToastManager.error(t('common:idVerification.title'));

        return router.push(paths.account.identityVerification);
      }

      e.preventDefault();

      return showModal('proxies.reactivate', { proxyId });
    },
    [proxyId, router, shouldForceIDVerification, showModal, t],
  );

  return (
    <Stack alignItems="center" justifyContent="flex-end">
      <Button size={size} onClick={onClick} {...props}>
        {t('common:buttons.reactivate')}
      </Button>
    </Stack>
  );
}
