export const SKELETON_HEIGHT: Record<string, string | number> = {
  hero: '72px',
  'hero-2': '72px',
  'headline-1': '44px',
  'headline-2': '32px',
  'paragraph-1': '44px',
  'paragraph-2': '32px',
  title: '24px',
  subtitle: '20px',
  'body-1': '24px',
  'body-2': '20px',
  caption: '16px',
  badge: '14px',
};
