import { useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography, { type TypographyProps } from '@mui/material/Typography';

import { SKELETON_HEIGHT } from 'constants/skeleton';
import { CurrencyFormatter } from 'utils/currency';

type PriceItemSlotProps = {
  label?: Partial<TypographyProps>;
  price?: Partial<TypographyProps>;
};

type PriceItemProps = {
  label: string;
  price: number;
  isLoading?: boolean;

  slotProps?: PriceItemSlotProps;
};

export function PriceItem({ label, price, isLoading, slotProps }: PriceItemProps) {
  const height = useMemo(() => {
    const priceVariant = slotProps?.price?.variant ?? 'headline-2';

    return SKELETON_HEIGHT[priceVariant];
  }, [slotProps?.price?.variant]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="title" {...slotProps?.label}>
        {label}
      </Typography>

      {isLoading ? (
        <Skeleton height={height} width={100} />
      ) : (
        <Typography variant="headline-2" {...slotProps?.price}>
          {CurrencyFormatter.format(price)}
        </Typography>
      )}
    </Stack>
  );
}
