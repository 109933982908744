import { t } from 'i18next';

import type {
  CredentialsType,
  NetNutCredentialsGeneratorValues,
  PacketStreamCredentialsGeneratorValues,
} from 'modules/proxies/generators/types';
import type { NetworkType } from 'store/proxies/types';

type CredentialsGeneratorPostHogEventValuesArgs = {
  networkType: NetworkType;
  values: PacketStreamCredentialsGeneratorValues | NetNutCredentialsGeneratorValues;
  type: CredentialsType;
};

function getHumanLikeNetworkType(networkType: NetworkType) {
  return t(`common:proxy.${networkType}`, { lng: 'en' });
}

export function getCredentialsGeneratorPostHogEventValues({
  networkType,
  type,
  values,
}: CredentialsGeneratorPostHogEventValuesArgs) {
  return {
    networkType: getHumanLikeNetworkType(networkType),
    values,
    type,
  };
}
