import { type ReactNode, forwardRef } from 'react';

import MuiCard, { type CardProps as MuiCardProps, type CardTypeMap } from '@mui/material/Card';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import Stack, { type StackProps } from '@mui/material/Stack';
import Typography, { type TypographyProps } from '@mui/material/Typography';

type CardSlotProps = {
  container?: StackProps;
  title?: TypographyProps;
  subtitle?: TypographyProps;
};

export type CardProps = MuiCardProps & {
  title?: string;
  subtitle?: string;
  actions?: ReactNode[];
  slotProps?: CardSlotProps;
};

export const Card: OverridableComponent<CardTypeMap<CardProps>> = forwardRef<HTMLDivElement, CardProps>(
  ({ title, subtitle, actions, children, slotProps, ...props }, ref) => {
    return (
      <Stack spacing={2} {...slotProps?.container}>
        {(title || subtitle) && (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack>
              {title && (
                <Typography variant="title" color="var(--mui-palette-text-primary)" {...slotProps?.title}>
                  {title}
                </Typography>
              )}

              {subtitle && (
                <Typography variant="body-2" color="var(--mui-palette-neutral-500)" {...slotProps?.subtitle}>
                  {subtitle}
                </Typography>
              )}
            </Stack>

            {actions}
          </Stack>
        )}

        <MuiCard ref={ref} {...props}>
          {children}
        </MuiCard>
      </Stack>
    );
  },
) as OverridableComponent<CardTypeMap<CardProps>>;
