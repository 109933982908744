import { radioClasses } from '@mui/material/Radio';
import type { Components, Theme } from '@mui/material/styles';

function CheckedIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_33_403)">
        <rect width="16" height="16" rx="8" fill="var(--mui-palette-primary-main)" />
        <circle cx="7.99992" cy="8.00001" r="2.66667" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_33_403">
          <rect width="16" height="16" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function Icon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill="var(--mui-palette-text-primary)" fillOpacity="0.08" />
    </svg>
  );
}

export const MuiRadio: Components<Theme>['MuiRadio'] = {
  defaultProps: {
    checkedIcon: <CheckedIcon />,
    icon: <Icon />,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '--Radio-padding': '8px',

      '--Radio-svg-borderRadius': '4px',
      '--Radio-svg-size': '24px',

      padding: 'var(--Radio-padding)',

      '& svg': {
        borderRadius: 'var(--Radio-svg-borderRadius)',
        width: 'var(--Radio-svg-size)',
        height: 'var(--Radio-svg-size)',

        '& rect': {
          transition: theme.transitions.create('all', { duration: 300 }),
        },
      },

      [`&.${radioClasses.sizeSmall}`]: {
        '--Radio-padding': '0px',

        '--Radio-svg-borderRadius': '8px',
        '--Radio-svg-size': '16px',
      },

      [`&:hover:not(.${radioClasses.checked})`]: {
        '& svg rect': {
          stroke: 'rgba(var(--mui-palette-primary-mainChannel) / 0.24)',
          fill: 'var(--mui-palette-common-white)',
        },
      },
    }),
  },
};
