import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import type { ProxyQueryParams } from 'store/proxies/payloads';
import type { SortOrder } from 'types';

export function useParsedProxyFilters() {
  const [searchParams] = useSearchParams();

  return useMemo<Partial<ProxyQueryParams>>(() => {
    const parsedProxyType = searchParams.getAll('proxyType') || undefined;
    const parsedStatus = searchParams.getAll('status') || undefined;
    const parsedSortExpiredAt = searchParams.get('order[expiresAt]') || undefined;
    const parsedSortCreatedAt = searchParams.get('order[createdAt]') || undefined;
    const parsedSortBandwidth = searchParams.get('order[bandwidth]') || undefined;
    const parsedPreset = searchParams.get('preset') || undefined;

    return {
      preset: parsedPreset ? (parsedPreset.toUpperCase() as ProxyQueryParams['preset']) : undefined,
      status: parsedStatus || undefined,
      ipVersion: searchParams.get('ipVersion') || undefined,
      publicIp: searchParams.get('publicIp') || undefined,
      name: searchParams.get('name') || undefined,
      connectionType: searchParams.get('connectionType') || undefined,
      authenticationType: searchParams.get('authenticationType') || undefined,
      proxyType: parsedProxyType || undefined,
      ispName: searchParams.get('ispName') || undefined,
      countryCode: searchParams.get('countryCode') || undefined,
      order: {
        bandwidth: (parsedSortBandwidth as SortOrder) || undefined,
        expiresAt: (parsedSortExpiredAt as SortOrder) || undefined,
        createdAt: (parsedSortCreatedAt as SortOrder) || undefined,
      },
    };
  }, [searchParams]);
}
