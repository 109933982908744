import { Field, getIn, type FieldProps } from 'formik';

import {
  AutocompleteNumberInput as BaseAutocompleteNumberInput,
  type AutocompleteNumberInputProps as BaseAutocompleteNumberInputProps,
} from 'components/form-partials/base';
import type { Optional } from 'types';

type AutocompleteNumberInputProps<
  T extends number,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
> = FieldProps<T, Values> & Omit<BaseAutocompleteNumberInputProps<T, Parameters>, 'hasError' | 'error' | 'value'>;

function fieldToAutocompleteNumberInput<
  T extends number,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
>({
  disabled,
  field,
  form: { errors, isSubmitting, touched, setFieldValue },
  onChange,
  onBlur,
  ...props
}: AutocompleteNumberInputProps<T, Parameters, Values>): BaseAutocompleteNumberInputProps<T, Parameters> {
  const hasError = Boolean(!!getIn(errors, field.name) && !!getIn(touched, field.name));
  const error = getIn(errors, field.name);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { onChange: _onChange, onBlur: _onBlur, multiple: _multiple, ...fieldSubSelection } = field;

  return {
    onBlur: (e) => {
      if (onBlur) return onBlur(e);

      return field.onBlur(e ?? field.name);
    },

    onChange: (event, value, reason, details) => {
      if (onChange) return onChange(event, value, reason, details);

      if (typeof value === 'string') {
        setFieldValue(field.name, value);
      } else {
        setFieldValue(field.name, value.value);
      }
    },
    disabled: disabled ?? isSubmitting,
    loading: isSubmitting,
    hasError,
    error,
    ...fieldSubSelection,
    ...props,
  };
}

function AutocompleteNumberInput<
  T extends number,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
>(props: AutocompleteNumberInputProps<T, Parameters, Values>) {
  return <BaseAutocompleteNumberInput {...fieldToAutocompleteNumberInput(props)} />;
}

export type FormikAutocompleteNumberInputProps<
  T extends number,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
> = Omit<AutocompleteNumberInputProps<T, Parameters, Values>, 'form' | 'meta' | 'field'>;

export function FormikAutocompleteNumberInput<
  T extends number,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
>(props: FormikAutocompleteNumberInputProps<T, Parameters, Values>) {
  return <Field component={AutocompleteNumberInput} {...props} />;
}
