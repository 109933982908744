import type { CurrencyDTO } from 'store/accounts/dtos';
import type { MaintenanceWindowDTO } from 'store/common/dtos';
import type { PlanID } from 'store/orders/types';
import type { Nullable } from 'types';

import type {
  AuthenticationType,
  IPVersion,
  NetNutHostnameRegion,
  NetworkType,
  OperationStatus,
  Protocol,
  ProxyBulkOperationChanges,
  ProxyPropositionAlias,
  ProxySubnetStatus,
} from './types';

// #region Common
type ChangeableOption = {
  id: number;
  name: string;
};

export enum ProxyActionDTO {
  ACTIVATE = 'ACTIVATE',
  ADMIN_DETAILS = 'ADMIN_DETAILS',
  AUTO_EXTEND = 'AUTO_EXTEND',
  BUY_TRAFFIC = 'BUY_TRAFFIC',
  BUY_THREADS = 'BUY_THREADS',
  BUY_UPLINK_SPEED = 'BUY_UPLINK_SPEED',
  CANCEL = 'CANCEL',
  CHANGE_AUTHENTICATION_METHOD = 'CHANGE_AUTHENTICATION_METHOD',
  CHANGE_HTTPS_PROTOCOL = 'CHANGE_HTTPS_PROTOCOL',
  CHANGE_PORT = 'CHANGE_PORT',
  CHANGE_PROTOCOL = 'CHANGE_PROTOCOL',
  CHANGE_THREADS = 'CHANGE_THREADS',
  CHANGE_UPLINK_SPEED = 'CHANGE_UPLINK_SPEED',
  CHANGE_WHITELIST_IP = 'CHANGE_WHITELIST_IP',
  CREATE_ROUTE = 'CREATE_ROUTE',
  DELETE_ROUTE = 'DELETE_ROUTE',
  DOWNLOAD_CREDENTIALS = 'DOWNLOAD_CREDENTIALS',
  EXTEND_PERIOD = 'EXTEND_PERIOD',
  EXPIRE = 'EXPIRE',
  GENERATE_CREDENTIALS = 'GENERATE_CREDENTIALS',
  INITIALIZE = 'INITIALIZE',
  LIST_EVENTS = 'LIST_EVENTS',
  LIST_HISTORY_CHANGES = 'LIST_HISTORY_CHANGES',
  REACTIVATE = 'REACTIVATE',
  REGENERATE_CREDENTIALS = 'REGENERATE_CREDENTIALS',
  REFUND = 'REFUND',
  REVIVE = 'REVIVE',
  RUN_DIAGNOSTIC_ROUTINE = 'RUN_DIAGNOSTIC_ROUTINE',
  RUN_TEST = 'RUN_TEST',
  ORDERING_SUBNET = 'ORDERING_SUBNET',
}

export type ProxyAdminPricesDTO = {
  unitPrice: Nullable<string>;
  totalValue: Nullable<string>;
};

export type ProxyAdminServerDTO = {
  id: string;
  name: string;
  ip: string;
};

export type ProxyAdminSubnetDTO = {
  id: string;
  subnetCidr: string;
  status: ProxySubnetStatus;
};

export type ProxyAdminVendorDTO = {
  urlText: string;
  url: string;
};

export type ProxyAuthenticationDTO = {
  whitelistedIps: string[];
  username: Nullable<string>;
  password: Nullable<string>;
};

export type ProxyBandwidthDTO = {
  total: Nullable<number>;
  used: Nullable<number>;
  updatedAt: string;
};

export type ProxyConnectionDTO = {
  publicIp: Nullable<string>;
  connectIp: Nullable<string>;
  ipVersion: IPVersion;
  lastIp: Nullable<string>;
  httpPort: Nullable<number>;
  httpsPort: Nullable<number>;
  socks5Port: Nullable<number>;
  hostnames: [] | Record<string, string>;
};

export type ProxyLocationDTO = {
  countryCode: string;
  regionCode: Nullable<string>;
};

export type ProxyMetadataDTO = {
  ispName: Nullable<string>;
};

export type ProxyRouteDTO = {
  id: number;
  proxyService: number;
  server: string;
  publicIp: string;
  port: number;
  serverName: string;
};

export enum ProxyStatusDTO {
  PENDING = 'PENDING',
  INITIATING = 'INITIATING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
}

export type ProxyThreadsDTO = {
  value: number;
  label: string;
};

export type ProxyUplinkSpeedDTO = {
  value: number;
  label: string;
};

type UpgradeableOption = ChangeableOption & {
  unitPrice: string;
  totalPrice: string;
};

export type ProxyTrafficDataDTO = {
  date: string;
  value: number;
};

export type ProxyTrafficDTO = {
  updatedAt: string;
  data: ProxyTrafficDataDTO[];
};

export type ProxyUptimeDataDTO = {
  date: string;
  value: Nullable<number>;
};

export type ProxyUptimeDTO = {
  updatedAt: string;
  data: ProxyUptimeDataDTO[];
};

// #endregion Common

/**
 * @POST /proxies/bulk/auto-extend/details
 */
export type BulkAutoExtendSettingsDTO = {
  isBandwidthRequired: boolean;
  isAutoExtendEnabled: boolean;
};

/**
 * @GET /proxies/{id}/change-authentication/type
 */
export type ChangeAuthenticationTypeDTO = {
  currentAuthenticationType: AuthenticationType;
  availableAuthenticationTypes: AuthenticationType[];
};

/**
 * @GET /proxies/{id}/change-protocol
 */
export type ChangeProtocolDTO = {
  currentType: Protocol;
  availableTypes: Protocol[];
};

/**
 * @GET /proxies/{id}/change-threads
 */
export type ChangeThreadsDTO = {
  currentThreads: number;
  availableThreads: ChangeableOption[];
};

/**
 * @GET /proxies/{id}/change-uplink-speed
 */
export type ChangeUplinkSpeedDTO = {
  currentUplinkSpeed: number;
  availableUplinkSpeeds: ChangeableOption[];
};

/**
 * @POST /proxies/{id}/admin/diagnostic-routine
 */
export type DiagnosticRoutineDTO = {
  url: string;
  statusCode: number;
  statusMessage: string;
  time: number;
  metadata: Record<string, string>;
};

/**
 * @GET /proxies/settings/global-ip-whitelist
 * @GET /proxies/{id}/whitelist-ip
 */
export type IPWhitelistDTO = {
  whitelistedIps: string[];
  allowedWhitelistIpCount: number;
};

/**
 * @GET /proxies/{id}/credential-generator
 */

type PacketStreamHostnameDTO = {
  hostnameIp: string;
  alias: ProxyPropositionAlias.PacketStream;
  region: null;
};

type NetNutHostnameDTO = {
  hostnameIp: string;
  alias: ProxyPropositionAlias.NetNutMobile | ProxyPropositionAlias.NetNutResidential;
  region: NetNutHostnameRegion;
};

export type HostnameDTO = {
  credentials: Array<NetNutHostnameDTO | PacketStreamHostnameDTO>;
};

/**
 * @GET /services/proxies
 */
export type ProxyDTO = {
  id: number;
  status: ProxyStatusDTO;
  networkType: NetworkType;
  authentication: ProxyAuthenticationDTO;
  connection: ProxyConnectionDTO;
  proxyType: Protocol;
  createdAt: string;
  expiresAt: Nullable<string>;
  metadata: ProxyMetadataDTO;
  bandwidth: ProxyBandwidthDTO;
  uplinkSpeed: Nullable<ProxyUplinkSpeedDTO>;
  threads: Nullable<ProxyThreadsDTO>;
  location: ProxyLocationDTO;
  note: Nullable<string>;
  actions: ProxyActionDTO[];
  maintenanceWindows: MaintenanceWindowDTO[];
};

/**
 * @GET /proxies/{id}/admin/details
 */
export type ProxyAdminDetailsDTO = {
  userName: string;
  userId: number;
  proposition: Nullable<string>;
  reservedIp: Nullable<string>;
  server: Nullable<ProxyAdminServerDTO>;
  subnet: Nullable<ProxyAdminSubnetDTO>;
  isUsed: boolean;
  isHttpsProtocolSupported: boolean;
  vendor: ProxyAdminVendorDTO[];
  prices: Nullable<ProxyAdminPricesDTO>;
  routes: ProxyRouteDTO[];
  type: 'basic' | 'standard' | 'premium';
};

/**
 * @GET /proxies/{id}/change-history
 */
export type ProxyChangelogDTO = {
  oldValue: string;
  newValue: string;
  createdAt: string;
  fieldName: string;
  changedBy: Nullable<string>;
  userId: Nullable<number>;
};

/**
 * @POST /proxies/bulk/buy-traffic/price
 * @POST /proxies/bulk/extend-period/price
 * @POST /proxies/{id}/bandwidth-price
 * @POST /proxies/{id}/period-extension-price
 * @POST /proxies/{id}/reactivate/price
 */
export type ProxyExtensionPriceDTO = {
  finalPrice: number;
  priceNoDiscounts: number;
  discount: number;
  unitPrice: number;
  additionalAmount: number;
  additionalAmountAfterDiscount: number;
  paymentFee: number;
  subtotal: number;
  discountAmount: number;
  finalPriceInCurrency: number;
  currency: Nullable<CurrencyDTO>;
};

/**
 * @GET /proxies/{id}/events
 */
export type ProxyEventDTO = {
  createdAt: string;
  message: string;
};

/**
 * @GET /services/proxies/isp
 */
export type ProxyISPDTO = {
  id: string;
  name: string;
};

/**
 * @GET /services/proxies/{id}
 */
export type ProxyOverviewDTO = ProxyDTO & {
  plan: Nullable<PlanID>;
  activatedAt: Nullable<string>;
  canceledAt: Nullable<string>;
  updatedAt: Nullable<string>;
  autoExtendEnabled: boolean;
  autoExtendBandwidthAmount: number;
  autoExtendAt: Nullable<string>;
  routes: ProxyRouteDTO[];
  traffic: ProxyTrafficDTO;
  uptime: ProxyUptimeDTO;
  type: string;
};

/**
 * @GET /services/proxies/replacement/check
 */
export type ProxyReplacementCheckDTO = {
  isReplacementAvailable: boolean;
};

/**
 * @GET /services/proxies/replacement/list
 */
export type ProxyReplacementDetailsDTO = {
  id: number;
  status: ProxyStatusDTO;
  networkType: NetworkType;
  publicIp: string;
  createdAt: string;
  expiresAt: string;
  ispId: string;
  countryCode: string;
  note: Nullable<string>;
  isUnusedProxy: boolean;
  ipVersion: Nullable<IPVersion>;
};

/**
 * @POST /order/configuration
 */
export type ProxyReplacementOptionsDTO = {
  isUnused: boolean;
  supportedCountries: Array<{ code: string; amount: Nullable<string> }>;
  supportedISPs: [] | Record<string, [] | Record<string, { name: string; amount: Nullable<string> }>>;
};

/**
 * @GET /proxies/{id}/admin/routes/details
 */
export type ProxySubnetDTO = {
  id: string;
  cidr: string;
  serverName: string;
  countryCode: string;
};

/**
 * @GET /proxies/summary
 */
export type ProxySummaryDTO = {
  activeProxyCount: number;
  inactiveProxyCount: number;
  expiringProxyCount: number;
};

/**
 * @GET /proxies/{id}/buy-threads
 */
export type UpgradeThreadsPriceDTO = {
  currentThreads: number;
  availableThreads: UpgradeableOption[];
};

/**
 * @GET /proxies/{id}/buy-uplink-speed
 */
export type UpgradeUplinkSpeedPriceDTO = {
  currentUplinkSpeed: number;
  availableUplinkSpeeds: UpgradeableOption[];
};

/**
 * @POST /proxies/bulk/extend-period
 */
export type BulkOperationDTO = {
  operationId: string;
};

/**
 * @GET /bulk-operations/{operationId}
 */
export enum OperationTypeDTO {
  PERIOD_EXTENSION = 'PERIOD_EXTENSION',
  TRAFFIC_PURCHASE = 'TRAFFIC_PURCHASE',
}

export type ProxyBulkOperationDTO = {
  id: string;
  type: OperationTypeDTO;
  changes: ProxyBulkOperationChanges;
  status: OperationStatus;
  total: number;
  processed: number;
  failureReasons: string[];
};

/**
 * @GET /bulk-operations/{operationId}/tasks
 */
export type ProxyBulkOperationTaskDetailsDTO = {
  id: number;
  note: Nullable<string>;
  publicIp: string;
  networkType: NetworkType;
  expiresAt: string;
  protocol: Protocol;
  authType: AuthenticationType;
  isAutoExtendable: boolean;
  trafficLimit: Nullable<number>;
};

export type ProxyBulkOperationTaskDTO = {
  type: 'proxy';
  reason: string;
  details: ProxyBulkOperationTaskDetailsDTO;
};
