import { useCallback, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { ModalContainer } from 'components/modals';
import { useCopy } from 'hooks/useCopy';
import { useHideModal } from 'modules/common/Modal';
import { CredentialsFormatter } from 'modules/proxies/generators';
import type { AccessConfig, CredentialsGeneratorFormat, CredentialsType } from 'modules/proxies/generators/types';
import type { NetworkType } from 'store/proxies/types';
import { createBlobLink } from 'utils/values';

type CredentialOption = {
  label: string;
  format: CredentialsGeneratorFormat;
};

export type ProxyGeneratedCredentialsModalProps = {
  proxyId: number;
  credentials: AccessConfig[];
  generatorType: CredentialsType;
  networkType?: NetworkType;
  variant: 'copy' | 'download';
};

const OPTIONS: CredentialOption[] = [
  { label: 'IP:PORT:USERNAME:PASSWORD', format: 'ip:port:username:password' },
  { label: 'USERNAME:PASSWORD@IP:PORT', format: 'username:password@ip:port' },
];

export function ProxyGeneratedCredentialsModal({
  proxyId,
  credentials,
  generatorType,
  networkType,
  variant,
}: ProxyGeneratedCredentialsModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const copy = useCopy();

  const footer = useMemo(
    () => (
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>
    ),
    [hideModal, t],
  );

  const handleClick = useCallback(
    async (format: CredentialsGeneratorFormat) => {
      const formatted = CredentialsFormatter.format({ credentials, type: generatorType, format, networkType });

      if (variant === 'copy') {
        return copy(formatted.join('\n'));
      }

      return createBlobLink(formatted.join('\n'), `proxy-${proxyId}-credentials`);
    },
    [copy, credentials, generatorType, networkType, proxyId, variant],
  );

  return (
    <ModalContainer
      title={t(`proxies.modals.${variant === 'copy' ? 'copyCredentials' : 'downloadCredentials'}.title`)}
      subtitle={t(`proxies.modals.${variant === 'copy' ? 'copyCredentials' : 'downloadCredentials'}.subtitle`)}
      footer={footer}
    >
      <Stack spacing={2} divider={<Divider />}>
        {OPTIONS.map(({ label, format }) => (
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <Typography variant="body-1">{label}</Typography>

            <Button color="secondary" variant="outlined" size="medium" onClick={() => handleClick(format)}>
              {t('common:buttons.copy')}
            </Button>
          </Stack>
        ))}
      </Stack>
    </ModalContainer>
  );
}
