import { type ComponentType, createContext, useMemo, useState, type Dispatch, type SetStateAction } from 'react';

import type { ContextChildren, Optional, Nullable } from 'types';
import { createUseContext } from 'utils/context';

import { Status } from './Status';
import type { MultiStepStatusModalStep, MultiStepStatusModalType } from './types';

type TMultiStepStatusModalContext = {
  operationId: Nullable<string>;
  setOperationId: Dispatch<SetStateAction<Nullable<string>>>;

  step: MultiStepStatusModalStep;
  setStep: Dispatch<SetStateAction<MultiStepStatusModalStep>>;

  wasEnabled: boolean;
  setWasEnabled: Dispatch<SetStateAction<boolean>>;
};

const MultiStepStatusModalContext = createContext<Optional<TMultiStepStatusModalContext>>(undefined);

type MultiStepModalContextProviderProps = {
  children: ContextChildren<TMultiStepStatusModalContext>;
};

function MultiStepModalContextProvider({ children }: MultiStepModalContextProviderProps) {
  const [operationId, setOperationId] = useState<Nullable<string>>(null);
  const [step, setStep] = useState<MultiStepStatusModalStep>('form');
  const [wasEnabled, setWasEnabled] = useState(false);

  const value = useMemo<TMultiStepStatusModalContext>(() => {
    return { operationId, step, wasEnabled, setOperationId, setStep, setWasEnabled };
  }, [operationId, step, wasEnabled]);

  return (
    <MultiStepStatusModalContext value={value}>
      {typeof children === 'function' ? children(value) : children}
    </MultiStepStatusModalContext>
  );
}

export const useMultiStepStatusModalContext = createUseContext(MultiStepStatusModalContext);

export function withMultiStepModalContext<T extends object>(Form: ComponentType<T>, type: MultiStepStatusModalType) {
  const displayName = Form.displayName || Form.name || 'Component';

  function WrappedComponent(props: T) {
    return (
      <MultiStepModalContextProvider>
        {({ operationId, step, wasEnabled }) => {
          if (step === 'form') return <Form {...props} />;

          return <Status operationId={operationId} type={type} wasEnabled={wasEnabled} />;
        }}
      </MultiStepModalContextProvider>
    );
  }

  WrappedComponent.displayName = `withMultiStepModalContext(${displayName})`;

  return WrappedComponent;
}
