import { type ReactNode, useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { Outlet, useParams } from 'react-router-dom';

import { useRouter } from 'hooks/useRouter';
import { useProxyActionPermissions } from 'modules/proxies/hooks';
import { paths } from 'paths';
import { useGetHostnameIpQuery, useGetProxyQuery } from 'store/api';

type CredentialsGeneratorGuardProps = {
  children?: ReactNode;
};

export function CredentialsGeneratorGuard({ children }: CredentialsGeneratorGuardProps) {
  const [checked, setChecked] = useState(false);
  const { proxyId } = useParams();
  const router = useRouter();

  const { data: proxy, isLoading, isFetching } = useGetProxyQuery(Number(proxyId), { skip: !proxyId });
  const { isLoading: isHostLoading } = useGetHostnameIpQuery(Number(proxyId), { skip: !proxyId });
  const { canProxy } = useProxyActionPermissions(proxy);

  useEffect(() => {
    if (!proxy) return;

    if (!canProxy('generate_credentials')) {
      setChecked(true);

      return router.replace(proxyId ? paths.proxies.overview(proxyId) : paths.proxies.index);
    }

    setChecked(true);
  }, [canProxy, proxy, proxyId, router]);

  if (!checked && (isLoading || isFetching || isHostLoading)) {
    return (
      <Stack component="main" flex="1 1 auto" minHeight="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={64} />
      </Stack>
    );
  }

  if (!checked) return null;

  return children ?? <Outlet />;
}
