import type { ReactNode } from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Card } from 'components/Card';

import { AuthenticationExampleItem } from './AuthneticationExampleItem';

type AuthenticationExampleProps = {
  children: ReactNode;
};

export function AuthenticationExample({ children }: AuthenticationExampleProps) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        '--Card-background': 'rgba(16, 26, 51, 0.04)',
        '--Card-boxShadow': 'none',
        '--Card-paddingBlock': '16px',
        '--Card-paddingInline': '16px',
      }}
    >
      <Stack spacing={2}>
        <Typography variant="headline-2">{t('proxies.credentials.generator.example.title')}</Typography>

        <Stack spacing={2} divider={<Divider sx={{ '--mui-palette-divider': 'rgba(16, 26, 51, 0.12)' }} />}>
          {children}
        </Stack>
      </Stack>
    </Card>
  );
}

AuthenticationExample.Item = AuthenticationExampleItem;
