import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';
import type { ProxyModel } from 'store/proxies/models';

import { useDiagnosticModalContext, withDiagnosticModalContextProvider } from './context';
import { LoadingStep } from './LoadingStep';
import { ResultsStep } from './ResultsStep';
import { SetupStep } from './SetupStep';

export type ProxyAdminDiagnosticModalProps = {
  proxyId: ProxyModel['id'];
};

function ProxyAdminDiagnosticModalComponent({ proxyId }: ProxyAdminDiagnosticModalProps) {
  const { t } = useTranslation();
  const { step } = useDiagnosticModalContext();
  const hideModal = useHideModal();

  const { title, subtitle } = useMemo(() => {
    if (step === 'setup') {
      return { title: t('proxies.overview.admin.actions.diagnostic.setup.title'), subtitle: '' };
    }

    if (step === 'results') {
      return { title: t('proxies.overview.admin.actions.diagnostic.results.title'), subtitle: '' };
    }

    return { title: null, subtitle: null };
  }, [step, t]);

  return (
    <ModalContainer title={title} subtitle={subtitle} onClose={() => hideModal()}>
      {step === 'setup' && <SetupStep proxyId={proxyId} />}

      {step === 'loading' && <LoadingStep />}

      {step === 'results' && <ResultsStep />}
    </ModalContainer>
  );
}

export const ProxyAdminDiagnosticModal = withDiagnosticModalContextProvider(ProxyAdminDiagnosticModalComponent);
