import { useMemo } from 'react';

import Link from '@mui/material/Link';

import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { ModalContainer } from 'components/modals';
import { RouterLink } from 'components/RouterLink';
import { useHideModal } from 'modules/common/Modal';
import { paths } from 'paths';

export function PayoutNotPossibleModal() {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const actions = useMemo(
    () => [
      <Button fullWidth onClick={() => hideModal()}>
        {t('common:buttons.okGotIt')}
      </Button>,
    ],
    [hideModal, t],
  );

  return (
    <ModalContainer
      title={t('referrals.modals.payoutNotPossible.title')}
      subtitle={
        <Trans i18nKey="referrals.modals.payoutNotPossible.subtitle">
          You need to accumulate at least $100 in referral earnings before you can request a payout. Keep referring and
          earning—you&apos;re almost there! You can read more in our{' '}
          <Link component={RouterLink} href={paths.external.termsAndConditions} target="_blank">
            Terms & Conditions
          </Link>
          .
        </Trans>
      }
      actions={actions}
    />
  );
}
