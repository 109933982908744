import { ConfirmationModal, type ConfirmationModalProps } from 'components/modals';
import {
  DownloadInvoicesModal,
  type DownloadInvoicesModalProps,
  PasswordModal,
  type PasswordModalProps,
  SoftwareTwoFactorAuthenticationModal,
  type SoftwareTwoFactorAuthenticationModalProps,
  VerifyEmailModal,
} from 'modules/accounts/modals';
import {
  AddCreditCardModal,
  type AddCreditCardModalProps,
  ManageCreditCardModal,
  type ManageCreditCardModalProps,
  TopUpModal,
  type TopUpModalProps,
} from 'modules/billing/modals';
import {
  CountryRequestModal,
  type CountryRequestModalProps,
  CouponCodeModal,
  type CouponCodeModalProps,
  OrderCheckoutStatusModal,
  type OrderCheckoutStatusModalProps,
} from 'modules/orders/modals';
import {
  ProxyAdminAddRouteModal,
  type ProxyAdminAddRouteModalProps,
  ProxyAdminChangePortModal,
  type ProxyAdminChangePortModalProps,
  ProxyAdminChangelogModal,
  type ProxyAdminChangelogModalProps,
  ProxyAdminDiagnosticModal,
  type ProxyAdminDiagnosticModalProps,
  ProxyAdminEventsModal,
  type ProxyAdminEventsModalProps,
  ProxyAdminRefundModal,
  type ProxyAdminRefundModalProps,
  ProxyAdminReviveModal,
  type ProxyAdminReviveModalProps,
  ProxyAutoExtendBandwidthModal,
  type ProxyAutoExtendBandwidthModalProps,
  ProxyAutoExtendModal,
  type ProxyAutoExtendModalProps,
  ProxyBulkAutoExtendModal,
  type ProxyBulkAutoExtendModalProps,
  ProxyBulkChangeAuthenticationMethodModal,
  type ProxyBulkChangeAuthenticationMethodModalProps,
  ProxyBulkChangeProtocolModal,
  type ProxyBulkChangeProtocolModalProps,
  ProxyBulkExtendBandwidthModal,
  type ProxyBulkExtendBandwidthModalProps,
  ProxyBulkExtendPeriodModal,
  type ProxyBulkExtendPeriodModalProps,
  ProxyChangeAuthenticationMethodModal,
  type ProxyChangeAuthenticationMethodModalProps,
  ProxyChangeProtocolModal,
  type ProxyChangeProtocolModalProps,
  ProxyChangeThreadsModal,
  type ProxyChangeThreadsModalProps,
  ProxyChangeUplinkSpeedModal,
  type ProxyChangeUplinkSpeedModalProps,
  ProxyCopyCredentialsModal,
  type ProxyCopyCredentialsModalProps,
  ProxyDownloadCredentialsModal,
  type ProxyDownloadCredentialsModalProps,
  ProxyExtendBandwidthModal,
  type ProxyExtendBandwidthModalProps,
  ProxyExtendPeriodModal,
  type ProxyExtendPeriodModalProps,
  ProxyGeneratedCredentialsModal,
  type ProxyGeneratedCredentialsModalProps,
  ProxyGlobalIPWhitelistModal,
  type ProxyGlobalIPWhitelistModalProps,
  ProxyIPv6CredentialsModal,
  type ProxyIPv6CredentialsModalProps,
  ProxyReactivateModal,
  type ProxyReactivateModalProps,
  ProxyUpgradeThreadsModal,
  type ProxyUpgradeThreadsModalProps,
  ProxyUpgradeUplinkSpeedModal,
  type ProxyUpgradeUplinkSpeedModalProps,
  ReplaceProxiesProgressModal,
  type ReplaceProxiesProgressModalProps,
} from 'modules/proxies/modals';
import {
  PayoutModal,
  PayoutNotPossibleModal,
  PayoutSetupModal,
  type PayoutSetupModalProps,
} from 'modules/referrals/modals';

import { createModalManager } from './context';
import type { Modal } from './types';

type ModalMapType =
  | Modal<'credit.card.add', AddCreditCardModalProps>
  | Modal<'credit.card.remove', ConfirmationModalProps>
  | Modal<'orders.coupon', CouponCodeModalProps>
  | Modal<'orders.cancel', ConfirmationModalProps>
  | Modal<'proxies.admin.https.toggle', ConfirmationModalProps>
  | Modal<'account.totp', SoftwareTwoFactorAuthenticationModalProps>
  | Modal<'account.password', PasswordModalProps>
  | Modal<'account.id.verification', ConfirmationModalProps>
  | Modal<'account.email.verification'>
  | Modal<'credit.card.manage', ManageCreditCardModalProps>
  | Modal<'account.billing.topUp', TopUpModalProps>
  | Modal<'referrals.payout'>
  | Modal<'referrals.payout-not-possible'>
  | Modal<'referrals.payout-setup', PayoutSetupModalProps>
  | Modal<'account.billing.topUp.confirmation', ConfirmationModalProps>
  | Modal<'proxies.cancel.confirmation', ConfirmationModalProps>
  | Modal<'api-keys.delete', ConfirmationModalProps>
  | Modal<'order.status', OrderCheckoutStatusModalProps>
  | Modal<'order.invoice', ConfirmationModalProps>
  | Modal<'order.no-deals', ConfirmationModalProps>
  | Modal<'account.invoices.download', DownloadInvoicesModalProps>
  | Modal<'order.request.country', CountryRequestModalProps>
  | Modal<'proxies.admin.changelog', ProxyAdminChangelogModalProps>
  | Modal<'proxies.admin.diagnostic', ProxyAdminDiagnosticModalProps>
  | Modal<'proxies.admin.events', ProxyAdminEventsModalProps>
  | Modal<'proxies.admin.port.change', ProxyAdminChangePortModalProps>
  | Modal<'proxies.admin.refund', ProxyAdminRefundModalProps>
  | Modal<'proxies.admin.revive', ProxyAdminReviveModalProps>
  | Modal<'proxies.admin.route.add', ProxyAdminAddRouteModalProps>
  | Modal<'proxies.admin.route.delete', ConfirmationModalProps>
  | Modal<'proxies.authentication-method.change', ProxyChangeAuthenticationMethodModalProps>
  | Modal<'proxies.authentication-method.change.bulk', ProxyBulkChangeAuthenticationMethodModalProps>
  | Modal<'proxies.bandwidth.auto-extend', ProxyAutoExtendBandwidthModalProps>
  | Modal<'proxies.bandwidth.extend', ProxyExtendBandwidthModalProps>
  | Modal<'proxies.bandwidth.extend.bulk', ProxyBulkExtendBandwidthModalProps>
  | Modal<'proxies.credentials.copy', ProxyCopyCredentialsModalProps>
  | Modal<'proxies.credentials.download', ProxyDownloadCredentialsModalProps>
  | Modal<'proxies.credentials.generated', ProxyGeneratedCredentialsModalProps>
  | Modal<'proxies.credentials.ipv6', ProxyIPv6CredentialsModalProps>
  | Modal<'proxies.period.auto-extend', ProxyAutoExtendModalProps>
  | Modal<'proxies.period.auto-extend.bulk', ProxyBulkAutoExtendModalProps>
  | Modal<'proxies.period.extend', ProxyExtendPeriodModalProps>
  | Modal<'proxies.period.extend.bulk', ProxyBulkExtendPeriodModalProps>
  | Modal<'proxies.protocol.change', ProxyChangeProtocolModalProps>
  | Modal<'proxies.protocol.change.bulk', ProxyBulkChangeProtocolModalProps>
  | Modal<'proxies.reactivate', ProxyReactivateModalProps>
  | Modal<'proxies.replace.progress', ReplaceProxiesProgressModalProps>
  | Modal<'proxies.threads.change', ProxyChangeThreadsModalProps>
  | Modal<'proxies.threads.upgrade', ProxyUpgradeThreadsModalProps>
  | Modal<'proxies.uplink-speed.change', ProxyChangeUplinkSpeedModalProps>
  | Modal<'proxies.uplink-speed.upgrade', ProxyUpgradeUplinkSpeedModalProps>
  | Modal<'proxies.whitelist.global', ProxyGlobalIPWhitelistModalProps>;

const { Provider, useShowModal, useHideModal } = createModalManager<ModalMapType>(({ id, params }) => {
  switch (id) {
    case 'credit.card.add':
      return <AddCreditCardModal {...params} />;
    case 'account.billing.topUp':
      return <TopUpModal {...params} />;
    case 'credit.card.manage':
      return <ManageCreditCardModal {...params} />;
    case 'orders.coupon':
      return <CouponCodeModal {...params} />;
    case 'orders.cancel':
    case 'order.no-deals':
    case 'order.invoice':
    case 'credit.card.remove':
    case 'account.id.verification':
    case 'account.billing.topUp.confirmation':
    case 'api-keys.delete':
    case 'proxies.cancel.confirmation':
    case 'proxies.admin.https.toggle':
    case 'proxies.admin.route.delete':
      return <ConfirmationModal {...params} />;
    case 'account.totp':
      return <SoftwareTwoFactorAuthenticationModal {...params} />;
    case 'account.password':
      return <PasswordModal {...params} />;
    case 'referrals.payout':
      return <PayoutModal />;
    case 'referrals.payout-not-possible':
      return <PayoutNotPossibleModal />;
    case 'referrals.payout-setup':
      return <PayoutSetupModal {...params} />;
    case 'order.status':
      return <OrderCheckoutStatusModal {...params} />;
    case 'account.email.verification':
      return <VerifyEmailModal />;
    case 'account.invoices.download':
      return <DownloadInvoicesModal {...params} />;
    case 'order.request.country':
      return <CountryRequestModal {...params} />;
    case 'proxies.admin.changelog':
      return <ProxyAdminChangelogModal {...params} />;
    case 'proxies.admin.diagnostic':
      return <ProxyAdminDiagnosticModal {...params} />;
    case 'proxies.admin.events':
      return <ProxyAdminEventsModal {...params} />;
    case 'proxies.admin.port.change':
      return <ProxyAdminChangePortModal {...params} />;
    case 'proxies.admin.refund':
      return <ProxyAdminRefundModal {...params} />;
    case 'proxies.admin.revive':
      return <ProxyAdminReviveModal {...params} />;
    case 'proxies.admin.route.add':
      return <ProxyAdminAddRouteModal {...params} />;
    case 'proxies.authentication-method.change':
      return <ProxyChangeAuthenticationMethodModal {...params} />;
    case 'proxies.authentication-method.change.bulk':
      return <ProxyBulkChangeAuthenticationMethodModal {...params} />;
    case 'proxies.bandwidth.auto-extend':
      return <ProxyAutoExtendBandwidthModal {...params} />;
    case 'proxies.bandwidth.extend':
      return <ProxyExtendBandwidthModal {...params} />;
    case 'proxies.bandwidth.extend.bulk':
      return <ProxyBulkExtendBandwidthModal {...params} />;
    case 'proxies.credentials.copy':
      return <ProxyCopyCredentialsModal {...params} />;
    case 'proxies.credentials.download':
      return <ProxyDownloadCredentialsModal {...params} />;
    case 'proxies.credentials.generated':
      return <ProxyGeneratedCredentialsModal {...params} />;
    case 'proxies.credentials.ipv6':
      return <ProxyIPv6CredentialsModal {...params} />;
    case 'proxies.period.auto-extend':
      return <ProxyAutoExtendModal {...params} />;
    case 'proxies.period.auto-extend.bulk':
      return <ProxyBulkAutoExtendModal {...params} />;
    case 'proxies.period.extend':
      return <ProxyExtendPeriodModal {...params} />;
    case 'proxies.period.extend.bulk':
      return <ProxyBulkExtendPeriodModal {...params} />;
    case 'proxies.protocol.change':
      return <ProxyChangeProtocolModal {...params} />;
    case 'proxies.protocol.change.bulk':
      return <ProxyBulkChangeProtocolModal {...params} />;
    case 'proxies.reactivate':
      return <ProxyReactivateModal {...params} />;
    case 'proxies.replace.progress':
      return <ReplaceProxiesProgressModal {...params} />;
    case 'proxies.threads.change':
      return <ProxyChangeThreadsModal {...params} />;
    case 'proxies.threads.upgrade':
      return <ProxyUpgradeThreadsModal {...params} />;
    case 'proxies.uplink-speed.change':
      return <ProxyChangeUplinkSpeedModal {...params} />;
    case 'proxies.uplink-speed.upgrade':
      return <ProxyUpgradeUplinkSpeedModal {...params} />;
    case 'proxies.whitelist.global':
      return <ProxyGlobalIPWhitelistModal {...params} />;
    default:
      return null;
  }
});

export { useShowModal, useHideModal };

export default { Provider };
