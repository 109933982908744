import { type ReactNode, useCallback, useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';

import { useNprogress } from 'hooks/useNProgress';
import { useRouter } from 'hooks/useRouter';
import { useFeatureFlag } from 'modules/common/hooks';
import { paths } from 'paths';
import type { FeaturesDTO } from 'store/common/dtos';

type FeatureFlagGuardProps = {
  name: keyof FeaturesDTO;
  children?: ReactNode;
};

export function FeatureFlagGuard({ name, children }: FeatureFlagGuardProps) {
  const [checked, setChecked] = useState(false);
  const router = useRouter();

  const { flags, isFeatureFlagsLoading } = useFeatureFlag();

  useNprogress();

  const check = useCallback(() => {
    if (flags?.[name] === false) {
      return router.replace(paths.index);
    }

    if (!isFeatureFlagsLoading) setChecked(true);
  }, [flags, isFeatureFlagsLoading, name, router]);

  useEffect(() => {
    check();
  }, [check]);

  if (!checked) return null;

  return children ?? <Outlet />;
}
