import { useMemo, useRef } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import type { Action, Nullable } from 'types';

import { BulkActionsMenu } from './BulkActionsMenu';

export type TableItemID = string | number;

type ActionType = {
  action: Action;
};

type ActionsType = {
  actions: Action[];
};

export type BulkActionsType = (ActionType | ActionsType) & {
  selectedOne: string;
  selectedMultiple: string;
  selectedAll: string;
  selectAllAction: string;
};

export type BulkActionsProps = BulkActionsType & {
  ids?: TableItemID[];
  total: number;
  isOpen?: boolean;
  isAllSelected: boolean;
  onSelectAll: () => void;
  onCloseClick: () => void;
};

export function BulkActions({
  ids = [],
  isOpen = false,
  selectedOne,
  selectedMultiple,
  selectedAll,
  selectAllAction,
  total,
  isAllSelected,
  onCloseClick,
  onSelectAll,
  ...props
}: BulkActionsProps) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const containerRef = useRef<Nullable<HTMLDivElement>>(null);

  const label = useMemo(() => {
    if (isAllSelected) {
      return selectedAll;
    }

    return ids.length === 1 ? selectedOne : selectedMultiple;
  }, [ids.length, isAllSelected, selectedAll, selectedMultiple, selectedOne]);

  return (
    <Snackbar ref={containerRef} open={isOpen}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={2}
        justifyContent="space-between"
        alignItems={{ md: 'center' }}
        flex="1 1 auto"
      >
        <Stack
          direction="row"
          spacing={2}
          flex="1 1 auto"
          alignItems="center"
          justifyContent={{ xs: 'space-between', md: 'flex-start' }}
        >
          <Stack direction="row" spacing={2} flex="1 1 auto" alignItems="center">
            <Chip label={isAllSelected ? total : ids.length} color="primary" />

            <Stack>
              <Typography variant="subtitle">{label}</Typography>

              {/* <Fade in={!isAllSelected} unmountOnExit>
                <Link variant="body-2" onClick={() => onSelectAll()}>
                  {selectAllAction}
                </Link>
              </Fade> */}
            </Stack>
          </Stack>

          <Fade in={isMobile} unmountOnExit>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              iconOnly
              startIcon="cross"
              onClick={onCloseClick}
            />
          </Fade>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          flex="1 1 auto"
          alignItems="center"
          justifyContent={{ xs: 'space-between', md: 'flex-start' }}
        >
          {'action' in props ? (
            <Button size="medium" onClick={props.action.onClick}>
              {props.action.label}
            </Button>
          ) : (
            <Box flex="1 1 auto">
              {props.actions.length > 0 && (
                <BulkActionsMenu
                  actions={props.actions}
                  anchorRef={containerRef}
                  MenuProps={{
                    anchorOrigin: { vertical: -16, horizontal: 'right' },
                    transformOrigin: { vertical: 'bottom', horizontal: 'right' },
                  }}
                  renderIndicator={({ onClick }) => (
                    <Button size="medium" onClick={(e) => onClick(e)}>
                      {t('common:buttons.takeAnAction')}...
                    </Button>
                  )}
                />
              )}
            </Box>
          )}

          <Fade in={!isMobile} unmountOnExit>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              iconOnly
              startIcon="cross"
              onClick={onCloseClick}
            />
          </Fade>
        </Stack>
      </Stack>
    </Snackbar>
  );
}
