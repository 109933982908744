import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Trans, useTranslation } from 'react-i18next';

import { useBalance } from 'modules/accounts/hooks';
import { TopUpButton } from 'modules/billing/TopUpButton';
import { CurrencyFormatter } from 'utils/currency';

type BalanceSummaryProps = {
  price?: number;
  isLoading?: boolean;
};

export function BalanceSummary({ price = 0, isLoading }: BalanceSummaryProps) {
  const { t } = useTranslation();
  const { balance, isBalanceLoading } = useBalance();

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="title" color="var(--mui-palette-text-primary)">
          {t('common:paymentMethods.balance')}
        </Typography>

        {isLoading || isBalanceLoading ? (
          <Skeleton height={20} width={100} />
        ) : (
          <Typography
            variant="subtitle"
            color={price > balance ? 'var(--mui-palette-error-main)' : 'var(--mui-palette-text-primary)'}
          >
            {CurrencyFormatter.format(balance)}
          </Typography>
        )}
      </Stack>

      <Collapse in={price > balance} unmountOnExit>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
            <Trans i18nKey="common:insufficientFunds.extend">
              <Typography variant="subtitle" color="var(--mui-palette-error-main)">
                Insufficient funds:
              </Typography>
              Top Up your balance in order to extend services.
            </Trans>
          </Typography>

          <TopUpButton size="medium" />
        </Stack>
      </Collapse>
    </Stack>
  );
}
