/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from 'react';

import { useGetBulkOperationQuery, useGetBulkOperationTasksQuery } from 'store/api';

export function useGetBulkOperationDetails(operationId?: string) {
  const {
    data: operation,
    isFetching: isOperationFetching,
    isLoading: isOperationLoading,
  } = useGetBulkOperationQuery(operationId!, { skip: !operationId });
  const {
    data: tasks,
    isFetching: areTasksFetching,
    isLoading: areTasksLoading,
  } = useGetBulkOperationTasksQuery(operationId!, { skip: !operationId });

  const isFetching = useMemo(() => {
    return isOperationFetching || areTasksFetching;
  }, [areTasksFetching, isOperationFetching]);

  const isLoading = useMemo(() => {
    return isOperationLoading || areTasksLoading;
  }, [areTasksLoading, isOperationLoading]);

  return useMemo(() => {
    return { operation, tasks, isFetching, isLoading };
  }, [isFetching, isLoading, operation, tasks]);
}
