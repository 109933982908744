import { paths } from 'paths';
import type { PlanModel } from 'store/orders/models';
import type { OrderStep } from 'store/orders/types';

export const ORDER_PATH_TO_STEP_MAP: Record<string, OrderStep> = {
  [paths.order.checkout]: 'checkout',
  [paths.order.details]: 'details',
  [paths.order.index]: 'setup',
};

export const ORDER_STEP_TO_PATH_MAP: Record<OrderStep, string> = {
  checkout: paths.order.checkout,
  details: paths.order.details,
  setup: paths.order.index,
};

export const POSTHOG_ORDER_FLOW_TEST_KEY = 'order-flow-experiment';

export const VPN_PLANS: PlanModel[] = [
  {
    id: 'vpn-1m',
    label: '1m',
    isAvailable: true,
    pricing: {
      discount: 0,
      subtotal: 2.99,
      subtotalAfterDiscount: 2.99,
      perUnit: 2.99,
      perUnitAfterDiscount: 2.99,
      total: 2.99,
      discountAmount: 0,
      unit: 'month',
    },
  },
  {
    id: 'vpn-12m',
    label: '12m',
    isAvailable: true,
    pricing: {
      discount: 0,
      subtotal: 1.99,
      subtotalAfterDiscount: 1.99,
      perUnit: 1.99,
      perUnitAfterDiscount: 1.99,
      total: 1.99,
      discountAmount: 0,
      unit: 'month',
    },
  },
];
