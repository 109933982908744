import { useCallback, useMemo, useRef } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { posthogEvents } from 'constants/posthogEvents';
import type {
  PacketStreamAccessConfig,
  PacketStreamCredentialsGeneratorValues,
} from 'modules/proxies/generators/types';
import type { HostnameModel, ProxyOverviewModel } from 'store/proxies/models';
import { Protocol } from 'store/proxies/types';

import { PACKETSTREAM_CREDENTIALS_GENERATOR_COUNTRIES } from './constants';
import { CredentialsGenerator } from './CredentialsGenerator';
import { GeneratorForm } from './GeneratorForm';
import { getCredentialsGeneratorPostHogEventValues } from '../helpers';

type PacketStreamGeneratorProps = {
  proxy?: ProxyOverviewModel;
  host?: HostnameModel[];

  onGenerate: (credentials: PacketStreamAccessConfig[]) => void;
};

type HandleSubmit = FormikConfig<PacketStreamCredentialsGeneratorValues>['onSubmit'];

export function PacketStreamGenerator({ proxy, host, onGenerate }: PacketStreamGeneratorProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const generator = useRef(new CredentialsGenerator()).current;

  const initialValues = useMemo<PacketStreamCredentialsGeneratorValues>(() => {
    return {
      connection: Protocol.HTTP,
      country: 'random',
      session: 'random',
      hostname: 'dns',

      proxyIp: 'proxy.proxy-cheap.com',
      port: 31112,
      username: proxy?.authentication.username ?? '',
      password: proxy?.authentication.password ?? '',

      count: 50,
    };
  }, [proxy?.authentication.password, proxy?.authentication.username]);

  const getSelectedCountry = useCallback((country: string) => {
    if (country === 'random') return country;

    return PACKETSTREAM_CREDENTIALS_GENERATOR_COUNTRIES.find(({ value }) => value === country)?.label ?? '';
  }, []);

  const validationSchema = useMemo<Yup.Schema>(() => {
    return Yup.object().shape({
      count: Yup.number().max(100).required(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    (values, { setSubmitting }) => {
      if (!proxy) return;

      const { connection, count, country, hostname, password, session, username } = values;

      const hostnameIp = host?.[0].hostnameIp ?? null;

      posthog.capture(
        posthogEvents.proxy.credentialsGenerator.generated,
        getCredentialsGeneratorPostHogEventValues({ networkType: proxy?.networkType, type: 'packet-stream', values }),
      );

      generator.setOptions({
        connection,
        country: t(getSelectedCountry(country), { lng: 'en' }),
        hostname,
        password,
        session,
        username,
        hostnameIp,
      });

      const credentials = Array.from(Array(count).keys()).map(() => generator.build());

      onGenerate(credentials);

      setSubmitting(false);
    },
    [generator, getSelectedCountry, host, onGenerate, posthog, proxy, t],
  );

  return (
    <Formik<PacketStreamCredentialsGeneratorValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <GeneratorForm host={host} generator={generator} />
    </Formik>
  );
}
