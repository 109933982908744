import {
  type ComponentType,
  createContext,
  type Dispatch,
  type SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import type { FormikErrors } from 'formik';

import { useRunDiagnosticRoutineMutation } from 'store/api';
import type { DiagnosticRoutineModel } from 'store/proxies/models';
import type { ContextChildren } from 'types';
import { createUseContext } from 'utils/context';
import { getValidationErrors } from 'utils/error';

import type { DiagnosticModalStep, DiagnosticValues } from './types';

type TDiagnosticModalContext = {
  step: DiagnosticModalStep;
  results: DiagnosticRoutineModel[];
  errors: FormikErrors<DiagnosticValues>;

  onDiagnosticRun: (proxyId: number, url?: string) => Promise<void>;
  setStep: Dispatch<SetStateAction<DiagnosticModalStep>>;
};

const DiagnosticModalContext = createContext<TDiagnosticModalContext | undefined>(undefined);

export type DiagnosticModalContextProviderProps = {
  children: ContextChildren<TDiagnosticModalContext>;
};

function DiagnosticModalContextProvider({ children }: DiagnosticModalContextProviderProps) {
  const [step, setStep] = useState<DiagnosticModalStep>('setup');
  const [results, setResults] = useState<DiagnosticRoutineModel[]>([]);
  const [errors, setErrors] = useState<FormikErrors<DiagnosticValues>>({});

  const [runDiagnosticRoutineMutation] = useRunDiagnosticRoutineMutation();

  const onDiagnosticRun = useCallback(
    async (proxyId: number, url?: string) => {
      try {
        setStep('loading');

        const response = await runDiagnosticRoutineMutation({ proxyId, url: url?.length ? url : undefined }).unwrap();

        setStep('results');
        setResults(response);
      } catch (error) {
        const formErrors = getValidationErrors(error);

        if (formErrors && Object.keys(formErrors).length > 0) {
          setErrors(formErrors);
        }

        setStep('setup');
      }
    },
    [runDiagnosticRoutineMutation],
  );

  const value = useMemo<TDiagnosticModalContext>(() => {
    return { step, results, errors, onDiagnosticRun, setStep };
  }, [errors, onDiagnosticRun, results, step]);

  return (
    <DiagnosticModalContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </DiagnosticModalContext.Provider>
  );
}

export const useDiagnosticModalContext = createUseContext(DiagnosticModalContext);

export function withDiagnosticModalContextProvider<T extends object>(Component: ComponentType<T>) {
  const displayName = Component.displayName || Component.name || 'Component';

  function WrappedComponent(props: T) {
    return (
      <DiagnosticModalContextProvider>
        <Component {...props} />
      </DiagnosticModalContextProvider>
    );
  }

  WrappedComponent.displayName = `withDiagnosticModalContextProvider(${displayName})`;

  return WrappedComponent;
}
