import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { posthogEvents } from 'constants/posthogEvents';
import { useGetGlobalIPWhitelistQuery, useUpdateGlobalIPWhitelistMutation } from 'store/api';
import type { UpdateGlobalIPWhitelistPayload } from 'store/proxies/payloads';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import { GlobalIPWhitelistForm, type GlobalIPWhitelistValues } from './GlobalIPWhitelistForm';

type HandleSubmit = FormikConfig<GlobalIPWhitelistValues>['onSubmit'];

export type ProxyGlobalIPWhitelistModalProps = {
  onConfirm: () => void;
};

export function ProxyGlobalIPWhitelistModal({ onConfirm }: ProxyGlobalIPWhitelistModalProps) {
  const { t } = useTranslation();
  const { data } = useGetGlobalIPWhitelistQuery();
  const posthog = usePostHog();
  const [updateGlobalIPWhitelist] = useUpdateGlobalIPWhitelistMutation();

  const initialValues = useMemo<GlobalIPWhitelistValues>(() => {
    if (!data) return { ipWhitelist: ['', '', ''] };

    const ipWhitelist = Array.from(Array(data.allowedWhitelistIpCount)).map(() => '');

    if (!data.whitelistedIps.length) {
      return { ipWhitelist };
    }

    return {
      ipWhitelist: ipWhitelist.map((item, i) => data.whitelistedIps[i] ?? item),
    };
  }, [data]);

  const validationSchema = useMemo<Yup.Schema<GlobalIPWhitelistValues>>(() => {
    return Yup.object().shape({
      ipWhitelist: Yup.array().of(Yup.string().default('').optional()).required(),
    });
  }, []);

  const mapResponseKeyIntoFormKey = useCallback(
    (key: keyof UpdateGlobalIPWhitelistPayload): keyof GlobalIPWhitelistValues => {
      if (key === 'ips') return 'ipWhitelist';

      throw new Error('Unsupported key');
    },
    [],
  );

  const onSubmit = useCallback<HandleSubmit>(
    async ({ ipWhitelist }, { setErrors, setSubmitting }) => {
      try {
        await updateGlobalIPWhitelist({ ips: ipWhitelist.filter((i) => Boolean(i.length)) }).unwrap();

        posthog?.capture(posthogEvents.proxy.globalIPWhitelist.success);

        ToastManager.success(t('proxies.modals.globalIPWhitelist.success'));
        onConfirm();
      } catch (error) {
        const errors = getValidationErrors(error, mapResponseKeyIntoFormKey);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        posthog?.capture(posthogEvents.proxy.globalIPWhitelist.failed);

        setSubmitting(false);
      }
    },
    [mapResponseKeyIntoFormKey, onConfirm, posthog, t, updateGlobalIPWhitelist],
  );

  return (
    <Formik<GlobalIPWhitelistValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      validateOnChange
    >
      <GlobalIPWhitelistForm />
    </Formik>
  );
}
