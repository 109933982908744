import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useHideModal } from 'modules/common/Modal';
import { useReviveProxyMutation } from 'store/api';
import type { ProxyOverviewModel } from 'store/proxies/models';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import { ReviveForm, type ReviveValues } from './ReviveForm';

type HandleSubmit = FormikConfig<ReviveValues>['onSubmit'];

export type ProxyAdminReviveModalProps = {
  proxy: ProxyOverviewModel;
};

export function ProxyAdminReviveModal({ proxy }: ProxyAdminReviveModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const [reviveProxy] = useReviveProxyMutation();

  const initialValues = useMemo<ReviveValues>(() => {
    return { reason: '', additionalDays: 0 };
  }, []);

  const validationSchema = useMemo<Yup.Schema<ReviveValues>>(() => {
    return Yup.object().shape({
      reason: Yup.string().required(),
      additionalDays: Yup.number().min(0).required(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ additionalDays, reason }, { setErrors, setSubmitting }) => {
      try {
        await reviveProxy({ proxyId: proxy.id, reason, additionalDays }).unwrap();

        ToastManager.success(t('proxies.overview.admin.modals.revive.success'));

        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        ToastManager.error(t('proxies.overview.admin.modals.revive.failure'));
      } finally {
        setSubmitting(false);
      }
    },
    [hideModal, proxy.id, reviveProxy, t],
  );

  return (
    <Formik<ReviveValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <ReviveForm />
    </Formik>
  );
}
