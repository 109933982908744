import { useMemo } from 'react';

import Stack from '@mui/material/Stack';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikInput, FormikNumberInput } from 'components/form-partials/formik';
import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';

export type ReviveValues = {
  reason: string;
  additionalDays: number;
};

export function ReviveForm() {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { handleSubmit, isSubmitting, isValid } = useFormikContext<ReviveValues>();

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="confirm" type="submit" fullWidth loading={isSubmitting} disabled={!isValid}>
        {t('common:buttons.confirm')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.overview.admin.modals.revive.title')}
      actions={actions}
    >
      <Stack spacing={4}>
        <FormikInput name="reason" multiline rows={4} label={t('common:form.reason')} />

        <FormikNumberInput name="additionalDays" label={t('common:form.additionalDays')} />
      </Stack>
    </ModalContainer>
  );
}
