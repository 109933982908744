import isNil from 'lodash/isNil';

import { NetworkType } from 'store/proxies/types';
import type { Nullable } from 'types';

import type {
  AccessConfig,
  CredentialsGeneratorFormat,
  CredentialsType,
  NetNutAccessConfig,
  PacketStreamAccessConfig,
} from './types';

function isNetNutCredential(item: AccessConfig): item is NetNutAccessConfig {
  return item.type === 'netnut';
}

function isPacketStreamCredential(item: AccessConfig): item is PacketStreamAccessConfig {
  return item.type === 'netnut';
}

type FormatArgs = {
  type: CredentialsType;
  credentials: AccessConfig[];
  format: CredentialsGeneratorFormat;
  networkType?: NetworkType;
};

export class CredentialsFormatter {
  static format({ credentials, type, format, networkType }: FormatArgs): string[] {
    const netNutCredentials = this.getNetNutCredentials(credentials);
    const packetStreamCredentials = this.getPacketStreamCredentials(credentials);

    if (type === 'netnut') {
      return netNutCredentials
        .map((item) => this.formatNetNutCredential(item, format, networkType))
        .filter((v) => !isNil(v));
    }

    return packetStreamCredentials.map((item) => this.formatPacketStreamCredential(item, format));
  }

  private static getNetNutCredentials(credentials: AccessConfig[]): NetNutAccessConfig[] {
    return credentials.filter(isNetNutCredential);
  }

  private static getPacketStreamCredentials(credentials: AccessConfig[]): PacketStreamAccessConfig[] {
    return credentials.filter(isPacketStreamCredential);
  }

  private static formatNetNutCredential(
    item: NetNutAccessConfig,
    format: CredentialsGeneratorFormat,
    networkType?: NetworkType,
  ): Nullable<string> {
    if (networkType === NetworkType.Mobile) {
      return this.formatNetNutMobileCredential(item, format);
    }

    if (networkType === NetworkType.Residential) {
      return this.formatNetNutResidentialCredential(item, format);
    }

    return null;
  }

  private static formatPacketStreamCredential(
    item: PacketStreamAccessConfig,
    format: CredentialsGeneratorFormat,
  ): string {
    const { ip, port, username, password } = item;

    if (format === 'ip:port:username:password') {
      return `${ip}:${port}:${username}:${password}`;
    }

    return `${username}:${password}@${ip}:${port}`;
  }

  private static formatNetNutMobileCredential(item: NetNutAccessConfig, format: CredentialsGeneratorFormat) {
    const { asn, city, country, id, mob, password, port, state, username, proxyHostname } = item;

    if (format === 'ip:port:username:password') {
      return `${proxyHostname}:${port}:${username}${mob}${country}${state}${city}${asn}${id}:${password}`;
    }

    return `${username}${mob}${country}${state}${city}${asn}${id}:${password}@${proxyHostname}:${port}`;
  }

  private static formatNetNutResidentialCredential(item: NetNutAccessConfig, format: CredentialsGeneratorFormat) {
    const { asn, city, country, id, password, port, res, state, username, proxyHostname } = item;

    if (format === 'ip:port:username:password') {
      return `${proxyHostname}:${port}:${username}${res}${country}${state}${city}${asn}${id}:${password}`;
    }

    return `${username}${res}${country}${state}${city}${asn}${id}:${password}@${proxyHostname}:${port}`;
  }
}
