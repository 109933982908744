import { t } from 'i18next';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

import { COUNTRIES } from 'constants/countries';
import type { PaymentOption } from 'modules/billing/types';
import { ORDER_PATH_TO_STEP_MAP, ORDER_STEP_TO_PATH_MAP } from 'modules/orders/constants';
import type { OrderSetupValues } from 'modules/orders/types';
import { paths } from 'paths';
import type { OrderStep, PlanID, ServiceID } from 'store/orders/types';
import type { ProxyISPModel } from 'store/proxies/models';
import type { Nullable, Option } from 'types';
import { parseTimePeriod } from 'utils/values';

export function isOrderStateMalformed(
  serviceId: ServiceID,
  planId: Nullable<PlanID>,
  setup: Nullable<OrderSetupValues>,
): boolean {
  switch (serviceId) {
    case 'vpn':
      return !isNil(planId) || !isNil(setup);
    case 'rotating-mobile':
    case 'rotating-residential':
    case 'static-datacenter-ipv6':
      return !isNil(planId) || isNil(setup);
    case 'static-datacenter-ipv4':
    case 'static-residential-ipv4':
      return isNil(planId) || isNil(setup);
    default:
      return false;
  }
}

export function isOrderStepMalformed(currentStep: OrderStep, pathname: string) {
  switch (currentStep) {
    case 'checkout':
      return !isEqual(pathname, paths.order.checkout);
    case 'details':
      return !isEqual(pathname, paths.order.details);
    case 'setup':
      return !isEqual(pathname, paths.order.index);
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`${currentStep} is not supported - Please do not mess with the logic here.`);
  }
}

export function getOrderStep(path: string): Nullable<OrderStep> {
  const currentStep = ORDER_PATH_TO_STEP_MAP[path];

  return currentStep ?? null;
}

export function getOrderPath(step: OrderStep): string {
  return ORDER_STEP_TO_PATH_MAP[step];
}

export function getHumanLikeCountry(defaultValue?: string, country?: Option<string>) {
  if (!country) return defaultValue;

  return t(country.label, { lng: 'en' });
}

export function getHumanLikeIsp(defaultValue?: string, isp?: ProxyISPModel) {
  if (!isp) return defaultValue;

  return isp.name;
}

export function getHumanLikeTimePeriod(timePeriod: Nullable<string>) {
  const parsedTimePeriod = parseTimePeriod(timePeriod);

  if (!parsedTimePeriod) return timePeriod;

  const { value, format } = parsedTimePeriod;

  return format === 'days' ? 'One week trial' : t('common:form.month', { count: value, lng: 'en' });
}

type OrderSetupPostHogEventValuesArgs = {
  serviceId: ServiceID;
  planId: Nullable<PlanID>;
  values: Nullable<OrderSetupValues>;
  isps?: ProxyISPModel[];
  isNewCustomer: boolean;
  isMarketplaceFlow: boolean;
  isSameSubnetFlow: boolean;
  originProxyId: Nullable<number | string>;
  couponCode: Nullable<string>;
  userId?: number;
  paymentType: Nullable<PaymentOption>;

  discount: number;
  discountAmount: number;
  unitPrice: number;
  totalPrice: number;
};

export function getOrderSetupPostHogEventValues({
  serviceId,
  planId,
  values,
  isps = [],
  paymentType,
  isNewCustomer,
  isMarketplaceFlow,
  isSameSubnetFlow,
  originProxyId,
  couponCode,
  discount,
  discountAmount,
  unitPrice,
  totalPrice,
  userId,
}: OrderSetupPostHogEventValuesArgs) {
  const country = COUNTRIES.find((c) => isEqual(c.value, values?.country));
  const isp = isps.find((i) => isEqual(i.id, values?.isp));

  const basePayload = {
    serviceId: t(`order.v2.setup.services.list.${serviceId}`, { lng: 'en' }),
    isNewCustomer: isNewCustomer ? 'Yes' : 'No',
    isMarketplaceFlow: isMarketplaceFlow ? 'Yes' : 'No',
    isSameSubnetFlow: isSameSubnetFlow ? 'Yes' : 'No',
    originProxyId,
    isAutoExtendEnabled: values?.isAutoExtendEnabled ? 'Yes' : 'No',
    paymentType,
    discount,
    discountAmount,
    unitPrice,
    totalPrice,
    ...(userId && { userId }),
    ...(couponCode && { couponCode }),
  };

  if (serviceId === 'rotating-mobile') {
    return {
      ...basePayload,
      traffic: `${values?.bandwidth} GB`,
      autoExtendTraffic: `${values?.autoExtendBandwidth} GB`,
    };
  }

  if (serviceId === 'rotating-residential') {
    return {
      ...basePayload,
      traffic: `${values?.bandwidth} GB`,
      autoExtendTraffic: `${values?.autoExtendBandwidth} GB`,
    };
  }

  if (serviceId === 'static-datacenter-ipv4') {
    return {
      ...basePayload,
      planId: t(`order.v2.setup.plans.list.${planId}`, { lng: 'en' }),
      country: values?.country === null ? 'Random' : getHumanLikeCountry(values?.country, country),
      quantity: t('common:form.ip', { lng: 'en', count: values?.quantity }),
      period: getHumanLikeTimePeriod(values?.period ?? null),
      ...(planId === 'basic' && { additionalTraffic: `${values?.additionalBandwidth}GB` }),
    };
  }

  if (serviceId === 'static-datacenter-ipv6') {
    return {
      ...basePayload,
      country: values?.country === null ? 'Random' : getHumanLikeCountry(values?.country, country),
      package: values?.package,
      period: getHumanLikeTimePeriod(values?.period ?? null),
    };
  }

  if (serviceId === 'static-residential-ipv4') {
    return {
      ...basePayload,
      planId: t(`order.v2.setup.plans.list.${planId}`, { lng: 'en' }),
      country: values?.country === null ? 'Random' : getHumanLikeCountry(values?.country, country),
      isp: values?.isp === null ? 'Random' : getHumanLikeIsp(values?.isp, isp),
      period: getHumanLikeTimePeriod(values?.period ?? null),
      quantity: t('common:form.ip', { lng: 'en', count: values?.quantity }),

      ...(planId === 'basic' && { additionalTraffic: `${values?.additionalBandwidth}GB` }),
    };
  }
}
