import { useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'components/Dropdown';
import { Flag } from 'components/Flag';
import { Icon } from 'components/Icon';
import { StatusIndicator } from 'components/StatusIndicator';
import { getStatusColor } from 'components/StatusIndicator/helpers';
import type { TableColumn } from 'components/Table';
import { ReactivateButton } from 'modules/proxies/components';
import { ProxyActionModel, ProxyStatus, type ProxyModel } from 'store/proxies/models';
import { toDashboardMaintenanceStatus } from 'utils/maintenance';

import { useGetAvailableProxyActions } from './useGetAvailableProxyActions';

export function useProxiesTable() {
  const { t } = useTranslation();

  const getAvailableProxyActions = useGetAvailableProxyActions();

  const renderFlag = useCallback(
    (item: ProxyModel) => {
      return (
        <Tooltip title={t(item.location.country?.label ?? 'common:labels.rotating')}>
          <Box display="inline-flex" onClick={(e) => e.stopPropagation()}>
            {!item.location.country ? (
              <Icon name="repeat" size={16} color="var(--mui-palette-neutral-500)" />
            ) : (
              <Flag size={16} countryCode={item.location.country.value} />
            )}
          </Box>
        </Tooltip>
      );
    },
    [t],
  );

  const renderMaintenance = useCallback(
    (item: ProxyModel) => {
      const maintenanceStatus = toDashboardMaintenanceStatus(item.maintenanceWindows);

      if (!maintenanceStatus) return null;

      const date = `${dayjs(maintenanceStatus.start).format('YYYY-MM-DD HH:mm')} - ${dayjs(
        maintenanceStatus.end,
      ).format('YYYY-MM-DD HH:mm')}`;

      const note =
        maintenanceStatus.replacementNote ?? maintenanceStatus.note ?? t('common:maintenance.proxy', { date });

      return (
        <Tooltip title={note}>
          <Box display="inline-flex" onClick={(e) => e.stopPropagation()}>
            <Icon name="danger-light" size={16} />
          </Box>
        </Tooltip>
      );
    },
    [t],
  );

  const renderProxyId = useCallback((item: ProxyModel) => item.id, []);

  const renderNote = useCallback((item: ProxyModel) => {
    if (!item.note) return '-';

    if (item.note.length < 15) {
      return item.note;
    }

    return (
      <Tooltip title={item.note}>
        <Box display="inline-flex" onClick={(e) => e.stopPropagation()}>
          <Typography variant="body-2" color="text.primary">
            {item.note.substring(0, 15)}
            {item.note.length > 15 ? '...' : ''}
          </Typography>
        </Box>
      </Tooltip>
    );
  }, []);

  const renderPublicIP = useCallback((item: ProxyModel) => item.connection.publicIp ?? item.connection.lastIp, []);

  const renderNetwork = useCallback(
    (item: ProxyModel) => {
      return (
        <Chip
          label={t(`common:proxy.${item.networkType}`)}
          // sx={(theme) => ({ paddingTop: theme.spacing(0.25), paddingBottom: theme.spacing(0.25), height: 'auto' })}
        />
      );
    },
    [t],
  );

  const renderIPVersion = useCallback((item: ProxyModel) => t(`common:ipVersion.${item.connection.ipVersion}`), [t]);

  const renderProxyType = useCallback((item: ProxyModel) => item.proxyType, []);

  const renderBandwidth = useCallback(
    (item: ProxyModel) => {
      if (item.bandwidth.total) {
        return t('common:form.gb', {
          capacity: item.bandwidth.used ? `${item.bandwidth.used}/${item.bandwidth.total}` : item.bandwidth.total,
          interpolation: { escapeValue: false },
        });
      }

      return t('common:bandwidth.unlimited');
    },
    [t],
  );

  const renderExpires = useCallback((item: ProxyModel) => {
    if (item.expiresAt) return dayjs(item.expiresAt).format('YYYY-MM-DD HH:mm');

    return '-';
  }, []);

  const renderStatus = useCallback(
    (item: ProxyModel) => {
      return (
        <Tooltip title={capitalize(t(`common:proxyStatus:${item.status}`).toLowerCase())}>
          <Box display="inline-flex" onClick={(e) => e.stopPropagation()}>
            <StatusIndicator {...getStatusColor(item.status)} />
          </Box>
        </Tooltip>
      );
    },
    [t],
  );

  const renderActions = useCallback(
    (item: ProxyModel) => {
      const actions = getAvailableProxyActions([item]);

      const isProxyExpired =
        [ProxyStatus.CANCELED, ProxyStatus.EXPIRED].includes(item.status) &&
        item.actions.includes(ProxyActionModel.REACTIVATE);

      if (isProxyExpired) {
        return <ReactivateButton size="small" proxyId={item.id} />;
      }

      if (!actions.length) return null;

      return (
        <Dropdown
          actions={actions}
          renderIndicator={({ ref, onClick }) => (
            <IconButton
              ref={ref}
              onClick={(e) => {
                e.stopPropagation();
                onClick(e);
              }}
            >
              <Icon name="more" size="small" />
            </IconButton>
          )}
        />
      );
    },
    [getAvailableProxyActions],
  );

  const columns = useMemo<Array<TableColumn<ProxyModel>>>(() => {
    return [
      { key: 'flag', width: 16, align: 'center', renderCell: renderFlag },
      { key: 'maintenance', width: 16, renderCell: renderMaintenance },
      { key: 'proxyId', title: t('dashboard.overview.recent.table.proxyId'), renderCell: renderProxyId },
      { key: 'note', title: t('dashboard.overview.recent.table.name'), renderCell: renderNote },
      { key: 'publicIp', title: t('dashboard.overview.recent.table.publicIp'), renderCell: renderPublicIP },
      { key: 'network', title: t('dashboard.overview.recent.table.network'), renderCell: renderNetwork },
      { key: 'ipVersion', title: t('dashboard.overview.recent.table.ipVersion'), renderCell: renderIPVersion },
      { key: 'proxyType', title: t('dashboard.overview.recent.table.connectionType'), renderCell: renderProxyType },
      { key: 'bandwidth', title: t('dashboard.overview.recent.table.bandwidth'), renderCell: renderBandwidth },
      { key: 'expires', title: t('dashboard.overview.recent.table.expires'), renderCell: renderExpires },
      { key: 'status', width: 24, renderCell: renderStatus },
      { key: 'actions', align: 'right' as const, renderCell: renderActions },
    ];
  }, [
    renderActions,
    renderBandwidth,
    renderExpires,
    renderFlag,
    renderIPVersion,
    renderMaintenance,
    renderNetwork,
    renderNote,
    renderProxyId,
    renderProxyType,
    renderPublicIP,
    renderStatus,
    t,
  ]);

  return useMemo(() => ({ columns }), [columns]);
}
