import { type MouseEvent, type RefObject, type ReactNode, useCallback, useState } from 'react';

import { boxClasses } from '@mui/material/Box';
import Menu, { type MenuProps as MuiMenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack, { stackClasses } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Icon } from 'components/Icon';
import type { Action, Nullable } from 'types';

type IndicatorProps = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

type BulkActionsMenuProps<T extends ReactNode> = {
  actions: Action[];
  anchorRef: RefObject<Nullable<HTMLDivElement>>;
  renderIndicator: (props: IndicatorProps) => T;
  MenuProps?: Partial<MuiMenuProps>;
};

export function BulkActionsMenu<T extends ReactNode>({
  actions,
  anchorRef,
  MenuProps,
  renderIndicator,
}: BulkActionsMenuProps<T>) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const handleOpenMenu = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsMenuVisible((prev) => !prev);
  }, []);

  const handleCloseMenu = useCallback((e: MouseEvent<HTMLDivElement>, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') e.stopPropagation();

    setIsMenuVisible(false);
  }, []);

  return (
    <>
      {renderIndicator({ onClick: handleOpenMenu })}

      <Menu
        anchorEl={anchorRef.current}
        {...MenuProps}
        open={isMenuVisible}
        onClose={handleCloseMenu}
        slotProps={{ ...MenuProps?.slotProps, list: { sx: { width: anchorRef.current?.offsetWidth } } }}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            onClick={(e) => {
              e.stopPropagation();

              setIsMenuVisible(false);

              action.onClick(e);
            }}
            sx={(theme) => ({
              color: 'var(--mui-palette-text-secondary)',
              transition: theme.transitions.create(['color'], { duration: 150 }),

              [`& > .${stackClasses.root} >  .${boxClasses.root}`]: {
                transition: theme.transitions.create(['color'], { duration: 150 }),
              },

              '&:hover': {
                color: 'var(--mui-palette-text-primary)',

                [`& > .${stackClasses.root} >  .${boxClasses.root}`]: {
                  color: 'var(--mui-palette-primary-main)',
                },
              },
            })}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              {action.icon && <Icon size="small" name={action.icon} />}

              <Typography variant="body-2" color="text.secondary">
                {action.label}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
