import type { CredentialsGeneratorHostname, NetNutAccessConfig, NetNutSession } from 'modules/proxies/generators/types';
import { type NetNutHostnameRegion, Protocol } from 'store/proxies/types';
import type { Nullable } from 'types';

type CredentialGeneratorOptions = {
  username: string;
  password: string;
  country: string;
  connection: Protocol;
  state: string;
  city: string;
  session: NetNutSession;
  hostname: CredentialsGeneratorHostname;
  region: NetNutHostnameRegion;
  hostnameIp?: Nullable<string>;
  asn: string;
};

export class CredentialsGenerator {
  private options: Nullable<CredentialGeneratorOptions> = null;

  setOptions(options: Nullable<CredentialGeneratorOptions>) {
    this.options = options;
  }

  public build(): NetNutAccessConfig {
    if (!this.options) throw new Error('Options are not provided');

    const { hostnameIp, username, password } = this.options;

    const { port, protocol } = this.getProtocolAndPort();

    return {
      type: 'netnut',
      ip: hostnameIp,
      protocol,
      port,
      res: this.getResidentialPrefix(),
      mob: '-mob-',
      city: this.getCity(),
      state: this.getState(),
      country: this.getCountry(),
      username,
      password,
      id: this.getId(),
      proxyHostname: this.getHostname(),
      asn: this.getASN(),
    };
  }

  private getResidentialPrefix() {
    if (!this.options) throw new Error('Options are not provided');

    const { asn, country, state, session } = this.options;

    if (asn.length > 0 || session === 'nnid') return '-resfix-';

    if (asn.length > 0 || (country !== 'random' && state !== 'random')) return '-res_sc-';

    return '-res-';
  }

  private getProtocolAndPort() {
    if (!this.options) throw new Error('Options are not provided');

    const { connection } = this.options;

    if (connection === Protocol.SOCKS5) {
      return { protocol: connection.toLowerCase(), port: 9595 };
    }

    return { protocol: connection.toLowerCase(), port: 5959 };
  }

  private getState() {
    if (!this.options) throw new Error('Options are not provided');

    const { asn, state, session } = this.options;

    if (asn.length > 0 || session === 'nnid') return '';

    if (state === 'random') return '';

    return `_${state.toLowerCase()}`;
  }

  private getCity() {
    if (!this.options) throw new Error('Options are not provided');

    const { asn, city, session } = this.options;

    if (asn.length > 0 || session === 'nnid') return '';

    if (city === 'random') return '';

    return `_${city.toLowerCase()}`;
  }

  private getCountry() {
    if (!this.options) throw new Error('Options are not provided');

    const { asn, country } = this.options;

    if (asn.length > 0) return '';

    if (country === 'random') return 'any';

    return country.toLowerCase();
  }

  private getId() {
    if (!this.options) throw new Error('Options are not provided');

    const { session } = this.options;
    const length = 8;
    let result = '';

    if (session === 'sid') {
      const chars = '123456789';

      for (let i = length; i > 0; i -= 1) {
        result += chars[Math.floor(Math.random() * chars.length)];
      }

      return `-sid-${result}`;
    }

    if (session === 'nnid') {
      return '-nnid-0';
    }

    return '';
  }

  private getHostname() {
    if (!this.options) throw new Error('Options are not provided');

    const { hostname, region, hostnameIp } = this.options;
    const defaultValue = region === 'EU' ? 'proxy-eu.proxy-cheap.com' : 'proxy-us.proxy-cheap.com';

    return hostname === 'dns' ? defaultValue : hostnameIp;
  }

  private getASN() {
    if (!this.options) throw new Error('Options are not provided');

    const { asn } = this.options;

    return asn.length > 0 ? `ASN${asn}` : '';
  }
}
