import { type MouseEvent, type Ref, type RefObject, forwardRef, useCallback, useRef, useState } from 'react';

import { boxClasses } from '@mui/material/Box';
import Menu, { type MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack, { stackClasses } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Button, type ButtonProps } from 'components/Button';
import { Icon, type IconName } from 'components/Icon';
import type { Action, LiteralNodeUnion } from 'types';

type IndicatorProps<T extends HTMLElement> = {
  ref: Ref<T>;
  onClick: (e: MouseEvent<T>) => void;
};

export type DropdownProps<T extends HTMLElement = HTMLElement, I extends HTMLElement = HTMLButtonElement> = {
  actions: Action[];
  renderIndicator: (props: IndicatorProps<I>) => LiteralNodeUnion<IconName>;

  anchorRef?: RefObject<T>;

  menuFullWidth?: boolean;
  MenuProps?: Partial<MenuProps>;
};

export function Dropdown<T extends HTMLElement = HTMLElement, I extends HTMLElement = HTMLButtonElement>({
  actions,
  anchorRef,
  menuFullWidth,
  MenuProps,
  renderIndicator,
}: DropdownProps<T, I>) {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const ref = useRef<I>(null);

  const handleOpenMenu = useCallback((e: MouseEvent<I>) => {
    e.stopPropagation();
    e.preventDefault();

    setMenuVisible((prev) => !prev);
  }, []);

  const handleCloseMenu = useCallback((e: MouseEvent<unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') e.stopPropagation();

    setMenuVisible(false);
  }, []);

  return (
    <>
      {renderIndicator({ ref, onClick: handleOpenMenu })}

      <Menu
        {...MenuProps}
        anchorEl={ref.current}
        open={isMenuVisible}
        onClose={handleCloseMenu}
        slotProps={{
          ...MenuProps?.slotProps,
          list: {
            ...MenuProps?.slotProps?.list,
            sx: { ...(menuFullWidth && { width: anchorRef?.current?.offsetWidth ?? ref.current?.offsetWidth }) },
          },
        }}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            onClick={(e) => {
              e.stopPropagation();

              setMenuVisible(false);

              action.onClick(e);
            }}
            sx={(theme) => ({
              '--MenuItem-divider': 'none',

              color: 'var(--mui-palette-text-primary)',
              transition: theme.transitions.create('all', { duration: 300 }),

              [`& > .${stackClasses.root} > .${stackClasses.root} > .${boxClasses.root}`]: {
                transition: theme.transitions.create('all', { duration: 300 }),
              },

              '&:hover': {
                color: 'var(--mui-palette-text-primary)',

                [`& > .${stackClasses.root} > .${stackClasses.root} > .${boxClasses.root}`]: {
                  color: 'var(--mui-palette-primary-main)',
                },
              },
            })}
          >
            <Stack direction="row" flex="1 1 auto" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                {!!action.icon && <Icon size="small" name={action.icon} />}

                <Stack>
                  <Typography variant="body-2" color="var(--mui-palette-text-primary)">
                    {action.label}
                  </Typography>

                  {action.subLabel && (
                    <Typography variant="caption" color="var(--mui-palette-neutral-500)" whiteSpace="break-spaces">
                      {action.subLabel}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

Dropdown.Indicator = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <Button ref={ref} endIcon="chevron-down" iconOnly {...props} />
));
