export const NETNUT_CREDENTIALS_GENERATOR_GROUPED_BY_COUNTRIES: Record<
  string,
  Array<{ state: string; city: string }>
> = {
  AE: [
    { state: 'Ajman', city: 'Ajman' },
    { state: 'Fujairah', city: 'Fujairah' },
    { state: 'Sharjah', city: 'Sharjahcity' },
    { state: 'Sharjah', city: 'Sharjah' },
    { state: 'Abudhabi', city: 'Abudhabi' },
    { state: 'Abudhabi', city: 'Musaffahcity' },
    { state: 'Dubai', city: 'Warisan' },
    { state: 'Dubai', city: 'Burdubai' },
    { state: 'Dubai', city: 'Dubai' },
    { state: 'Dubai', city: 'Dubai(academiccity)' },
    { state: 'Dubai', city: 'Dubai(alsufouh)' },
    { state: 'Dubai', city: 'Jebelali' },
  ],
  AG: [
    { state: 'Saintjohnparish', city: 'Stjohns' },
    { state: 'Saintgeorgeparish', city: 'Saintgeorgeparish' },
    { state: 'Saintjohnparish', city: 'Cedargrove' },
    { state: 'Saintjohnparish', city: 'Saintjohnparish' },
    { state: 'Saintpaulparish', city: 'Englishharbourtown' },
    { state: 'Saintpaulparish', city: 'Saintpaulparish' },
    { state: 'Saintgeorgeparish', city: 'Piggotts' },
  ],
  AI: [{ state: 'Thevalley', city: 'Thevalley' }],
  AL: [
    { state: 'Shkodercounty', city: 'Shkodercounty' },
    { state: 'Elbasancounty', city: 'Lumas' },
    { state: 'Vlorecounty', city: 'Vlorecounty' },
    { state: 'Kukescounty', city: 'Kukescounty' },
    { state: 'Lezhecounty', city: 'Lezhecounty' },
    { state: 'Fiercounty', city: 'Fiercounty' },
    { state: 'Elbasancounty', city: 'Elbasancounty' },
    { state: 'Tirana', city: 'Tirana' },
    { state: 'Durrescounty', city: 'Durrescounty' },
    { state: 'Korcecounty', city: 'Korcecounty' },
  ],
  AM: [
    { state: 'Kotayk', city: 'Kotayk' },
    { state: 'Kotayk', city: 'Abovyan' },
    { state: 'Yerevan', city: 'Yerevan(kentrondistrict)' },
    { state: 'Yerevan', city: 'Yerevan' },
    { state: 'Armavir', city: 'Armavir' },
  ],
  AO: [
    { state: 'Luanda', city: 'Luanda' },
    { state: 'Luanda', city: 'Luanda(ingombota)' },
  ],
  AR: [
    { state: 'Buenosairesfd', city: 'Sannicolas' },
    { state: 'Buenosaires', city: 'Santoslugares' },
    { state: 'Buenosairesfd', city: 'Santelmo' },
    { state: 'Buenosairesfd', city: 'Palermo' },
    { state: 'Buenosairesfd', city: 'Buenosairesfd' },
    { state: 'Salta', city: 'Salta' },
    { state: 'Buenosairesfd', city: 'Villacrespo' },
    { state: 'Sanjuan', city: 'Chimbas' },
    { state: 'Corrientes', city: 'Corrientes' },
    { state: 'Cordoba', city: 'Cordoba' },
    { state: 'Chubut', city: 'Chubut' },
    { state: 'Rionegro', city: 'Rionegro' },
    { state: 'Chaco', city: 'Chaco' },
    { state: 'Chaco', city: 'Resistencia' },
    { state: 'Buenosaires', city: 'Avellaneda' },
    { state: 'Buenosaires', city: 'Generalvillegas' },
    { state: 'Buenosaires', city: 'Generalrodriguez' },
    { state: 'Buenosaires', city: 'Ezeiza' },
    { state: 'Buenosaires', city: 'Ciudaddellibertadorgeneralsanmartin' },
    { state: 'Buenosaires', city: 'Burzaco' },
    { state: 'Buenosaires', city: 'Buenosaires' },
    { state: 'Buenosaires', city: 'Bragado' },
    { state: 'Buenosaires', city: 'Berazategui' },
    { state: 'Buenosaires', city: 'Benavidez' },
    { state: 'Buenosaires', city: 'Gonzalezcatan' },
    { state: 'Buenosaires', city: 'Adrogue' },
    { state: 'Sanjuan', city: 'Sanjuan' },
    { state: 'Sanjuan', city: 'Villabasilionievas' },
    { state: 'Sanluis', city: 'Sanluis' },
    { state: 'Santacruz', city: 'Santacruz' },
    { state: 'Santafe', city: 'Rosario' },
    { state: 'Santafe', city: 'Santafe' },
    { state: 'Santiagodelestero', city: 'Santiagodelestero' },
    { state: 'Buenosaires', city: 'Sanjuanbautista' },
    { state: 'Buenosairesfd', city: 'Buenosaires(santelmo)' },
    { state: 'Buenosairesfd', city: 'Buenosaires(palermo)' },
    { state: 'Buenosairesfd', city: 'Buenosaires(monserrat)' },
    { state: 'Buenosairesfd', city: 'Belgrano' },
    { state: 'Buenosaires', city: 'Sanvicente' },
    { state: 'Entrerios', city: 'Entrerios' },
    { state: 'Buenosaires', city: 'Santamaria' },
    { state: 'Buenosaires', city: 'Sanmiguel' },
    { state: 'Buenosaires', city: 'Sanjusto' },
    { state: 'Buenosairesfd', city: 'Buenosaires(villacrespo)' },
    { state: 'Buenosaires', city: 'Quilmes' },
    { state: 'Buenosaires', city: 'Pontevedra' },
    { state: 'Buenosaires', city: 'Merlo' },
    { state: 'Buenosaires', city: 'Mardelplata' },
    { state: 'Buenosaires', city: 'Lomasdezamora' },
    { state: 'Buenosaires', city: 'Laplata' },
    { state: 'Buenosaires', city: 'Lanus' },
    { state: 'Buenosaires', city: 'Guernica' },
    { state: 'Misiones', city: 'Misiones' },
    { state: 'Larioja', city: 'Larioja' },
    { state: 'Mendoza', city: 'Godoycruz' },
    { state: 'Mendoza', city: 'Lasheras' },
    { state: 'Mendoza', city: 'Lujandecuyo' },
    { state: 'Mendoza', city: 'Maipu' },
    { state: 'Mendoza', city: 'Mendoza' },
    { state: 'Mendoza', city: 'Sanmartin' },
    { state: 'Mendoza', city: 'Sanrafael' },
    { state: 'Tucuman', city: 'Tucuman' },
    { state: 'Tucuman', city: 'Tafiviejo' },
    { state: 'Tucuman', city: 'Simoca' },
    { state: 'Tucuman', city: 'Sanmigueldetucuman' },
    { state: 'Lapampa', city: 'Lapampa' },
    { state: 'Tucuman', city: 'Bandadelriosali' },
    { state: 'Mendoza', city: 'Villanueva' },
    { state: 'Jujuy', city: 'Jujuy' },
    { state: 'Neuquen', city: 'Neuquen' },
  ],
  AT: [
    { state: 'Vorarlberg', city: 'Feldkirch' },
    { state: 'Styria', city: 'Styria' },
    { state: 'Loweraustria', city: 'Loweraustria' },
    { state: 'Upperaustria', city: 'Upperaustria' },
    { state: 'Tyrol', city: 'Tyrol' },
    { state: 'Vorarlberg', city: 'Thuringerberg' },
    { state: 'Vorarlberg', city: 'Rankweil' },
    { state: 'Vorarlberg', city: 'Nenzing' },
    { state: 'Vorarlberg', city: 'Koblach' },
    { state: 'Vorarlberg', city: 'Hohenems' },
    { state: 'Vorarlberg', city: 'Gotzis' },
    { state: 'Vorarlberg', city: 'Bludenz' },
    { state: 'Vorarlberg', city: 'Altenstadt' },
    { state: 'Vienna', city: 'Vienna(floridsdorf)' },
    { state: 'Vienna', city: 'Vienna' },
  ],
  AU: [
    { state: 'Australiancapitalterritory', city: 'Canberra' },
    { state: 'Westernaustralia', city: 'Wundowie' },
    { state: 'Westernaustralia', city: 'Westernaustralia' },
    { state: 'Westernaustralia', city: 'Perth' },
    { state: 'Westernaustralia', city: 'Leinster' },
    { state: 'Westernaustralia', city: 'Claremont' },
    { state: 'Westernaustralia', city: 'Bentley' },
    { state: 'Westernaustralia', city: 'Balcatta' },
    { state: 'Victoria', city: 'Victoria' },
    { state: 'Victoria', city: 'Sunshinewest' },
    { state: 'Victoria', city: 'Melbourne' },
    { state: 'Victoria', city: 'Balwynnorth' },
    { state: 'Victoria', city: 'Sthelena' },
    { state: 'Victoria', city: 'Burwood' },
    { state: 'Victoria', city: 'Caulfieldsouth' },
    { state: 'Victoria', city: 'Elsternwick' },
    { state: 'Victoria', city: 'Fawkner' },
    { state: 'Victoria', city: 'Frankstoneast' },
    { state: 'Victoria', city: 'Geelong' },
    { state: 'Victoria', city: 'Stalbans' },
    { state: 'Victoria', city: 'Pointlonsdale' },
    { state: 'Victoria', city: 'Morwell' },
    { state: 'Victoria', city: 'Kooyong' },
    { state: 'Victoria', city: 'Laverton' },
    { state: 'Newsouthwales', city: 'Alexandria' },
    { state: 'Newsouthwales', city: 'Liverpool' },
    { state: 'Newsouthwales', city: 'Parramatta' },
    { state: 'Newsouthwales', city: 'Nyngan' },
    { state: 'Newsouthwales', city: 'Northsydney' },
    { state: 'Newsouthwales', city: 'Northryde' },
    { state: 'Newsouthwales', city: 'Newsouthwales' },
    { state: 'Newsouthwales', city: 'Moruya' },
    { state: 'Newsouthwales', city: 'Mascot' },
    { state: 'Australiancapitalterritory', city: 'Australiancapitalterritory' },
    { state: 'Newsouthwales', city: 'Macquariepark' },
    { state: 'Queensland', city: 'Townsvillecitycenter' },
    { state: 'Southaustralia', city: 'Adelaide' },
    { state: 'Southaustralia', city: 'Roxbydowns' },
    { state: 'Southaustralia', city: 'Southaustralia' },
    { state: 'Newsouthwales', city: 'Haymarket' },
    { state: 'Newsouthwales', city: 'Forestlodge' },
    { state: 'Newsouthwales', city: 'Darlington' },
    { state: 'Newsouthwales', city: 'Brunswickheads' },
    { state: 'Newsouthwales', city: 'Blacktown' },
    { state: 'Newsouthwales', city: 'Southwestrocks' },
    { state: 'Queensland', city: 'Redcliffe' },
    { state: 'Queensland', city: 'Queensland' },
    { state: 'Queensland', city: 'Longreach' },
    { state: 'Queensland', city: 'Kelvingrove' },
    { state: 'Queensland', city: 'Goldcoast' },
    { state: 'Queensland', city: 'Coomera' },
    { state: 'Queensland', city: 'Brisbane' },
    { state: 'Queensland', city: 'Arundel' },
    { state: 'Tasmania', city: 'Hobart' },
    { state: 'Newsouthwales', city: 'Zetland' },
    { state: 'Newsouthwales', city: 'Wollongong' },
    { state: 'Newsouthwales', city: 'Toongabbiewest' },
    { state: 'Newsouthwales', city: 'Therocks' },
    { state: 'Tasmania', city: 'Tasmania' },
    { state: 'Newsouthwales', city: 'Sydney' },
    { state: 'Newsouthwales', city: 'Pymble' },
  ],
  AZ: [
    { state: 'Baki', city: 'Bakikhanov' },
    { state: 'Baki', city: 'Baku(suraxani)' },
    { state: 'Baki', city: 'Baku(nsimi)' },
    { state: 'Baki', city: 'Baku(nrimanov)' },
    { state: 'Baki', city: 'Baku(narimanovskiyrayon)' },
    { state: 'Baki', city: 'Baku' },
    { state: 'Baki', city: 'Amiradzhan' },
    { state: 'Baki', city: 'Baki' },
    { state: 'Baki', city: 'Badamdar' },
    { state: 'Baki', city: 'Baku(yasamal)' },
    { state: 'Baki', city: 'Baladjary' },
    { state: 'Baki', city: 'Khojasan' },
    { state: 'Baki', city: 'Qaracuxur' },
    { state: 'Baki', city: 'Sabuncu' },
    { state: 'Aberon', city: 'Ceyranbatan' },
    { state: 'Gnc', city: 'Ganja' },
    { state: 'Gnc', city: 'Gnc' },
    { state: 'Nakhchivan', city: 'Nakhchivan' },
    { state: 'Mkir', city: 'Mkir' },
    { state: 'Ada', city: 'Ada' },
    { state: 'Sumqayit', city: 'Sumqayit' },
    { state: 'Aberon', city: 'Aberon' },
  ],
  BA: [
    { state: 'Srpska', city: 'Srpska' },
    { state: 'Srpska', city: 'Banjaluka' },
    { state: 'Federationofb&h', city: 'Sarajevo' },
    { state: 'Federationofb&h', city: 'Federationofb&h' },
  ],
  BB: [
    { state: 'Christchurch', city: 'Oistins' },
    { state: 'Saintlucy', city: 'Saintlucy' },
    { state: 'Saintlucy', city: 'Crabhill' },
    { state: 'Saintlucy', city: 'Checkerhall' },
    { state: 'Saintjoseph', city: 'Saintjoseph' },
    { state: 'Saintjoseph', city: 'Blackmans' },
    { state: 'Saintandrew', city: 'Hillaby' },
    { state: 'Saintandrew', city: 'Greenland' },
    { state: 'Christchurch', city: 'Hastings' },
    { state: 'Saintjohn', city: 'Saintjohn' },
    { state: 'Saintjohn', city: 'Fourcrossroads' },
    { state: 'Saintjames', city: 'Saintjames' },
    { state: 'Saintjames', city: 'Holetown' },
    { state: 'Christchurch', city: 'Christchurch' },
    { state: 'Saintandrew', city: 'Saintandrew' },
    { state: 'Saintmichael', city: 'Bridgetown' },
    { state: 'Saintmichael', city: 'Bridgetown(wildey)' },
    { state: 'Saintmichael', city: 'Edenlodge' },
    { state: 'Saintmichael', city: 'Saintmichael' },
    { state: 'Saintpeter', city: 'Saintpeter' },
    { state: 'Saintpeter', city: 'Speightstown' },
    { state: 'Saintphilip', city: 'Crane' },
    { state: 'Saintphilip', city: 'Saintphilip' },
    { state: 'Saintthomas', city: 'Saintthomas' },
    { state: 'Saintthomas', city: 'Welchmanhall' },
  ],
  BD: [
    { state: 'Dhakadivision', city: 'Dhaka(mohammadpur)' },
    { state: 'Dhakadivision', city: 'Dhaka(ramnamaidan)' },
    { state: 'Dhakadivision', city: 'Dhaka(pallabi)' },
    { state: 'Dhakadivision', city: 'Dhaka(motijheel)' },
    { state: 'Dhakadivision', city: 'Dhaka(gulshan)' },
    { state: 'Dhakadivision', city: 'Dhaka(rampura)' },
    { state: 'Dhakadivision', city: 'Dhaka(sutrapur)' },
    { state: 'Dhakadivision', city: 'Dhaka(tejgaon)' },
    { state: 'Dhakadivision', city: 'Dhaka(uttara)' },
    { state: 'Dhakadivision', city: 'Dhakadivision' },
    { state: 'Dhakadivision', city: 'Dohar' },
    { state: 'Dhakadivision', city: 'Dukuria' },
    { state: 'Dhakadivision', city: 'Faridpur' },
    { state: 'Dhakadivision', city: 'Dhaka(mirpur)' },
    { state: 'Dhakadivision', city: 'Dhaka(lalbagh)' },
    { state: 'Dhakadivision', city: 'Dhaka(khilgaon)' },
    { state: 'Dhakadivision', city: 'Dhaka(kafrul)' },
    { state: 'Dhakadivision', city: 'Dhaka(jatrabari)' },
    { state: 'Dhakadivision', city: 'Sakhipur' },
    { state: 'Dhakadivision', city: 'Dhaka(basundhararesidentialarea)' },
    { state: 'Dhakadivision', city: 'Dhaka(badda)' },
    { state: 'Dhakadivision', city: 'Charbhadrasanupazila' },
    { state: 'Dhakadivision', city: 'Charbhadrasan' },
    { state: 'Dhakadivision', city: 'Binodpur' },
    { state: 'Dhakadivision', city: 'Bhatara' },
    { state: 'Dhakadivision', city: 'Sonargaon' },
    { state: 'Sylhetdivision', city: 'Sylhet(zindabazar)' },
    { state: 'Sylhetdivision', city: 'Maulavibazar' },
    { state: 'Sylhetdivision', city: 'Jahedpur' },
    { state: 'Sylhetdivision', city: 'Sylhetdivision' },
    { state: 'Sylhetdivision', city: 'Habiganj' },
    { state: 'Mymensinghdivision', city: 'Gafargaon' },
    { state: 'Mymensinghdivision', city: 'Jamalpur' },
    { state: 'Mymensinghdivision', city: 'Muktagacha' },
    { state: 'Mymensinghdivision', city: 'Mymensinghdivision' },
    { state: 'Mymensinghdivision', city: 'Netrakona' },
    { state: 'Mymensinghdivision', city: 'Sarishabari' },
    { state: 'Mymensinghdivision', city: 'Sherpur' },
    { state: 'Dhakadivision', city: 'Tungipara' },
    { state: 'Dhakadivision', city: 'Tongi' },
    { state: 'Dhakadivision', city: 'Tangail' },
    { state: 'Dhakadivision', city: 'Gazipur' },
    { state: 'Dhakadivision', city: 'Savarupazila' },
    { state: 'Dhaka', city: 'Dhaka(gulshan)' },
    { state: 'Dhakadivision', city: 'Paltan' },
    { state: 'Dhakadivision', city: 'Palang' },
    { state: 'Dhakadivision', city: 'Nawabchar' },
    { state: 'Dhakadivision', city: 'Narsingdi' },
    { state: 'Dhakadivision', city: 'Narayanganj' },
    { state: 'Dhakadivision', city: 'Nagarnaluakot' },
    { state: 'Dhakadivision', city: 'Mirzapur' },
    { state: 'Dhakadivision', city: 'Matuail' },
    { state: 'Dhakadivision', city: 'Kishorganj' },
    { state: 'Dhakadivision', city: 'Kafrul' },
    { state: 'Dhakadivision', city: 'Greenmodeltown' },
    { state: 'Dhakadivision', city: 'Gopalganj' },
    { state: 'Chittagong', city: 'Chittagong(bakalia)' },
    { state: 'Rangpurdivision', city: 'Rangpurdivision' },
    { state: 'Rangpurdivision', city: 'Saidpur' },
    { state: 'Rangpurdivision', city: 'Thakurgaon' },
    { state: 'Chittagong', city: 'Ashuganjcity' },
    { state: 'Chittagong', city: 'Bibirhat' },
    { state: 'Chittagong', city: 'Chhagalnaiya' },
    { state: 'Chittagong', city: 'Chittagong' },
    { state: 'Chittagong', city: 'Chittagong(15nobagmoniramward)' },
    { state: 'Rangpurdivision', city: 'Rangpurcity' },
    { state: 'Chittagong', city: 'Chittagong(bayazid)' },
    { state: 'Chittagong', city: 'Chittagong(khulshiresidentialarea)' },
    { state: 'Chittagong', city: 'Comilla' },
    { state: 'Chittagong', city: 'Coxsbazar' },
    { state: 'Chittagong', city: 'Feni' },
    { state: 'Chittagong', city: 'Hajiganj' },
    { state: 'Khulnadivision', city: 'Bagerhat' },
    { state: 'Khulnadivision', city: 'Bhatparaabhaynagar' },
    { state: 'Rajshahidivision', city: 'Shahzadpur' },
    { state: 'Rajshahidivision', city: 'Bogra' },
    { state: 'Rajshahidivision', city: 'Joypurhat' },
    { state: 'Rajshahidivision', city: 'Natore' },
    { state: 'Rajshahidivision', city: 'Nawabganj' },
    { state: 'Rajshahidivision', city: 'Pabna' },
    { state: 'Rajshahidivision', city: 'Parnaogaon' },
    { state: 'Rajshahidivision', city: 'Puthia' },
    { state: 'Rajshahidivision', city: 'Rajshahidivision' },
    { state: 'Dhakadivision', city: 'Azimpur' },
    { state: 'Rajshahidivision', city: 'Shibganj' },
    { state: 'Rajshahidivision', city: 'Sirajganj' },
    { state: 'Rangpurdivision', city: 'Badarganj' },
    { state: 'Rangpurdivision', city: 'Dinajpur' },
    { state: 'Rangpurdivision', city: 'Gaibandha' },
    { state: 'Rangpurdivision', city: 'Nageswari' },
    { state: 'Rangpurdivision', city: 'Nilphamari' },
    { state: 'Rangpurdivision', city: 'Parbatipur' },
    { state: 'Khulnadivision', city: 'Ujalpur' },
    { state: 'Chittagong', city: 'Sandwip' },
    { state: 'Chittagong', city: 'Satkania' },
    { state: 'Chittagong', city: 'Raojan' },
    { state: 'Chittagong', city: 'Rangamati' },
    { state: 'Chittagong', city: 'Ramganj' },
    { state: 'Chittagong', city: 'Raipur' },
    { state: 'Chittagong', city: 'Nabinagar' },
    { state: 'Chittagong', city: 'Lakshmipur' },
    { state: 'Khulnadivision', city: 'Jessore' },
    { state: 'Chittagong', city: 'Shahbazpurtown' },
    { state: 'Sylhetdivision', city: 'Chhatak' },
    { state: 'Dhaka', city: 'Dhaka' },
    { state: 'Chittagong', city: 'Laksham' },
    { state: 'Chittagong', city: 'Hathazari' },
    { state: 'Dhakadivision', city: 'Bhairabbazar' },
    { state: 'Khulnadivision', city: 'Satkhira' },
    { state: 'Khulnadivision', city: 'Nowlamary' },
    { state: 'Barisaldivision', city: 'Pirojpur' },
    { state: 'Barisaldivision', city: 'Nalchiti' },
    { state: 'Barisaldivision', city: 'Mathba' },
    { state: 'Barisaldivision', city: 'Bhandaria' },
    { state: 'Barisaldivision', city: 'Barishal(chawckbazar)' },
    { state: 'Barisaldivision', city: 'Barishal' },
    { state: 'Barisaldivision', city: 'Barisaldivision' },
    { state: 'Khulnadivision', city: 'Maguradistrict' },
    { state: 'Khulnadivision', city: 'Kushtia' },
    { state: 'Khulnadivision', city: 'Khulnadivision' },
    { state: 'Khulnadivision', city: 'Kesabpur' },
    { state: 'Khulnadivision', city: 'Kaliganj' },
    { state: 'Khulnadivision', city: 'Kalia' },
  ],
  BE: [
    { state: 'Brusselscapital', city: 'Saintjossetennoode' },
    { state: 'Flanders', city: 'Bruges' },
    { state: 'Flanders', city: 'Flanders' },
    { state: 'Flanders', city: 'Mechelen' },
    { state: 'Brusselscapital', city: 'Saintgilles' },
    { state: 'Brusselscapital', city: 'Evere' },
    { state: 'Brusselscapital', city: 'Brusselscapital' },
    { state: 'Wallonia', city: 'Wallonia' },
  ],
  BF: [
    { state: 'Centre', city: 'Ouagadougou' },
    { state: 'Centre', city: 'Centre' },
  ],
  BG: [
    { state: 'Sofiacapital', city: 'Sofia' },
    { state: 'Sofiagrad', city: 'Sofiagrad' },
    { state: 'Burgas', city: 'Burgas' },
    { state: 'Pazardzhik', city: 'Pazardzhik' },
    { state: 'Plovdiv', city: 'Plovdiv' },
    { state: 'Gabrovo', city: 'Gabrovo' },
    { state: 'Blagoevgrad', city: 'Blagoevgrad' },
    { state: 'Varna', city: 'Varna' },
  ],
  BH: [
    { state: 'Northerngovernorate', city: 'Northerngovernorate' },
    { state: 'Southerngovernorate', city: 'Southerngovernorate' },
    { state: 'Southerngovernorate', city: 'Riffa' },
    { state: 'Southerngovernorate', city: 'Isatown' },
    { state: 'Southerngovernorate', city: 'Darkulayb' },
    { state: 'Muharraq', city: 'Muharraq' },
    { state: 'Muharraq', city: 'Almuharraq' },
    { state: 'Muharraq', city: 'Aladd' },
    { state: 'Manama', city: 'Jiddaf' },
    { state: 'Manama', city: 'Manama(alsalmaniya)' },
    { state: 'Manama', city: 'Sitrah' },
    { state: 'Manama', city: 'Manama' },
    { state: 'Northerngovernorate', city: 'Hamadtown' },
  ],
  BJ: [{ state: 'Littoral', city: 'Littoral' }],
  BM: [
    { state: 'Hamiltoncity', city: 'Hamiltoncity' },
    { state: 'Saintgeorge', city: 'Saintgeorge' },
  ],
  BN: [
    { state: 'Bruneimuara', city: 'Bandarseribegawan(anggerekdesa)' },
    { state: 'Bruneimuara', city: 'Bruneimuara' },
  ],
  BO: [
    { state: 'Cochabamba', city: 'Cochabamba' },
    { state: 'Lapazdepartment', city: 'Achocalla' },
    { state: 'Oruro', city: 'Oruro' },
    { state: 'Santacruzdepartment', city: 'Santacruzdepartment' },
    { state: 'Lapazdepartment', city: 'Lapazdepartment' },
  ],
  BR: [
    { state: 'Goias', city: 'Anapolis' },
    { state: 'Goias', city: 'Goiania' },
    { state: 'Goias', city: 'Formosa' },
    { state: 'Goias', city: 'Cidadeocidental' },
    { state: 'Goias', city: 'Catalao' },
    { state: 'Goias', city: 'Caldasnovas' },
    { state: 'Goias', city: 'Aparecidadegoiania' },
    { state: 'Goias', city: 'Anapolis(conjuntoeldorado)' },
    { state: 'Federaldistrict', city: 'Brasilia' },
    { state: 'Goias', city: 'Alexania' },
    { state: 'Acre', city: 'Riobranco' },
    { state: 'Acre', city: 'Acre' },
    { state: 'Espiritosanto', city: 'Vilavelha' },
    { state: 'Espiritosanto', city: 'Vitoria' },
    { state: 'Espiritosanto', city: 'Linhares' },
    { state: 'Federaldistrict', city: 'Planaltina' },
    { state: 'Federaldistrict', city: 'Federaldistrict' },
    { state: 'Goias', city: 'Senadorcanedo' },
    { state: 'Maranhao', city: 'Imperatriz' },
    { state: 'Maranhao', city: 'Maranhao' },
    { state: 'Maranhao', city: 'Pacodolumiar' },
    { state: 'Saopaulo', city: 'Valinhos' },
    { state: 'Alagoas', city: 'Satuba' },
    { state: 'Alagoas', city: 'Maceio' },
    { state: 'Alagoas', city: 'Arapiraca' },
    { state: 'Alagoas', city: 'Alagoas' },
    { state: 'Goias', city: 'Trindade' },
    { state: 'Goias', city: 'Goianira' },
    { state: 'Goias', city: 'Rioverde' },
    { state: 'Saopaulo', city: 'Vargemgrandepaulista' },
    { state: 'Goias', city: 'Planaltina' },
    { state: 'Goias', city: 'Neropolis' },
    { state: 'Goias', city: 'Morrinhos' },
    { state: 'Goias', city: 'Luziania' },
    { state: 'Goias', city: 'Jatai' },
    { state: 'Goias', city: 'Itumbiara' },
    { state: 'Goias', city: 'Goias' },
    { state: 'Parana', city: 'Ibipora' },
    { state: 'Parana', city: 'Cambara' },
    { state: 'Parana', city: 'Cambe' },
    { state: 'Parana', city: 'Campolargo' },
    { state: 'Parana', city: 'Campomagro' },
    { state: 'Parana', city: 'Cascavel' },
    { state: 'Espiritosanto', city: 'Cachoeirodeitapemirim' },
    { state: 'Parana', city: 'Castro' },
    { state: 'Parana', city: 'Colombo' },
    { state: 'Parana', city: 'Curitiba' },
    { state: 'Parana', city: 'Curitiba(centrocivico)' },
    { state: 'Parana', city: 'Fozdoiguacu' },
    { state: 'Parana', city: 'Guarapuava' },
    { state: 'Parana', city: 'Guaratuba' },
    { state: 'Parana', city: 'Araucaria' },
    { state: 'Parana', city: 'Londrina' },
    { state: 'Parana', city: 'Maringa' },
    { state: 'Parana', city: 'Palmeira' },
    { state: 'Parana', city: 'Parana' },
    { state: 'Parana', city: 'Paranagua' },
    { state: 'Parana', city: 'Pinhais' },
    { state: 'Parana', city: 'Piraquara' },
    { state: 'Parana', city: 'Pontagrossa' },
    { state: 'Parana', city: 'Pontaldoparana' },
    { state: 'Parana', city: 'Quatrobarras' },
    { state: 'Parana', city: 'Saojosedospinhais' },
    { state: 'Parana', city: 'Telemacoborba' },
    { state: 'Saopaulo', city: 'Maranduba' },
    { state: 'Paraiba', city: 'Bayeux' },
    { state: 'Espiritosanto', city: 'Saomateus' },
    { state: 'Espiritosanto', city: 'Marataizes' },
    { state: 'Sergipe', city: 'Itabaiana' },
    { state: 'Para', city: 'Ananindeua' },
    { state: 'Para', city: 'Belem' },
    { state: 'Para', city: 'Braganca' },
    { state: 'Para', city: 'Castanhal' },
    { state: 'Para', city: 'Maraba' },
    { state: 'Para', city: 'Para' },
    { state: 'Para', city: 'Parauapebas' },
    { state: 'Para', city: 'Redencao' },
    { state: 'Para', city: 'Santarem' },
    { state: 'Para', city: 'Xinguara' },
    { state: 'Espiritosanto', city: 'Serra' },
    { state: 'Saopaulo', city: 'Ubatuba' },
    { state: 'Saopaulo', city: 'Taubate' },
    { state: 'Paraiba', city: 'Cajazeiras' },
    { state: 'Paraiba', city: 'Campinagrande' },
    { state: 'Paraiba', city: 'Guarabira' },
    { state: 'Espiritosanto', city: 'Guarapari' },
    { state: 'Espiritosanto', city: 'Espiritosanto' },
    { state: 'Paraiba', city: 'Joaopessoa' },
    { state: 'Paraiba', city: 'Paraiba' },
    { state: 'Paraiba', city: 'Patos' },
    { state: 'Parana', city: 'Almirantetamandare' },
    { state: 'Parana', city: 'Ampere' },
    { state: 'Minasgerais', city: 'Mateusleme' },
    { state: 'Minasgerais', city: 'Pirapora' },
    { state: 'Minasgerais', city: 'Pedroleopoldo' },
    { state: 'Minasgerais', city: 'Patrocinio' },
    { state: 'Minasgerais', city: 'Patosdeminas' },
    { state: 'Minasgerais', city: 'Passos' },
    { state: 'Minasgerais', city: 'Parademinas' },
    { state: 'Minasgerais', city: 'Ouropreto' },
    { state: 'Minasgerais', city: 'Novalima' },
    { state: 'Minasgerais', city: 'Muriae' },
    { state: 'Minasgerais', city: 'Montesclaros' },
    { state: 'Minasgerais', city: 'Montecarmelo' },
    { state: 'Minasgerais', city: 'Minasgerais' },
    { state: 'Minasgerais', city: 'Pitangui' },
    { state: 'Minasgerais', city: 'Mariana' },
    { state: 'Minasgerais', city: 'Manhuacu' },
    { state: 'Minasgerais', city: 'Lavras' },
    { state: 'Minasgerais', city: 'Lagoasanta' },
    { state: 'Minasgerais', city: 'Juizdefora' },
    { state: 'Minasgerais', city: 'Itauna' },
    { state: 'Minasgerais', city: 'Itajuba' },
    { state: 'Minasgerais', city: 'Itabirito' },
    { state: 'Minasgerais', city: 'Itabira' },
    { state: 'Minasgerais', city: 'Ipatinga' },
    { state: 'Minasgerais', city: 'Igarape' },
    { state: 'Minasgerais', city: 'Ibirite' },
    { state: 'Amapa', city: 'Amapa' },
    { state: 'Amazonas', city: 'Manaus' },
    { state: 'Amapa', city: 'Macapa' },
    { state: 'Minasgerais', city: 'Trescoracoes' },
    { state: 'Minasgerais', city: 'Trespontas' },
    { state: 'Minasgerais', city: 'Uba' },
    { state: 'Minasgerais', city: 'Uberaba' },
    { state: 'Minasgerais', city: 'Uberlandia' },
    { state: 'Minasgerais', city: 'Unai' },
    { state: 'Minasgerais', city: 'Varginha' },
    { state: 'Minasgerais', city: 'Varzeadapalma' },
    { state: 'Minasgerais', city: 'Vicosa' },
    { state: 'Minasgerais', city: 'Viscondedoriobranco' },
    { state: 'Minasgerais', city: 'Governadorvaladares' },
    { state: 'Minasgerais', city: 'Teofilootoni' },
    { state: 'Minasgerais', city: 'Setelagoas' },
    { state: 'Minasgerais', city: 'Sarzedo' },
    { state: 'Minasgerais', city: 'Saojosedalapa' },
    { state: 'Minasgerais', city: 'Saojoaquimdebicas' },
    { state: 'Minasgerais', city: 'Saojoaodelrei' },
    { state: 'Minasgerais', city: 'Santaluzia' },
    { state: 'Minasgerais', city: 'Ribeiraodasneves' },
    { state: 'Minasgerais', city: 'Raposos' },
    { state: 'Minasgerais', city: 'Pousoalegre' },
    { state: 'Minasgerais', city: 'Pontenova' },
    { state: 'Minasgerais', city: 'Pocosdecaldas' },
    { state: 'Matogrosso', city: 'Matogrosso' },
    { state: 'Minasgerais', city: 'Araxa' },
    { state: 'Minasgerais', city: 'Araguari' },
    { state: 'Minasgerais', city: 'Abaete' },
    { state: 'Tocantins', city: 'Tocantins' },
    { state: 'Matogrossodosul', city: 'Treslagoas' },
    { state: 'Matogrossodosul', city: 'Matogrossodosul' },
    { state: 'Matogrossodosul', city: 'Dourados' },
    { state: 'Matogrossodosul', city: 'Campogrande' },
    { state: 'Matogrossodosul', city: 'Camapua' },
    { state: 'Matogrosso', city: 'Varzeagrande' },
    { state: 'Matogrosso', city: 'Sinop' },
    { state: 'Matogrosso', city: 'Rondonopolis' },
    { state: 'Minasgerais', city: 'Baraodecocais' },
    { state: 'Matogrosso', city: 'Cuiaba' },
    { state: 'Maranhao', city: 'Saoluis' },
    { state: 'Sergipe', city: 'Tobiasbarreto' },
    { state: 'Sergipe', city: 'Sergipe' },
    { state: 'Sergipe', city: 'Nossasenhoradosocorro' },
    { state: 'Sergipe', city: 'Lagarto' },
    { state: 'Amazonas', city: 'Amazonas' },
    { state: 'Sergipe', city: 'Estancia' },
    { state: 'Sergipe', city: 'Aracaju' },
    { state: 'Saopaulo', city: 'Votorantim' },
    { state: 'Saopaulo', city: 'Vinhedo' },
    { state: 'Saopaulo', city: 'Vilagalvao' },
    { state: 'Minasgerais', city: 'Congonhas' },
    { state: 'Minasgerais', city: 'Formiga' },
    { state: 'Minasgerais', city: 'Extrema' },
    { state: 'Minasgerais', city: 'Esmeraldas' },
    { state: 'Tocantins', city: 'Palmas' },
    { state: 'Tocantins', city: 'Taguatinga' },
    { state: 'Minasgerais', city: 'Divinopolis' },
    { state: 'Minasgerais', city: 'Diamantina' },
    { state: 'Minasgerais', city: 'Curvelo' },
    { state: 'Minasgerais', city: 'Coronelfabriciano' },
    { state: 'Minasgerais', city: 'Corinto' },
    { state: 'Minasgerais', city: 'Contagem' },
    { state: 'Minasgerais', city: 'Conselheirolafaiete' },
    { state: 'Saopaulo', city: 'Varzeapaulista' },
    { state: 'Minasgerais', city: 'Caratinga' },
    { state: 'Minasgerais', city: 'Carangola' },
    { state: 'Minasgerais', city: 'Campobelo' },
    { state: 'Minasgerais', city: 'Caete' },
    { state: 'Minasgerais', city: 'Buenobrandao' },
    { state: 'Minasgerais', city: 'Brumadinho' },
    { state: 'Minasgerais', city: 'Bocaiuva' },
    { state: 'Minasgerais', city: 'Boaesperanca' },
    { state: 'Minasgerais', city: 'Betim' },
    { state: 'Minasgerais', city: 'Belohorizonte' },
    { state: 'Minasgerais', city: 'Barbacena' },
    { state: 'Saopaulo', city: 'Novaeuropa' },
    { state: 'Bahia', city: 'Curaca' },
    { state: 'Bahia', city: 'Cipo' },
    { state: 'Bahia', city: 'Capimgrosso' },
    { state: 'Bahia', city: 'Camacari' },
    { state: 'Bahia', city: 'Barreiras' },
    { state: 'Bahia', city: 'Barradaestiva' },
    { state: 'Bahia', city: 'Bahia' },
    { state: 'Bahia', city: 'Alagoinhas' },
    { state: 'Saopaulo', city: 'Maua' },
    { state: 'Saopaulo', city: 'Mogidascruzes' },
    { state: 'Saopaulo', city: 'Mogiguacu' },
    { state: 'Saopaulo', city: 'Mongagua' },
    { state: 'Bahia', city: 'Eunapolis' },
    { state: 'Saopaulo', city: 'Novaodessa' },
    { state: 'Saopaulo', city: 'Olimpia' },
    { state: 'Saopaulo', city: 'Osasco' },
    { state: 'Saopaulo', city: 'Ourinhos' },
    { state: 'Saopaulo', city: 'Paulinia' },
    { state: 'Saopaulo', city: 'Pedreira' },
    { state: 'Saopaulo', city: 'Peruibe' },
    { state: 'Saopaulo', city: 'Piedade' },
    { state: 'Saopaulo', city: 'Pindamonhangaba' },
    { state: 'Saopaulo', city: 'Piracicaba' },
    { state: 'Saopaulo', city: 'Poa' },
    { state: 'Santacatarina', city: 'Imbituba' },
    { state: 'Santacatarina', city: 'Riodosul' },
    { state: 'Santacatarina', city: 'Portobelo' },
    { state: 'Santacatarina', city: 'Penha' },
    { state: 'Santacatarina', city: 'Palhoca' },
    { state: 'Santacatarina', city: 'Modelo' },
    { state: 'Santacatarina', city: 'Maravilha' },
    { state: 'Santacatarina', city: 'Lages' },
    { state: 'Santacatarina', city: 'Joinville' },
    { state: 'Santacatarina', city: 'Itapema' },
    { state: 'Santacatarina', city: 'Itajai' },
    { state: 'Santacatarina', city: 'Itacorubi' },
    { state: 'Saopaulo', city: 'Praiagrande' },
    { state: 'Santacatarina', city: 'Florianopolis' },
    { state: 'Santacatarina', city: 'Criciuma' },
    { state: 'Santacatarina', city: 'Chapeco' },
    { state: 'Santacatarina', city: 'Campinas' },
    { state: 'Santacatarina', city: 'Camboriu' },
    { state: 'Santacatarina', city: 'Cacador' },
    { state: 'Santacatarina', city: 'Brusque' },
    { state: 'Santacatarina', city: 'Blumenau' },
    { state: 'Santacatarina', city: 'Balneariocamboriu' },
    { state: 'Santacatarina', city: 'Ararangua' },
    { state: 'Bahia', city: 'Feiradesantana' },
    { state: 'Riograndedosul', city: 'Santocristo' },
    { state: 'Rondonia', city: 'Jiparana' },
    { state: 'Rondonia', city: 'Ariquemes' },
    { state: 'Saopaulo', city: 'Saoroque' },
    { state: 'Riograndedosul', city: 'Viamao' },
    { state: 'Riograndedosul', city: 'Uruguaiana' },
    { state: 'Riograndedosul', city: 'Tramandai' },
    { state: 'Riograndedosul', city: 'Torres' },
    { state: 'Riograndedosul', city: 'Sapucaiadosul' },
    { state: 'Riograndedosul', city: 'Sapiranga(centro)' },
    { state: 'Riograndedosul', city: 'Sapiranga' },
    { state: 'Riograndedosul', city: 'Saosebastiaodocai' },
    { state: 'Riograndedosul', city: 'Saoleopoldo' },
    { state: 'Rondonia', city: 'Portovelho' },
    { state: 'Riograndedosul', city: 'Santavitoriadopalmar' },
    { state: 'Riograndedosul', city: 'Santanadolivramento' },
    { state: 'Riograndedosul', city: 'Santamaria(nossasenhoradelourdes)' },
    { state: 'Riograndedosul', city: 'Santamaria' },
    { state: 'Riograndedosul', city: 'Santacruzdosul' },
    { state: 'Riograndedosul', city: 'Rolante' },
    { state: 'Riograndedosul', city: 'Riograndedosul' },
    { state: 'Riograndedosul', city: 'Portoalegre' },
    { state: 'Riograndedosul', city: 'Pelotas' },
    { state: 'Riograndedosul', city: 'Passofundo' },
    { state: 'Riograndedosul', city: 'Osorio' },
    { state: 'Saopaulo', city: 'Santanadeparnaiba' },
    { state: 'Saopaulo', city: 'Presidenteprudente' },
    { state: 'Saopaulo', city: 'Ribeiraopires' },
    { state: 'Saopaulo', city: 'Ribeiraopreto' },
    { state: 'Saopaulo', city: 'Rioclaro' },
    { state: 'Saopaulo', city: 'Riodaspedras' },
    { state: 'Saopaulo', city: 'Riograndedaserra' },
    { state: 'Saopaulo', city: 'Salto' },
    { state: 'Saopaulo', city: 'Saltodepirapora' },
    { state: 'Saopaulo', city: 'Santabarbaradoeste' },
    { state: 'Saopaulo', city: 'Santabranca' },
    { state: 'Saopaulo', city: 'Santaisabel' },
    { state: 'Santacatarina', city: 'Santacatarina' },
    { state: 'Saopaulo', city: 'Santoandre' },
    { state: 'Saopaulo', city: 'Santos' },
    { state: 'Saopaulo', city: 'Saobernardodocampo' },
    { state: 'Saopaulo', city: 'Saocaetanodosul' },
    { state: 'Saopaulo', city: 'Saocarlos' },
    { state: 'Saopaulo', city: 'Saojoaodaboavista' },
    { state: 'Saopaulo', city: 'Saojosedoriopreto' },
    { state: 'Saopaulo', city: 'Saojosedoscampos' },
    { state: 'Saopaulo', city: 'Saopaulo' },
    { state: 'Roraima', city: 'Roraima' },
    { state: 'Rondonia', city: 'Rondonia' },
    { state: 'Saopaulo', city: 'Indaiatuba' },
    { state: 'Saopaulo', city: 'Jandira' },
    { state: 'Saopaulo', city: 'Jacarei' },
    { state: 'Saopaulo', city: 'Itupeva' },
    { state: 'Saopaulo', city: 'Itu' },
    { state: 'Saopaulo', city: 'Itatiba' },
    { state: 'Saopaulo', city: 'Itaquaquecetuba' },
    { state: 'Saopaulo', city: 'Itapira' },
    { state: 'Saopaulo', city: 'Itapevi' },
    { state: 'Saopaulo', city: 'Itapetininga' },
    { state: 'Saopaulo', city: 'Itapecericadaserra' },
    { state: 'Saopaulo', city: 'Itanhaem' },
    { state: 'Saopaulo', city: 'Itaimbibi' },
    { state: 'Saopaulo', city: 'Jarinu' },
    { state: 'Saopaulo', city: 'Ibiuna' },
    { state: 'Saopaulo', city: 'Hortolandia' },
    { state: 'Saopaulo', city: 'Guarulhos' },
    { state: 'Saopaulo', city: 'Guaruja' },
    { state: 'Saopaulo', city: 'Francodarocha' },
    { state: 'Saopaulo', city: 'Franciscomorato' },
    { state: 'Saopaulo', city: 'Franca' },
    { state: 'Saopaulo', city: 'Ferrazdevasconcelos' },
    { state: 'Saopaulo', city: 'Embuguacu' },
    { state: 'Saopaulo', city: 'Embudasartes' },
    { state: 'Saopaulo', city: 'Duartina' },
    { state: 'Bahia', city: 'Laurodefreitas' },
    { state: 'Saopaulo', city: 'Mairipora' },
    { state: 'Saopaulo', city: 'Marilia' },
    { state: 'Bahia', city: 'Vitoriadaconquista' },
    { state: 'Bahia', city: 'Teixeiradefreitas' },
    { state: 'Bahia', city: 'Simoesfilho' },
    { state: 'Bahia', city: 'Serrinha' },
    { state: 'Bahia', city: 'Santoantoniodejesus' },
    { state: 'Bahia', city: 'Salvador' },
    { state: 'Bahia', city: 'Ribeiradopombal' },
    { state: 'Bahia', city: 'Portoseguro' },
    { state: 'Bahia', city: 'Matadesaojoao' },
    { state: 'Saopaulo', city: 'Diadema' },
    { state: 'Bahia', city: 'Jequie' },
    { state: 'Bahia', city: 'Itapetinga' },
    { state: 'Saopaulo', city: 'Mairinque' },
    { state: 'Saopaulo', city: 'Louveira' },
    { state: 'Saopaulo', city: 'Lorena' },
    { state: 'Saopaulo', city: 'Limeira' },
    { state: 'Saopaulo', city: 'Lencoispaulista(jardimmorumbi)' },
    { state: 'Saopaulo', city: 'Juquitiba' },
    { state: 'Saopaulo', city: 'Jundiai' },
    { state: 'Saopaulo', city: 'Josebonifacio' },
    { state: 'Saopaulo', city: 'Jau' },
    { state: 'Bahia', city: 'Irece' },
    { state: 'Saopaulo', city: 'Barretos' },
    { state: 'Saopaulo', city: 'Badybassitt' },
    { state: 'Saopaulo', city: 'Atibaia' },
    { state: 'Saopaulo', city: 'Aruja' },
    { state: 'Saopaulo', city: 'Araras' },
    { state: 'Saopaulo', city: 'Araraquara' },
    { state: 'Saopaulo', city: 'Aracoiabadaserra' },
    { state: 'Saopaulo', city: 'Aracatuba' },
    { state: 'Saopaulo', city: 'Andradina' },
    { state: 'Saopaulo', city: 'Amparo' },
    { state: 'Saopaulo', city: 'Americana' },
    { state: 'Bahia', city: 'Itabuna' },
    { state: 'Saopaulo', city: 'Barueri' },
    { state: 'Bahia', city: 'Ilheus' },
    { state: 'Bahia', city: 'Guanambi' },
    { state: 'Santacatarina', city: 'Xanxere' },
    { state: 'Santacatarina', city: 'Tubarao' },
    { state: 'Santacatarina', city: 'Timbo' },
    { state: 'Santacatarina', city: 'Sombrio' },
    { state: 'Santacatarina', city: 'Saoludgero' },
    { state: 'Santacatarina', city: 'Saojose' },
    { state: 'Santacatarina', city: 'Saofranciscodosul' },
    { state: 'Santacatarina', city: 'Saobentodosul' },
    { state: 'Santacatarina', city: 'Santoamarodaimperatriz' },
    { state: 'Saopaulo', city: 'Cajamar' },
    { state: 'Saopaulo', city: 'Cubatao' },
    { state: 'Saopaulo', city: 'Cruzeiro' },
    { state: 'Saopaulo', city: 'Cravinhos' },
    { state: 'Saopaulo', city: 'Cotia' },
    { state: 'Saopaulo', city: 'Catanduva' },
    { state: 'Saopaulo', city: 'Carapicuiba' },
    { state: 'Saopaulo', city: 'Caraguatatuba' },
    { state: 'Saopaulo', city: 'Camposdojordao' },
    { state: 'Saopaulo', city: 'Campolimpopaulista' },
    { state: 'Saopaulo', city: 'Campinas' },
    { state: 'Saopaulo', city: 'Cajuru' },
    { state: 'Pernambuco', city: 'Cabodesantoagostinho' },
    { state: 'Saopaulo', city: 'Caieiras' },
    { state: 'Saopaulo', city: 'Cacapava' },
    { state: 'Saopaulo', city: 'Cabreuva' },
    { state: 'Saopaulo', city: 'Bragancapaulista' },
    { state: 'Saopaulo', city: 'Botucatu' },
    { state: 'Saopaulo', city: 'Bomjesusdosperdoes' },
    { state: 'Saopaulo', city: 'Boituva(vilaginasial)' },
    { state: 'Saopaulo', city: 'Boituva' },
    { state: 'Saopaulo', city: 'Birigui' },
    { state: 'Saopaulo', city: 'Bertioga' },
    { state: 'Saopaulo', city: 'Bauru' },
    { state: 'Ceara', city: 'Maracanau' },
    { state: 'Saopaulo', city: 'Saosebastiao' },
    { state: 'Saopaulo', city: 'Saovicente' },
    { state: 'Saopaulo', city: 'Sertaozinho' },
    { state: 'Saopaulo', city: 'Socorro' },
    { state: 'Saopaulo', city: 'Sorocaba' },
    { state: 'Saopaulo', city: 'Sumare' },
    { state: 'Saopaulo', city: 'Sumare(centro)' },
    { state: 'Saopaulo', city: 'Suzano' },
    { state: 'Saopaulo', city: 'Taboaodaserra' },
    { state: 'Ceara', city: 'Caucaia' },
    { state: 'Ceara', city: 'Ceara' },
    { state: 'Ceara', city: 'Eusebio' },
    { state: 'Ceara', city: 'Fortaleza' },
    { state: 'Ceara', city: 'Juazeirodonorte' },
    { state: 'Riodejaneiro', city: 'Angradosreis' },
    { state: 'Ceara', city: 'Pereiro' },
    { state: 'Ceara', city: 'Sobral' },
    { state: 'Riodejaneiro', city: 'Resende' },
    { state: 'Riodejaneiro', city: 'Riodasostras' },
    { state: 'Piaui', city: 'Teresina' },
    { state: 'Piaui', city: 'Saoraimundononato' },
    { state: 'Piaui', city: 'Picos' },
    { state: 'Piaui', city: 'Piaui' },
    { state: 'Pernambuco', city: 'Vitoriadesantoantao' },
    { state: 'Pernambuco', city: 'Surubim' },
    { state: 'Pernambuco', city: 'Saolourencodamata' },
    { state: 'Riodejaneiro', city: 'Riodejaneiro' },
    { state: 'Riodejaneiro', city: 'Rocinha' },
    { state: 'Riodejaneiro', city: 'Novafriburgo' },
    { state: 'Riodejaneiro', city: 'Itaborai' },
    { state: 'Riodejaneiro', city: 'Itaguai' },
    { state: 'Riodejaneiro', city: 'Itaperuna' },
    { state: 'Riodejaneiro', city: 'Macae' },
    { state: 'Riodejaneiro', city: 'Mangaratiba' },
    { state: 'Riodejaneiro', city: 'Marica' },
    { state: 'Riodejaneiro', city: 'Nilopolis' },
    { state: 'Riodejaneiro', city: 'Niteroi' },
    { state: 'Riodejaneiro', city: 'Iguabagrande' },
    { state: 'Riodejaneiro', city: 'Guapimirim' },
    { state: 'Riodejaneiro', city: 'Duquedecaxias' },
    { state: 'Riodejaneiro', city: 'Cordeiro' },
    { state: 'Riodejaneiro', city: 'Carmo' },
    { state: 'Riodejaneiro', city: 'Camposdosgoytacazes' },
    { state: 'Riograndedosul', city: 'Lajeado' },
    { state: 'Riodejaneiro', city: 'Novaiguacu' },
    { state: 'Riodejaneiro', city: 'Petropolis' },
    { state: 'Riodejaneiro', city: 'Pinheiral' },
    { state: 'Riodejaneiro', city: 'Cabofrio' },
    { state: 'Riodejaneiro', city: 'Belfordroxo' },
    { state: 'Riodejaneiro', city: 'Queimados' },
    { state: 'Riograndedosul', city: 'Novohamburgo' },
    { state: 'Riodejaneiro', city: 'Barramansa' },
    { state: 'Riodejaneiro', city: 'Barradopirai' },
    { state: 'Riodejaneiro', city: 'Barradatijuca' },
    { state: 'Riodejaneiro', city: 'Armacaodosbuzios' },
    { state: 'Riodejaneiro', city: 'Areal' },
    { state: 'Riodejaneiro', city: 'Araruama' },
    { state: 'Pernambuco', city: 'Garanhuns' },
    { state: 'Riodejaneiro', city: 'Valenca' },
    { state: 'Riograndedosul', city: 'Cachoeirinha' },
    { state: 'Riograndedosul', city: 'Cachoeiradosul' },
    { state: 'Riograndedosul', city: 'Bentogoncalves' },
    { state: 'Riograndedosul', city: 'Bage' },
    { state: 'Riograndedosul', city: 'Alvorada' },
    { state: 'Riograndedosul', city: 'Alegrete' },
    { state: 'Riograndedonorte', city: 'Riograndedonorte' },
    { state: 'Pernambuco', city: 'Belemdesaofrancisco' },
    { state: 'Pernambuco', city: 'Caruaru' },
    { state: 'Riodejaneiro', city: 'Vilasarapui' },
    { state: 'Pernambuco', city: 'Cupira' },
    { state: 'Riodejaneiro', city: 'Saojoaodemeriti' },
    { state: 'Pernambuco', city: 'Jaboatao' },
    { state: 'Riodejaneiro', city: 'Voltaredonda' },
    { state: 'Pernambuco', city: 'Jaboataodosguararapes' },
    { state: 'Riograndedonorte', city: 'Curraisnovos' },
    { state: 'Riograndedonorte', city: 'Extremoz' },
    { state: 'Riograndedonorte', city: 'Mossoro' },
    { state: 'Riograndedonorte', city: 'Natal' },
    { state: 'Riograndedonorte', city: 'Parnamirim' },
    { state: 'Riodejaneiro', city: 'Saogoncalo' },
    { state: 'Pernambuco', city: 'Olinda' },
    { state: 'Pernambuco', city: 'Paulista' },
    { state: 'Riodejaneiro', city: 'Universidaderural' },
    { state: 'Riodejaneiro', city: 'Saojosedovaledoriopreto' },
    { state: 'Pernambuco', city: 'Santacruzdocapibaribe' },
    { state: 'Pernambuco', city: 'Recife' },
    { state: 'Pernambuco', city: 'Petrolina' },
    { state: 'Pernambuco', city: 'Pernambuco' },
    { state: 'Riodejaneiro', city: 'Saopedro' },
    { state: 'Riograndedosul', city: 'Ibiruba' },
    { state: 'Riograndedosul', city: 'Guaiba' },
    { state: 'Riograndedosul', city: 'Gravatai' },
    { state: 'Riodejaneiro', city: 'Saopedrodaaldeia' },
    { state: 'Riograndedosul', city: 'Esteio' },
    { state: 'Riograndedosul', city: 'Estanciavelha' },
    { state: 'Riodejaneiro', city: 'Saquarema' },
    { state: 'Riodejaneiro', city: 'Tangua' },
    { state: 'Riograndedosul', city: 'Erechim' },
    { state: 'Riograndedosul', city: 'Caxiasdosul' },
    { state: 'Riodejaneiro', city: 'Teresopolis' },
    { state: 'Riograndedosul', city: 'Capaodacanoa' },
    { state: 'Riograndedosul', city: 'Canoas' },
    { state: 'Riograndedosul', city: 'Canela' },
    { state: 'Riodejaneiro', city: 'Tresrios' },
    { state: 'Riograndedosul', city: 'Camaqua' },
  ],
  BS: [
    { state: 'Newprovidence', city: 'Newprovidence' },
    { state: 'Newprovidence', city: 'Killarney' },
    { state: 'Newprovidence', city: 'Nassau' },
    { state: 'Freeport', city: 'Freeport' },
    { state: 'Freeport', city: 'Lucaya' },
    { state: 'Newprovidence', city: 'Nassau(greaterchippingham)' },
  ],
  BW: [{ state: 'Gaborone', city: 'Gaborone' }],
  BY: [
    { state: 'Minskcity', city: 'Minskcity' },
    { state: 'Mogilev', city: 'Mogilev' },
    { state: 'Minskcity', city: 'Minsk(centranyrajon)' },
    { state: 'Minsk', city: 'Minsk' },
    { state: 'Brest', city: 'Brest' },
    { state: 'Grodnenskaya', city: 'Hrodna' },
    { state: 'Grodnenskaya', city: 'Lida' },
    { state: 'Grodnenskaya', city: 'Grodnenskaya' },
    { state: 'Homyelvoblasc', city: 'Homyelvoblasc' },
  ],
  BZ: [
    { state: 'Belizedistrict', city: 'Belizecity' },
    { state: 'Belizedistrict', city: 'Belizedistrict' },
    { state: 'Cayodistrict', city: 'Cayodistrict' },
    { state: 'Cayodistrict', city: 'Belmopan' },
    { state: 'Orangewalkdistrict', city: 'Orangewalkdistrict' },
  ],
  CA: [
    { state: 'Alberta', city: 'Calgary(northeastcalgary)' },
    { state: 'Ontario', city: 'Toronto' },
    { state: 'Manitoba', city: 'Manitoba' },
    { state: 'Manitoba', city: 'Winnipeg' },
    { state: 'Quebec', city: 'Dorval' },
    { state: 'Quebec', city: 'Beauharnois' },
    { state: 'Princeedwardisland', city: 'Princeedwardisland' },
    { state: 'Princeedwardisland', city: 'Charlottetown' },
    { state: 'Manitoba', city: 'Brandon' },
    { state: 'Alberta', city: 'Calgary(southwestcalgary)' },
    { state: 'Ontario', city: 'Windsor(fontainbleu)' },
    { state: 'Alberta', city: 'Calgary(downtown)' },
    { state: 'Quebec', city: 'Montreal' },
    { state: 'Quebec', city: 'Montreal(rosemontlapetitepatrie)' },
    { state: 'Quebec', city: 'Montreal(verdun)' },
    { state: 'Quebec', city: 'Montreal(villemarie)' },
    { state: 'Quebec', city: 'Quebec' },
    { state: 'Alberta', city: 'Calgary' },
    { state: 'Alberta', city: 'Alberta' },
    { state: 'Quebec', city: 'Saintmichel' },
    { state: 'Ontario', city: 'Woodstock' },
    { state: 'Ontario', city: 'Windsor(southcentral)' },
    { state: 'Ontario', city: 'Willowdale' },
    { state: 'Ontario', city: 'Hamilton' },
    { state: 'Ontario', city: 'Brantford' },
    { state: 'Ontario', city: 'Burlington' },
    { state: 'Ontario', city: 'Cambridge' },
    { state: 'Ontario', city: 'Concord' },
    { state: 'Ontario', city: 'Cornwall' },
    { state: 'Ontario', city: 'Etobicoke' },
    { state: 'Ontario', city: 'Greatersudbury' },
    { state: 'Ontario', city: 'Greatersudbury(sudbury)' },
    { state: 'Ontario', city: 'Grimsby' },
    { state: 'Ontario', city: 'Guelph' },
    { state: 'Ontario', city: 'Brampton' },
    { state: 'Britishcolumbia', city: 'Whiterock' },
    { state: 'Britishcolumbia', city: 'Victoria' },
    { state: 'Britishcolumbia', city: 'Vancouver(eastvancouver)' },
    { state: 'Britishcolumbia', city: 'Vancouver(centralvancouver)' },
    { state: 'Britishcolumbia', city: 'Vancouver' },
    { state: 'Britishcolumbia', city: 'Surrey' },
    { state: 'Britishcolumbia', city: 'Kelowna' },
    { state: 'Britishcolumbia', city: 'Coquitlam' },
    { state: 'Britishcolumbia', city: 'Britishcolumbia' },
    { state: 'Saskatchewan', city: 'Regina(downtown)' },
    { state: 'Newbrunswick', city: 'Fredericton' },
    { state: 'Newbrunswick', city: 'Moncton' },
    { state: 'Newbrunswick', city: 'Newbrunswick' },
    { state: 'Newbrunswick', city: 'Stjohn' },
    { state: 'Newfoundlandandlabrador', city: 'Cornerbrook' },
    { state: 'Newfoundlandandlabrador', city: 'Mountpearl' },
    { state: 'Newfoundlandandlabrador', city: 'Newfoundlandandlabrador' },
    { state: 'Newfoundlandandlabrador', city: 'Stjohns' },
    { state: 'Saskatchewan', city: 'Saskatoon' },
    { state: 'Saskatchewan', city: 'Saskatchewan' },
    { state: 'Ontario', city: 'Welland' },
    { state: 'Saskatchewan', city: 'Regina' },
    { state: 'Novascotia', city: 'Dartmouth' },
    { state: 'Novascotia', city: 'Halifax' },
    { state: 'Novascotia', city: 'Halifax(claytonparkwest)' },
    { state: 'Novascotia', city: 'Halifax(northend)' },
    { state: 'Novascotia', city: 'Novascotia' },
    { state: 'Novascotia', city: 'Sydney' },
    { state: 'Ontario', city: 'Ajax' },
    { state: 'Ontario', city: 'Barrie' },
    { state: 'Ontario', city: 'Toronto(scarborough)' },
    { state: 'Ontario', city: 'Petawawa' },
    { state: 'Ontario', city: 'Richmondhill' },
    { state: 'Ontario', city: 'Saultstemarie' },
    { state: 'Ontario', city: 'Stcatharines' },
    { state: 'Ontario', city: 'Thornhill' },
    { state: 'Ontario', city: 'Thunderbay' },
    { state: 'Ontario', city: 'Toronto(etobicoke)' },
    { state: 'Ontario', city: 'Toronto(northyork)' },
    { state: 'Ontario', city: 'Toronto(oldtoronto)' },
    { state: 'Ontario', city: 'Kingston' },
    { state: 'Ontario', city: 'Vaughan' },
    { state: 'Ontario', city: 'Vaughan(concord)' },
    { state: 'Ontario', city: 'Vaughan(woodbridge)' },
    { state: 'Ontario', city: 'Waterloo' },
    { state: 'Alberta', city: 'Reddeer' },
    { state: 'Alberta', city: 'Edmonton(millwoodstowncentre)' },
    { state: 'Alberta', city: 'Edmonton(kildare)' },
    { state: 'Alberta', city: 'Edmonton' },
    { state: 'Ontario', city: 'Whitby' },
    { state: 'Ontario', city: 'Oakville' },
    { state: 'Ontario', city: 'London(centrallondon)' },
    { state: 'Ontario', city: 'London' },
    { state: 'Ontario', city: 'Markham' },
    { state: 'Ontario', city: 'Millbrook' },
    { state: 'Ontario', city: 'Milton' },
    { state: 'Ontario', city: 'Mississauga' },
    { state: 'Ontario', city: 'Newmarket' },
    { state: 'Ontario', city: 'Niagarafalls' },
    { state: 'Ontario', city: 'Northyork' },
    { state: 'Ontario', city: 'Ontario' },
    { state: 'Ontario', city: 'Orangeville' },
    { state: 'Ontario', city: 'Oshawa' },
    { state: 'Ontario', city: 'Oshawa(oneill)' },
    { state: 'Ontario', city: 'Ottawa' },
    { state: 'Ontario', city: 'Leamington' },
    { state: 'Ontario', city: 'Pelham' },
    { state: 'Ontario', city: 'Kitchener' },
  ],
  CD: [{ state: 'Kinshasa', city: 'Kinshasa' }],
  CG: [
    { state: 'Pointenoire', city: 'Pointenoire' },
    { state: 'Brazzaville', city: 'Brazzaville' },
  ],
  CH: [
    { state: 'Vaud', city: 'Vaud' },
    { state: 'Vaud', city: 'Renens' },
    { state: 'Zurich', city: 'Opfikon' },
    { state: 'Zurich', city: 'Zurich' },
    { state: 'Bern', city: 'Bern' },
  ],
  CI: [
    { state: 'Abidjan', city: 'Abidjan' },
    { state: 'Abidjanautonomousdistrict', city: 'Abidjanautonomousdistrict' },
    { state: 'Abidjanautonomousdistrict', city: 'Marcory' },
    { state: 'Lacsdistrict', city: 'Yamoussoukro' },
    { state: 'Lacsdistrict', city: 'Lacsdistrict' },
    { state: 'Abidjan', city: 'Abidjan(leplateau)' },
  ],
  CL: [
    { state: 'Santiagometropolitan', city: 'Huechuraba' },
    { state: 'Santiagometropolitan', city: 'Lapintana' },
    { state: 'Santiagometropolitan', city: 'Lascondes' },
    { state: 'Santiagometropolitan', city: 'Loprado' },
    { state: 'Santiagometropolitan', city: 'Providencia' },
    { state: 'Santiagometropolitan', city: 'Puentealto' },
    { state: 'Santiagometropolitan', city: 'Santiagometropolitan' },
    { state: 'Biobio', city: 'Concepcion' },
    { state: 'Valparaiso', city: 'Valparaiso' },
    { state: 'Ohigginsregion', city: 'Ohigginsregion' },
    { state: 'Biobio', city: 'Biobio' },
    { state: 'Mauleregion', city: 'Mauleregion' },
  ],
  CM: [{ state: 'Littoral', city: 'Littoral' }],
  CN: [
    { state: 'Hainan', city: 'Hainan' },
    { state: 'Fujian', city: 'Zhangzhou(neimeng)' },
    { state: 'Hebei', city: 'Hebei' },
    { state: 'Beijing', city: 'Jinrongjie(xichengdistrict)' },
    { state: 'Guangdong', city: 'Guangzhou' },
    { state: 'Guangdong', city: 'Guangdong' },
    { state: 'Innermongolia', city: 'Innermongolia' },
    { state: 'Beijing', city: 'Beijing' },
    { state: 'Jiangsu', city: 'Jiangsu' },
    { state: 'Fujian', city: 'Fujian' },
    { state: 'Zhejiang', city: 'Zhejiang' },
    { state: 'Sichuan', city: 'Muping' },
    { state: 'Sichuan', city: 'Sichuan' },
    { state: 'Gansu', city: 'Gansu' },
    { state: 'Chongqing', city: 'Chongqing' },
    { state: 'Xinjiang', city: 'Xinjiang' },
    { state: 'Xinjiang', city: 'Xingfulu' },
    { state: 'Gansu', city: 'Zhangyelu' },
  ],
  CO: [
    { state: 'Bogotadc', city: 'Bogota(fontibon)' },
    { state: 'Bogotadc', city: 'Bogota(engativa)' },
    { state: 'Bogotadc', city: 'Bogota(lacandelaria)' },
    { state: 'Bogotadc', city: 'Bogota(localidaddechapinero)' },
    { state: 'Bogotadc', city: 'Bogota(suba)' },
    { state: 'Bogotadc', city: 'Bogotadc' },
    { state: 'Bolivar', city: 'Bolivar' },
    { state: 'Bolivar', city: 'Cartagena' },
    { state: 'Valledelcaucadepartment', city: 'Santiagodecali' },
    { state: 'Valledelcaucadepartment', city: 'Valledelcaucadepartment' },
    { state: 'Atlantico', city: 'Atlantico' },
    { state: 'Narino', city: 'Narino' },
    { state: 'Risaraldadepartment', city: 'Pereira' },
    { state: 'Risaraldadepartment', city: 'Risaraldadepartment' },
    { state: 'Huiladepartment', city: 'Pitalito' },
    { state: 'Huiladepartment', city: 'Neiva' },
    { state: 'Huiladepartment', city: 'Huiladepartment' },
    { state: 'Bogotadc', city: 'Bogota(comunachapinero)' },
    { state: 'Santanderdepartment', city: 'Santanderdepartment' },
    { state: 'Tolimadepartment', city: 'Tolimadepartment' },
    { state: 'Antioquia', city: 'Medellin(elpoblado)' },
    { state: 'Tolimadepartment', city: 'Fresno' },
    { state: 'Putumayodepartment', city: 'Putumayodepartment' },
    { state: 'Magdalenadepartment', city: 'Magdalenadepartment' },
    { state: 'Cundinamarca', city: 'Soacha' },
    { state: 'Antioquia', city: 'Antioquia' },
    { state: 'Cundinamarca', city: 'Cundinamarca' },
    { state: 'Antioquia', city: 'Medellin' },
    { state: 'Antioquia', city: 'Envigado(alcala)' },
  ],
  CR: [
    { state: 'Alajuelaprovince', city: 'Alajuelaprovince' },
    { state: 'Sanjose', city: 'Sanjose' },
    { state: 'Herediaprovince', city: 'Herediaprovince' },
    { state: 'Cartagoprovince', city: 'Cartagoprovince' },
  ],
  CW: [
    { state: 'Curacao', city: 'Curacao' },
    { state: 'Curacao', city: 'Willemstad' },
  ],
  CY: [
    { state: 'Lemesos', city: 'Limassol' },
    { state: 'Lemesos', city: 'Lemesos' },
    { state: 'Nicosia', city: 'Nicosia' },
    { state: 'Nicosia', city: 'Nicosia(citycentre)' },
    { state: 'Pafos', city: 'Pafos' },
    { state: 'Larnaka', city: 'Larnaka' },
    { state: 'Ammochostos', city: 'Ammochostos' },
  ],
  CZ: [
    { state: 'Prague', city: 'Prague' },
    { state: 'Hlavnimestopraha', city: 'Prague' },
    { state: 'Hlavnimestopraha', city: 'Hlavnimestopraha' },
  ],
  DE: [
    { state: 'Lowersaxony', city: 'Hanover' },
    { state: 'Saarland', city: 'Saarland' },
    { state: 'Hamburg', city: 'Hamburg' },
    { state: 'Schleswigholstein', city: 'Flensburg' },
    { state: 'Schleswigholstein', city: 'Kiel' },
    { state: 'Schleswigholstein', city: 'Schleswigholstein' },
    { state: 'Rheinlandpfalz', city: 'Rheinlandpfalz' },
    { state: 'Landberlin', city: 'Berlin' },
    { state: 'Lowersaxony', city: 'Lowersaxony' },
    { state: 'Schleswigholstein', city: 'Norderstedt' },
    { state: 'Thuringia', city: 'Thuringia' },
    { state: 'Hesse', city: 'Eschborn' },
    { state: 'Hesse', city: 'Frankfurtammain' },
    { state: 'Hesse', city: 'Frankfurtammain(frankfurtammainwest)' },
    { state: 'Hesse', city: 'Frankfurtammain(innenstadti)' },
    { state: 'Hesse', city: 'Hesse' },
    { state: 'Hesse', city: 'Sulzbach' },
    { state: 'Bavaria', city: 'Passau' },
    { state: 'Badenwurttemberg', city: 'Stuttgart' },
    { state: 'Badenwurttemberg', city: 'Sinsheim' },
    { state: 'Badenwurttemberg', city: 'Heiligkreuzsteinach' },
    { state: 'Badenwurttemberg', city: 'Badenwurttemberg' },
    { state: 'Brandenburg', city: 'Brandenburg' },
    { state: 'Bavaria', city: 'Bavaria' },
    { state: 'Bavaria', city: 'Deggendorf' },
    { state: 'Bavaria', city: 'Munich' },
    { state: 'Berlin', city: 'Berlin' },
    { state: 'Berlin', city: 'Berlin(bezirkmitte)' },
    { state: 'Berlin', city: 'Berlin(bezirktempelhofschoneberg)' },
    { state: 'Bavaria', city: 'Nuremberg' },
    { state: 'Bavaria', city: 'Osterhofen' },
    { state: 'Bavaria', city: 'Plattling' },
    { state: 'Saxony', city: 'Saxony' },
    { state: 'Northrhinewestphalia', city: 'Northrhinewestphalia' },
    { state: 'Northrhinewestphalia', city: 'Dusseldorf(stadtbezirk06)' },
    { state: 'Northrhinewestphalia', city: 'Dusseldorf' },
    { state: 'Northrhinewestphalia', city: 'Dortmund' },
    { state: 'Northrhinewestphalia', city: 'Cologne(lindenthal,cologne)' },
    { state: 'Saxonyanhalt', city: 'Saxonyanhalt' },
    { state: 'Northrhinewestphalia', city: 'Bielefeld' },
    { state: 'Northrhinewestphalia', city: 'Cologne(ehrenfeld,cologne)' },
    { state: 'Northrhinewestphalia', city: 'Dusseldorf(stadtbezirk4)' },
    { state: 'Northrhinewestphalia', city: 'Essen' },
    { state: 'Northrhinewestphalia', city: 'Cologne' },
    { state: 'Northrhinewestphalia', city: 'Munster' },
  ],
  DK: [
    { state: 'Centraljutland', city: 'Centraljutland' },
    { state: 'Capitalregion', city: 'Copenhagen' },
    { state: 'Capitalregion', city: 'Capitalregion' },
  ],
  DM: [
    { state: 'Saintgeorgeparish', city: 'Roseau' },
    { state: 'Saintgeorgeparish', city: 'Saintgeorgeparish' },
  ],
  DO: [
    { state: 'Santiagoprovince', city: 'Santiagoprovince' },
    { state: 'Santiagoprovince', city: 'Santiagodeloscaballeros' },
    { state: 'Santiagoprovince', city: 'Villabisono' },
    { state: 'Santodomingoprovince', city: 'Santodomingoprovince' },
    { state: 'Laaltagraciaprovince', city: 'Laaltagraciaprovince' },
    { state: 'Santodomingoprovince', city: 'Santodomingoeste' },
    { state: 'Santodomingoprovince', city: 'Santodomingooeste' },
    { state: 'Laaltagraciaprovince', city: 'Puntacana' },
    { state: 'Nacional', city: 'Santodomingo(bellavista)' },
    { state: 'Samana', city: 'Samana' },
    { state: 'Nacional', city: 'Santodomingo' },
    { state: 'Nacional', city: 'Nacional' },
  ],
  DZ: [
    { state: 'Oran', city: 'Oran' },
    { state: 'Batna', city: 'Batna' },
    { state: 'Batna', city: 'Batnacity' },
    { state: 'Chlef', city: 'Chlef' },
    { state: 'Tipaza', city: 'Cheraga' },
    { state: 'Constantine', city: 'Constantine' },
    { state: 'Tipaza', city: 'Tipaza' },
    { state: 'Tlemcen', city: 'Tlemcen' },
    { state: 'Algiers', city: 'Sidimhamed(algiers)' },
    { state: 'Algiers', city: 'Sidimhamed' },
    { state: 'Algiers', city: 'Kouba' },
    { state: 'Algiers', city: 'Benaknoun' },
    { state: 'Blida', city: 'Blida' },
    { state: 'Algiers', city: 'Algiers' },
    { state: 'Setif', city: 'Setif' },
    { state: 'Annaba', city: 'Annaba' },
  ],
  EC: [
    { state: 'Imbabura', city: 'Imbabura' },
    { state: 'Guayas', city: 'Guayaquil' },
    { state: 'Azuay', city: 'Cuenca' },
    { state: 'Guayas', city: 'Guayas' },
    { state: 'Guayas', city: 'Loslojas(enriquebaquerizomoreno)' },
    { state: 'Eloro', city: 'Eloro' },
    { state: 'Santodomingodelostsachilas', city: 'Santodomingodeloscolorados' },
    { state: 'Santodomingodelostsachilas', city: 'Santodomingodelostsachilas' },
    { state: 'Manabi', city: 'Manabi' },
    { state: 'Pichincha', city: 'Quito' },
    { state: 'Pichincha', city: 'Pichincha' },
    { state: 'Azuay', city: 'Azuay' },
  ],
  EE: [{ state: 'Harjumaa', city: 'Harjumaa' }],
  EG: [
    { state: 'Giza', city: 'Ashshaykhzayid' },
    { state: 'Giza', city: 'Giza' },
    { state: 'Cairo', city: 'Cairo' },
  ],
  ES: [
    { state: 'Madrid', city: 'Pozuelodealarcon' },
    { state: 'Madrid', city: 'Chamartin' },
    { state: 'Madrid', city: 'Ciudadlineal' },
    { state: 'Asturias', city: 'Asturias' },
    { state: 'Madrid', city: 'Getafe' },
    { state: 'Madrid', city: 'Madrid' },
    { state: 'Madrid', city: 'Madrid(fuencarralelpardo)' },
    { state: 'Cantabria', city: 'Cantabria' },
    { state: 'Extremadura', city: 'Extremadura' },
    { state: 'Aragon', city: 'Zaragoza' },
    { state: 'Aragon', city: 'Aragon' },
    { state: 'Andalusia', city: 'Albox' },
    { state: 'Andalusia', city: 'Malaga' },
    { state: 'Catalonia', city: 'Lametllademar' },
    { state: 'Catalonia', city: 'Santmarti' },
    { state: 'Catalonia', city: 'Torredembarra' },
    { state: 'Catalonia', city: 'Barcelona' },
    { state: 'Castillelamancha', city: 'Castillelamancha' },
    { state: 'Castillelamancha', city: 'Albacete' },
    { state: 'Castilleandleon', city: 'Castilleandleon' },
    { state: 'Larioja', city: 'Larioja' },
    { state: 'Andalusia', city: 'Seville' },
    { state: 'Andalusia', city: 'Sanpedro' },
    { state: 'Madrid', city: 'Alcobendas' },
    { state: 'Andalusia', city: 'Arboleas' },
    { state: 'Andalusia', city: 'Andalusia' },
    { state: 'Galicia', city: 'Vigo' },
    { state: 'Galicia', city: 'Galicia' },
    { state: 'Balearicislands', city: 'Balearicislands' },
    { state: 'Basquecountry', city: 'Basquecountry' },
    { state: 'Basquecountry', city: 'Bilbao' },
    { state: 'Catalonia', city: 'Catalonia' },
    { state: 'Canaryislands', city: 'Canaryislands' },
    { state: 'Valencia', city: 'Valencia' },
    { state: 'Murcia', city: 'Mazarron' },
    { state: 'Murcia', city: 'Murcia' },
    { state: 'Navarre', city: 'Navarre' },
    { state: 'Valencia', city: 'Alicante' },
    { state: 'Valencia', city: 'Benidorm' },
    { state: 'Valencia', city: 'Castellodelaplana' },
    { state: 'Valencia', city: 'Catral' },
    { state: 'Valencia', city: 'Elche' },
    { state: 'Valencia', city: 'Vinaros' },
    { state: 'Valencia', city: 'Torrevieja' },
    { state: 'Valencia', city: 'Crevillent' },
    { state: 'Valencia', city: 'Guardamardelsegura' },
  ],
  ET: [
    { state: 'Oromiya', city: 'Oromiya' },
    { state: 'Oromiya', city: 'Burayu' },
    { state: 'Addisababa', city: 'Addisababa' },
  ],
  FI: [
    { state: 'Uusimaa', city: 'Uusimaa' },
    { state: 'Uusimaa', city: 'Helsinki' },
  ],
  FJ: [
    { state: 'Central', city: 'Central' },
    { state: 'Central', city: 'Suva' },
    { state: 'Western', city: 'Western' },
  ],
  FR: [
    { state: 'Nouvelleaquitaine', city: 'Nouvelleaquitaine' },
    { state: 'Grandest', city: 'Strasbourg' },
    { state: 'Provence', city: 'Nice' },
    { state: 'Paysdelaloire', city: 'Paysdelaloire' },
    { state: 'Paysdelaloire', city: 'Nantes' },
    { state: 'Grandest', city: 'Grandest' },
    { state: 'Nouvelleaquitaine', city: 'Poitiers' },
    { state: 'Centrevaldeloire', city: 'Centrevaldeloire' },
    { state: 'Auvergnerhonealpes', city: 'Auvergnerhonealpes' },
    { state: 'Auvergnerhonealpes', city: 'Lyon' },
    { state: 'Bourgognefranchecomte', city: 'Bourgognefranchecomte' },
    { state: 'Brittany', city: 'Brittany' },
    { state: 'Provence', city: 'Provence' },
    { state: 'Provence', city: 'Marseille' },
    { state: 'Iledefrance', city: 'Nanterre' },
    { state: 'Iledefrance', city: 'Aubervilliers' },
    { state: 'Iledefrance', city: 'Bobigny' },
    { state: 'Hautsdefrance', city: 'Lille' },
    { state: 'Hautsdefrance', city: 'Hautsdefrance' },
    { state: 'Hautsdefrance', city: 'Gravelines' },
    { state: 'Iledefrance', city: 'Iledefrance' },
    { state: 'Iledefrance', city: 'Paris' },
    { state: 'Iledefrance', city: 'Puteaux' },
    { state: 'Iledefrance', city: 'Saintdenis' },
    { state: 'Normandy', city: 'Normandy' },
    { state: 'Occitanie', city: 'Occitanie' },
  ],
  GA: [
    { state: 'Estuaire', city: 'Estuaire' },
    { state: 'Estuaire', city: 'Libreville' },
  ],
  UK: [
    { state: 'England', city: 'Stockport' },
    { state: 'England', city: 'London' },
    { state: 'England', city: 'Cottingham' },
    { state: 'Northernireland', city: 'Jordanstown' },
    { state: 'Scotland', city: 'Midcalder' },
    { state: 'Northernireland', city: 'Glengormley' },
    { state: 'Northernireland', city: 'Eglinton' },
    { state: 'Scotland', city: 'Livingston' },
    { state: 'Scotland', city: 'Mayfield' },
    { state: 'Northernireland', city: 'Antrim' },
    { state: 'Scotland', city: 'Linlithgow' },
    { state: 'Scotland', city: 'Lenzie' },
    { state: 'Scotland', city: 'Kirkintilloch' },
    { state: 'Scotland', city: 'Kirkcaldy' },
    { state: 'Scotland', city: 'Kilmarnock' },
    { state: 'Scotland', city: 'Johnstone' },
    { state: 'Scotland', city: 'Irvine' },
    { state: 'Scotland', city: 'Scotland' },
    { state: 'England', city: 'Consett' },
    { state: 'Scotland', city: 'Wishaw' },
    { state: 'Scotland', city: 'Uddingston' },
    { state: 'Scotland', city: 'Thorntonhall' },
    { state: 'Scotland', city: 'Thornliebank' },
    { state: 'Scotland', city: 'Stepps' },
    { state: 'Northernireland', city: 'Annahilt' },
    { state: 'Scotland', city: 'Howwood' },
    { state: 'Northernireland', city: 'Bangor' },
    { state: 'Northernireland', city: 'Belfast' },
    { state: 'Northernireland', city: 'Dundonald' },
    { state: 'Scotland', city: 'Rutherglen' },
    { state: 'Scotland', city: 'Renfrew' },
    { state: 'Scotland', city: 'Perth' },
    { state: 'Scotland', city: 'Paisley' },
    { state: 'Scotland', city: 'Musselburgh' },
    { state: 'Scotland', city: 'Motherwell' },
    { state: 'Scotland', city: 'Milngavie' },
    { state: 'Northernireland', city: 'Carnmoney' },
    { state: 'Northernireland', city: 'Carrickfergus' },
    { state: 'Northernireland', city: 'Castlereagh' },
    { state: 'Scotland', city: 'Cults' },
    { state: 'Scotland', city: 'Glasgow' },
    { state: 'Scotland', city: 'Glasgow(broomhill)' },
    { state: 'Wales', city: 'Wrexham' },
    { state: 'Wales', city: 'Wales' },
    { state: 'Wales', city: 'Thornhill' },
    { state: 'Wales', city: 'Swansea' },
    { state: 'Wales', city: 'Rhoose' },
    { state: 'Wales', city: 'Radyr' },
    { state: 'Scotland', city: 'Giffnock' },
    { state: 'Scotland', city: 'Colinton' },
    { state: 'Scotland', city: 'Coatbridge' },
    { state: 'Scotland', city: 'Clydebank' },
    { state: 'Scotland', city: 'Chapelhall' },
    { state: 'Wales', city: 'Porttalbot' },
    { state: 'Wales', city: 'Pontypool' },
    { state: 'Wales', city: 'Penarth' },
    { state: 'Wales', city: 'Newport' },
    { state: 'Scotland', city: 'Dumbarton' },
    { state: 'Scotland', city: 'Edinburgh' },
    { state: 'Northernireland', city: 'Portadown' },
    { state: 'Scotland', city: 'Eastkilbride' },
    { state: 'Scotland', city: 'Erskine' },
    { state: 'Northernireland', city: 'Northernireland' },
    { state: 'Northernireland', city: 'Newtownards' },
    { state: 'Scotland', city: 'Dyce' },
    { state: 'Scotland', city: 'Dundee' },
    { state: 'Wales', city: 'Neath' },
    { state: 'Scotland', city: 'Dullatur' },
    { state: 'Northernireland', city: 'Newry' },
    { state: 'Scotland', city: 'Danderhall' },
    { state: 'Scotland', city: 'Cumbernauld' },
    { state: 'Scotland', city: 'Falkirk' },
    { state: 'Northernireland', city: 'Londonderry' },
    { state: 'Northernireland', city: 'Lisburn' },
    { state: 'Wales', city: 'Bridgend' },
    { state: 'Wales', city: 'Cwmbwrla' },
    { state: 'Wales', city: 'Cwmbran' },
    { state: 'Wales', city: 'Clydach' },
    { state: 'Northernireland', city: 'Larne' },
    { state: 'Scotland', city: 'Airdrie' },
    { state: 'Scotland', city: 'Aberdeen' },
    { state: 'Wales', city: 'Cardiff' },
    { state: 'Wales', city: 'Caerleon' },
    { state: 'Scotland', city: 'Alloa' },
    { state: 'Scotland', city: 'Glenrothes' },
    { state: 'Scotland', city: 'Gourock' },
    { state: 'Scotland', city: 'Greenock' },
    { state: 'Scotland', city: 'Hawkhead' },
    { state: 'Scotland', city: 'Highblantyre' },
    { state: 'Wales', city: 'Barry' },
    { state: 'Wales', city: 'Aberkenfig' },
    { state: 'Scotland', city: 'Edinburgh(southgyle)' },
    { state: 'Scotland', city: 'Cambuslang' },
    { state: 'Scotland', city: 'Glasgow(shettleston)' },
    { state: 'Scotland', city: 'Castlecary' },
    { state: 'Scotland', city: 'Carmunnock' },
    { state: 'Wales', city: 'Morriston' },
    { state: 'Wales', city: 'Marshfield' },
    { state: 'Wales', city: 'Llanelli' },
    { state: 'Wales', city: 'Landore' },
    { state: 'Scotland', city: 'Carluke' },
    { state: 'Scotland', city: 'Inverness' },
    { state: 'Wales', city: 'Gorseinon' },
    { state: 'Scotland', city: 'Bishopbriggs' },
    { state: 'Scotland', city: 'Bellshill' },
    { state: 'Scotland', city: 'Bearsden' },
    { state: 'Scotland', city: 'Barrhead' },
    { state: 'Scotland', city: 'Ayr' },
    { state: 'Scotland', city: 'Ardrossan' },
    { state: 'England', city: 'Welwyngardencity' },
    { state: 'England', city: 'Westhoughton' },
    { state: 'England', city: 'Westham' },
    { state: 'England', city: 'Westealing' },
    { state: 'England', city: 'Birmingham(smallheath)' },
    { state: 'England', city: 'Birmingham(witton)' },
    { state: 'England', city: 'Westderby' },
    { state: 'England', city: 'Birstall' },
    { state: 'England', city: 'Westbromwich' },
    { state: 'England', city: 'Westbridgford' },
    { state: 'England', city: 'Wembley' },
    { state: 'England', city: 'Westminster' },
    { state: 'England', city: 'Wellington' },
    { state: 'England', city: 'Wellingborough' },
    { state: 'England', city: 'Welling' },
    { state: 'England', city: 'Wednesfield' },
    { state: 'England', city: 'Wednesbury' },
    { state: 'England', city: 'Wawne' },
    { state: 'England', city: 'Watton' },
    { state: 'England', city: 'Wathupondearne' },
    { state: 'England', city: 'Watford' },
    { state: 'England', city: 'Beverley' },
    { state: 'England', city: 'Wigan' },
    { state: 'England', city: 'Widnes' },
    { state: 'England', city: 'Wickford' },
    { state: 'England', city: 'Whitworth' },
    { state: 'England', city: 'Whitleybay' },
    { state: 'England', city: 'Whitehaven' },
    { state: 'England', city: 'Whitchurch' },
    { state: 'England', city: 'Westwickham' },
    { state: 'England', city: 'Benwell' },
    { state: 'England', city: 'Waterorton' },
    { state: 'England', city: 'Bexleyheath' },
    { state: 'England', city: 'Biddulph' },
    { state: 'England', city: 'Billericay' },
    { state: 'England', city: 'Billingham' },
    { state: 'England', city: 'Bilston' },
    { state: 'England', city: 'Birkenhead' },
    { state: 'England', city: 'Birkenhead(bromborough)' },
    { state: 'England', city: 'Birmingham' },
    { state: 'England', city: 'Birmingham(kingsnorton)' },
    { state: 'England', city: 'Bradwell' },
    { state: 'England', city: 'Burtonontrent' },
    { state: 'England', city: 'Bognorregis' },
    { state: 'England', city: 'Bolton' },
    { state: 'England', city: 'Borrowash' },
    { state: 'England', city: 'Botley' },
    { state: 'England', city: 'Bottesford' },
    { state: 'England', city: 'Burnley' },
    { state: 'England', city: 'Bournemouth' },
    { state: 'England', city: 'Bracknell' },
    { state: 'England', city: 'Bradford' },
    { state: 'England', city: 'Bury' },
    { state: 'England', city: 'Bramley' },
    { state: 'England', city: 'Brandon' },
    { state: 'England', city: 'Braunstone' },
    { state: 'England', city: 'Brentwood' },
    { state: 'England', city: 'Bridgwater' },
    { state: 'England', city: 'Bridlington' },
    { state: 'England', city: 'Brierleyhill' },
    { state: 'England', city: 'Brightlingsea' },
    { state: 'England', city: 'Burngreave' },
    { state: 'England', city: 'Blackpool' },
    { state: 'England', city: 'Catcliffe' },
    { state: 'England', city: 'Castlevale' },
    { state: 'England', city: 'Castleford' },
    { state: 'England', city: 'Carshalton' },
    { state: 'England', city: 'Carlisle' },
    { state: 'England', city: 'Bishopsstortford' },
    { state: 'England', city: 'Canterbury' },
    { state: 'England', city: 'Cannock' },
    { state: 'England', city: 'Blackburn' },
    { state: 'England', city: 'Willenhall' },
    { state: 'England', city: 'Canningtown' },
    { state: 'England', city: 'Cambridge' },
    { state: 'England', city: 'Camberwell' },
    { state: 'England', city: 'Blaydon' },
    { state: 'England', city: 'Bloxwich' },
    { state: 'England', city: 'Blyth' },
    { state: 'England', city: 'Camberley' },
    { state: 'England', city: 'Caddington' },
    { state: 'England', city: 'Buxton' },
    { state: 'England', city: 'Newcastleupontyne(benwell)' },
    { state: 'England', city: 'Beckenham' },
    { state: 'England', city: 'Bedford' },
    { state: 'England', city: 'Belgrave' },
    { state: 'England', city: 'Belper' },
    { state: 'England', city: 'Belsizepark' },
    { state: 'England', city: 'Bentley' },
    { state: 'England', city: 'Newburn' },
    { state: 'England', city: 'Newbury' },
    { state: 'England', city: 'Newcastleunderlyme' },
    { state: 'England', city: 'Newcastleupontyne' },
    { state: 'England', city: 'Batley' },
    { state: 'England', city: 'Newmalden' },
    { state: 'England', city: 'Newmilton' },
    { state: 'England', city: 'Newtonabbot' },
    { state: 'England', city: 'Newtonaycliffe' },
    { state: 'England', city: 'Normanton' },
    { state: 'England', city: 'Northampton' },
    { state: 'England', city: 'Northolt' },
    { state: 'England', city: 'Northshields' },
    { state: 'England', city: 'Northwootton' },
    { state: 'England', city: 'Balham' },
    { state: 'England', city: 'Moreton' },
    { state: 'England', city: 'Morley' },
    { state: 'England', city: 'Mossley' },
    { state: 'England', city: 'Mossleyhill' },
    { state: 'England', city: 'Murton' },
    { state: 'England', city: 'Nelson' },
    { state: 'England', city: 'Newarkontrent' },
    { state: 'England', city: 'Newbasford' },
    { state: 'England', city: 'Backworth' },
    { state: 'England', city: 'Nortonhawkfield' },
    { state: 'England', city: 'Banbury' },
    { state: 'England', city: 'Barnsley' },
    { state: 'England', city: 'Barrowinfurness' },
    { state: 'England', city: 'Bartleygreen' },
    { state: 'England', city: 'Basford' },
    { state: 'England', city: 'Basford,stokeontrent' },
    { state: 'England', city: 'Basildon' },
    { state: 'England', city: 'Basingstoke' },
    { state: 'England', city: 'Bath' },
    { state: 'England', city: 'Woodplumpton' },
    { state: 'England', city: 'Yeovil' },
    { state: 'England', city: 'Yeadon' },
    { state: 'England', city: 'Yaxley' },
    { state: 'England', city: 'Wythenshawe(baguley)' },
    { state: 'England', city: 'Worthing' },
    { state: 'England', city: 'Worsley(wardley)' },
    { state: 'England', city: 'Worksop' },
    { state: 'England', city: 'Worcesterpark' },
    { state: 'England', city: 'Worcester' },
    { state: 'England', city: 'Woolton' },
    { state: 'England', city: 'York' },
    { state: 'England', city: 'Wombourne' },
    { state: 'England', city: 'Wolverhampton' },
    { state: 'England', city: 'Woking' },
    { state: 'England', city: 'Wisbech' },
    { state: 'England', city: 'Wingerworth' },
    { state: 'England', city: 'Winchester' },
    { state: 'England', city: 'Wimbledonpark' },
    { state: 'England', city: 'Wilmslow' },
    { state: 'England', city: 'Willesden' },
    { state: 'England', city: 'Chorley' },
    { state: 'England', city: 'Norwich' },
    { state: 'England', city: 'Nottingham' },
    { state: 'England', city: 'Nottingham(mapperley)' },
    { state: 'England', city: 'Nottingham(radford)' },
    { state: 'England', city: 'Nuneaton' },
    { state: 'England', city: 'Nunthorpe' },
    { state: 'England', city: 'Oadby' },
    { state: 'England', city: 'Oakengates' },
    { state: 'England', city: 'Oldbury' },
    { state: 'England', city: 'Burghbysands' },
    { state: 'England', city: 'Christchurch' },
    { state: 'England', city: 'Clactononsea' },
    { state: 'England', city: 'Clayton' },
    { state: 'England', city: 'Claytonlewoods' },
    { state: 'England', city: 'Clerkenwell' },
    { state: 'England', city: 'Clevedon' },
    { state: 'England', city: 'Clubmoor' },
    { state: 'England', city: 'Codsall' },
    { state: 'England', city: 'Yoxall' },
    { state: 'England', city: 'Thornaby' },
    { state: 'England', city: 'Tonbridge' },
    { state: 'England', city: 'Tolworth' },
    { state: 'England', city: 'Titchfield' },
    { state: 'England', city: 'Tipton' },
    { state: 'England', city: 'Timperley' },
    { state: 'England', city: 'Tilehurst' },
    { state: 'England', city: 'Tilbury' },
    { state: 'England', city: 'Tidburygreen' },
    { state: 'England', city: 'Thorntonheath' },
    { state: 'England', city: 'Thorntoncleveleys' },
    { state: 'England', city: 'Torpoint' },
    { state: 'England', city: 'Theboldons' },
    { state: 'England', city: 'Thamesditton' },
    { state: 'England', city: 'Tewkesbury' },
    { state: 'England', city: 'Telford' },
    { state: 'England', city: 'Bristol' },
    { state: 'England', city: 'Taunton' },
    { state: 'England', city: 'Tamworth' },
    { state: 'England', city: 'Syston' },
    { state: 'England', city: 'Swinton' },
    { state: 'England', city: 'Shiremoor' },
    { state: 'England', city: 'Scholes' },
    { state: 'England', city: 'Scunthorpe' },
    { state: 'England', city: 'Shadwell' },
    { state: 'England', city: 'Shaw' },
    { state: 'England', city: 'Sheffield' },
    { state: 'England', city: 'Shepherdsbush' },
    { state: 'England', city: 'Shepshed' },
    { state: 'England', city: 'Shevington' },
    { state: 'England', city: 'Shipley' },
    { state: 'England', city: 'Swindon(cheneymanorindustrialestate)' },
    { state: 'England', city: 'Shirley' },
    { state: 'England', city: 'Shrewsbury' },
    { state: 'England', city: 'Simonstone' },
    { state: 'England', city: 'Sinfin' },
    { state: 'England', city: 'Skelmersdale' },
    { state: 'England', city: 'Slough' },
    { state: 'England', city: 'Smethwick' },
    { state: 'England', city: 'Snaresbrook' },
    { state: 'England', city: 'Solihull' },
    { state: 'England', city: 'Stone' },
    { state: 'England', city: 'Stapleford' },
    { state: 'England', city: 'Staustell' },
    { state: 'England', city: 'Staverton' },
    { state: 'England', city: 'Stepney' },
    { state: 'England', city: 'Stevenage' },
    { state: 'England', city: 'Sthelens' },
    { state: 'England', city: 'Stockport' },
    { state: 'England', city: 'Stocktonontees' },
    { state: 'England', city: 'Stokegifford' },
    { state: 'England', city: 'Stokeontrent' },
    { state: 'England', city: 'Stanley' },
    { state: 'England', city: 'Stourbridge' },
    { state: 'England', city: 'Stratforduponavon' },
    { state: 'England', city: 'Streatham' },
    { state: 'England', city: 'Streatley' },
    { state: 'England', city: 'Streetly' },
    { state: 'England', city: 'Stretford' },
    { state: 'England', city: 'Stretford(oldtrafford)' },
    { state: 'England', city: 'Stretford(traffordpark)' },
    { state: 'England', city: 'Sunderland' },
    { state: 'England', city: 'Southendonsea' },
    { state: 'England', city: 'Swindon' },
    { state: 'England', city: 'Swadlincote' },
    { state: 'England', city: 'Suttoninashfield' },
    { state: 'England', city: 'Suttoncoldfield' },
    { state: 'England', city: 'Sutton' },
    { state: 'England', city: 'Southall' },
    { state: 'England', city: 'Southam' },
    { state: 'England', city: 'Southampton' },
    { state: 'England', city: 'Southbank' },
    { state: 'England', city: 'Scarborough' },
    { state: 'England', city: 'Southgate' },
    { state: 'England', city: 'Southport' },
    { state: 'England', city: 'Southshields' },
    { state: 'England', city: 'Sowerbybridge' },
    { state: 'England', city: 'Spalding(fulney)' },
    { state: 'England', city: 'Speke' },
    { state: 'England', city: 'Spennymoor' },
    { state: 'England', city: 'Stafford' },
    { state: 'England', city: 'Stalybridge' },
    { state: 'England', city: 'Orgreave' },
    { state: 'England', city: 'Wallington' },
    { state: 'England', city: 'Wallasey' },
    { state: 'England', city: 'Wakefield' },
    { state: 'England', city: 'Brighton' },
    { state: 'England', city: 'Ushawmoor' },
    { state: 'England', city: 'Upminster' },
    { state: 'England', city: 'Tyldesley' },
    { state: 'England', city: 'Tunstall' },
    { state: 'England', city: 'Trowbridge' },
    { state: 'England', city: 'Tottenham' },
    { state: 'England', city: 'Wallsend' },
    { state: 'England', city: 'Ormskirk' },
    { state: 'England', city: 'Orpington' },
    { state: 'England', city: 'Oxford' },
    { state: 'England', city: 'Paignton' },
    { state: 'England', city: 'Palmersgreen' },
    { state: 'England', city: 'Parkstone' },
    { state: 'England', city: 'Paull' },
    { state: 'England', city: 'Pelsall' },
    { state: 'England', city: 'Pelton' },
    { state: 'England', city: 'Oldham' },
    { state: 'England', city: 'Brownhills' },
    { state: 'England', city: 'Bromsgrove' },
    { state: 'England', city: 'Bromley' },
    { state: 'England', city: 'Brockley' },
    { state: 'England', city: 'Broadstone' },
    { state: 'England', city: 'Broadblunsdon' },
    { state: 'England', city: 'Brixtonhill' },
    { state: 'England', city: 'Washington' },
    { state: 'England', city: 'Bristol(staplehill)' },
    { state: 'England', city: 'Peterborough' },
    { state: 'England', city: 'Oldswan' },
    { state: 'England', city: 'Warwick' },
    { state: 'England', city: 'Warrington' },
    { state: 'England', city: 'Ware' },
    { state: 'England', city: 'Wanstead' },
    { state: 'England', city: 'Wandsworth' },
    { state: 'England', city: 'Waltononthames' },
    { state: 'England', city: 'Walthamstow' },
    { state: 'England', city: 'Walsall' },
    { state: 'England', city: 'Ruislip' },
    { state: 'England', city: 'Ringway' },
    { state: 'England', city: 'Rochdale' },
    { state: 'England', city: 'Romford' },
    { state: 'England', city: 'Rotherham' },
    { state: 'England', city: 'Rowleyregis' },
    { state: 'England', city: 'Royalleamingtonspa' },
    { state: 'England', city: 'Royaltunbridgewells' },
    { state: 'England', city: 'Royston' },
    { state: 'England', city: 'Royton' },
    { state: 'England', city: 'Rugby' },
    { state: 'England', city: 'Redditch' },
    { state: 'England', city: 'Runcorn' },
    { state: 'England', city: 'Ryde' },
    { state: 'England', city: 'Ryhope' },
    { state: 'England', city: 'Saintandrewsquay' },
    { state: 'England', city: 'Saintleonardsonsea' },
    { state: 'England', city: 'Saintneots' },
    { state: 'England', city: 'Sale' },
    { state: 'England', city: 'Salford' },
    { state: 'England', city: 'Sandbach' },
    { state: 'England', city: 'Potterheigham' },
    { state: 'England', city: 'Pinner' },
    { state: 'England', city: 'Plymouth' },
    { state: 'England', city: 'Plympton' },
    { state: 'England', city: 'Plymstock' },
    { state: 'England', city: 'Polesworth' },
    { state: 'England', city: 'Poole' },
    { state: 'England', city: 'Poplar' },
    { state: 'England', city: 'Portsmouth' },
    { state: 'England', city: 'Portsmouth(cosham)' },
    { state: 'England', city: 'Morden' },
    { state: 'England', city: 'Prenton' },
    { state: 'England', city: 'Preston' },
    { state: 'England', city: 'Prestwich' },
    { state: 'England', city: 'Pudsey' },
    { state: 'England', city: 'Rainham' },
    { state: 'England', city: 'Ramsgate' },
    { state: 'England', city: 'Rastrick' },
    { state: 'England', city: 'Rawtenstall' },
    { state: 'England', city: 'Reading' },
    { state: 'England', city: 'Greatyarmouth' },
    { state: 'England', city: 'Gosforth' },
    { state: 'England', city: 'Gosport' },
    { state: 'England', city: 'Grangehill' },
    { state: 'England', city: 'Grantham' },
    { state: 'England', city: 'Gravesend' },
    { state: 'England', city: 'Grays' },
    { state: 'England', city: 'Greatham' },
    { state: 'England', city: 'Greatmarton' },
    { state: 'England', city: 'Goringbysea' },
    { state: 'England', city: 'Grimsby' },
    { state: 'England', city: 'Groby' },
    { state: 'England', city: 'Guildford' },
    { state: 'England', city: 'Hackney' },
    { state: 'England', city: 'Hadley' },
    { state: 'England', city: 'Halebarns' },
    { state: 'England', city: 'Halesowen' },
    { state: 'England', city: 'Georgegreen' },
    { state: 'England', city: 'Friernbarnet' },
    { state: 'England', city: 'Fulford' },
    { state: 'England', city: 'Fulham' },
    { state: 'England', city: 'Fulwood' },
    { state: 'England', city: 'Garston' },
    { state: 'England', city: 'Garswood' },
    { state: 'England', city: 'Gateshead' },
    { state: 'England', city: 'Gedling' },
    { state: 'England', city: 'Halifax' },
    { state: 'England', city: 'Gildersome' },
    { state: 'England', city: 'Gillingham' },
    { state: 'England', city: 'Glenfield' },
    { state: 'England', city: 'Gloucester' },
    { state: 'England', city: 'Golborne' },
    { state: 'England', city: 'Goodmayes' },
    { state: 'England', city: 'Goosnargh' },
    { state: 'England', city: 'Chelmsford' },
    { state: 'England', city: 'Hendon' },
    { state: 'England', city: 'Hersham' },
    { state: 'England', city: 'Hertford' },
    { state: 'England', city: 'Cheadlehulme' },
    { state: 'England', city: 'Chatham' },
    { state: 'England', city: 'Chapeltown' },
    { state: 'England', city: 'Chapelallerton' },
    { state: 'England', city: 'Chalton' },
    { state: 'England', city: 'Hemelhempstead' },
    { state: 'England', city: 'Chelmsleywood' },
    { state: 'England', city: 'Cheltenham' },
    { state: 'England', city: 'Cheshunt' },
    { state: 'England', city: 'Chester' },
    { state: 'England', city: 'Chesterfield' },
    { state: 'England', city: 'Heslington' },
    { state: 'England', city: 'Hessle' },
    { state: 'England', city: 'Hatfield' },
    { state: 'England', city: 'Halton' },
    { state: 'England', city: 'Hammersmith' },
    { state: 'England', city: 'Hardingstone' },
    { state: 'England', city: 'Harlesden' },
    { state: 'England', city: 'Harlow' },
    { state: 'England', city: 'Harringay' },
    { state: 'England', city: 'Harrogate' },
    { state: 'England', city: 'Hartlepool' },
    { state: 'England', city: 'Findern' },
    { state: 'England', city: 'Havant' },
    { state: 'England', city: 'Hayes' },
    { state: 'England', city: 'Haywardsheath' },
    { state: 'England', city: 'Hazelgrove' },
    { state: 'England', city: 'Hazlerigg' },
    { state: 'England', city: 'Hebburn' },
    { state: 'England', city: 'Heckmondwike' },
    { state: 'England', city: 'Dersingham' },
    { state: 'England', city: 'Dagenham' },
    { state: 'England', city: 'Darlaston' },
    { state: 'England', city: 'Darlington' },
    { state: 'England', city: 'Dartford' },
    { state: 'England', city: 'Darwen' },
    { state: 'England', city: 'Denton' },
    { state: 'England', city: 'Derby' },
    { state: 'England', city: 'Dereham' },
    { state: 'England', city: 'Cudworth' },
    { state: 'England', city: 'Dewsbury' },
    { state: 'England', city: 'Deysbrook' },
    { state: 'England', city: 'Dickensheath' },
    { state: 'England', city: 'Didsbury' },
    { state: 'England', city: 'Dingle' },
    { state: 'England', city: 'Doncaster' },
    { state: 'England', city: 'Drighlington' },
    { state: 'England', city: 'Crawley' },
    { state: 'England', city: 'Colchester' },
    { state: 'England', city: 'Coppull' },
    { state: 'England', city: 'Cosham' },
    { state: 'England', city: 'Coventry' },
    { state: 'England', city: 'Cowes' },
    { state: 'England', city: 'Cowley' },
    { state: 'England', city: 'Cowpen' },
    { state: 'England', city: 'Cramlington' },
    { state: 'England', city: 'Dronfield' },
    { state: 'England', city: 'Credenhill' },
    { state: 'England', city: 'Crewe' },
    { state: 'England', city: 'Cricklewood' },
    { state: 'England', city: 'Crosby' },
    { state: 'England', city: 'Crowthorne' },
    { state: 'England', city: 'Croydon' },
    { state: 'England', city: 'Crystalpalace' },
    { state: 'England', city: 'Exhall' },
    { state: 'England', city: 'England' },
    { state: 'England', city: 'Epsom' },
    { state: 'England', city: 'Erith' },
    { state: 'England', city: 'Essington' },
    { state: 'England', city: 'Eston' },
    { state: 'England', city: 'Etonwick' },
    { state: 'England', city: 'Euxton' },
    { state: 'England', city: 'Exeter' },
    { state: 'England', city: 'Enfield' },
    { state: 'England', city: 'Exmouth' },
    { state: 'England', city: 'Failsworth' },
    { state: 'England', city: 'Farnborough' },
    { state: 'England', city: 'Fazakerley' },
    { state: 'England', city: 'Felling' },
    { state: 'England', city: 'Feltham' },
    { state: 'England', city: 'Finchley' },
    { state: 'England', city: 'Eastbourne' },
    { state: 'England', city: 'Droylsden' },
    { state: 'England', city: 'Dudley' },
    { state: 'England', city: 'Dukinfield' },
    { state: 'England', city: 'Dunstable' },
    { state: 'England', city: 'Dunswell' },
    { state: 'England', city: 'Durham' },
    { state: 'England', city: 'Earlsfield' },
    { state: 'England', city: 'Eastboldon' },
    { state: 'England', city: 'Mitcham' },
    { state: 'England', city: 'Eastham' },
    { state: 'England', city: 'Eastleigh' },
    { state: 'England', city: 'Edgware' },
    { state: 'England', city: 'Elland' },
    { state: 'England', city: 'Ellesmereport' },
    { state: 'England', city: 'Elton' },
    { state: 'England', city: 'Ely' },
    { state: 'England', city: 'Knebworth' },
    { state: 'England', city: 'Acton' },
    { state: 'England', city: 'Aldershot' },
    { state: 'England', city: 'Aldridge' },
    { state: 'England', city: 'Alfreton' },
    { state: 'England', city: 'Kirkby' },
    { state: 'England', city: 'Kirkbyinashfield' },
    { state: 'England', city: 'Kirkleatham' },
    { state: 'England', city: 'Kislingbury' },
    { state: 'England', city: 'Kingswood' },
    { state: 'England', city: 'Knowsley' },
    { state: 'England', city: 'Lamesley' },
    { state: 'England', city: 'Lancaster' },
    { state: 'England', city: 'Lancing' },
    { state: 'England', city: 'Langleypark' },
    { state: 'England', city: 'Lee' },
    { state: 'England', city: 'Leeds' },
    { state: 'England', city: 'Leeds(seacroft)' },
    { state: 'England', city: 'London(finchley)' },
    { state: 'England', city: 'London(holborn)' },
    { state: 'England', city: 'London(nottinghill)' },
    { state: 'England', city: 'London(paddington)' },
    { state: 'England', city: 'London(royalkensingtonandchelsea)' },
    { state: 'England', city: 'London(shadwell)' },
    { state: 'England', city: 'London(southbank)' },
    { state: 'England', city: 'London(walthamstow)' },
    { state: 'England', city: 'Longeaton' },
    { state: 'England', city: 'Kingswinford' },
    { state: 'England', city: 'Kingstonuponthames' },
    { state: 'England', city: 'Kingslynn' },
    { state: 'England', city: 'Kimberley' },
    { state: 'England', city: 'Kidderminster' },
    { state: 'England', city: 'Kettering' },
    { state: 'England', city: 'Aylestone' },
    { state: 'England', city: 'Litherland' },
    { state: 'England', city: 'Arnold' },
    { state: 'England', city: 'Ashford' },
    { state: 'England', city: 'Ashington' },
    { state: 'England', city: 'Ashtoninmakerfield' },
    { state: 'England', city: 'Ashtonunderlyne' },
    { state: 'England', city: 'Auckley' },
    { state: 'England', city: 'Aylesbury' },
    { state: 'England', city: 'Linthwaite' },
    { state: 'England', city: 'Littlehulton' },
    { state: 'England', city: 'Littlelever' },
    { state: 'England', city: 'Liverpool' },
    { state: 'England', city: 'Lofthouse' },
    { state: 'England', city: 'London' },
    { state: 'England', city: 'London(colindale)' },
    { state: 'England', city: 'London(dalston)' },
    { state: 'England', city: 'Kessingland' },
    { state: 'England', city: 'Lincoln' },
    { state: 'England', city: 'Lichfield' },
    { state: 'England', city: 'Leyton' },
    { state: 'England', city: 'Leysdownonsea' },
    { state: 'England', city: 'Leyland' },
    { state: 'England', city: 'Leightonbuzzard' },
    { state: 'England', city: 'Leighonsea' },
    { state: 'England', city: 'Leigh' },
    { state: 'England', city: 'Leicester' },
    { state: 'England', city: 'Leeonthesolent' },
    { state: 'England', city: 'Archway' },
    { state: 'England', city: 'Annfieldplain' },
    { state: 'England', city: 'Andover' },
    { state: 'England', city: 'Allerton' },
    { state: 'England', city: 'Leek' },
    { state: 'England', city: 'Maidstone' },
    { state: 'England', city: 'Hitchin' },
    { state: 'England', city: 'Hinckley' },
    { state: 'England', city: 'Loughborough' },
    { state: 'England', city: 'Lowestoft' },
    { state: 'England', city: 'Chilworth' },
    { state: 'England', city: 'Chingford' },
    { state: 'England', city: 'Chippenham' },
    { state: 'England', city: 'Luton' },
    { state: 'England', city: 'Lythamstannes' },
    { state: 'England', city: 'Macclesfield' },
    { state: 'England', city: 'Madeley' },
    { state: 'England', city: 'Maghull' },
    { state: 'England', city: 'Maidenhead' },
    { state: 'England', city: 'Hillingdon' },
    { state: 'England', city: 'Chesterlestreet' },
    { state: 'England', city: 'Holbeck' },
    { state: 'England', city: 'Maldon' },
    { state: 'England', city: 'Highwycombe' },
    { state: 'England', city: 'Highbury' },
    { state: 'England', city: 'Manchester' },
    { state: 'England', city: 'Mangotsfield' },
    { state: 'England', city: 'Heysham' },
    { state: 'England', city: 'Hethersett' },
    { state: 'England', city: 'Heswall' },
    { state: 'England', city: 'Heston' },
    { state: 'England', city: 'Mansfield' },
    { state: 'England', city: 'Mansfieldwoodhouse' },
    { state: 'England', city: 'Marketharborough' },
    { state: 'England', city: 'Marske' },
    { state: 'England', city: 'Martlesham' },
    { state: 'England', city: 'Martlesham(martleshamheath)' },
    { state: 'England', city: 'Humberstone' },
    { state: 'England', city: 'Keresley' },
    { state: 'England', city: 'Kensington' },
    { state: 'England', city: 'Kennington' },
    { state: 'England', city: 'Kendal' },
    { state: 'England', city: 'Kempston' },
    { state: 'England', city: 'Keighley' },
    { state: 'England', city: 'Jarrow' },
    { state: 'England', city: 'Miltonkeynes' },
    { state: 'England', city: 'Irlam' },
    { state: 'England', city: 'Ipswich' },
    { state: 'England', city: 'Ilkeston' },
    { state: 'England', city: 'Ilford(sevenkings)' },
    { state: 'England', city: 'Ilford' },
    { state: 'England', city: 'Hyde' },
    { state: 'England', city: 'Huyton' },
    { state: 'England', city: 'Hull' },
    { state: 'England', city: 'Huddersfield' },
    { state: 'England', city: 'Hucknall' },
    { state: 'England', city: 'Hove' },
    { state: 'England', city: 'Hounslow' },
    { state: 'England', city: 'Houghtonregis' },
    { state: 'England', city: 'Houghtonlespring' },
    { state: 'England', city: 'Horsham' },
    { state: 'England', city: 'Longton' },
    { state: 'England', city: 'Lostock' },
    { state: 'England', city: 'Middleton' },
    { state: 'England', city: 'Middlesbrough' },
    { state: 'England', city: 'Loudwater' },
    { state: 'England', city: 'Horsforth' },
    { state: 'England', city: 'Hoole' },
  ],
  GD: [
    { state: 'Saintgeorgeparish', city: 'Saintgeorgeparish' },
    { state: 'Saintgeorgeparish', city: 'Stgeorges' },
    { state: 'Saintandrewparish', city: 'Grenville' },
    { state: 'Saintandrewparish', city: 'Saintandrewparish' },
    { state: 'Saintgeorge', city: 'Stgeorges' },
    { state: 'Saintgeorge', city: 'Saintgeorge' },
  ],
  GE: [
    { state: 'Kvemokartli', city: 'Kvemokartli' },
    { state: 'Tbilisi', city: 'Tbilisi(dzvelitbilisi)' },
    { state: 'Imereti', city: 'Kulashi' },
    { state: 'Imereti', city: 'Imereti' },
    { state: 'Tbilisi', city: 'Tbilisi' },
    { state: 'Mtskhetamtianeti', city: 'Mtskhetamtianeti' },
    { state: 'Mtskhetamtianeti', city: 'Zahesi(gldaninadzaladevi)' },
    { state: 'Samtskhejavakheti', city: 'Samtskhejavakheti' },
    { state: 'Adjara', city: 'Adjara' },
    { state: 'Adjara', city: 'Batumi' },
  ],
  GH: [
    { state: 'Greateraccra', city: 'Greateraccra' },
    { state: 'Greateraccra', city: 'Accra(osuklottey)' },
  ],
  GR: [
    { state: 'Attica', city: 'Athens' },
    { state: 'Attica', city: 'Attica' },
    { state: 'Attica', city: 'Chalandri' },
    { state: 'Attica', city: 'Karellas' },
    { state: 'Attica', city: 'Marousi' },
    { state: 'Westmacedonia', city: 'Westmacedonia' },
    { state: 'Crete', city: 'Limenashersonissou' },
    { state: 'Crete', city: 'Crete' },
    { state: 'Southaegean', city: 'Southaegean' },
    { state: 'Centralmacedonia', city: 'Centralmacedonia' },
    { state: 'Centralmacedonia', city: 'Thessaloniki' },
  ],
  GT: [
    { state: 'Guatemala', city: 'Guatemalacity(zone1)' },
    { state: 'Guatemala', city: 'Guatemalacity' },
    { state: 'Guatemala', city: 'Guatemala' },
    { state: 'Chiquimula', city: 'Esquipulas' },
    { state: 'Chiquimula', city: 'Chiquimula' },
  ],
  GY: [
    { state: 'Upperdemeraraberbice', city: 'Linden' },
    { state: 'Upperdemeraraberbice', city: 'Upperdemeraraberbice' },
    { state: 'Demeraramahaica', city: 'Georgetown' },
    { state: 'Essequiboislandswestdemerara', city: 'Essequiboislandswestdemerara' },
    { state: 'Essequiboislandswestdemerara', city: 'Vreedenhoop' },
    { state: 'Demeraramahaica', city: 'Demeraramahaica' },
  ],
  HK: [
    { state: 'Kwaitsing', city: 'Kwaichung' },
    { state: 'Kowloon', city: 'Kowloon' },
    { state: 'Kowloon', city: 'Hongkong' },
    { state: 'Kwaitsing', city: 'Kwaitsing' },
    { state: 'Centralandwestern', city: 'Centralandwestern' },
    { state: 'Tsuenwan', city: 'Tsuenwan' },
  ],
  HN: [
    { state: 'Franciscomorazandepartment', city: 'Tegucigalpa' },
    { state: 'Cortesdepartment', city: 'Cortesdepartment' },
    { state: 'Franciscomorazandepartment', city: 'Franciscomorazandepartment' },
  ],
  HR: [
    { state: 'Cityofzagreb', city: 'Cityofzagreb' },
    { state: 'Zagreb', city: 'Zagreb' },
    { state: 'Splitdalmatia', city: 'Splitdalmatia' },
  ],
  HU: [
    { state: 'Bacskiskun', city: 'Ersekcsanad' },
    { state: 'Pestcounty', city: 'Pestcounty' },
    { state: 'Jasznagykunszolnok', city: 'Turkeve' },
    { state: 'Jasznagykunszolnok', city: 'Szolnok' },
    { state: 'Budapest', city: 'Budapest' },
  ],
  ID: [
    { state: 'Westjava', city: 'Bekasi' },
    { state: 'Banten', city: 'Southtangerang' },
    { state: 'Eastjava', city: 'Malang' },
    { state: 'Banten', city: 'Banten' },
    { state: 'Eastjava', city: 'Magetan' },
    { state: 'Westsumatra', city: 'Westsumatra' },
    { state: 'Westsumatra', city: 'Tabing' },
    { state: 'Eastjava', city: 'Madiun' },
    { state: 'Eastjava', city: 'Kumendungsatu' },
    { state: 'Westnusatenggara', city: 'Westnusatenggara' },
    { state: 'Banten', city: 'Tangerang' },
    { state: 'Westkalimantan', city: 'Westkalimantan' },
    { state: 'Westkalimantan', city: 'Pontianak' },
    { state: 'Westjava', city: 'Westjava' },
    { state: 'Bangkabelitungislands', city: 'Kepoh' },
    { state: 'Westjava', city: 'Teluknaga' },
    { state: 'Westjava', city: 'Sukabumi' },
    { state: 'Eastjava', city: 'Kalibarukulon' },
    { state: 'Eastjava', city: 'Jagirsidosermo' },
    { state: 'Eastjava', city: 'Gubengairlangga' },
    { state: 'Riauislands', city: 'Pancurbirulestariii' },
    { state: 'Riau', city: 'Batam' },
    { state: 'Centraljava', city: 'Jatiroto' },
    { state: 'Riau', city: 'Paritkalipatopah' },
    { state: 'Eastjava', city: 'Pacitan' },
    { state: 'Riau', city: 'Pekanbaru' },
    { state: 'Riau', city: 'Riau' },
    { state: 'Riau', city: 'Tembilahan' },
    { state: 'Bengkulu', city: 'Bengkulu' },
    { state: 'Riauislands', city: 'Batam' },
    { state: 'Riau', city: 'Dumai' },
    { state: 'Aceh', city: 'Aceh' },
    { state: 'Riauislands', city: 'Riauislands' },
    { state: 'Riauislands', city: 'Sekupang' },
    { state: 'Westjava', city: 'Bandung' },
    { state: 'Eastjava', city: 'Pakiskrajan' },
    { state: 'Yogyakarta', city: 'Yogyakarta' },
    { state: 'Riauislands', city: 'Tanjungpinang' },
    { state: 'Yogyakarta', city: 'Sleman' },
    { state: 'Northsulawesi', city: 'Northsulawesi' },
    { state: 'Westjava', city: 'Karawang' },
    { state: 'Westjava', city: 'Jatibarang' },
    { state: 'Bali', city: 'Ubud' },
    { state: 'Bali', city: 'Legian' },
    { state: 'Bali', city: 'Gianyar' },
    { state: 'Bali', city: 'Banjarmedura' },
    { state: 'Bali', city: 'Bali' },
    { state: 'Northsumatra', city: 'Northsumatra' },
    { state: 'Northsumatra', city: 'Medan' },
    { state: 'Northsumatra', city: 'Binjai' },
    { state: 'Westjava', city: 'Pamanukan' },
    { state: 'Westjava', city: 'Indramayu' },
    { state: 'Westjava', city: 'Cileungsir' },
    { state: 'Northsulawesi', city: 'Manado' },
    { state: 'Northkalimantan', city: 'Tarakan' },
    { state: 'Northkalimantan', city: 'Northkalimantan' },
    { state: 'Westjava', city: 'Depok' },
    { state: 'Eastjava', city: 'Munjungan' },
    { state: 'Westjava', city: 'Cileunyi' },
    { state: 'Westjava', city: 'Cirebon' },
    { state: 'Westjava', city: 'Ciputat' },
    { state: 'Eastjava', city: 'Batu' },
    { state: 'Westjava', city: 'Cimahi' },
    { state: 'Westjava', city: 'Bogor' },
    { state: 'Westjava', city: 'Cianjur' },
    { state: 'Bangkabelitungislands', city: 'Bangkabelitungislands' },
    { state: 'Westjava', city: 'Cibinong' },
    { state: 'Eastjava', city: 'Eastjava' },
    { state: 'Westjava', city: 'Sawangan' },
    { state: 'Westjava', city: 'Pasarkemis' },
    { state: 'Eastjava', city: 'Boyolangu' },
    { state: 'Eastjava', city: 'Blitar' },
    { state: 'Westjava', city: 'Serpong' },
    { state: 'Westjava', city: 'Cikampek' },
    { state: 'Eastjava', city: 'Banyuwangiregency' },
    { state: 'Eastjava', city: 'Banyuwangi' },
    { state: 'Westjava', city: 'Parung' },
    { state: 'Eastjava', city: 'Bakalan' },
    { state: 'Gorontalo', city: 'Gorontalo' },
    { state: 'Westjava', city: 'Pamulang' },
    { state: 'Westjava', city: 'Cikarang' },
    { state: 'Westjava', city: 'Cikupa' },
    { state: 'Westjava', city: 'Pameungpeuk' },
    { state: 'Southkalimantan', city: 'Southkalimantan' },
    { state: 'Centraljava', city: 'Wonogiri' },
    { state: 'Centralkalimantan', city: 'Centralkalimantan' },
    { state: 'Southsumatra', city: 'Sungaigerong' },
    { state: 'Southsumatra', city: 'Southsumatra' },
    { state: 'Southsumatra', city: 'Palembang' },
    { state: 'Southsulawesi', city: 'Southsulawesi' },
    { state: 'Southsulawesi', city: 'Pinrang' },
    { state: 'Southsulawesi', city: 'Makassar' },
    { state: 'Centraljava', city: 'Temanggung' },
    { state: 'Southkalimantan', city: 'Banjarmasin' },
    { state: 'Southeastsulawesi', city: 'Southeastsulawesi' },
    { state: 'Eastnusatenggara', city: 'Eastnusatenggara' },
    { state: 'Lampung', city: 'Lampung' },
    { state: 'Lampung', city: 'Kedaton' },
    { state: 'Eastkalimantan', city: 'Samarinda' },
    { state: 'Eastkalimantan', city: 'Loajanan' },
    { state: 'Maluku', city: 'Maluku' },
    { state: 'Centraljava', city: 'Centraljava' },
    { state: 'Centraljava', city: 'Banjarnegara' },
    { state: 'Centraljava', city: 'Bancakwetan' },
    { state: 'Centraljava', city: 'Jepara' },
    { state: 'Centraljava', city: 'Kaborongan' },
    { state: 'Centraljava', city: 'Kebumen' },
    { state: 'Centraljava', city: 'Klaten' },
    { state: 'Centraljava', city: 'Krajangrogolan' },
    { state: 'Jakarta', city: 'Jakartapusat' },
    { state: 'Maluku', city: 'Amboncity' },
    { state: 'Centraljava', city: 'Kubangwaru' },
    { state: 'Centraljava', city: 'Kudus' },
    { state: 'Centraljava', city: 'Rejoso' },
    { state: 'Centraljava', city: 'Semarang' },
    { state: 'Centraljava', city: 'Sragen' },
    { state: 'Centraljava', city: 'Surakarta' },
    { state: 'Eastkalimantan', city: 'Eastkalimantan' },
    { state: 'Jakarta', city: 'Jakarta' },
    { state: 'Jakarta', city: 'Utan' },
    { state: 'Jambi', city: 'Jambi' },
    { state: 'Jambi', city: 'Jambicity' },
    { state: 'Eastjava', city: 'Pare' },
    { state: 'Eastjava', city: 'Setro' },
    { state: 'Eastjava', city: 'Surabaya' },
    { state: 'Eastjava', city: 'Tamanan' },
    { state: 'Eastjava', city: 'Tulungagung' },
    { state: 'Eastkalimantan', city: 'Kahala' },
    { state: 'Eastkalimantan', city: 'Bontang' },
    { state: 'Eastkalimantan', city: 'Balikpapan' },
    { state: 'Eastjava', city: 'Wonosari' },
    { state: 'Eastjava', city: 'Wonorejo' },
  ],
  IE: [
    { state: 'Leinster', city: 'Dundalk' },
    { state: 'Leinster', city: 'Dublin(cooldowncommons)' },
    { state: 'Munster', city: 'Munster' },
    { state: 'Munster', city: 'Mallow' },
    { state: 'Leinster', city: 'Dublin(tallaght)' },
    { state: 'Connacht', city: 'Galway' },
    { state: 'Leinster', city: 'Dublin(sandyford)' },
    { state: 'Munster', city: 'Limerick' },
    { state: 'Munster', city: 'Cork' },
    { state: 'Munster', city: 'Castletroy' },
    { state: 'Connacht', city: 'Sligo' },
    { state: 'Leinster', city: 'Dublin(priorswood)' },
    { state: 'Leinster', city: 'Dublin(kimmage)' },
    { state: 'Leinster', city: 'Dublin(grandcanaldock)' },
    { state: 'Leinster', city: 'Blackrock' },
    { state: 'Leinster', city: 'Dunlaoghaire' },
    { state: 'Munster', city: 'Waterford' },
    { state: 'Leinster', city: 'Edenderry' },
    { state: 'Leinster', city: 'Greystones' },
    { state: 'Leinster', city: 'Hartstown' },
    { state: 'Leinster', city: 'Leinster' },
    { state: 'Connacht', city: 'Foxford' },
    { state: 'Connacht', city: 'Connacht' },
    { state: 'Leinster', city: 'Mullingar' },
    { state: 'Leinster', city: 'Portlaoise' },
    { state: 'Leinster', city: 'Raheny' },
    { state: 'Leinster', city: 'Rathgar' },
    { state: 'Leinster', city: 'Saggart(nangor)' },
    { state: 'Leinster', city: 'Sandyford(leopardstown)' },
    { state: 'Ulster', city: 'Bridgeend(elaghbeg)' },
    { state: 'Ulster', city: 'Ulster' },
    { state: 'Leinster', city: 'Ballyogan(sandyford)' },
    { state: 'Leinster', city: 'Dublin' },
    { state: 'Leinster', city: 'Dublin(ballycoolen)' },
    { state: 'Leinster', city: 'Ballyogan(leopardstown)' },
    { state: 'Leinster', city: 'Dublin(blanchardstown)' },
    { state: 'Leinster', city: 'Dublin(cherryorchard)' },
    { state: 'Leinster', city: 'Drumcondra' },
    { state: 'Leinster', city: 'Dublin(citywestbusinesscampus)' },
    { state: 'Leinster', city: 'Dublin(citywest)' },
    { state: 'Leinster', city: 'Clondalkin' },
  ],
  IL: [
    { state: 'Judeaandsamariaarea', city: 'Givonhahadasha' },
    { state: 'Centraldistrict', city: 'Petahtikva' },
    { state: 'Northerndistrict', city: 'Northerndistrict' },
    { state: 'Centraldistrict', city: 'Roshhaayin' },
    { state: 'Centraldistrict', city: 'Yakum' },
    { state: 'Northerndistrict', city: 'Kafrmanda' },
    { state: 'Centraldistrict', city: 'Yaqum' },
    { state: 'Judeaandsamariaarea', city: 'Judeaandsamariaarea' },
    { state: 'Centraldistrict', city: 'Netanya' },
    { state: 'Telaviv', city: 'Telaviv' },
    { state: 'Haifa', city: 'Haifa' },
    { state: 'Southerndistrict', city: 'Southerndistrict' },
    { state: 'Jerusalem', city: 'Jerusalem' },
    { state: 'Centraldistrict', city: 'Centraldistrict' },
    { state: 'Haifa', city: 'Tiratcarmel' },
    { state: 'Telaviv', city: 'Holon' },
    { state: 'Haifa', city: 'Haifa(matam)' },
  ],
  IM: [{ state: 'Douglas', city: 'Douglas' }],
  IN: [
    { state: 'Karnataka', city: 'Bengaluru' },
    { state: 'Maharashtra', city: 'Pune' },
    { state: 'Maharashtra', city: 'Thane' },
    { state: 'Maharashtra', city: 'Mumbai(rajanpada)' },
    { state: 'Maharashtra', city: 'Powai' },
    { state: 'Maharashtra', city: 'Navimumbai(reliancecorporatepark)' },
    { state: 'Maharashtra', city: 'Nashik' },
    { state: 'Maharashtra', city: 'Mumbai(sioneast)' },
    { state: 'Maharashtra', city: 'Mumbai(prabhadevi)' },
    { state: 'Maharashtra', city: 'Khadki' },
    { state: 'Maharashtra', city: 'Mumbai(parel)' },
    { state: 'Maharashtra', city: 'Mumbai(chakalaindustrialarea)' },
    { state: 'Maharashtra', city: 'Mumbai(centurymills)' },
    { state: 'Maharashtra', city: 'Mumbai' },
    { state: 'Maharashtra', city: 'Maharashtra' },
    { state: 'Karnataka', city: 'Karnataka' },
    { state: 'Karnataka', city: 'Bengaluru(industriallayout)' },
    { state: 'Karnataka', city: 'Bengaluru(newgurappanapalya)' },
    { state: 'Delhi', city: 'Newdelhi(okhla)' },
    { state: 'Delhi', city: 'Newdelhi(jiasarai)' },
    { state: 'Delhi', city: 'Newdelhi(defencecolony)' },
    { state: 'Delhi', city: 'Newdelhi(connaughtlane)' },
    { state: 'Delhi', city: 'Newdelhi' },
    { state: 'Delhi', city: 'Nangloijat' },
    { state: 'Delhi', city: 'Najafgarh' },
    { state: 'Delhi', city: 'Deoli' },
    { state: 'Delhi', city: 'Delhi' },
    { state: 'Delhi', city: 'Defencecolony' },
    { state: 'Haryana', city: 'Sonipat' },
    { state: 'Maharashtra', city: 'Virar' },
    { state: 'Delhi', city: 'Newdelhi(okhlaphaseii)' },
    { state: 'Haryana', city: 'Karnal' },
    { state: 'Haryana', city: 'Haryana' },
    { state: 'Delhi', city: 'Newdelhi(okhlaphaseiii)' },
    { state: 'Delhi', city: 'Newdelhi(pocketc)' },
    { state: 'Jammuandkashmir', city: 'Jammuandkashmir' },
    { state: 'Jharkhand', city: 'Jamshedpur' },
    { state: 'Jharkhand', city: 'Jharkhand' },
    { state: 'Jharkhand', city: 'Ranchi' },
    { state: 'Haryana', city: 'Ambala' },
    { state: 'Punjab', city: 'Punjab' },
    { state: 'Punjab', city: 'Sasnagar' },
    { state: 'Haryana', city: 'Gurugram(sector44)' },
    { state: 'Haryana', city: 'Gurugram(sector39)' },
    { state: 'Haryana', city: 'Gurugram(phaseiv)' },
    { state: 'Goa', city: 'Goa' },
    { state: 'Haryana', city: 'Gurugram(phaseiii)' },
    { state: 'Haryana', city: 'Gurugram(durgacolony)' },
    { state: 'Haryana', city: 'Gurugram' },
    { state: 'Haryana', city: 'Faridabad' },
    { state: 'Delhi', city: 'Newdelhi(nehruplace)' },
    { state: 'Haryana', city: 'Chandigarh' },
    { state: 'Punjab', city: 'Ludhiana(aggarnagarbblock)' },
    { state: 'Odisha', city: 'Kendraparha' },
    { state: 'Odisha', city: 'Bhubaneswar' },
    { state: 'Odisha', city: 'Odisha' },
    { state: 'Kerala', city: 'Kerala' },
    { state: 'Rajasthan', city: 'Jaipur' },
    { state: 'Rajasthan', city: 'Rajasthan' },
    { state: 'Gujarat', city: 'Ahmedabad' },
    { state: 'Gujarat', city: 'Ahmedabad(shahibaugh)' },
    { state: 'Gujarat', city: 'Gujarat' },
    { state: 'Gujarat', city: 'Naroda' },
    { state: 'Gujarat', city: 'Surat' },
    { state: 'Gujarat', city: 'Rajkot' },
    { state: 'Madhyapradesh', city: 'Madhyapradesh' },
    { state: 'Bihar', city: 'Bihar' },
    { state: 'Bihar', city: 'Patna' },
    { state: 'Bihar', city: 'Patna(policecolony)' },
    { state: 'Maharashtra', city: 'Borivali' },
    { state: 'Maharashtra', city: 'Bhayandar' },
    { state: 'Karnataka', city: 'Bengaluru(whitefield)' },
    { state: 'Gujarat', city: 'Sarkhej' },
    { state: 'Karnataka', city: 'Mangaluru' },
    { state: 'Rajasthan', city: 'Sriganganagar' },
    { state: 'Rajasthan', city: 'Sirohi' },
    { state: 'Chhattisgarh', city: 'Chhattisgarh' },
    { state: 'Chhattisgarh', city: 'Durg' },
    { state: 'Maharashtra', city: 'Dombivali' },
    { state: 'Madhyapradesh', city: 'Jabalpur' },
    { state: 'Madhyapradesh', city: 'Indore' },
    { state: 'Delhi', city: 'Newdelhi(pocketd)' },
    { state: 'Madhyapradesh', city: 'Bhopal(bsector)' },
    { state: 'Delhi', city: 'Pitampura' },
    { state: 'Delhi', city: 'Shahdara' },
    { state: 'Madhyapradesh', city: 'Bhopal' },
    { state: 'Chhattisgarh', city: 'Raipur' },
    { state: 'Punjab', city: 'Abohar' },
    { state: 'Punjab', city: 'Amritsar' },
    { state: 'Punjab', city: 'Jalandhar' },
    { state: 'Punjab', city: 'Ludhiana' },
    { state: 'Uttarpradesh', city: 'Faizabad' },
    { state: 'Uttarpradesh', city: 'Loni' },
    { state: 'Assam', city: 'Assam' },
    { state: 'Uttarpradesh', city: 'Etawah' },
    { state: 'Uttarpradesh', city: 'Etmadpur' },
    { state: 'Uttarpradesh', city: 'Lucknow' },
    { state: 'Uttarakhand', city: 'Uttarakhand' },
    { state: 'Uttarpradesh', city: 'Bhagwantnagar' },
    { state: 'Uttarpradesh', city: 'Bikapur' },
    { state: 'Andhrapradesh', city: 'Andhrapradesh' },
    { state: 'Andhrapradesh', city: 'Guntur' },
    { state: 'Uttarpradesh', city: 'Moth' },
    { state: 'Uttarpradesh', city: 'Fatehpur' },
    { state: 'Uttarpradesh', city: 'Mathura' },
    { state: 'Uttarpradesh', city: 'Khalilabad' },
    { state: 'Uttarpradesh', city: 'Jhansi' },
    { state: 'Uttarpradesh', city: 'Itaunja' },
    { state: 'Uttarakhand', city: 'Sitarganj' },
    { state: 'Uttarakhand', city: 'Rishikesh' },
    { state: 'Uttarakhand', city: 'Kashipur' },
    { state: 'Uttarakhand', city: 'Haridwar' },
    { state: 'Uttarpradesh', city: 'Deoband' },
    { state: 'Telangana', city: 'Hyderabad(gachibowli)' },
    { state: 'Tamilnadu', city: 'Tamilnadu' },
    { state: 'Tamilnadu', city: 'Chennai' },
    { state: 'Uttarpradesh', city: 'Khatauli' },
    { state: 'Uttarpradesh', city: 'Khanpur' },
    { state: 'Uttarpradesh', city: 'Bareilly' },
    { state: 'Uttarpradesh', city: 'Baragaon' },
    { state: 'Uttarpradesh', city: 'Bahraich' },
    { state: 'Uttarpradesh', city: 'Aligarh' },
    { state: 'Uttarpradesh', city: 'Meerut' },
    { state: 'Uttarakhand', city: 'Roorkee' },
    { state: 'Telangana', city: 'Mancherial' },
    { state: 'Telangana', city: 'Telangana' },
    { state: 'Telangana', city: 'Hyderabad' },
    { state: 'Uttarpradesh', city: 'Akbarpur' },
    { state: 'Uttarpradesh', city: 'Moradabad' },
    { state: 'Uttarpradesh', city: 'Agra' },
    { state: 'Uttarpradesh', city: 'Lar' },
    { state: 'Uttarpradesh', city: 'Afzalgarh' },
    { state: 'Uttarpradesh', city: 'Dibai' },
    { state: 'Uttarpradesh', city: 'Uttarpradesh' },
    { state: 'Uttarpradesh', city: 'Raebareli' },
    { state: 'Uttarpradesh', city: 'Ramkola' },
    { state: 'Uttarpradesh', city: 'Mau' },
    { state: 'Uttarpradesh', city: 'Rath' },
    { state: 'Uttarpradesh', city: 'Sector' },
    { state: 'Uttarpradesh', city: 'Shahganj' },
    { state: 'Uttarpradesh', city: 'Suar' },
    { state: 'Uttarpradesh', city: 'Utraula' },
    { state: 'Uttarpradesh', city: 'Dayalbagh' },
    { state: 'Uttarpradesh', city: 'Ramnagar' },
    { state: 'Uttarpradesh', city: 'Varanasi' },
    { state: 'Uttarpradesh', city: 'Mawana' },
    { state: 'Uttarpradesh', city: 'Kanpur' },
    { state: 'Uttarpradesh', city: 'Ghaziabad' },
    { state: 'Uttarpradesh', city: 'Ghazipur' },
    { state: 'Uttarpradesh', city: 'Gorakhpur' },
    { state: 'Uttarpradesh', city: 'Goshainganj' },
    { state: 'Uttarpradesh', city: 'Hathras' },
    { state: 'Uttarpradesh', city: 'Kasganj' },
    { state: 'Westbengal', city: 'Durgapur(bidhannagar)' },
    { state: 'Uttarakhand', city: 'Haldwani' },
    { state: 'Uttarakhand', city: 'Dehradun' },
    { state: 'Uttarpradesh', city: 'Najibabad' },
    { state: 'Westbengal', city: 'Westbengal' },
    { state: 'Westbengal', city: 'Kolkata(epblock)' },
    { state: 'Westbengal', city: 'Kolkata(entally)' },
    { state: 'Westbengal', city: 'Kolkata' },
    { state: 'Westbengal', city: 'Howrah' },
    { state: 'Uttarpradesh', city: 'Prayagraj' },
    { state: 'Westbengal', city: 'Durgapur' },
    { state: 'Uttarpradesh', city: 'Padrauna' },
    { state: 'Uttarpradesh', city: 'Orai' },
    { state: 'Uttarpradesh', city: 'Noida' },
    { state: 'Uttarpradesh', city: 'Kannauj' },
  ],
  IQ: [
    { state: 'Nineveh', city: 'Nineveh' },
    { state: 'Basra', city: 'Basra' },
    { state: 'Baghdad', city: 'Baghdad' },
    { state: 'Baghdad', city: 'Baghdad(mansour)' },
    { state: 'Erbil', city: 'Erbil' },
    { state: 'Nineveh', city: 'Mosul' },
  ],
  IR: [
    { state: 'Tehran', city: 'Tehran(district4)' },
    { state: 'Tehran', city: 'Tehran' },
    { state: 'Fars', city: 'Shiraz(district6)' },
    { state: 'Fars', city: 'Fars' },
    { state: 'Isfahan', city: 'Isfahan' },
  ],
  IS: [{ state: 'Capitalregion', city: 'Capitalregion' }],
  IT: [
    { state: 'Lombardy', city: 'Como' },
    { state: 'Lombardy', city: 'Figino' },
    { state: 'Lombardy', city: 'Lombardy' },
    { state: 'Campania', city: 'Campania' },
    { state: 'Liguria', city: 'Liguria' },
    { state: 'Liguria', city: 'Genoa' },
    { state: 'Emiliaromagna', city: 'Rimini' },
    { state: 'Emiliaromagna', city: 'Emiliaromagna' },
    { state: 'Sardinia', city: 'Elmas' },
    { state: 'Sardinia', city: 'Sardinia' },
    { state: 'Lazio', city: 'Rome' },
    { state: 'Lazio', city: 'Lazio' },
    { state: 'Abruzzo', city: 'Chieti' },
    { state: 'Emiliaromagna', city: 'Bologna' },
    { state: 'Calabria', city: 'Calabria' },
    { state: 'Veneto', city: 'Conegliano' },
    { state: 'Veneto', city: 'Padova' },
    { state: 'Veneto', city: 'Veneto' },
    { state: 'Campania', city: 'Naples' },
    { state: 'Lombardy', city: 'Milan' },
    { state: 'Tuscany', city: 'Florence' },
    { state: 'Tuscany', city: 'Tuscany' },
    { state: 'Apulia', city: 'Apulia' },
    { state: 'Piedmont', city: 'Piedmont' },
    { state: 'Piedmont', city: 'Turin' },
    { state: 'Umbria', city: 'Umbria' },
    { state: 'Friuliveneziagiulia', city: 'Trieste' },
    { state: 'Friuliveneziagiulia', city: 'Friuliveneziagiulia' },
    { state: 'Trentinoaltoadige', city: 'Trentinoaltoadige' },
    { state: 'Abruzzo', city: 'Abruzzo' },
    { state: 'Themarches', city: 'Themarches' },
    { state: 'Sicily', city: 'Palermo' },
    { state: 'Lombardy', city: 'Rho' },
    { state: 'Lombardy', city: 'Pavia' },
    { state: 'Sicily', city: 'Sicily' },
  ],
  JE: [
    { state: 'Sthelier', city: 'Sthelier' },
    { state: 'Sthelier', city: 'Sainthelier' },
  ],
  JM: [
    { state: 'Saintcatherineparish', city: 'Oldharbour' },
    { state: 'Saintcatherineparish', city: 'Portmore' },
    { state: 'Kingston', city: 'Kingston' },
    { state: 'Clarendon', city: 'Clarendon' },
    { state: 'Saintjamesparish', city: 'Montegobay' },
    { state: 'Trelawnyparish', city: 'Trelawnyparish' },
    { state: 'Trelawnyparish', city: 'Falmouth' },
    { state: 'Standrew', city: 'Kingston' },
    { state: 'Standrew', city: 'Standrew' },
    { state: 'Stelizabeth', city: 'Stelizabeth' },
    { state: 'Saintcatherineparish', city: 'Saintcatherineparish' },
    { state: 'Saintcatherineparish', city: 'Spanishtown' },
    { state: 'Saintjamesparish', city: 'Saintjamesparish' },
    { state: 'Saintannparish', city: 'Saintannparish' },
    { state: 'Saintandrewparish', city: 'Halfwaytree' },
    { state: 'Saintandrewparish', city: 'Constantspring' },
    { state: 'Saintandrewparish', city: 'Kingston' },
    { state: 'Saintandrewparish', city: 'Saintandrewparish' },
  ],
  JO: [{ state: 'Amman', city: 'Amman' }],
  JP: [
    { state: 'Tokyo', city: 'Tokyo' },
    { state: 'Tokyo', city: 'Chiyoda' },
    { state: 'Osaka', city: 'Osaka' },
    { state: 'Tokyo', city: 'Minatoku' },
    { state: 'Tokyo', city: 'Shibuya' },
  ],
  KE: [
    { state: 'Kiambucounty', city: 'Kiambucounty' },
    { state: 'Nairobicounty', city: 'Imaradaimaestate' },
    { state: 'Nairobicounty', city: 'Nairobi(embakasi)' },
    { state: 'Nairobicounty', city: 'Nairobi(springvalley)' },
    { state: 'Nairobiarea', city: 'Nairobiarea' },
    { state: 'Nairobiarea', city: 'Nairobi(springvalley)' },
    { state: 'Nairobicounty', city: 'Nairobi(springvalleyestate)' },
    { state: 'Nairobicounty', city: 'Nairobicounty' },
    { state: 'Mombasacounty', city: 'Mombasacounty' },
    { state: 'Machakoscounty', city: 'Githunguri' },
    { state: 'Machakoscounty', city: 'Machakoscounty' },
    { state: 'Kiambucounty', city: 'Ruiru' },
  ],
  KG: [
    { state: 'Bishkek', city: 'Bishkek' },
    { state: 'Oshregion', city: 'Oshregion' },
  ],
  KH: [{ state: 'Phnompenh', city: 'Phnompenh' }],
  KN: [
    { state: 'Saintjameswindwa', city: 'Saintjameswindwa' },
    { state: 'Saintgeorgebasseterre', city: 'Saintgeorgebasseterre' },
    { state: 'Saintmarycayon', city: 'Saintmarycayon' },
    { state: 'Saintjameswindwa', city: 'Newcastle' },
  ],
  KR: [
    { state: 'Gyeonggido', city: 'Gwangmyeong' },
    { state: 'Seoul', city: 'Yongsandong(hangangdaero)' },
    { state: 'Seoul', city: 'Seoul' },
    { state: 'Gyeonggido', city: 'Gyeonggido' },
    { state: 'Gyeonggido', city: 'Seongnamsi(buljeongro)' },
    { state: 'Seoul', city: 'Seoul(toegyero)' },
  ],
  KW: [
    { state: 'Alasimah', city: 'Kuwaitcity(jibla)' },
    { state: 'Alasimah', city: 'Alasimah' },
    { state: 'Alamadi', city: 'Alamadi' },
    { state: 'Alasimah', city: 'Kuwaitcity' },
    { state: 'Alasimah', city: 'Kuwaitcity(mirqab)' },
    { state: 'Hawalli', city: 'Hawalli' },
    { state: 'Alfarwaniyah', city: 'Alfarwaniyah' },
  ],
  KY: [
    { state: 'Georgetown', city: 'Georgetown' },
    { state: 'Northside', city: 'Northside' },
  ],
  KZ: [
    { state: 'Karaganda', city: 'Karaganda' },
    { state: 'Qostanay', city: 'Kostanay' },
    { state: 'Mangghystau', city: 'Mangghystau' },
    { state: 'Aqtobe', city: 'Aktobe' },
    { state: 'Aqtobe', city: 'Aqtobe' },
    { state: 'Turkistan', city: 'Turkistan' },
    { state: 'Eastkazakhstan', city: 'Eastkazakhstan' },
    { state: 'Shymkent', city: 'Shymkent' },
    { state: 'Almatyoblysy', city: 'Almatyoblysy' },
    { state: 'Almaty', city: 'Almaty(bostandykdistrict)' },
    { state: 'Almaty', city: 'Almaty(almalyaudany)' },
    { state: 'Astana', city: 'Astana' },
    { state: 'Almaty', city: 'Almaty' },
    { state: 'Qostanay', city: 'Qostanay' },
    { state: 'Zhambyl', city: 'Zhambyl' },
    { state: 'Jetisuregion', city: 'Taldykorgan' },
    { state: 'Mangghystau', city: 'Aktau' },
    { state: 'Pavlodarregion', city: 'Pavlodarregion' },
    { state: 'Jetisuregion', city: 'Jetisuregion' },
    { state: 'Ulytauregion', city: 'Ulytauregion' },
    { state: 'Ulytauregion', city: 'Zhezqazghan' },
  ],
  LB: [
    { state: 'Beyrouth', city: 'Beyrouth' },
    { state: 'Beqaa', city: 'Beqaa' },
    { state: 'Beqaa', city: 'Zahle' },
    { state: 'Montliban', city: 'Jdaidetelmatn' },
    { state: 'Beyrouth', city: 'Beirut' },
    { state: 'Montliban', city: 'Montliban' },
  ],
  LC: [
    { state: 'Choiseul', city: 'Choiseul' },
    { state: 'Castries', city: 'Castries' },
    { state: 'Grosislet', city: 'Grosislet' },
    { state: 'Castries', city: 'Bisee' },
  ],
  LK: [
    { state: 'Westernprovince', city: 'Westernprovince' },
    { state: 'Western', city: 'Western' },
    { state: 'Western', city: 'Colombo(colombodivision)' },
    { state: 'Westernprovince', city: 'Colombo(colombodivision)' },
  ],
  LT: [{ state: 'Vilnius', city: 'Vilnius' }],
  LU: [
    { state: 'Diekirch', city: 'Diekirch' },
    { state: 'Diekirch', city: 'Schieren' },
  ],
  LV: [
    { state: 'Riga', city: 'Riga(latgalesuburb)' },
    { state: 'Riga', city: 'Riga(ziemeurajons)' },
    { state: 'Riga', city: 'Riga(vidzemespriekpilseta)' },
    { state: 'Riga', city: 'Riga(latgalespriekpilseta)' },
    { state: 'Riga', city: 'Riga(centrarajons)' },
    { state: 'Riga', city: 'Riga' },
  ],
  LY: [
    { state: 'Miratah', city: 'Miratah' },
    { state: 'Miratah', city: 'Zliten' },
    { state: 'Banghazi', city: 'Banghazi' },
    { state: 'Tripoli', city: 'Tripoli(souqaljumaa)' },
    { state: 'Tripoli', city: 'Tripoli' },
    { state: 'Banghazi', city: 'Benghazi' },
  ],
  MA: [
    { state: 'Marrakeshsafi', city: 'Marrakeshsafi' },
    { state: 'Rabatsalekenitra', city: 'Tiflet' },
    { state: 'Rabatsalekenitra', city: 'Temara' },
    { state: 'Rabatsalekenitra', city: 'Rabatsalekenitra' },
    { state: 'Casablancasettat', city: 'Bouskoura' },
    { state: 'Rabatsalekenitra', city: 'Rabat(agdal)' },
    { state: 'Casablancasettat', city: 'Darbouazza' },
    { state: 'Casablancasettat', city: 'Eljadida' },
    { state: 'Casablancasettat', city: 'Casablancasettat' },
    { state: 'Casablancasettat', city: 'Casablanca(prefecturedarrondissementsdainchock)' },
    { state: 'Casablancasettat', city: 'Casablanca(maarif)' },
    { state: 'Oriental', city: 'Oujda' },
    { state: 'Oriental', city: 'Oriental' },
    { state: 'Casablancasettat', city: 'Mohammedia' },
    { state: 'Casablancasettat', city: 'Mechouardecasablanca' },
    { state: 'Casablancasettat', city: 'Lahraouyine' },
    { state: 'Tangertetouanalhoceima', city: 'Larache' },
    { state: 'Soussmassa', city: 'Soussmassa' },
    { state: 'Soussmassa', city: 'Sidiifni' },
    { state: 'Soussmassa', city: 'Inezgane' },
    { state: 'Soussmassa', city: 'Agadir' },
    { state: 'Guelmimouednoun', city: 'Guelmimouednoun' },
    { state: 'Tangertetouanalhoceima', city: 'Tangier' },
    { state: 'Tangertetouanalhoceima', city: 'Tangertetouanalhoceima' },
    { state: 'Fesmeknes', city: 'Douartoulal' },
    { state: 'Fesmeknes', city: 'Fesmeknes' },
    { state: 'Marrakeshsafi', city: 'Sidiabdallahghiat' },
    { state: 'Draatafilalet', city: 'Draatafilalet' },
    { state: 'Casablancasettat', city: 'Titmellil' },
    { state: 'Tangertetouanalhoceima', city: 'Boukhalef' },
    { state: 'Benimellalkhenifra', city: 'Benimellalkhenifra' },
    { state: 'Marrakeshsafi', city: 'Mechouarkasba' },
  ],
  MD: [
    { state: 'Chiinaumunicipality', city: 'Chisinau(buiucani)' },
    { state: 'Transnistria', city: 'Transnistria' },
    { state: 'Chiinaumunicipality', city: 'Chisinau' },
    { state: 'Chiinaumunicipality', city: 'Chiinaumunicipality' },
    { state: 'Transnistria', city: 'Tiraspol' },
  ],
  ME: [{ state: 'Podgorica', city: 'Podgorica' }],
  MK: [
    { state: 'Gradskopje', city: 'Gradskopje' },
    { state: 'Kumanovo', city: 'Kumanovo' },
  ],
  ML: [
    { state: 'Bamako', city: 'Bamako' },
    { state: 'Koulikoro', city: 'Koulikoro' },
  ],
  MM: [
    { state: 'Yangon', city: 'Yangon' },
    { state: 'Naypyitaw', city: 'Naypyitaw' },
    { state: 'Rangoon', city: 'Yangon(hlaing)' },
    { state: 'Rangoon', city: 'Rangoon' },
    { state: 'Mandalayregion', city: 'Mandalayregion' },
    { state: 'Yangon', city: 'Hmawbi' },
  ],
  MN: [
    { state: 'Ulaanbaatar', city: 'Ulaanbaatar(khoroo8)' },
    { state: 'Ulaanbaatar', city: 'Ulaanbaatar' },
  ],
  MT: [
    { state: 'Ilmarsa', city: 'Ilmarsa' },
    { state: 'Birkirkara', city: 'Birkirkara' },
  ],
  MU: [
    { state: 'Plaineswilhems', city: 'Quatrebornes(ebenecybercity)' },
    { state: 'Plaineswilhems', city: 'Plaineswilhems' },
  ],
  MV: [
    { state: 'Kaafuatoll', city: 'Male(maafannu)' },
    { state: 'Kaafuatoll', city: 'Male(hulhumale)' },
    { state: 'Kaafuatoll', city: 'Male' },
    { state: 'Kaafuatoll', city: 'Kaafuatoll' },
  ],
  MW: [{ state: 'Southernregion', city: 'Southernregion' }],
  MX: [
    { state: 'Tamaulipas', city: 'Tamaulipas' },
    { state: 'Nayarit', city: 'Nayarit' },
    { state: 'Yucatan', city: 'Yucatan' },
    { state: 'Bajacalifornia', city: 'Tijuana(zonaeste)' },
    { state: 'Bajacalifornia', city: 'Tijuana' },
    { state: 'Bajacalifornia', city: 'Bajacalifornia' },
    { state: 'Puebla', city: 'Puebla' },
    { state: 'Jalisco', city: 'Jalisco' },
    { state: 'Coahuila', city: 'Torreon' },
    { state: 'Jalisco', city: 'Guadalajara(delfresno)' },
    { state: 'Coahuila', city: 'Coahuila' },
    { state: 'Sanluispotosi', city: 'Sanluispotosi' },
    { state: 'Michoacan', city: 'Michoacan' },
    { state: 'Tabasco', city: 'Tabasco' },
    { state: 'Chiapas', city: 'Chiapas' },
    { state: 'Aguascalientes', city: 'Aguascalientes' },
    { state: 'Nuevoleon', city: 'Nuevoleon' },
    { state: 'Nuevoleon', city: 'Monterrey' },
    { state: 'Veracruz', city: 'Veracruz' },
    { state: 'Hidalgo', city: 'Hidalgo' },
    { state: 'Mexicocity', city: 'Mexicocity(zedecsantafe)' },
    { state: 'Sonora', city: 'Ciudadobregon' },
    { state: 'Sonora', city: 'Sonora' },
    { state: 'Mexicocity', city: 'Mexicocity(sanlorenzoatemoaya)' },
    { state: 'Mexicocity', city: 'Mexicocity(manantialpenapobre)' },
    { state: 'Mexicocity', city: 'Mexicocity(fuentesdelpedregal)' },
    { state: 'Mexicocity', city: 'Mexicocity(centro)' },
    { state: 'Mexicocity', city: 'Mexicocity' },
    { state: 'Mexicocity', city: 'Magdalenacontreras' },
    { state: 'Mexico', city: 'Mexico' },
    { state: 'Queretaro', city: 'Queretaro' },
    { state: 'Sinaloa', city: 'Sinaloa' },
    { state: 'Chihuahua', city: 'Chihuahua' },
    { state: 'Guanajuato', city: 'Guanajuato' },
    { state: 'Quintanaroo', city: 'Quintanaroo' },
    { state: 'Queretaro', city: 'Queretarocity' },
  ],
  MY: [
    { state: 'Kualalumpur', city: 'Kualalumpur(kualalumpurcitycentre)' },
    { state: 'Pahang', city: 'Pahang' },
    { state: 'Kualalumpur', city: 'Kualalumpur(kampungattap)' },
    { state: 'Kualalumpur', city: 'Kualalumpur(citycentre)' },
    { state: 'Kualalumpur', city: 'Kualalumpur(brickfields)' },
    { state: 'Kualalumpur', city: 'Kualalumpur' },
    { state: 'Kualalumpur', city: 'Goldenfish' },
    { state: 'Perak', city: 'Bercham' },
    { state: 'Kualalumpur', city: 'Ampang' },
    { state: 'Penang', city: 'Relau' },
    { state: 'Selangor', city: 'Ladangserikundang' },
    { state: 'Penang', city: 'Penang' },
    { state: 'Kualalumpur', city: 'Kualalumpur(technologyparkmalaysia)' },
    { state: 'Kualalumpur', city: 'Montkiara' },
    { state: 'Kualalumpur', city: 'Oug' },
    { state: 'Kualalumpur', city: 'Tamanmelati' },
    { state: 'Kualalumpur', city: 'Tamanpetaling' },
    { state: 'Kualalumpur', city: 'Wangsamaju' },
    { state: 'Perak', city: 'Ipoh' },
    { state: 'Melaka', city: 'Melaka' },
    { state: 'Perak', city: 'Perak' },
    { state: 'Perak', city: 'Taiping' },
    { state: 'Kedah', city: 'Kedah' },
    { state: 'Kedah', city: 'Sik' },
    { state: 'Kelantan', city: 'Kelantan' },
    { state: 'Perak', city: 'Telukintan' },
    { state: 'Terengganu', city: 'Terengganu' },
    { state: 'Selangor', city: 'Bandarmahkotacheras' },
    { state: 'Selangor', city: 'Kotadamansara' },
    { state: 'Negerisembilan', city: 'Seremban' },
    { state: 'Negerisembilan', city: 'Negerisembilan' },
    { state: 'Sarawak', city: 'Bintulu' },
    { state: 'Sarawak', city: 'Kuching' },
    { state: 'Sarawak', city: 'Miri' },
    { state: 'Selangor', city: 'Klang' },
    { state: 'Selangor', city: 'Kampungbarubalakong' },
    { state: 'Selangor', city: 'Cyberjaya' },
    { state: 'Sarawak', city: 'Sarawak' },
    { state: 'Johor', city: 'Tamanuniversiti' },
    { state: 'Sarawak', city: 'Sibu' },
    { state: 'Sabah', city: 'Sandakan' },
    { state: 'Sabah', city: 'Sabah' },
    { state: 'Sabah', city: 'Ranau' },
    { state: 'Sabah', city: 'Putatan' },
    { state: 'Sabah', city: 'Papar' },
    { state: 'Sabah', city: 'Kotakinabalu' },
    { state: 'Sabah', city: 'Kotabelud' },
    { state: 'Sabah', city: 'Donggongon' },
    { state: 'Selangor', city: 'Shahalam(subanghitechindustrialpark)' },
    { state: 'Johor', city: 'Johorbahru' },
    { state: 'Johor', city: 'Kampungkangkarteberau' },
    { state: 'Johor', city: 'Johor' },
    { state: 'Johor', city: 'Mountaustin' },
    { state: 'Selangor', city: 'Petalingjaya' },
    { state: 'Johor', city: 'Pelentong' },
    { state: 'Johor', city: 'Bukitindah' },
    { state: 'Selangor', city: 'Sungaibuloh' },
    { state: 'Selangor', city: 'Shahalam(hicomglenmarieindustrialpark)' },
    { state: 'Selangor', city: 'Shahalam' },
    { state: 'Selangor', city: 'Selayangbaruutara' },
    { state: 'Selangor', city: 'Selangor' },
    { state: 'Selangor', city: 'Rawang' },
    { state: 'Johor', city: 'Perling' },
    { state: 'Selangor', city: 'Portklang' },
    { state: 'Selangor', city: 'Petalingjaya(seksyen52petalingjaya)' },
  ],
  MZ: [{ state: 'Maputocity', city: 'Maputocity' }],
  NG: [
    { state: 'Lagos', city: 'Lagos(victoriaislandannex)' },
    { state: 'Lagos', city: 'Lagos(ikoyi)' },
    { state: 'Lagos', city: 'Lagos' },
  ],
  NI: [{ state: 'Managuadepartment', city: 'Managuadepartment' }],
  NL: [
    { state: 'Southholland', city: 'Rotterdam' },
    { state: 'Zeeland', city: 'Goes' },
    { state: 'Southholland', city: 'Thehague(laak)' },
    { state: 'Overijssel', city: 'Zwolle' },
    { state: 'Northholland', city: 'Northholland' },
    { state: 'Northholland', city: 'Hilversum' },
    { state: 'Northholland', city: 'Amsterdam' },
    { state: 'Southholland', city: 'Naaldwijk' },
    { state: 'Southholland', city: 'Southholland' },
    { state: 'Northbrabant', city: 'Northbrabant' },
    { state: 'Limburg', city: 'Limburg' },
    { state: 'Gelderland', city: 'Arnhem' },
    { state: 'Utrecht', city: 'Utrecht' },
    { state: 'Northbrabant', city: 'Roosendaal' },
  ],
  NO: [{ state: 'Oslo', city: 'Oslo' }],
  NP: [
    { state: 'Bagmatiprovince', city: 'Bharatpur' },
    { state: 'Province1', city: 'Iahari' },
    { state: 'Province1', city: 'Province1' },
    { state: 'Lumbiniprovince', city: 'Butwal' },
    { state: 'Lumbiniprovince', city: 'Lumbiniprovince' },
    { state: 'Madhesh', city: 'Birgunj' },
    { state: 'Madhesh', city: 'Madhesh' },
    { state: 'Bagmatiprovince', city: 'Bagmatiprovince' },
    { state: 'Koshi', city: 'Koshi' },
    { state: 'Koshi', city: 'Ilam' },
    { state: 'Koshi', city: 'Iahari' },
    { state: 'Koshi', city: 'Damak' },
    { state: 'Koshi', city: 'Bhadrapur' },
    { state: 'Bagmatiprovince', city: 'Hetauda' },
    { state: 'Bagmatiprovince', city: 'Kathmandu' },
    { state: 'Bagmatiprovince', city: 'Kathmandu(balaju)' },
    { state: 'Bagmatiprovince', city: 'Kathmandu(koteshwor)' },
    { state: 'Bagmatiprovince', city: 'Kathmandu(tripureshwor)' },
    { state: 'Bagmatiprovince', city: 'Kirtipur' },
    { state: 'Bagmatiprovince', city: 'Patan(jawalakhel)' },
    { state: 'Bagmatiprovince', city: 'Patan(thasikhel)' },
    { state: 'Gandakipradesh', city: 'Pokhara(chauthe)' },
    { state: 'Gandakipradesh', city: 'Gandakipradesh' },
    { state: 'Gandakipradesh', city: 'Dihi' },
  ],
  NZ: [
    { state: 'Wellingtonregion', city: 'Wellingtonregion' },
    { state: 'Auckland', city: 'Auckland' },
    { state: 'Canterbury', city: 'Canterbury' },
    { state: 'Auckland', city: 'Auckland(aucklandcbd)' },
    { state: 'Auckland', city: 'Auckland(newmarket)' },
    { state: 'Auckland', city: 'Auckland(takapuna)' },
    { state: 'Auckland', city: 'Newmarket' },
    { state: 'Bayofplenty', city: 'Bayofplenty' },
    { state: 'Bayofplenty', city: 'Tauranga' },
    { state: 'Waikatoregion', city: 'Waikatoregion' },
  ],
  OM: [
    { state: 'Muscat', city: 'Bawshar' },
    { state: 'Muscat', city: 'Muscat' },
    { state: 'Muscat', city: 'Muscat(ruwi)' },
  ],
  PA: [
    { state: 'Panama', city: 'Panamacity' },
    { state: 'Panama', city: 'Panama' },
  ],
  PE: [
    { state: 'Limaregion', city: 'Sanisidro' },
    { state: 'Lalibertad', city: 'Lalibertad' },
    { state: 'Lambayeque', city: 'Lambayeque' },
    { state: 'Ica', city: 'Ica' },
    { state: 'Junin', city: 'Junin' },
    { state: 'Arequipa', city: 'Arequipa' },
    { state: 'Callao', city: 'Callao' },
    { state: 'Callao', city: 'Carmendelaleguareynoso' },
    { state: 'Limaregion', city: 'Sanfranciscodeborja' },
    { state: 'Limaregion', city: 'Limaregion' },
    { state: 'Limaregion', city: 'Lamolina' },
    { state: 'Limaregion', city: 'Jesusmaria' },
    { state: 'Limaregion', city: 'Independencia' },
    { state: 'Limaregion', city: 'Huacoy' },
    { state: 'Limaregion', city: 'Carabayllo' },
    { state: 'Limaregion', city: 'Brena' },
    { state: 'Limaprovince', city: 'Limaprovince' },
    { state: 'Piura', city: 'Piura' },
    { state: 'Callao', city: 'Ventanilla' },
  ],
  PG: [
    { state: 'Nationalcapital', city: 'Nationalcapital' },
    { state: 'Nationalcapital', city: 'Portmoresby' },
  ],
  PH: [
    { state: 'Calabarzon', city: 'Imus' },
    { state: 'Calabarzon', city: 'Naic(sabang)' },
    { state: 'Calabarzon', city: 'Dasmarinas' },
    { state: 'Westernvisayas', city: 'Pasil' },
    { state: 'Calabarzon', city: 'Calamba' },
    { state: 'Calabarzon', city: 'Calabarzon' },
    { state: 'Calabarzon', city: 'Cainta' },
    { state: 'Calabarzon', city: 'Binangonan' },
    { state: 'Calabarzon', city: 'Binan' },
    { state: 'Calabarzon', city: 'Bagongpagasa' },
    { state: 'Calabarzon', city: 'Bacoor' },
    { state: 'Calabarzon', city: 'Baclaran' },
    { state: 'Calabarzon', city: 'Angono' },
    { state: 'Calabarzon', city: 'Aliang' },
    { state: 'Westernvisayas', city: 'Westernvisayas' },
    { state: 'Westernvisayas', city: 'Bacolodcity' },
    { state: 'Calabarzon', city: 'Quezoncity(project6)' },
    { state: 'Calabarzon', city: 'Sanfranciscodelmonte' },
    { state: 'Calabarzon', city: 'Sanmateo' },
    { state: 'Cordillera', city: 'Cordillera' },
    { state: 'Bicolregion', city: 'Bicolregion' },
    { state: 'Zamboangapeninsula', city: 'Zamboangapeninsula' },
    { state: 'Caraga', city: 'Tandag' },
    { state: 'Davaoregion', city: 'Davaocity' },
    { state: 'Easternvisayas', city: 'Pawing' },
    { state: 'Davaoregion', city: 'Davaoregion' },
    { state: 'Caraga', city: 'Caraga' },
    { state: 'Calabarzon', city: 'Mariana' },
    { state: 'Nationalcapitalregion', city: 'Pasig' },
    { state: 'Centralvisayas', city: 'Mandauecity' },
    { state: 'Centralvisayas', city: 'Centralvisayas' },
    { state: 'Centralvisayas', city: 'Cebucity' },
    { state: 'Cordillera', city: 'Baguiocity' },
    { state: 'Bicolregion', city: 'Legazpi' },
    { state: 'Soccsksargen', city: 'Generalsantos' },
    { state: 'Mimaropa', city: 'Mimaropa' },
    { state: 'Nationalcapitalregion', city: 'Upperbicutan' },
    { state: 'Nationalcapitalregion', city: 'Taguig' },
    { state: 'Nationalcapitalregion', city: 'Sanjuancity' },
    { state: 'Nationalcapitalregion', city: 'Quezoncity(novaliches)' },
    { state: 'Mimaropa', city: 'Elnido' },
    { state: 'Nationalcapitalregion', city: 'Quezoncity' },
    { state: 'Nationalcapitalregion', city: 'Pasig(sanantonio)' },
    { state: 'Nationalcapitalregion', city: 'Makaticity' },
    { state: 'Nationalcapitalregion', city: 'Pasay' },
    { state: 'Nationalcapitalregion', city: 'Paranaquecity' },
    { state: 'Nationalcapitalregion', city: 'Nationalcapitalregion' },
    { state: 'Nationalcapitalregion', city: 'Namayan' },
    { state: 'Soccsksargen', city: 'Soccsksargen' },
    { state: 'Centralvisayas', city: 'Apas' },
    { state: 'Nationalcapitalregion', city: 'Marikinacity' },
    { state: 'Nationalcapitalregion', city: 'Manila(sampaloc)' },
    { state: 'Autonomousregioninmuslimmindanao', city: 'Autonomousregioninmuslimmindanao' },
    { state: 'Nationalcapitalregion', city: 'Manila' },
    { state: 'Nationalcapitalregion', city: 'Mandaluyongcity' },
    { state: 'Cagayanvalley', city: 'Cagayanvalley' },
    { state: 'Nationalcapitalregion', city: 'Mandaluyong' },
    { state: 'Easternvisayas', city: 'Easternvisayas' },
    { state: 'Calabarzon', city: 'Sanpablo' },
    { state: 'Centralluzon', city: 'Meycauayan' },
    { state: 'Northernmindanao', city: 'Cagayandeoro' },
    { state: 'Ilocos', city: 'Ilocos' },
    { state: 'Ilocos', city: 'Urdaneta' },
    { state: 'Centralluzon', city: 'Olongapocity' },
    { state: 'Centralluzon', city: 'Plaridel' },
    { state: 'Nationalcapitalregion', city: 'Belair' },
    { state: 'Calabarzon', city: 'Tanza' },
    { state: 'Centralluzon', city: 'Lomadegato' },
    { state: 'Centralluzon', city: 'Sanfernandocity' },
    { state: 'Northernmindanao', city: 'Northernmindanao' },
    { state: 'Nationalcapitalregion', city: 'Bagongsilang' },
    { state: 'Calabarzon', city: 'Teresa' },
    { state: 'Calabarzon', city: 'Tagkawayansabang' },
    { state: 'Nationalcapitalregion', city: 'Bagongsilangan' },
    { state: 'Calabarzon', city: 'Silang' },
    { state: 'Centralluzon', city: 'Iba' },
    { state: 'Centralluzon', city: 'Donaremediostrinidad' },
    { state: 'Nationalcapitalregion', city: 'Caloocancity' },
    { state: 'Centralluzon', city: 'Sanjosedelmonte' },
    { state: 'Centralluzon', city: 'Centralluzon' },
    { state: 'Nationalcapitalregion', city: 'Calumpang' },
    { state: 'Centralluzon', city: 'Balibago' },
    { state: 'Centralluzon', city: 'Balagtas' },
    { state: 'Centralluzon', city: 'Angelescity(clarkfreeportzone)' },
    { state: 'Centralluzon', city: 'Sanmiguel' },
    { state: 'Centralluzon', city: 'Angelescity(clarkfreeport)' },
    { state: 'Nationalcapitalregion', city: 'Laspinas' },
    { state: 'Centralluzon', city: 'Tarlaccity' },
    { state: 'Calabarzon', city: 'Taytay' },
  ],
  PK: [
    { state: 'Punjab', city: 'Jhelum' },
    { state: 'Punjab', city: 'Kahnanau' },
    { state: 'Punjab', city: 'Faisalabad' },
    { state: 'Punjab', city: 'Gujranwala' },
    { state: 'Sindh', city: 'Sindh' },
    { state: 'Punjab', city: 'Lahore' },
    { state: 'Punjab', city: 'Lahore(gulberg)' },
    { state: 'Sindh', city: 'Gharo' },
    { state: 'Punjab', city: 'Lahore(nishattown)' },
    { state: 'Khyberpakhtunkhwa', city: 'Khyberpakhtunkhwa' },
    { state: 'Punjab', city: 'Multan' },
    { state: 'Punjab', city: 'Punjab' },
    { state: 'Punjab', city: 'Sialkot' },
    { state: 'Balochistan', city: 'Quetta' },
    { state: 'Balochistan', city: 'Balochistan' },
    { state: 'Punjab', city: 'Rawalpindi' },
    { state: 'Sindh', city: 'Hyderabad' },
    { state: 'Islamabad', city: 'Islamabad(h91)' },
    { state: 'Islamabad', city: 'Islamabad(f7markaz)' },
    { state: 'Islamabad', city: 'Islamabad' },
    { state: 'Sindh', city: 'Karachi' },
    { state: 'Punjab', city: 'Bahawalpur' },
    { state: 'Sindh', city: 'Karachi(sadr)' },
    { state: 'Sindh', city: 'Karachi(civillines)' },
  ],
  PL: [
    { state: 'Silesia', city: 'Silesia' },
    { state: 'Mazovia', city: 'Mazovia' },
    { state: 'Mazovia', city: 'Warsaw' },
  ],
  PR: [
    { state: 'Sanjuan', city: 'Sanjuan' },
    { state: 'Ponce', city: 'Ponce' },
    { state: 'Aguadilla', city: 'Aguadilla' },
  ],
  PS: [
    { state: 'Westbank', city: 'Nablus' },
    { state: 'Westbank', city: 'Ramallah' },
    { state: 'Gazastrip', city: 'Gazastrip' },
    { state: 'Westbank', city: 'Westbank' },
  ],
  PT: [
    { state: 'Azores', city: 'Azores' },
    { state: 'Porto', city: 'Porto' },
    { state: 'Leiria', city: 'Leiria' },
    { state: 'Lisbon', city: 'Lisbon' },
    { state: 'Faro', city: 'Faro' },
    { state: 'Coimbra', city: 'Coimbra' },
    { state: 'Braga', city: 'Braga' },
    { state: 'Aveiro', city: 'Aveiro' },
    { state: 'Setubal', city: 'Setubal' },
  ],
  PY: [
    { state: 'Canindeyu', city: 'Canindeyu' },
    { state: 'Sanpedrodepartment', city: 'Sanpedrodepartment' },
    { state: 'Altoparana', city: 'Altoparana' },
    { state: 'Centraldepartment', city: 'Fernandodelamora(fernandozonasur)' },
    { state: 'Altoparana', city: 'Ciudaddeleste' },
    { state: 'Altoparana', city: 'Ciudaddeleste(area3)' },
    { state: 'Centraldepartment', city: 'Centraldepartment' },
    { state: 'Canindeyu', city: 'Katuete' },
    { state: 'Asuncion', city: 'Asuncion' },
  ],
  QA: [
    { state: 'Baladiyatarrayyan', city: 'Muaydhirrawatrashid' },
    { state: 'Baladiyatarrayyan', city: 'Baladiyatarrayyan' },
    { state: 'Baladiyatarrayyan', city: 'Alrayyan' },
    { state: 'Baladiyataddawah', city: 'Nuayjah' },
    { state: 'Baladiyataddawah', city: 'Doha' },
    { state: 'Baladiyataddawah', city: 'Baladiyataddawah' },
  ],
  RE: [
    { state: 'Reunion', city: 'Sainteclotilde' },
    { state: 'Reunion', city: 'Reunion' },
    { state: 'Reunion', city: 'Saintdenis' },
  ],
  RO: [
    { state: 'Ilfov', city: 'Ilfov' },
    { state: 'Bucureti', city: 'Bucureti' },
    { state: 'Bucureti', city: 'Bucharest' },
    { state: 'Bucureti', city: 'Bucharest(sector1)' },
    { state: 'Timicounty', city: 'Timicounty' },
  ],
  RS: [
    { state: 'Centralserbia', city: 'Belgrade(newbelgrade)' },
    { state: 'Vojvodina', city: 'Subotica' },
    { state: 'Vojvodina', city: 'Vojvodina' },
    { state: 'Vojvodina', city: 'Novisad' },
    { state: 'Centralserbia', city: 'Centralserbia' },
    { state: 'Centralserbia', city: 'Kragujevac' },
    { state: 'Centralserbia', city: 'Ni' },
    { state: 'Centralserbia', city: 'Belgrade' },
  ],
  RU: [
    { state: 'Moscow', city: 'Moscow' },
    { state: 'Novosibirskoblast', city: 'Novosibirskoblast' },
    { state: 'Nizhnynovgorodoblast', city: 'Nizhnynovgorodoblast' },
    { state: 'Sverdlovskoblast', city: 'Yekaterinburg' },
    { state: 'Adygeyarepublic', city: 'Adygeyarepublic' },
    { state: 'Crimea', city: 'Simferopol(tsentralnyidistrict)' },
    { state: 'Crimea', city: 'Simferopol' },
    { state: 'Adygeyarepublic', city: 'Sadovoye' },
    { state: 'Crimea', city: 'Crimea' },
    { state: 'Moscowoblast', city: 'Moscowoblast' },
    { state: 'Stpetersburg', city: 'Stpetersburg' },
    { state: 'Stavropolkray', city: 'Stavropolkray' },
    { state: 'Sverdlovskoblast', city: 'Kamenskuralsky' },
    { state: 'Sverdlovskoblast', city: 'Martyush' },
    { state: 'Sverdlovskoblast', city: 'Sverdlovskoblast' },
    { state: 'Krasnodarkrai', city: 'Taman' },
    { state: 'Leningradoblast', city: 'Kudrovo' },
    { state: 'Krasnodarkrai', city: 'Sochi' },
    { state: 'Saratovoblast', city: 'Saratovoblast' },
    { state: 'Krasnodarkrai', city: 'Krasnodarkrai' },
    { state: 'Rostov', city: 'Rostov' },
    { state: 'Rostov', city: 'Rostovondon' },
    { state: 'Samaraoblast', city: 'Samaraoblast' },
  ],
  RW: [
    { state: 'Kigali', city: 'Kigali' },
    { state: 'Kigali', city: 'Kigali(kimihurura)' },
  ],
  SA: [
    { state: 'Easternprovince', city: 'Dammam' },
    { state: 'Easternprovince', city: 'Easternprovince' },
    { state: 'Meccaregion', city: 'Meccaregion' },
    { state: 'Meccaregion', city: 'Makkahalmukarramah' },
    { state: 'Jazanregion', city: 'Jazanregion' },
    { state: 'Riyadhregion', city: 'Riyadhregion' },
    { state: 'Riyadhregion', city: 'Riyadh(gharnaah)' },
    { state: 'Meccaregion', city: 'Jeddah' },
    { state: 'Riyadhregion', city: 'Riyadh(alolaya)' },
  ],
  SE: [{ state: 'Stockholm', city: 'Stockholm' }],
  SI: [{ state: 'Ljubljana', city: 'Ljubljana' }],
  SK: [
    { state: 'Bratislava', city: 'Bratislava' },
    { state: 'Bratislavaregion', city: 'Bratislavaregion' },
  ],
  SN: [
    { state: 'Dakar', city: 'Dakar(yoff)' },
    { state: 'Dakar', city: 'Dakar(sicapliberte)' },
    { state: 'Dakar', city: 'Mermozboabab' },
    { state: 'Dakar', city: 'Dakar(mermozsacrecour)' },
    { state: 'Dakar', city: 'Dakar' },
    { state: 'Dakar', city: 'Niakoulrab' },
    { state: 'Dakar', city: 'Pikine' },
  ],
  SO: [
    { state: 'Banaadir', city: 'Mogadishu' },
    { state: 'Woqooyigalbeed', city: 'Hargeisa' },
    { state: 'Woqooyigalbeed', city: 'Woqooyigalbeed' },
    { state: 'Banaadir', city: 'Banaadir' },
  ],
  SR: [{ state: 'Paramaribodistrict', city: 'Paramaribodistrict' }],
  SV: [
    { state: 'Lalibertaddepartment', city: 'Antiguocuscatlan' },
    { state: 'Sansalvadordepartment', city: 'Sansalvadordepartment' },
    { state: 'Lalibertaddepartment', city: 'Lalibertaddepartment' },
  ],
  SX: [
    { state: 'Sintmaarten', city: 'Sintmaarten' },
    { state: 'Sintmaarten', city: 'Philipsburg' },
  ],
  SY: [
    { state: 'Dimashq', city: 'Damascus(almouhajrin)' },
    { state: 'Dimashq', city: 'Damascus(almazzeh)' },
    { state: 'Dimashq', city: 'Dimashq' },
    { state: 'Dimashq', city: 'Damascus' },
    { state: 'Hama', city: 'Hama' },
    { state: 'Latakia', city: 'Latakia' },
    { state: 'Homs', city: 'Homs' },
    { state: 'Rifdimashq', city: 'Attall' },
    { state: 'Rifdimashq', city: 'Babila' },
    { state: 'Rifdimashq', city: 'Darayya' },
    { state: 'Aleppo', city: 'Aleppo' },
    { state: 'Rifdimashq', city: 'Jaramana' },
    { state: 'Rifdimashq', city: 'Rifdimashq' },
    { state: 'Alhasakah', city: 'Alhasakah' },
    { state: 'Alhasakah', city: 'Alasakah' },
  ],
  TC: [
    { state: 'Caicosislands', city: 'Caicosislands' },
    { state: 'Caicosislands', city: 'Cockburnharbour' },
  ],
  TG: [
    { state: 'Maritime', city: 'Lome' },
    { state: 'Maritime', city: 'Maritime' },
  ],
  TH: [
    { state: 'Chiangmai', city: 'Chiangmai' },
    { state: 'Bangkok', city: 'Phasicharoen' },
    { state: 'Bangkok', city: 'Phayathai' },
    { state: 'Chiangrai', city: 'Chiangrai' },
    { state: 'Chachoengsao', city: 'Phanomsarakham' },
    { state: 'Kanchanaburi', city: 'Kanchanaburi' },
    { state: 'Chachoengsao', city: 'Chachoengsao' },
    { state: 'Ratchaburi', city: 'Ratchaburi' },
    { state: 'Nakhonphanom', city: 'Nakhonphanom' },
    { state: 'Nakhonratchasima', city: 'Nakhonratchasima' },
    { state: 'Bangkok', city: 'Bangkok' },
    { state: 'Yala', city: 'Yala' },
    { state: 'Bangkok', city: 'Bangphlat' },
    { state: 'Bangkok', city: 'Bangrak' },
    { state: 'Bangkok', city: 'Bangrak(khwaengpathumwan)' },
    { state: 'Bangkok', city: 'Dindaeng' },
    { state: 'Rayong', city: 'Rayong' },
    { state: 'Khonkaen', city: 'Khonkaen' },
    { state: 'Chonburi', city: 'Pattaya' },
    { state: 'Chonburi', city: 'Nakluea' },
    { state: 'Bangkok', city: 'Dindaeng(khwaengdindaeng)' },
    { state: 'Chonburi', city: 'Chonburi' },
    { state: 'Nonthaburi', city: 'Pakkret' },
    { state: 'Nonthaburi', city: 'Nonthaburi' },
    { state: 'Bangkok', city: 'Dindaeng(khwaenghuaikhwang)' },
    { state: 'Bangkok', city: 'Khwaengthungsonghong' },
    { state: 'Lopburi', city: 'Lopburi' },
    { state: 'Sukhothai', city: 'Sukhothai' },
    { state: 'Bangkok', city: 'Prakanong' },
    { state: 'Phuket', city: 'Phuket' },
    { state: 'Suratthani', city: 'Kosamui' },
    { state: 'Suratthani', city: 'Suratthani' },
    { state: 'Songkhla', city: 'Songkhla' },
    { state: 'Prachuapkhirikhan', city: 'Prachuapkhirikhan' },
    { state: 'Prachuapkhirikhan', city: 'Huahin' },
    { state: 'Bangkok', city: 'Ratchathewi(khwaengsamsennai)' },
  ],
  TJ: [{ state: 'Dushanbe', city: 'Dushanbe' }],
  TM: [
    { state: 'Daoguz', city: 'Daoguz' },
    { state: 'Daoguz', city: 'Dashoguz' },
    { state: 'Ashgabat', city: 'Ashgabat' },
  ],
  TN: [
    { state: 'Monastirgovernorate', city: 'Monastirgovernorate' },
    { state: 'Sfaxgovernorate', city: 'Sfaxgovernorate' },
    { state: 'Sfaxgovernorate', city: 'Gremda' },
    { state: 'Arianagovernorate', city: 'Arianagovernorate' },
    { state: 'Arianagovernorate', city: 'Aryanah' },
    { state: 'Tunisgovernorate', city: 'Tunisgovernorate' },
    { state: 'Tunisgovernorate', city: 'Tunis(elmenzah)' },
    { state: 'Bizertegovernorate', city: 'Bizertegovernorate' },
    { state: 'Benarousgovernorate', city: 'Benarousgovernorate' },
    { state: 'Tunisgovernorate', city: 'Lagoulette' },
    { state: 'Tunisgovernorate', city: 'Tunis(centreurbainnord)' },
    { state: 'Nabeulgovernorate', city: 'Nabeulgovernorate' },
    { state: 'Nabeulgovernorate', city: 'Korba' },
    { state: 'Manouba', city: 'Manouba' },
    { state: 'Soussegovernorate', city: 'Soussegovernorate' },
  ],
  TR: [
    { state: 'Bartin', city: 'Bartin' },
    { state: 'Bursaprovince', city: 'Bursaprovince' },
    { state: 'Hatay', city: 'Reyhanli' },
    { state: 'Corum', city: 'Corum' },
    { state: 'Hatay', city: 'Skenderun' },
    { state: 'Trabzon', city: 'Trabzon' },
    { state: 'Rizeprovince', city: 'Rizeprovince' },
    { state: 'Zmirprovince', city: 'Bayrakli' },
    { state: 'Bartin', city: 'Ulus' },
    { state: 'Bolu', city: 'Bolu' },
    { state: 'Canakkale', city: 'Canakkale' },
    { state: 'Mula', city: 'Mula' },
    { state: 'Hatay', city: 'Kilak' },
    { state: 'Gaziantep', city: 'Yamactepe' },
    { state: 'Gaziantep', city: 'Gaziantep' },
    { state: 'Mula', city: 'Marmaris' },
    { state: 'Balikesir', city: 'Balikesir' },
    { state: 'Mardin', city: 'Yaarkoy' },
    { state: 'Mugla', city: 'Fethiye' },
    { state: 'Tekirda', city: 'Tekirda' },
    { state: 'Konya', city: 'Konya' },
    { state: 'Tekirda', city: 'Corlu' },
    { state: 'Diyarbakirprovince', city: 'Diyarbakirprovince' },
    { state: 'Zonguldakprovince', city: 'Zonguldakprovince' },
    { state: 'Erzurum', city: 'Erzurum' },
    { state: 'Eskiehir', city: 'Eskiehir' },
    { state: 'Zmirprovince', city: 'Izmir' },
    { state: 'Manisa', city: 'Manisa' },
    { state: 'Denizli', city: 'Denizli' },
    { state: 'Adana', city: 'Adana' },
    { state: 'Mardin', city: 'Mardin' },
    { state: 'Zmirprovince', city: 'Cordaleo' },
    { state: 'Hatay', city: 'Antakya' },
    { state: 'Aydin', city: 'Aydin' },
    { state: 'Hatay', city: 'Demirkonak' },
    { state: 'Hatay', city: 'Hatay' },
    { state: 'Bursaprovince', city: 'Yeniehir' },
    { state: 'Sakarya', city: 'Adapazari' },
    { state: 'Giresun', city: 'Giresun' },
    { state: 'Sakarya', city: 'Sakarya' },
    { state: 'Bursaprovince', city: 'Nilufer' },
    { state: 'Afyonkarahisarprovince', city: 'Afyonkarahisarprovince' },
    { state: 'Zmirprovince', city: 'Zmirprovince' },
    { state: 'Samsun', city: 'Samsun' },
    { state: 'Istanbul', city: 'Tuzla' },
    { state: 'Ankara', city: 'Ankara' },
    { state: 'Kayseri', city: 'Ncesu' },
    { state: 'Kayseri', city: 'Kayseri' },
    { state: 'Kayseri', city: 'Hacilar' },
    { state: 'Antalya', city: 'Antalya' },
    { state: 'Kahramanmara', city: 'Kahramanmara' },
    { state: 'Istanbul', city: 'Zeytinburnu' },
    { state: 'Istanbul', city: 'Umraniye' },
    { state: 'Ankara', city: 'Etimesgut' },
    { state: 'Istanbul', city: 'Sultangazi' },
    { state: 'Istanbul', city: 'Sultanbeyli' },
    { state: 'Istanbul', city: 'Sariyer' },
    { state: 'Istanbul', city: 'Maltepe' },
    { state: 'Istanbul', city: 'Mahmutbey' },
    { state: 'Istanbul', city: 'Kiziltoprak' },
    { state: 'Istanbul', city: 'Kartal' },
    { state: 'Istanbul', city: 'Pendik' },
    { state: 'Ankara', city: 'Mamak' },
    { state: 'Anliurfa', city: 'Akcakale' },
    { state: 'Kilis', city: 'Kilis' },
    { state: 'Mersin', city: 'Mersin' },
    { state: 'Kirklareli', city: 'Atakoy' },
    { state: 'Kirklareli', city: 'Kirklareli' },
    { state: 'Mersin', city: 'Silifke' },
    { state: 'Kocaeli', city: 'Gebze' },
    { state: 'Kocaeli', city: 'Kocaeli' },
    { state: 'Anliurfa', city: 'Anliurfa' },
    { state: 'Kutahya', city: 'Kutahya' },
    { state: 'Anliurfa', city: 'Sanliurfa' },
    { state: 'Sivas', city: 'Sivas' },
    { state: 'Kocaeli', city: 'Zmit' },
    { state: 'Konya', city: 'Selcuklu' },
    { state: 'Istanbul', city: 'Istanbul' },
    { state: 'Istanbul', city: 'Atakoy' },
    { state: 'Istanbul', city: 'Acibadem' },
    { state: 'Isparta', city: 'Isparta' },
    { state: 'Irnak', city: 'Irnak' },
    { state: 'Amasya', city: 'Amasya' },
    { state: 'Istanbul', city: 'Bahcelievler' },
    { state: 'Istanbul', city: 'Beyolu' },
    { state: 'Elazi', city: 'Halkali' },
    { state: 'Istanbul', city: 'Esenler' },
    { state: 'Elazi', city: 'Elazi' },
    { state: 'Istanbul', city: 'Esenyurt' },
    { state: 'Istanbul', city: 'Ili' },
  ],
  TT: [
    { state: 'Pointfortin', city: 'Pointfortin' },
    { state: 'Diegomartinregionalcorporation', city: 'Petitvalley' },
    { state: 'Portofspain', city: 'Portofspain' },
    { state: 'Diegomartinregionalcorporation', city: 'Diegomartinregionalcorporation' },
    { state: 'Portofspain', city: 'Portofspain(downtown)' },
    { state: 'Portofspain', city: 'Portofspain(mucurapo)' },
    { state: 'Boroughofarima', city: 'Boroughofarima' },
    { state: 'Chaguanas', city: 'Chaguanas' },
    { state: 'Penaldebe', city: 'Penaldebe' },
    { state: 'Tobago', city: 'Scarborough' },
    { state: 'Sangregranderegionalcorporation', city: 'Sangregranderegionalcorporation' },
    { state: 'Sanfernando', city: 'Sanfernando' },
    { state: 'Sanfernando', city: 'Monrepos' },
    { state: 'Portofspain', city: 'Mucurapo' },
    { state: 'Couvatabaquitetalparo', city: 'Couvatabaquitetalparo' },
    { state: 'Princestown', city: 'Princestown' },
    { state: 'Tunapunapiarco', city: 'Tunapunapiarco' },
    { state: 'Sanjuanlaventille', city: 'Sanjuanlaventille' },
    { state: 'Tunapunapiarco', city: 'Arouca' },
    { state: 'Mayaro', city: 'Mayaro' },
    { state: 'Mayaro', city: 'Rioclaro' },
    { state: 'Tobago', city: 'Tobago' },
  ],
  TW: [
    { state: 'Taipei', city: 'Taipei' },
    { state: 'Taiwan', city: 'Taiwan' },
    { state: 'Taiwan', city: 'Taipei' },
    { state: 'Taiwan', city: 'Taichung' },
    { state: 'Taipei', city: 'Yinggedistrict' },
    { state: 'Taiwan', city: 'Neihudistrict' },
  ],
  TZ: [{ state: 'Daressalaamregion', city: 'Daressalaamregion' }],
  UA: [
    { state: 'Zhytomyr', city: 'Zhytomyr' },
    { state: 'Volyn', city: 'Volyn' },
    { state: 'Zakarpattia', city: 'Zakarpattia' },
    { state: 'Zaporizhzhia', city: 'Zaporizhzhia' },
    { state: 'Mykolaiv', city: 'Kalynivka' },
    { state: 'Mykolaiv', city: 'Mykolaiv' },
    { state: 'Mykolayivoblast', city: 'Mykolayivoblast' },
    { state: 'Zaporizhzhia', city: 'Zaporizhzhya' },
    { state: 'Ivanofrankivsk', city: 'Ivanofrankivsk' },
    { state: 'Chernihiv', city: 'Chernihiv' },
    { state: 'Chernivtsi', city: 'Chernivtsi' },
    { state: 'Chernivtsi', city: 'Chernivtsi(leninskyidistrict)' },
    { state: 'Kirovohrad', city: 'Kirovohrad' },
    { state: 'Khmelnytskyioblast', city: 'Starasyniava' },
    { state: 'Khmelnytskyi', city: 'Khmelnytskyi' },
    { state: 'Kharkivskaoblast', city: 'Kharkivskaoblast' },
    { state: 'Kharkiv', city: 'Kharkiv' },
    { state: 'Ivanofrankivskoblast', city: 'Ivanofrankivskoblast' },
    { state: 'Cherkasy', city: 'Cherkasy' },
    { state: 'Rivne', city: 'Rivne' },
    { state: 'Odessa', city: 'Odessa' },
    { state: 'Odesa', city: 'Odesa' },
    { state: 'Dnipropetrovsk', city: 'Dnipropetrovsk' },
    { state: 'Dnipropetrovsk', city: 'Kamianske' },
    { state: 'Donetsk', city: 'Donetsk' },
    { state: 'Vinnytsia', city: 'Vinnytsia' },
    { state: 'Volyn', city: 'Kovel' },
    { state: 'Kyiv', city: 'Kyiv' },
    { state: 'Poltava', city: 'Kremenchuk(avtozavodskyidistrict)' },
    { state: 'Poltava', city: 'Poltava' },
    { state: 'Poltavaoblast', city: 'Kremenchuk(avtozavodskyidistrict)' },
    { state: 'Poltavaoblast', city: 'Poltavaoblast' },
    { state: 'Ternopil', city: 'Ternopil' },
    { state: 'Lviv', city: 'Turka' },
    { state: 'Lviv', city: 'Sambir' },
    { state: 'Lviv', city: 'Lviv(halytskyidistrict)' },
    { state: 'Lviv', city: 'Lviv' },
    { state: 'Lviv', city: 'Drohobych' },
    { state: 'Sumy', city: 'Sumy' },
    { state: 'Kyivcity', city: 'Kyivcity' },
    { state: 'Kyivcity', city: 'Kyiv(solomyanskyidistrict)' },
    { state: 'Kyivcity', city: 'Kyiv(shevchenkivskyidistrict)' },
  ],
  UG: [
    { state: 'Centralregion', city: 'Centralregion' },
    { state: 'Centralregion', city: 'Kampalacentraldivision' },
  ],
  US: [
    { state: 'Florida', city: 'Melbourne' },
    { state: 'Florida', city: 'Hollywood' },
    { state: 'Florida', city: 'Homestead' },
    { state: 'Florida', city: 'Hudson' },
    { state: 'Florida', city: 'Hialeah' },
    { state: 'Florida', city: 'Jacksonville' },
    { state: 'Florida', city: 'Jacksonville(southsideestates)' },
    { state: 'Florida', city: 'Jacksonville(southwestjacksonville)' },
    { state: 'Florida', city: 'Kissimmee' },
    { state: 'Florida', city: 'Lakeland' },
    { state: 'Florida', city: 'Largo' },
    { state: 'Florida', city: 'Lauderdalelakes' },
    { state: 'Florida', city: 'Lecanto' },
    { state: 'Florida', city: 'Leesburg' },
    { state: 'Florida', city: 'Liveoak' },
    { state: 'Florida', city: 'Margate' },
    { state: 'Florida', city: 'Tallahassee' },
    { state: 'Arizona', city: 'Tucson' },
    { state: 'Florida', city: 'Alafaya' },
    { state: 'Florida', city: 'Brandon' },
    { state: 'Florida', city: 'Bradenton' },
    { state: 'Florida', city: 'Boyntonbeach' },
    { state: 'Florida', city: 'Bonitasprings' },
    { state: 'Florida', city: 'Bocaraton' },
    { state: 'Florida', city: 'Baylake' },
    { state: 'Florida', city: 'Auburndale' },
    { state: 'Florida', city: 'Apopka' },
    { state: 'Arizona', city: 'Arizona' },
    { state: 'Florida', city: 'Aloma' },
    { state: 'Florida', city: 'Brooksville' },
    { state: 'Arizona', city: 'Phoenix' },
    { state: 'Arizona', city: 'Sells' },
    { state: 'Arizona', city: 'Surprise' },
    { state: 'Arizona', city: 'Tempe' },
    { state: 'Arkansas', city: 'Pinebluff' },
    { state: 'Arkansas', city: 'Littlerock(rivermountain)' },
    { state: 'Arkansas', city: 'Littlerock' },
    { state: 'Arkansas', city: 'Fayetteville' },
    { state: 'Arkansas', city: 'Arkansas' },
    { state: 'Arizona', city: 'Yuma' },
    { state: 'Florida', city: 'Deltona' },
    { state: 'Florida', city: 'Grantvalkaria' },
    { state: 'Florida', city: 'Gainesville' },
    { state: 'Florida', city: 'Ftpierce' },
    { state: 'Florida', city: 'Fruitlandpark' },
    { state: 'Florida', city: 'Fortwaltonbeach' },
    { state: 'Florida', city: 'Fortmyers' },
    { state: 'Florida', city: 'Fortlauderdale(northfortlauderdale)' },
    { state: 'Florida', city: 'Florida' },
    { state: 'Florida', city: 'Englewood' },
    { state: 'Florida', city: 'Doral' },
    { state: 'Florida', city: 'Hainescity' },
    { state: 'Florida', city: 'Delraybeach' },
    { state: 'Florida', city: 'Deland' },
    { state: 'Florida', city: 'Davie' },
    { state: 'Florida', city: 'Davenport' },
    { state: 'Florida', city: 'Coralsprings(r&dpark)' },
    { state: 'Florida', city: 'Coralsprings' },
    { state: 'Florida', city: 'Coopercity' },
    { state: 'Florida', city: 'Cocoa' },
    { state: 'Florida', city: 'Clermont' },
    { state: 'Florida', city: 'Buenaventuralakes' },
    { state: 'Alabama', city: 'Leeds' },
    { state: 'Texas', city: 'Ysletadelsurpueblo' },
    { state: 'Utah', city: 'Ogden' },
    { state: 'Utah', city: 'Saltlakecity' },
    { state: 'Utah', city: 'Utah' },
    { state: 'Vermont', city: 'Vermont' },
    { state: 'Alaska', city: 'Alaska' },
    { state: 'Alabama', city: 'Tuscaloosa' },
    { state: 'Alabama', city: 'Rainsville' },
    { state: 'Alabama', city: 'Phenixcity' },
    { state: 'Alabama', city: 'Opelika' },
    { state: 'Alabama', city: 'Montgomery' },
    { state: 'Alabama', city: 'Montevallo' },
    { state: 'Texas', city: 'Wylie' },
    { state: 'Alabama', city: 'Huntsville' },
    { state: 'Alabama', city: 'Gardendale' },
    { state: 'Alabama', city: 'Dothan' },
    { state: 'Alabama', city: 'Decatur' },
    { state: 'Virginia', city: 'Alexandria' },
    { state: 'Virginia', city: 'Ashburn' },
    { state: 'Virginia', city: 'Blacksburg' },
    { state: 'Virginia', city: 'Chantilly' },
    { state: 'Virginia', city: 'Charlottesville' },
    { state: 'Virginia', city: 'Chesterfieldcourthouse' },
    { state: 'Virginia', city: 'Culpeper' },
    { state: 'Virginia', city: 'Danville' },
    { state: 'Texas', city: 'Spring' },
    { state: 'Texas', city: 'Pearland' },
    { state: 'Texas', city: 'Pflugerville' },
    { state: 'Texas', city: 'Pharr' },
    { state: 'Texas', city: 'Plainview' },
    { state: 'Texas', city: 'Plano' },
    { state: 'Texas', city: 'Plano(originaldonation)' },
    { state: 'Texas', city: 'Prosper' },
    { state: 'Texas', city: 'Rosenberg' },
    { state: 'Texas', city: 'Roundrock' },
    { state: 'Texas', city: 'Sanantonio' },
    { state: 'Texas', city: 'Sanantonio(northeastside)' },
    { state: 'Texas', city: 'Sanantonio(southside)' },
    { state: 'Virginia', city: 'Dulles' },
    { state: 'Texas', city: 'Sugarland' },
    { state: 'Texas', city: 'Temple' },
    { state: 'Texas', city: 'Texarkana' },
    { state: 'Texas', city: 'Texas' },
    { state: 'Texas', city: 'Thewoodlands(researchforest)' },
    { state: 'Texas', city: 'Tyler' },
    { state: 'Texas', city: 'Universalcity(northeastside)' },
    { state: 'Texas', city: 'Waco' },
    { state: 'Texas', city: 'Wallisville' },
    { state: 'Texas', city: 'Waxahachie' },
    { state: 'Texas', city: 'Weslaco' },
    { state: 'Texas', city: 'Wichitafalls' },
    { state: 'Wisconsin', city: 'Greenbay' },
    { state: 'Washington', city: 'Tacoma' },
    { state: 'Washington', city: 'Tukwila' },
    { state: 'Washington', city: 'Vancouver' },
    { state: 'Washington', city: 'Washington' },
    { state: 'Washington', city: 'Yakima' },
    { state: 'Westvirginia', city: 'Charleston' },
    { state: 'Westvirginia', city: 'Clarksburg' },
    { state: 'Westvirginia', city: 'Westvirginia' },
    { state: 'Wisconsin', city: 'Browndeer' },
    { state: 'Wisconsin', city: 'Cedarburg' },
    { state: 'Wisconsin', city: 'Fonddulac' },
    { state: 'Wisconsin', city: 'Germantown' },
    { state: 'Washington', city: 'Seattle' },
    { state: 'Wisconsin', city: 'Jackson' },
    { state: 'Wisconsin', city: 'Lomira' },
    { state: 'Wisconsin', city: 'Madison' },
    { state: 'Wisconsin', city: 'Menomoneefalls' },
    { state: 'Wisconsin', city: 'Milton' },
    { state: 'Wisconsin', city: 'Milwaukee' },
    { state: 'Wisconsin', city: 'Oconomowoc' },
    { state: 'Wisconsin', city: 'Racine' },
    { state: 'Wisconsin', city: 'Westbend' },
    { state: 'Wisconsin', city: 'Wisconsin' },
    { state: 'Wyoming', city: 'Cheyenne' },
    { state: 'Wyoming', city: 'Wyoming' },
    { state: 'Virginia', city: 'Suffolk' },
    { state: 'Virginia', city: 'Fairfax' },
    { state: 'Virginia', city: 'Fortlee' },
    { state: 'Virginia', city: 'Greenville' },
    { state: 'Virginia', city: 'Manassas' },
    { state: 'Virginia', city: 'Martinsville' },
    { state: 'Virginia', city: 'Norfolk' },
    { state: 'Virginia', city: 'Reston' },
    { state: 'Virginia', city: 'Richmond' },
    { state: 'Virginia', city: 'Richmond(southrichmond)' },
    { state: 'Virginia', city: 'Richmond(thewestend)' },
    { state: 'Virginia', city: 'Roanoke' },
    { state: 'Virginia', city: 'Sterling' },
    { state: 'Texas', city: 'Orange' },
    { state: 'Virginia', city: 'Virginia' },
    { state: 'Virginia', city: 'Virginiabeach' },
    { state: 'Alabama', city: 'Chickasaw' },
    { state: 'Alabama', city: 'Brighton' },
    { state: 'Alabama', city: 'Birmingham' },
    { state: 'Alabama', city: 'Anniston' },
    { state: 'Alabama', city: 'Alexandercity' },
    { state: 'Alabama', city: 'Alabama' },
    { state: 'Washington', city: 'Burien' },
    { state: 'Washington', city: 'Everett' },
    { state: 'Washington', city: 'Federalway' },
    { state: 'Washington', city: 'Kennewick' },
    { state: 'Tennessee', city: 'Maryville' },
    { state: 'Tennessee', city: 'Algood' },
    { state: 'Tennessee', city: 'Bartlett' },
    { state: 'Tennessee', city: 'Blountville' },
    { state: 'Tennessee', city: 'Chattanooga' },
    { state: 'Tennessee', city: 'Chattanooga(citycenter)' },
    { state: 'Tennessee', city: 'Clarksville' },
    { state: 'Tennessee', city: 'Cleveland' },
    { state: 'Tennessee', city: 'Columbia' },
    { state: 'Tennessee', city: 'Germantown' },
    { state: 'Tennessee', city: 'Greeneville' },
    { state: 'Tennessee', city: 'Jackson' },
    { state: 'Tennessee', city: 'Knoxville' },
    { state: 'Southdakota', city: 'Southdakota' },
    { state: 'Tennessee', city: 'Mckenzie' },
    { state: 'Tennessee', city: 'Memphis' },
    { state: 'Tennessee', city: 'Murfreesboro' },
    { state: 'Tennessee', city: 'Nashville' },
    { state: 'Tennessee', city: 'Tennessee' },
    { state: 'Texas', city: 'Aldine' },
    { state: 'Texas', city: 'Allen' },
    { state: 'Texas', city: 'Amarillo' },
    { state: 'Texas', city: 'Arlington' },
    { state: 'Texas', city: 'Austin' },
    { state: 'Texas', city: 'Bearcreek' },
    { state: 'Texas', city: 'Beaumont' },
    { state: 'Southcarolina', city: 'Kingstree' },
    { state: 'Southcarolina', city: 'Beaufort' },
    { state: 'Southcarolina', city: 'Bluffton' },
    { state: 'Southcarolina', city: 'Charleston' },
    { state: 'Southcarolina', city: 'Columbia' },
    { state: 'Southcarolina', city: 'Conway' },
    { state: 'Southcarolina', city: 'Darlington' },
    { state: 'Southcarolina', city: 'Florence' },
    { state: 'Southcarolina', city: 'Forestbrook' },
    { state: 'Southcarolina', city: 'Greenville' },
    { state: 'Southcarolina', city: 'Hartsville' },
    { state: 'Southcarolina', city: 'Hiltonheadisland' },
    { state: 'Southcarolina', city: 'Irmo' },
    { state: 'Texas', city: 'Bluemound' },
    { state: 'Southcarolina', city: 'Ladson' },
    { state: 'Southcarolina', city: 'Lexington' },
    { state: 'Southcarolina', city: 'Littleriver' },
    { state: 'Southcarolina', city: 'Monckscorner' },
    { state: 'Southcarolina', city: 'Murrellsinlet' },
    { state: 'Southcarolina', city: 'Myrtlebeach' },
    { state: 'Southcarolina', city: 'Orangeburg' },
    { state: 'Southcarolina', city: 'Rockhill' },
    { state: 'Southcarolina', city: 'Simpsonville' },
    { state: 'Southcarolina', city: 'Southcarolina' },
    { state: 'Southcarolina', city: 'Spartanburg' },
    { state: 'Southcarolina', city: 'Summerville' },
    { state: 'Texas', city: 'Lancaster' },
    { state: 'Texas', city: 'Harlingen' },
    { state: 'Texas', city: 'Houston' },
    { state: 'Texas', city: 'Houston(alief)' },
    { state: 'Texas', city: 'Houston(greatergreenspoint)' },
    { state: 'Texas', city: 'Houston(northshore)' },
    { state: 'Texas', city: 'Irving' },
    { state: 'Texas', city: 'Katy' },
    { state: 'Texas', city: 'Keller' },
    { state: 'Texas', city: 'Killeen' },
    { state: 'Texas', city: 'Klein' },
    { state: 'Texas', city: 'Kyle' },
    { state: 'Texas', city: 'Lamarque' },
    { state: 'Texas', city: 'Harkerheights' },
    { state: 'Texas', city: 'Laredo' },
    { state: 'Texas', city: 'Lewisville' },
    { state: 'Texas', city: 'Longview' },
    { state: 'Texas', city: 'Losfresnos' },
    { state: 'Texas', city: 'Lubbock' },
    { state: 'Texas', city: 'Mansfield' },
    { state: 'Texas', city: 'Mckinney' },
    { state: 'Texas', city: 'Mesquite' },
    { state: 'Texas', city: 'Midland' },
    { state: 'Texas', city: 'Mission' },
    { state: 'Texas', city: 'Odessa' },
    { state: 'Texas', city: 'Olton' },
    { state: 'Texas', city: 'Dumas' },
    { state: 'Texas', city: 'Borger' },
    { state: 'Texas', city: 'Brownsville' },
    { state: 'Texas', city: 'Cactus' },
    { state: 'Texas', city: 'Canyon' },
    { state: 'Texas', city: 'Canyonlake' },
    { state: 'Texas', city: 'Cedarhill' },
    { state: 'Texas', city: 'Corpuschristi' },
    { state: 'Texas', city: 'Cypress' },
    { state: 'Texas', city: 'Dallas' },
    { state: 'Texas', city: 'Dallas(northdallas)' },
    { state: 'Texas', city: 'Denton' },
    { state: 'Texas', city: 'Desoto' },
    { state: 'Southcarolina', city: 'Anderson' },
    { state: 'Texas', city: 'Duncanville' },
    { state: 'Texas', city: 'Edinburg' },
    { state: 'Texas', city: 'Elpaso' },
    { state: 'Texas', city: 'Elpaso(missionvalley)' },
    { state: 'Texas', city: 'Euless' },
    { state: 'Texas', city: 'Forney' },
    { state: 'Texas', city: 'Fortworth' },
    { state: 'Texas', city: 'Frisco' },
    { state: 'Texas', city: 'Fritch' },
    { state: 'Texas', city: 'Garland' },
    { state: 'Texas', city: 'Grandprairie' },
    { state: 'Colorado', city: 'Arvada' },
    { state: 'Oregon', city: 'Eugene' },
    { state: 'Oregon', city: 'Beaverton' },
    { state: 'Michigan', city: 'Southfield' },
    { state: 'Michigan', city: 'Taylor' },
    { state: 'Michigan', city: 'Troy' },
    { state: 'Michigan', city: 'Ubly' },
    { state: 'Michigan', city: 'Unionville' },
    { state: 'Michigan', city: 'Vassar' },
    { state: 'Michigan', city: 'Walledlake' },
    { state: 'Michigan', city: 'Wayne' },
    { state: 'Michigan', city: 'Yale' },
    { state: 'Oregon', city: 'Oregon' },
    { state: 'Colorado', city: 'Aurora' },
    { state: 'Colorado', city: 'Colorado' },
    { state: 'Colorado', city: 'Coloradosprings' },
    { state: 'Colorado', city: 'Denver' },
    { state: 'Colorado', city: 'Englewood(denvertechcenter)' },
    { state: 'Colorado', city: 'Fortcollins' },
    { state: 'Colorado', city: 'Greeley' },
    { state: 'Colorado', city: 'Highlandsranch' },
    { state: 'Colorado', city: 'Littleton' },
    { state: 'Colorado', city: 'Pueblo' },
    { state: 'Michigan', city: 'Muskegon' },
    { state: 'Michigan', city: 'Grandrapids' },
    { state: 'Michigan', city: 'Holland' },
    { state: 'Michigan', city: 'Kalamazoo' },
    { state: 'Michigan', city: 'Kingston' },
    { state: 'Michigan', city: 'Livonia' },
    { state: 'Michigan', city: 'Marlette' },
    { state: 'Michigan', city: 'Mayville' },
    { state: 'Michigan', city: 'Melvindale' },
    { state: 'Michigan', city: 'Michigan' },
    { state: 'Michigan', city: 'Millington' },
    { state: 'Michigan', city: 'Mtmorris' },
    { state: 'Colorado', city: 'Stratmoor' },
    { state: 'Michigan', city: 'Northbranch' },
    { state: 'Michigan', city: 'Pigeon' },
    { state: 'Michigan', city: 'Pontiac' },
    { state: 'Michigan', city: 'Roseville' },
    { state: 'Michigan', city: 'Ruth' },
    { state: 'Michigan', city: 'Saginaw' },
    { state: 'Michigan', city: 'Sandusky' },
    { state: 'Michigan', city: 'Sebewaing' },
    { state: 'Oregon', city: 'Troutdale' },
    { state: 'Oregon', city: 'Portland' },
    { state: 'Ohio', city: 'Northolmsted' },
    { state: 'Ohio', city: 'Youngstown' },
    { state: 'Ohio', city: 'Warren' },
    { state: 'Ohio', city: 'Wapakoneta' },
    { state: 'Ohio', city: 'Valleyview' },
    { state: 'Ohio', city: 'Trotwood' },
    { state: 'Ohio', city: 'Toledo' },
    { state: 'Ohio', city: 'Springfield' },
    { state: 'Ohio', city: 'Portsmouth' },
    { state: 'Ohio', city: 'Ohio' },
    { state: 'Ohio', city: 'Obetz' },
    { state: 'Ohio', city: 'Norwalk' },
    { state: 'Ohio', city: 'Zanesville' },
    { state: 'Ohio', city: 'Mounthealthy' },
    { state: 'Ohio', city: 'Mentor' },
    { state: 'Ohio', city: 'Massillon' },
    { state: 'Ohio', city: 'Mapleheights' },
    { state: 'Ohio', city: 'Mansfield' },
    { state: 'Ohio', city: 'Lyndhurst' },
    { state: 'Ohio', city: 'Louisville' },
    { state: 'Ohio', city: 'Lorain' },
    { state: 'Ohio', city: 'Lima' },
    { state: 'Ohio', city: 'Kent' },
    { state: 'Connecticut', city: 'Stamford' },
    { state: 'Connecticut', city: 'Bloomfield' },
    { state: 'Connecticut', city: 'Branford' },
    { state: 'Connecticut', city: 'Bridgeport' },
    { state: 'Connecticut', city: 'Connecticut' },
    { state: 'Connecticut', city: 'Hamden' },
    { state: 'Connecticut', city: 'Hartford' },
    { state: 'Connecticut', city: 'Killingworth' },
    { state: 'Connecticut', city: 'Meriden' },
    { state: 'Connecticut', city: 'Newhaven' },
    { state: 'Connecticut', city: 'Newtown' },
    { state: 'Connecticut', city: 'Norwalk' },
    { state: 'Michigan', city: 'Frankenmuth' },
    { state: 'Connecticut', city: 'Wallingford' },
    { state: 'Connecticut', city: 'Waterbury' },
    { state: 'Oklahoma', city: 'Weatherford' },
    { state: 'Oklahoma', city: 'Tulsa' },
    { state: 'Oklahoma', city: 'Oklahomacity' },
    { state: 'Oklahoma', city: 'Oklahoma' },
    { state: 'Oklahoma', city: 'Norman' },
    { state: 'Oklahoma', city: 'Kingfisher' },
    { state: 'Oklahoma', city: 'Idabel' },
    { state: 'Oklahoma', city: 'Edmond' },
    { state: 'Massachusetts', city: 'Brockton' },
    { state: 'Maryland', city: 'Maryland' },
    { state: 'Maryland', city: 'Monkton' },
    { state: 'Maryland', city: 'Rockville' },
    { state: 'Maryland', city: 'Salisbury' },
    { state: 'Maryland', city: 'Sudlersville' },
    { state: 'Maryland', city: 'Whitemarsh' },
    { state: 'Maryland', city: 'Worton' },
    { state: 'Massachusetts', city: 'Amherst' },
    { state: 'Massachusetts', city: 'Boston' },
    { state: 'Massachusetts', city: 'Boston(roxbury)' },
    { state: 'Massachusetts', city: 'Boston(westroxbury)' },
    { state: 'Maryland', city: 'Lanham' },
    { state: 'Massachusetts', city: 'Chicopee' },
    { state: 'Massachusetts', city: 'Dalton' },
    { state: 'Massachusetts', city: 'Foxborough' },
    { state: 'Massachusetts', city: 'Greenfield' },
    { state: 'Massachusetts', city: 'Groveland' },
    { state: 'Massachusetts', city: 'Holyoke' },
    { state: 'Massachusetts', city: 'Lawrence' },
    { state: 'Massachusetts', city: 'Mashpee' },
    { state: 'Massachusetts', city: 'Massachusetts' },
    { state: 'Massachusetts', city: 'Maynard' },
    { state: 'Louisiana', city: 'Slidell' },
    { state: 'Louisiana', city: 'Batonrouge' },
    { state: 'Louisiana', city: 'Gonzales' },
    { state: 'Louisiana', city: 'Hammond' },
    { state: 'Louisiana', city: 'Lafayette' },
    { state: 'Louisiana', city: 'Lakecharles' },
    { state: 'Louisiana', city: 'Louisiana' },
    { state: 'Louisiana', city: 'Marrero' },
    { state: 'Louisiana', city: 'Monroe' },
    { state: 'Louisiana', city: 'Neworleans' },
    { state: 'Louisiana', city: 'Opelousas' },
    { state: 'Louisiana', city: 'Shreveport' },
    { state: 'Pennsylvania', city: 'Stroudsburg' },
    { state: 'Maine', city: 'Maine' },
    { state: 'Maryland', city: 'Baltimore' },
    { state: 'Maryland', city: 'Baltimore(reisterstownstation)' },
    { state: 'Maryland', city: 'Capitolheights' },
    { state: 'Maryland', city: 'Centreville' },
    { state: 'Maryland', city: 'Frederick' },
    { state: 'Maryland', city: 'Gaithersburg' },
    { state: 'Maryland', city: 'Gambrills' },
    { state: 'Maryland', city: 'Germantown' },
    { state: 'Florida', city: 'Miami' },
    { state: 'Michigan', city: 'Caro' },
    { state: 'Massachusetts', city: 'Quincy' },
    { state: 'Massachusetts', city: 'Sheffield' },
    { state: 'Massachusetts', city: 'Springfield' },
    { state: 'Massachusetts', city: 'Westfield' },
    { state: 'Massachusetts', city: 'Woburn' },
    { state: 'Massachusetts', city: 'Worcester' },
    { state: 'Michigan', city: 'Badaxe' },
    { state: 'Michigan', city: 'Baldwin' },
    { state: 'Michigan', city: 'Baycity' },
    { state: 'Michigan', city: 'Bessemer' },
    { state: 'Michigan', city: 'Browncity' },
    { state: 'Massachusetts', city: 'Plymouth' },
    { state: 'Michigan', city: 'Casscity' },
    { state: 'Michigan', city: 'Chelsea' },
    { state: 'Michigan', city: 'Clintontownship' },
    { state: 'Michigan', city: 'Columbiaville' },
    { state: 'Michigan', city: 'Croswell' },
    { state: 'Michigan', city: 'Dearborn' },
    { state: 'Michigan', city: 'Detroit' },
    { state: 'Michigan', city: 'Detroit(airportsub)' },
    { state: 'Michigan', city: 'Fairgrove' },
    { state: 'Michigan', city: 'Flint' },
    { state: 'Pennsylvania', city: 'Erie' },
    { state: 'Pennsylvania', city: 'Scranton' },
    { state: 'Pennsylvania', city: 'Rosstownship' },
    { state: 'Pennsylvania', city: 'Pittsburgh' },
    { state: 'Pennsylvania', city: 'Philadelphia(northeastphiladelphia)' },
    { state: 'Pennsylvania', city: 'Philadelphia' },
    { state: 'Pennsylvania', city: 'Pennsylvania' },
    { state: 'Pennsylvania', city: 'Palmerton' },
    { state: 'Pennsylvania', city: 'Linglestown' },
    { state: 'Pennsylvania', city: 'Lebanon' },
    { state: 'Pennsylvania', city: 'Lancaster' },
    { state: 'Pennsylvania', city: 'Harrisburg' },
    { state: 'Ohio', city: 'Hudson' },
    { state: 'Pennsylvania', city: 'Eddington' },
    { state: 'Pennsylvania', city: 'Easton' },
    { state: 'Pennsylvania', city: 'Coatesville' },
    { state: 'Pennsylvania', city: 'Bushkill' },
    { state: 'Pennsylvania', city: 'Blairsville' },
    { state: 'Pennsylvania', city: 'Bethlehem' },
    { state: 'Pennsylvania', city: 'Bethelpark' },
    { state: 'Pennsylvania', city: 'Allentown' },
    { state: 'Massachusetts', city: 'Northampton' },
    { state: 'Massachusetts', city: 'Pittsfield' },
    { state: 'Newyork', city: 'Yonkers' },
    { state: 'Northcarolina', city: 'Asheboro' },
    { state: 'Newjersey', city: 'Eastwindsor' },
    { state: 'Newjersey', city: 'Eatontown' },
    { state: 'Newjersey', city: 'Edison' },
    { state: 'Newjersey', city: 'Elizabeth' },
    { state: 'Newjersey', city: 'Elmwoodpark' },
    { state: 'Newjersey', city: 'Fortlee' },
    { state: 'Newjersey', city: 'Hackensack' },
    { state: 'Newjersey', city: 'Harrison' },
    { state: 'Newjersey', city: 'Jacksontownship' },
    { state: 'Newjersey', city: 'Lyndhurst' },
    { state: 'Northcarolina', city: 'Asheville' },
    { state: 'Newyork', city: 'Williamsville' },
    { state: 'Newyork', city: 'Whiteplains' },
    { state: 'Newyork', city: 'Wappingersfalls' },
    { state: 'Newyork', city: 'Utica' },
    { state: 'Newyork', city: 'Syracuse' },
    { state: 'Newyork', city: 'Statenisland' },
    { state: 'Newyork', city: 'Schenectady' },
    { state: 'Newyork', city: 'Ryebrook' },
    { state: 'Newyork', city: 'Rochester(downtown)' },
    { state: 'Newyork', city: 'Rochester' },
    { state: 'Northcarolina', city: 'Goldsboro' },
    { state: 'Northcarolina', city: 'Knightdale' },
    { state: 'Northcarolina', city: 'Kingsmountain' },
    { state: 'Northcarolina', city: 'Kernersville' },
    { state: 'Northcarolina', city: 'Kannapolis' },
    { state: 'Northcarolina', city: 'Hopemills' },
    { state: 'Northcarolina', city: 'Hollyridge' },
    { state: 'Northcarolina', city: 'Highpoint' },
    { state: 'Northcarolina', city: 'Hickory' },
    { state: 'Northcarolina', city: 'Harrisburg' },
    { state: 'Northcarolina', city: 'Halfmoon' },
    { state: 'Northcarolina', city: 'Greensboro' },
    { state: 'Newyork', city: 'Queensvillage' },
    { state: 'Northcarolina', city: 'Gastonia' },
    { state: 'Northcarolina', city: 'Garner' },
    { state: 'Northcarolina', city: 'Fayetteville' },
    { state: 'Northcarolina', city: 'Elizabethcity' },
    { state: 'Northcarolina', city: 'Durham' },
    { state: 'Northcarolina', city: 'Concord' },
    { state: 'Northcarolina', city: 'Charlotte' },
    { state: 'Northcarolina', city: 'Cary(highmeadows)' },
    { state: 'Northcarolina', city: 'Burlington' },
    { state: 'Northcarolina', city: 'Buiescreek' },
    { state: 'Delaware', city: 'Dover' },
    { state: 'Newjersey', city: 'Morristown' },
    { state: 'Newjersey', city: 'Mountlaureltownship' },
    { state: 'Newjersey', city: 'Newark' },
    { state: 'Newjersey', city: 'Newjersey' },
    { state: 'Newjersey', city: 'Northbergen' },
    { state: 'Newjersey', city: 'Parsippanytroyhillstownship' },
    { state: 'Newjersey', city: 'Paterson' },
    { state: 'Newjersey', city: 'Piscataway' },
    { state: 'Newjersey', city: 'Randolphtownship' },
    { state: 'Newjersey', city: 'Sayreville' },
    { state: 'Delaware', city: 'Delaware' },
    { state: 'Newyork', city: 'Albany' },
    { state: 'Delaware', city: 'Millsboro' },
    { state: 'Delaware', city: 'Newcastle' },
    { state: 'Newjersey', city: 'Secaucus' },
    { state: 'Newjersey', city: 'Trenton' },
    { state: 'Newjersey', city: 'Union' },
    { state: 'Newjersey', city: 'Vineland' },
    { state: 'Newjersey', city: 'Washingtontownship(turnersville)' },
    { state: 'Newmexico', city: 'Albuquerque' },
    { state: 'Newmexico', city: 'Clovis' },
    { state: 'Newmexico', city: 'Lascruces' },
    { state: 'Newyork', city: 'Jamaica' },
    { state: 'Newyork', city: 'Queens' },
    { state: 'Newyork', city: 'Poughkeepsie' },
    { state: 'Newyork', city: 'Niagarafalls' },
    { state: 'Newyork', city: 'Newyork(brooklyn)' },
    { state: 'Newyork', city: 'Newyork' },
    { state: 'Newyork', city: 'Newburgh' },
    { state: 'Newyork', city: 'Mountvernon' },
    { state: 'Newyork', city: 'Middletown' },
    { state: 'Newyork', city: 'Mamaroneck' },
    { state: 'Newyork', city: 'Lynbrook' },
    { state: 'Newyork', city: 'Loudonville' },
    { state: 'Northcarolina', city: 'Laurinburg' },
    { state: 'Newyork', city: 'Islip' },
    { state: 'Newyork', city: 'Hicksville' },
    { state: 'Newyork', city: 'Freeport' },
    { state: 'Newyork', city: 'Flushing' },
    { state: 'Newyork', city: 'Farmingville' },
    { state: 'Newyork', city: 'Coram' },
    { state: 'Newyork', city: 'Buffalo' },
    { state: 'Newyork', city: 'Brooklyn' },
    { state: 'Newyork', city: 'Binghamton' },
    { state: 'Newyork', city: 'Babylon' },
    { state: 'Missouri', city: 'Charlack' },
    { state: 'Mississippi', city: 'Hattiesburg' },
    { state: 'Mississippi', city: 'Hornlake' },
    { state: 'Mississippi', city: 'Jackson' },
    { state: 'Mississippi', city: 'Longbeach' },
    { state: 'Mississippi', city: 'Meridian' },
    { state: 'Mississippi', city: 'Mississippi' },
    { state: 'Mississippi', city: 'Oxford' },
    { state: 'Mississippi', city: 'Pascagoula' },
    { state: 'Mississippi', city: 'Ridgeland' },
    { state: 'Mississippi', city: 'Tupelo' },
    { state: 'Mississippi', city: 'Vicksburg' },
    { state: 'Mississippi', city: 'Gulfport' },
    { state: 'Missouri', city: 'Chesterfield' },
    { state: 'Missouri', city: 'Eldoradosprings' },
    { state: 'Missouri', city: 'Florissant' },
    { state: 'Missouri', city: 'Independence' },
    { state: 'Missouri', city: 'Kansascity' },
    { state: 'Missouri', city: 'Missouri' },
    { state: 'Missouri', city: 'Ofallon' },
    { state: 'Missouri', city: 'Smithville' },
    { state: 'Missouri', city: 'Stlouis' },
    { state: 'Districtofcolumbia', city: 'Washingtondc(capitolhill)' },
    { state: 'Ohio', city: 'Berea' },
    { state: 'Ohio', city: 'Elyria' },
    { state: 'Ohio', city: 'Eaton' },
    { state: 'Ohio', city: 'Eastcleveland' },
    { state: 'Ohio', city: 'Dayton' },
    { state: 'Ohio', city: 'Columbus(sharonheights)' },
    { state: 'Ohio', city: 'Columbus' },
    { state: 'Ohio', city: 'Cleveland' },
    { state: 'Ohio', city: 'Cincinnati(centralbusinessdistrict)' },
    { state: 'Ohio', city: 'Cincinnati' },
    { state: 'Ohio', city: 'Canton' },
    { state: 'Ohio', city: 'Bridgetownnorth' },
    { state: 'Districtofcolumbia', city: 'Washingtondc' },
    { state: 'Ohio', city: 'Akron' },
    { state: 'Minnesota', city: 'Maplegrove' },
    { state: 'Minnesota', city: 'Minneapolis' },
    { state: 'Minnesota', city: 'Minnesota' },
    { state: 'Minnesota', city: 'Roseville' },
    { state: 'Mississippi', city: 'Biloxi' },
    { state: 'Mississippi', city: 'Brookhaven' },
    { state: 'Mississippi', city: 'Clarksdale' },
    { state: 'Mississippi', city: 'Columbus' },
    { state: 'Mississippi', city: 'Corinth' },
    { state: 'Northcarolina', city: 'Raeford' },
    { state: 'Northcarolina', city: 'Wendell' },
    { state: 'Northcarolina', city: 'Wakeforest' },
    { state: 'Northcarolina', city: 'Thomasville' },
    { state: 'Northcarolina', city: 'Statesville' },
    { state: 'Northcarolina', city: 'Springlake' },
    { state: 'Northcarolina', city: 'Shelby' },
    { state: 'Northcarolina', city: 'Selma' },
    { state: 'Northcarolina', city: 'Salisbury' },
    { state: 'Northcarolina', city: 'Rockymount' },
    { state: 'Northcarolina', city: 'Rockingham' },
    { state: 'Northcarolina', city: 'Raleigh' },
    { state: 'Northcarolina', city: 'Wentworth' },
    { state: 'Northcarolina', city: 'Pfafftown' },
    { state: 'Northcarolina', city: 'Northcarolina' },
    { state: 'Northcarolina', city: 'Morrisville' },
    { state: 'Northcarolina', city: 'Mooresville' },
    { state: 'Northcarolina', city: 'Monroe' },
    { state: 'Northcarolina', city: 'Mebane' },
    { state: 'Northcarolina', city: 'Lumberton' },
    { state: 'Northcarolina', city: 'Lincolnton' },
    { state: 'Northcarolina', city: 'Lexington' },
    { state: 'Northcarolina', city: 'Leland' },
    { state: 'Newhampshire', city: 'Londonderry' },
    { state: 'Montana', city: 'Montana' },
    { state: 'Districtofcolumbia', city: 'Washington' },
    { state: 'Districtofcolumbia', city: 'Districtofcolumbia' },
    { state: 'Nebraska', city: 'Lincoln' },
    { state: 'Nebraska', city: 'Nebraska' },
    { state: 'Nebraska', city: 'Omaha' },
    { state: 'Nevada', city: 'Lasvegas' },
    { state: 'Nevada', city: 'Nevada' },
    { state: 'Nevada', city: 'Northlasvegas' },
    { state: 'Nevada', city: 'Reno' },
    { state: 'Newhampshire', city: 'Exeter' },
    { state: 'Maryland', city: 'Hagerstown' },
    { state: 'Newhampshire', city: 'Newhampshire' },
    { state: 'Newjersey', city: 'Absecon' },
    { state: 'Newjersey', city: 'Audubon' },
    { state: 'Newjersey', city: 'Bloomingdale' },
    { state: 'Newjersey', city: 'Camden' },
    { state: 'Newjersey', city: 'Clifton' },
    { state: 'Northdakota', city: 'Northdakota' },
    { state: 'Northcarolina', city: 'Winstonsalem' },
    { state: 'Northcarolina', city: 'Wilson' },
    { state: 'Northcarolina', city: 'Wilmington' },
    { state: 'Illinois', city: 'Eastpeoria' },
    { state: 'California', city: 'Anaheim' },
    { state: 'California', city: 'Bakersfield' },
    { state: 'California', city: 'Baldwinhills' },
    { state: 'California', city: 'Bellflower' },
    { state: 'Illinois', city: 'Bellwood' },
    { state: 'Illinois', city: 'Champaign' },
    { state: 'Illinois', city: 'Chicago' },
    { state: 'Illinois', city: 'Chicago(nearsouthside)' },
    { state: 'Illinois', city: 'Chicago(southside)' },
    { state: 'Illinois', city: 'Algonquin' },
    { state: 'Illinois', city: 'Elmhurst' },
    { state: 'California', city: 'Brea' },
    { state: 'California', city: 'California' },
    { state: 'California', city: 'Carlsbad' },
    { state: 'California', city: 'Chiriacosummit' },
    { state: 'California', city: 'Corona' },
    { state: 'California', city: 'Covina' },
    { state: 'California', city: 'Dalycity' },
    { state: 'Georgia', city: 'Suwanee' },
    { state: 'Georgia', city: 'Pooler' },
    { state: 'Georgia', city: 'Powdersprings' },
    { state: 'Georgia', city: 'Riverdale' },
    { state: 'Georgia', city: 'Rome' },
    { state: 'Georgia', city: 'Savannah' },
    { state: 'Georgia', city: 'Smyrna' },
    { state: 'Georgia', city: 'Snellville' },
    { state: 'Georgia', city: 'Stockbridge' },
    { state: 'Georgia', city: 'Stonemountain' },
    { state: 'California', city: 'Delano' },
    { state: 'Georgia', city: 'Thomasville' },
    { state: 'Georgia', city: 'Valdosta' },
    { state: 'Georgia', city: 'Woodstock' },
    { state: 'Hawaii', city: 'Hawaii' },
    { state: 'Hawaii', city: 'Honolulu' },
    { state: 'California', city: 'Aguadulce' },
    { state: 'Idaho', city: 'Boise' },
    { state: 'Idaho', city: 'Idaho' },
    { state: 'California', city: 'Losangeles(southlosangeles)' },
    { state: 'California', city: 'Lamesa' },
    { state: 'California', city: 'Lamirada' },
    { state: 'California', city: 'Lamont' },
    { state: 'California', city: 'Longbeach' },
    { state: 'California', city: 'Longbeach(eastranchodominguez)' },
    { state: 'California', city: 'Losangeles' },
    { state: 'California', city: 'Losangeles(canogapark)' },
    { state: 'California', city: 'Losangeles(downtownlosangeles)' },
    { state: 'California', city: 'Losangeles(sherwoodforest)' },
    { state: 'California', city: 'Lakewood' },
    { state: 'California', city: 'Losangeles(tujunga)' },
    { state: 'California', city: 'Losangeles(valleyglen)' },
    { state: 'California', city: 'Madera' },
    { state: 'California', city: 'Modesto' },
    { state: 'California', city: 'Monrovia' },
    { state: 'California', city: 'Montereypark' },
    { state: 'California', city: 'Morenovalley' },
    { state: 'California', city: 'Norwalk' },
    { state: 'California', city: 'Haciendaheights' },
    { state: 'California', city: 'Devoreheights' },
    { state: 'California', city: 'Eastranchodominguez' },
    { state: 'California', city: 'Fairfield' },
    { state: 'California', city: 'Florin' },
    { state: 'California', city: 'Fontana' },
    { state: 'California', city: 'Fresno' },
    { state: 'California', city: 'Fullerton' },
    { state: 'California', city: 'Gardena' },
    { state: 'California', city: 'Gardengrove' },
    { state: 'Georgia', city: 'Newnan' },
    { state: 'California', city: 'Hawthorne' },
    { state: 'California', city: 'Hayward' },
    { state: 'California', city: 'Hemet' },
    { state: 'California', city: 'Hesperia' },
    { state: 'California', city: 'Huntingtonbeach' },
    { state: 'California', city: 'Inglewood' },
    { state: 'California', city: 'Irvine' },
    { state: 'California', city: 'Lakeelsinore' },
    { state: 'Florida', city: 'Stpetersburg' },
    { state: 'Florida', city: 'Portorange' },
    { state: 'Florida', city: 'Portsaintlucie' },
    { state: 'Florida', city: 'Royalpalmbeach' },
    { state: 'Florida', city: 'Ruskin' },
    { state: 'Florida', city: 'Sanford' },
    { state: 'Florida', city: 'Sarasota' },
    { state: 'Florida', city: 'Sebring' },
    { state: 'Florida', city: 'Springfield' },
    { state: 'Florida', city: 'Stcloud' },
    { state: 'Florida', city: 'Pompanobeach' },
    { state: 'Florida', city: 'Stuart' },
    { state: 'Newmexico', city: 'Newmexico' },
    { state: 'Florida', city: 'Tamarac' },
    { state: 'Florida', city: 'Tampa' },
    { state: 'Florida', city: 'Thevillages' },
    { state: 'Florida', city: 'Unionpark' },
    { state: 'Florida', city: 'Venice' },
    { state: 'Florida', city: 'Verobeach' },
    { state: 'Florida', city: 'Ocoee' },
    { state: 'Florida', city: 'Miamigardens' },
    { state: 'Florida', city: 'Miramar' },
    { state: 'Florida', city: 'Newportrichey' },
    { state: 'Florida', city: 'Nokomis' },
    { state: 'Florida', city: 'Northfortmyers(hancock)' },
    { state: 'Florida', city: 'Northmiami' },
    { state: 'Florida', city: 'Northmiamibeach' },
    { state: 'Florida', city: 'Northport' },
    { state: 'Florida', city: 'Ocala' },
    { state: 'Florida', city: 'Wesleychapel' },
    { state: 'Florida', city: 'Orlando' },
    { state: 'Florida', city: 'Orlando(southwestorlando)' },
    { state: 'Florida', city: 'Ormondbeach' },
    { state: 'Florida', city: 'Palmcoast' },
    { state: 'Florida', city: 'Panamacity' },
    { state: 'Florida', city: 'Pembrokepines' },
    { state: 'Florida', city: 'Pensacola' },
    { state: 'Florida', city: 'Plantcity' },
    { state: 'Georgia', city: 'Jonesboro' },
    { state: 'Georgia', city: 'Decatur' },
    { state: 'Georgia', city: 'Douglas' },
    { state: 'Georgia', city: 'Douglasville' },
    { state: 'Georgia', city: 'Fairburn' },
    { state: 'Georgia', city: 'Fayetteville' },
    { state: 'Georgia', city: 'Fitzgerald' },
    { state: 'Georgia', city: 'Georgia' },
    { state: 'Georgia', city: 'Griffin' },
    { state: 'Georgia', city: 'Hinesville' },
    { state: 'Georgia', city: 'Dalton' },
    { state: 'Georgia', city: 'Lagrange' },
    { state: 'Georgia', city: 'Lawrenceville' },
    { state: 'Georgia', city: 'Lilburn' },
    { state: 'Georgia', city: 'Lithonia' },
    { state: 'Georgia', city: 'Macon' },
    { state: 'Georgia', city: 'Marietta' },
    { state: 'Georgia', city: 'Mcdonough' },
    { state: 'Georgia', city: 'Milledgeville' },
    { state: 'Georgia', city: 'Atlanta(perimetercenter)' },
    { state: 'Florida', city: 'Westperrine(perrine)' },
    { state: 'Florida', city: 'Wildwood' },
    { state: 'Florida', city: 'Wimauma' },
    { state: 'Florida', city: 'Zephyrhills' },
    { state: 'Georgia', city: 'Albany' },
    { state: 'Georgia', city: 'Alpharetta' },
    { state: 'Georgia', city: 'Athens' },
    { state: 'Georgia', city: 'Atlanta' },
    { state: 'Georgia', city: 'Atlanta(downtownatlanta)' },
    { state: 'California', city: 'Azusa' },
    { state: 'Georgia', city: 'Augusta' },
    { state: 'Georgia', city: 'Austell' },
    { state: 'Georgia', city: 'Buford' },
    { state: 'Georgia', city: 'Carrollton' },
    { state: 'Georgia', city: 'Columbus' },
    { state: 'Georgia', city: 'Commerce' },
    { state: 'Georgia', city: 'Conyers' },
    { state: 'Georgia', city: 'Covington' },
    { state: 'Indiana', city: 'Evansville' },
    { state: 'Indiana', city: 'Newalbany' },
    { state: 'Indiana', city: 'Mishawaka' },
    { state: 'Indiana', city: 'Lawrence' },
    { state: 'Indiana', city: 'Indianapolis' },
    { state: 'Rhodeisland', city: 'Eastprovidence' },
    { state: 'Rhodeisland', city: 'Providence' },
    { state: 'Rhodeisland', city: 'Rhodeisland' },
    { state: 'Indiana', city: 'Indiana' },
    { state: 'Indiana', city: 'Hammond' },
    { state: 'Indiana', city: 'Hagerstown' },
    { state: 'Indiana', city: 'Griffith' },
    { state: 'Indiana', city: 'Gaston' },
    { state: 'Indiana', city: 'Fortwayne' },
    { state: 'Indiana', city: 'Noblesville' },
    { state: 'Indiana', city: 'Elwood' },
    { state: 'Indiana', city: 'Economy' },
    { state: 'Indiana', city: 'Daleville' },
    { state: 'Indiana', city: 'Connersville' },
    { state: 'Indiana', city: 'Bloomington' },
    { state: 'Indiana', city: 'Attica' },
    { state: 'Indiana', city: 'Anderson' },
    { state: 'Illinois', city: 'Westchicago' },
    { state: 'Illinois', city: 'Swansea' },
    { state: 'Illinois', city: 'Springfield' },
    { state: 'Illinois', city: 'Roxana' },
    { state: 'Illinois', city: 'Romeoville' },
    { state: 'Illinois', city: 'Rockford' },
    { state: 'Kentucky', city: 'Ashland' },
    { state: 'Kentucky', city: 'Paris' },
    { state: 'Kentucky', city: 'Owensboro' },
    { state: 'Kentucky', city: 'Louisville' },
    { state: 'Kentucky', city: 'Lexingtonfayette' },
    { state: 'Kentucky', city: 'Kentucky' },
    { state: 'Kentucky', city: 'Hopkinsville' },
    { state: 'Kentucky', city: 'Heritagecreek' },
    { state: 'Kentucky', city: 'Hadley' },
    { state: 'Kentucky', city: 'Flemingsburg' },
    { state: 'Kentucky', city: 'Ewing' },
    { state: 'Kentucky', city: 'Edgewood' },
    { state: 'Kentucky', city: 'Brooksville' },
    { state: 'Kentucky', city: 'Barbourville' },
    { state: 'California', city: 'Oakland' },
    { state: 'Kansas', city: 'Wichita' },
    { state: 'Kansas', city: 'Topeka' },
    { state: 'Kansas', city: 'Kansascity' },
    { state: 'Kansas', city: 'Kansas' },
    { state: 'Kansas', city: 'Coffeyville' },
    { state: 'Iowa', city: 'Iowa' },
    { state: 'Iowa', city: 'Desmoines' },
    { state: 'Iowa', city: 'Davenport' },
    { state: 'Indiana', city: 'Yorktown' },
    { state: 'Indiana', city: 'Watson' },
    { state: 'Indiana', city: 'Terrehaute' },
    { state: 'Indiana', city: 'Sheridan' },
    { state: 'Indiana', city: 'Richmond' },
    { state: 'Illinois', city: 'Homewood' },
    { state: 'California', city: 'Sanjose(edenvale)' },
    { state: 'California', city: 'Riverside' },
    { state: 'California', city: 'Ranchocucamonga' },
    { state: 'California', city: 'Porterville' },
    { state: 'California', city: 'Pomona' },
    { state: 'California', city: 'Pleasanton' },
    { state: 'California', city: 'Pittsburg' },
    { state: 'California', city: 'Picorivera' },
    { state: 'California', city: 'Rohnertpark' },
    { state: 'California', city: 'Pasadena' },
    { state: 'California', city: 'Palmdesert' },
    { state: 'California', city: 'Palmdale' },
    { state: 'California', city: 'Oxnard' },
    { state: 'California', city: 'Sanjose(downtownsanjose)' },
    { state: 'California', city: 'Ontario' },
    { state: 'California', city: 'Sanjose' },
    { state: 'California', city: 'Vista' },
    { state: 'California', city: 'Santamonica' },
    { state: 'California', city: 'Scottsvalley' },
    { state: 'California', city: 'Stockton' },
    { state: 'California', city: 'Santamaria' },
    { state: 'California', city: 'Temecula' },
    { state: 'California', city: 'Turlock' },
    { state: 'California', city: 'Santaclara' },
    { state: 'California', city: 'Victorville' },
    { state: 'California', city: 'Perris' },
    { state: 'California', city: 'Watsonville' },
    { state: 'California', city: 'Westcovina' },
    { state: 'California', city: 'Westsacramento' },
    { state: 'California', city: 'Whittier' },
    { state: 'California', city: 'Wilmington' },
    { state: 'California', city: 'Sacramento' },
    { state: 'California', city: 'Santaana' },
    { state: 'California', city: 'Oakglen' },
    { state: 'Illinois', city: 'Peoria' },
    { state: 'California', city: 'Sanfrancisco' },
    { state: 'Illinois', city: 'Oakpark' },
    { state: 'Illinois', city: 'Northchicago' },
    { state: 'Illinois', city: 'Mountprospect' },
    { state: 'Illinois', city: 'Moline' },
    { state: 'Illinois', city: 'Riverforest' },
    { state: 'California', city: 'Sanfernando' },
    { state: 'Illinois', city: 'Mchenry' },
    { state: 'California', city: 'Sandiego' },
    { state: 'Illinois', city: 'Illinois' },
    { state: 'California', city: 'Sanbernardino' },
  ],
  UY: [
    { state: 'Canelones', city: 'Canelones' },
    { state: 'Montevideodepartment', city: 'Montevideodepartment' },
    { state: 'Canelones', city: 'Pasodecarrasco' },
    { state: 'Maldonado', city: 'Puntadeleste' },
    { state: 'Maldonado', city: 'Maldonado' },
  ],
  UZ: [
    { state: 'Samarqandregion', city: 'Samarqandregion' },
    { state: 'Tashkent', city: 'Tashkent(shayxontohurtumani)' },
    { state: 'Tashkent', city: 'Tashkent' },
  ],
  VC: [
    { state: 'Saintgeorgeparish', city: 'Saintgeorgeparish' },
    { state: 'Saintgeorgeparish', city: 'Kingstown' },
  ],
  VE: [
    { state: 'Aragua', city: 'Maracay' },
    { state: 'Zulia', city: 'Sancarlosdelzulia' },
    { state: 'Merida', city: 'Merida' },
    { state: 'Anzoategui', city: 'Anzoategui' },
    { state: 'Merida', city: 'Elvigia' },
    { state: 'Carabobo', city: 'Carabobo' },
    { state: 'Zulia', city: 'Zulia' },
    { state: 'Aragua', city: 'Aragua' },
    { state: 'Zulia', city: 'Sanfrancisco' },
    { state: 'Miranda', city: 'Miranda' },
    { state: 'Zulia', city: 'Maracaibo' },
    { state: 'Estadolaguaira', city: 'Estadolaguaira' },
    { state: 'Miranda', city: 'Caracas(lospalosgrandes)' },
    { state: 'Distritofederal', city: 'Caracas(zonaindustriallatrinidad)' },
    { state: 'Distritofederal', city: 'Distritofederal' },
    { state: 'Bolivar', city: 'Bolivar' },
    { state: 'Distritofederal', city: 'Caracas' },
    { state: 'Lara', city: 'Barquisimeto' },
    { state: 'Portuguesa', city: 'Portuguesa' },
    { state: 'Lara', city: 'Barquisimeto(urbanizacionfundalara)' },
    { state: 'Lara', city: 'Lara' },
    { state: 'Falcon', city: 'Falcon' },
    { state: 'Falcon', city: 'Coro' },
    { state: 'Carabobo', city: 'Valencia' },
  ],
  VG: [
    { state: 'Tortola', city: 'Roadtown' },
    { state: 'Tortola', city: 'Tortola' },
  ],
  VI: [
    { state: 'Saintthomasisland', city: 'Charlotteamalie' },
    { state: 'Saintthomasisland', city: 'Saintthomasisland' },
  ],
  VN: [
    { state: 'Lngsnprovince', city: 'Lngsnprovince' },
    { state: 'Dngnaiprovince', city: 'Dngnaiprovince' },
    { state: 'Binhphcprovince', city: 'Binhphcprovince' },
    { state: 'Binhdnhprovince', city: 'Quinhon' },
    { state: 'Binhthunprovince', city: 'Binhthunprovince' },
    { state: 'Dngthapprovince', city: 'Dngthapprovince' },
    { state: 'Binhdnhprovince', city: 'Binhdnhprovince' },
    { state: 'Qungnamprovince', city: 'Qungnamprovince' },
    { state: 'Lngsnprovince', city: 'Langson' },
    { state: 'Lamdngprovince', city: 'Lamdngprovince' },
    { state: 'Lamdngprovince', city: 'Dalat' },
    { state: 'Danang', city: 'Danang' },
    { state: 'Daknongprovince', city: 'Daknongprovince' },
    { state: 'Kiengiangprovince', city: 'Kiengiangprovince' },
    { state: 'Khanhhoaprovince', city: 'Nhatrang' },
    { state: 'Khanhhoaprovince', city: 'Khanhhoaprovince' },
    { state: 'Baravungtauprovince', city: 'Baravungtauprovince' },
    { state: 'Binhdngprovince', city: 'Thunan' },
    { state: 'Qungninh', city: 'Qungninh' },
    { state: 'Phuthprovince', city: 'Phuthprovince' },
    { state: 'Bcgiangprovince', city: 'Bcgiangprovince' },
    { state: 'Bcninhprovince', city: 'Bcninhprovince' },
    { state: 'Angiangprovince', city: 'Angiangprovince' },
    { state: 'Nghanprovince', city: 'Nghanprovince' },
    { state: 'Binhdngprovince', city: 'Binhdngprovince' },
    { state: 'Binhdngprovince', city: 'Thudaumot' },
    { state: 'Hatinhprovince', city: 'Hatinhprovince' },
    { state: 'Hochiminh', city: 'Qunbinhthnh' },
    { state: 'Hochiminh', city: 'Qunba' },
    { state: 'Hochiminh', city: 'Hocmon' },
    { state: 'Hochiminh', city: 'Hochiminhcity' },
    { state: 'Hochiminh', city: 'Hochiminh' },
    { state: 'Hoabinhprovince', city: 'Hoabinhprovince' },
    { state: 'Hngyenprovince', city: 'Hngyenprovince' },
    { state: 'Hidngprovince', city: 'Hidngprovince' },
    { state: 'Hochiminh', city: 'Qunbn' },
    { state: 'Hanoi', city: 'Thanhxuan' },
    { state: 'Hanoi', city: 'Langthng' },
    { state: 'Hanoi', city: 'Hoankim' },
    { state: 'Hanoi', city: 'Hanoi' },
    { state: 'Hanoi', city: 'Haibatrng' },
    { state: 'Hanoi', city: 'Hadong' },
    { state: 'Hanoi', city: 'Cugiy' },
    { state: 'Hanoi', city: 'Cudin' },
    { state: 'Hochiminh', city: 'Quntanphu' },
    { state: 'Longanpovince', city: 'Longanpovince' },
    { state: 'Camauprovince', city: 'Camauprovince' },
    { state: 'Dngnaiprovince', city: 'Bienhoa' },
    { state: 'Dklk', city: 'Dklk' },
    { state: 'Dklk', city: 'Buonmathuot' },
    { state: 'Hochiminh', city: 'Thdc' },
    { state: 'Hochiminh', city: 'Tantuc' },
    { state: 'Hochiminh', city: 'Tandinh' },
    { state: 'Cantho', city: 'Cairang' },
    { state: 'Vinhphucprovince', city: 'Vinhphucprovince' },
    { state: 'Hochiminh', city: 'Qunsau' },
    { state: 'Hochiminh', city: 'Qunphunhun' },
    { state: 'Hochiminh', city: 'Qunmt' },
    { state: 'Hochiminh', city: 'Qunmi' },
    { state: 'Hochiminh', city: 'Qunhai' },
    { state: 'Hochiminh', city: 'Qunchin' },
    { state: 'Tayninhprovince', city: 'Tayninhprovince' },
    { state: 'Haiphong', city: 'Haiphong' },
    { state: 'Namdnhprovince', city: 'Namdnhprovince' },
    { state: 'Gialaiprovince', city: 'Gialaiprovince' },
    { state: 'Snlaprovince', city: 'Snlaprovince' },
    { state: 'Soctrangprovince', city: 'Soctrangprovince' },
    { state: 'Tingiang', city: 'Tingiang' },
    { state: 'Thanhhoaprovince', city: 'Thanhhoaprovince' },
    { state: 'Thainguyenprovince', city: 'Thainguyenprovince' },
    { state: 'Thaibinhprovince', city: 'Thaibinhprovince' },
    { state: 'Bntreprovince', city: 'Bntreprovince' },
    { state: 'Cantho', city: 'Cantho' },
  ],
  XK: [
    { state: 'Pristina', city: 'Pristina' },
    { state: 'Pristina', city: 'Lipljan' },
    { state: 'Prizren', city: 'Pagarua' },
    { state: 'Prizren', city: 'Prizren' },
    { state: 'Pec', city: 'Pec' },
    { state: 'Ferizaj', city: 'Ferizaj' },
    { state: 'Gjakova', city: 'Gjakova' },
    { state: 'Gjilan', city: 'Gjilan' },
    { state: 'Mitrovica', city: 'Mitrovica' },
    { state: 'Mitrovica', city: 'Vushtrri' },
  ],
  YE: [
    { state: 'Amanatalasimah', city: 'Amanatalasimah' },
    { state: 'Amanatalasimah', city: 'Sanaa' },
  ],
  ZA: [
    { state: 'Gauteng', city: 'Randburg(blackheath)' },
    { state: 'Westerncape', city: 'Rondebosch' },
    { state: 'Kwazulunatal', city: 'Berea' },
    { state: 'Westerncape', city: 'Retreat' },
    { state: 'Westerncape', city: 'Kraaifontein' },
    { state: 'Westerncape', city: 'Capetown(greenpoint)' },
    { state: 'Kwazulunatal', city: 'Durban' },
    { state: 'Kwazulunatal', city: 'Kwazulunatal' },
    { state: 'Westerncape', city: 'Capetown(golfcourse)' },
    { state: 'Westerncape', city: 'Capetown(gardens)' },
    { state: 'Westerncape', city: 'Capetown(claremont)' },
    { state: 'Gauteng', city: 'Pretoria' },
    { state: 'Gauteng', city: 'Randburg' },
    { state: 'Westerncape', city: 'Rosebank' },
    { state: 'Gauteng', city: 'Randburg(fairland)' },
    { state: 'Westerncape', city: 'Capetown(capetowncitycentre)' },
    { state: 'Westerncape', city: 'Capetown' },
    { state: 'Limpopo', city: 'Limpopo' },
    { state: 'Limpopo', city: 'Thohoyandou' },
    { state: 'Gauteng', city: 'Randburg(newlands)' },
    { state: 'Gauteng', city: 'Sandton(bryanston)' },
    { state: 'Easterncape', city: 'Easterncape' },
    { state: 'Gauteng', city: 'Sandton(edenburg)' },
    { state: 'Gauteng', city: 'Sandton(sandhurst)' },
    { state: 'Gauteng', city: 'Soweto' },
    { state: 'Gauteng', city: 'Westonaria' },
    { state: 'Gauteng', city: 'Centurion' },
    { state: 'Gauteng', city: 'Centurion(highveld)' },
    { state: 'Easterncape', city: 'Portelizabeth(millpark)' },
    { state: 'Gauteng', city: 'Centurion(highveldtechnopark)' },
    { state: 'Gauteng', city: 'Gauteng' },
    { state: 'Gauteng', city: 'Johannesburg' },
    { state: 'Gauteng', city: 'Johannesburg(waverley)' },
    { state: 'Gauteng', city: 'Kemptonpark(isando)' },
    { state: 'Easterncape', city: 'Portelizabeth' },
    { state: 'Northerncape', city: 'Northerncape' },
    { state: 'Gauteng', city: 'Kemptonpark(serengetigolfandwildlifeestate)' },
    { state: 'Freestate', city: 'Freestate' },
    { state: 'Gauteng', city: 'Midrand(halfwayhouseestate)' },
    { state: 'Gauteng', city: 'Midrand(midridgepark)' },
    { state: 'Mpumalanga', city: 'Mpumalanga' },
    { state: 'Gauteng', city: 'Nigel' },
    { state: 'Westerncape', city: 'Worcester' },
    { state: 'Westerncape', city: 'Westerncape' },
    { state: 'Westerncape', city: 'Stellenbosch(stellenboschcentral)' },
    { state: 'Westerncape', city: 'Stellenbosch' },
    { state: 'Northwest', city: 'Letlhabile' },
    { state: 'Northwest', city: 'Northwest' },
    { state: 'Northwest', city: 'Rustenburg' },
  ],
  ZM: [{ state: 'Lusakaprovince', city: 'Lusakaprovince' }],
  ZW: [{ state: 'Harare', city: 'Harare' }],
};

export const NETNUT_CREDENTIALS_GENERATOR_GROUPED_BY_COUNTRIES_AND_STATES: Record<
  string,
  Record<string, Array<{ city: string }>>
> = {
  AE: {
    Ajman: [{ city: 'Ajman' }],
    Fujairah: [{ city: 'Fujairah' }],
    Sharjah: [{ city: 'Sharjahcity' }, { city: 'Sharjah' }],
    Abudhabi: [{ city: 'Abudhabi' }, { city: 'Musaffahcity' }],
    Dubai: [
      { city: 'Warisan' },
      { city: 'Burdubai' },
      { city: 'Dubai' },
      { city: 'Dubai(academiccity)' },
      { city: 'Dubai(alsufouh)' },
      { city: 'Jebelali' },
    ],
  },
  AG: {
    Saintjohnparish: [{ city: 'Stjohns' }, { city: 'Cedargrove' }, { city: 'Saintjohnparish' }],
    Saintgeorgeparish: [{ city: 'Saintgeorgeparish' }, { city: 'Piggotts' }],
    Saintpaulparish: [{ city: 'Englishharbourtown' }, { city: 'Saintpaulparish' }],
  },
  AI: { Thevalley: [{ city: 'Thevalley' }] },
  AL: {
    Shkodercounty: [{ city: 'Shkodercounty' }],
    Elbasancounty: [{ city: 'Lumas' }, { city: 'Elbasancounty' }],
    Vlorecounty: [{ city: 'Vlorecounty' }],
    Kukescounty: [{ city: 'Kukescounty' }],
    Lezhecounty: [{ city: 'Lezhecounty' }],
    Fiercounty: [{ city: 'Fiercounty' }],
    Tirana: [{ city: 'Tirana' }],
    Durrescounty: [{ city: 'Durrescounty' }],
    Korcecounty: [{ city: 'Korcecounty' }],
  },
  AM: {
    Kotayk: [{ city: 'Kotayk' }, { city: 'Abovyan' }],
    Yerevan: [{ city: 'Yerevan(kentrondistrict)' }, { city: 'Yerevan' }],
    Armavir: [{ city: 'Armavir' }],
  },
  AO: { Luanda: [{ city: 'Luanda' }, { city: 'Luanda(ingombota)' }] },
  AR: {
    Buenosairesfd: [
      { city: 'Sannicolas' },
      { city: 'Santelmo' },
      { city: 'Palermo' },
      { city: 'Buenosairesfd' },
      { city: 'Villacrespo' },
      { city: 'Buenosaires(santelmo)' },
      { city: 'Buenosaires(palermo)' },
      { city: 'Buenosaires(monserrat)' },
      { city: 'Belgrano' },
      { city: 'Buenosaires(villacrespo)' },
    ],
    Buenosaires: [
      { city: 'Santoslugares' },
      { city: 'Avellaneda' },
      { city: 'Generalvillegas' },
      { city: 'Generalrodriguez' },
      { city: 'Ezeiza' },
      { city: 'Ciudaddellibertadorgeneralsanmartin' },
      { city: 'Burzaco' },
      { city: 'Buenosaires' },
      { city: 'Bragado' },
      { city: 'Berazategui' },
      { city: 'Benavidez' },
      { city: 'Gonzalezcatan' },
      { city: 'Adrogue' },
      { city: 'Sanjuanbautista' },
      { city: 'Sanvicente' },
      { city: 'Santamaria' },
      { city: 'Sanmiguel' },
      { city: 'Sanjusto' },
      { city: 'Quilmes' },
      { city: 'Pontevedra' },
      { city: 'Merlo' },
      { city: 'Mardelplata' },
      { city: 'Lomasdezamora' },
      { city: 'Laplata' },
      { city: 'Lanus' },
      { city: 'Guernica' },
    ],
    Salta: [{ city: 'Salta' }],
    Sanjuan: [{ city: 'Chimbas' }, { city: 'Sanjuan' }, { city: 'Villabasilionievas' }],
    Corrientes: [{ city: 'Corrientes' }],
    Cordoba: [{ city: 'Cordoba' }],
    Chubut: [{ city: 'Chubut' }],
    Rionegro: [{ city: 'Rionegro' }],
    Chaco: [{ city: 'Chaco' }, { city: 'Resistencia' }],
    Sanluis: [{ city: 'Sanluis' }],
    Santacruz: [{ city: 'Santacruz' }],
    Santafe: [{ city: 'Rosario' }, { city: 'Santafe' }],
    Santiagodelestero: [{ city: 'Santiagodelestero' }],
    Entrerios: [{ city: 'Entrerios' }],
    Misiones: [{ city: 'Misiones' }],
    Larioja: [{ city: 'Larioja' }],
    Mendoza: [
      { city: 'Godoycruz' },
      { city: 'Lasheras' },
      { city: 'Lujandecuyo' },
      { city: 'Maipu' },
      { city: 'Mendoza' },
      { city: 'Sanmartin' },
      { city: 'Sanrafael' },
      { city: 'Villanueva' },
    ],
    Tucuman: [
      { city: 'Tucuman' },
      { city: 'Tafiviejo' },
      { city: 'Simoca' },
      { city: 'Sanmigueldetucuman' },
      { city: 'Bandadelriosali' },
    ],
    Lapampa: [{ city: 'Lapampa' }],
    Jujuy: [{ city: 'Jujuy' }],
    Neuquen: [{ city: 'Neuquen' }],
  },
  AT: {
    Vorarlberg: [
      { city: 'Feldkirch' },
      { city: 'Thuringerberg' },
      { city: 'Rankweil' },
      { city: 'Nenzing' },
      { city: 'Koblach' },
      { city: 'Hohenems' },
      { city: 'Gotzis' },
      { city: 'Bludenz' },
      { city: 'Altenstadt' },
    ],
    Styria: [{ city: 'Styria' }],
    Loweraustria: [{ city: 'Loweraustria' }],
    Upperaustria: [{ city: 'Upperaustria' }],
    Tyrol: [{ city: 'Tyrol' }],
    Vienna: [{ city: 'Vienna(floridsdorf)' }, { city: 'Vienna' }],
  },
  AU: {
    Australiancapitalterritory: [{ city: 'Canberra' }, { city: 'Australiancapitalterritory' }],
    Westernaustralia: [
      { city: 'Wundowie' },
      { city: 'Westernaustralia' },
      { city: 'Perth' },
      { city: 'Leinster' },
      { city: 'Claremont' },
      { city: 'Bentley' },
      { city: 'Balcatta' },
    ],
    Victoria: [
      { city: 'Victoria' },
      { city: 'Sunshinewest' },
      { city: 'Melbourne' },
      { city: 'Balwynnorth' },
      { city: 'Sthelena' },
      { city: 'Burwood' },
      { city: 'Caulfieldsouth' },
      { city: 'Elsternwick' },
      { city: 'Fawkner' },
      { city: 'Frankstoneast' },
      { city: 'Geelong' },
      { city: 'Stalbans' },
      { city: 'Pointlonsdale' },
      { city: 'Morwell' },
      { city: 'Kooyong' },
      { city: 'Laverton' },
    ],
    Newsouthwales: [
      { city: 'Alexandria' },
      { city: 'Liverpool' },
      { city: 'Parramatta' },
      { city: 'Nyngan' },
      { city: 'Northsydney' },
      { city: 'Northryde' },
      { city: 'Newsouthwales' },
      { city: 'Moruya' },
      { city: 'Mascot' },
      { city: 'Macquariepark' },
      { city: 'Haymarket' },
      { city: 'Forestlodge' },
      { city: 'Darlington' },
      { city: 'Brunswickheads' },
      { city: 'Blacktown' },
      { city: 'Southwestrocks' },
      { city: 'Zetland' },
      { city: 'Wollongong' },
      { city: 'Toongabbiewest' },
      { city: 'Therocks' },
      { city: 'Sydney' },
      { city: 'Pymble' },
    ],
    Queensland: [
      { city: 'Townsvillecitycenter' },
      { city: 'Redcliffe' },
      { city: 'Queensland' },
      { city: 'Longreach' },
      { city: 'Kelvingrove' },
      { city: 'Goldcoast' },
      { city: 'Coomera' },
      { city: 'Brisbane' },
      { city: 'Arundel' },
    ],
    Southaustralia: [{ city: 'Adelaide' }, { city: 'Roxbydowns' }, { city: 'Southaustralia' }],
    Tasmania: [{ city: 'Hobart' }, { city: 'Tasmania' }],
  },
  AZ: {
    Baki: [
      { city: 'Bakikhanov' },
      { city: 'Baku(suraxani)' },
      { city: 'Baku(nsimi)' },
      { city: 'Baku(nrimanov)' },
      { city: 'Baku(narimanovskiyrayon)' },
      { city: 'Baku' },
      { city: 'Amiradzhan' },
      { city: 'Baki' },
      { city: 'Badamdar' },
      { city: 'Baku(yasamal)' },
      { city: 'Baladjary' },
      { city: 'Khojasan' },
      { city: 'Qaracuxur' },
      { city: 'Sabuncu' },
    ],
    Aberon: [{ city: 'Ceyranbatan' }, { city: 'Aberon' }],
    Gnc: [{ city: 'Ganja' }, { city: 'Gnc' }],
    Nakhchivan: [{ city: 'Nakhchivan' }],
    Mkir: [{ city: 'Mkir' }],
    Ada: [{ city: 'Ada' }],
    Sumqayit: [{ city: 'Sumqayit' }],
  },
  BA: {
    Srpska: [{ city: 'Srpska' }, { city: 'Banjaluka' }],
    'Federationofb&h': [{ city: 'Sarajevo' }, { city: 'Federationofb&h' }],
  },
  BB: {
    Christchurch: [{ city: 'Oistins' }, { city: 'Hastings' }, { city: 'Christchurch' }],
    Saintlucy: [{ city: 'Saintlucy' }, { city: 'Crabhill' }, { city: 'Checkerhall' }],
    Saintjoseph: [{ city: 'Saintjoseph' }, { city: 'Blackmans' }],
    Saintandrew: [{ city: 'Hillaby' }, { city: 'Greenland' }, { city: 'Saintandrew' }],
    Saintjohn: [{ city: 'Saintjohn' }, { city: 'Fourcrossroads' }],
    Saintjames: [{ city: 'Saintjames' }, { city: 'Holetown' }],
    Saintmichael: [
      { city: 'Bridgetown' },
      { city: 'Bridgetown(wildey)' },
      { city: 'Edenlodge' },
      { city: 'Saintmichael' },
    ],
    Saintpeter: [{ city: 'Saintpeter' }, { city: 'Speightstown' }],
    Saintphilip: [{ city: 'Crane' }, { city: 'Saintphilip' }],
    Saintthomas: [{ city: 'Saintthomas' }, { city: 'Welchmanhall' }],
  },
  BD: {
    Dhakadivision: [
      { city: 'Dhaka(mohammadpur)' },
      { city: 'Dhaka(ramnamaidan)' },
      { city: 'Dhaka(pallabi)' },
      { city: 'Dhaka(motijheel)' },
      { city: 'Dhaka(gulshan)' },
      { city: 'Dhaka(rampura)' },
      { city: 'Dhaka(sutrapur)' },
      { city: 'Dhaka(tejgaon)' },
      { city: 'Dhaka(uttara)' },
      { city: 'Dhakadivision' },
      { city: 'Dohar' },
      { city: 'Dukuria' },
      { city: 'Faridpur' },
      { city: 'Dhaka(mirpur)' },
      { city: 'Dhaka(lalbagh)' },
      { city: 'Dhaka(khilgaon)' },
      { city: 'Dhaka(kafrul)' },
      { city: 'Dhaka(jatrabari)' },
      { city: 'Sakhipur' },
      { city: 'Dhaka(basundhararesidentialarea)' },
      { city: 'Dhaka(badda)' },
      { city: 'Charbhadrasanupazila' },
      { city: 'Charbhadrasan' },
      { city: 'Binodpur' },
      { city: 'Bhatara' },
      { city: 'Sonargaon' },
      { city: 'Tungipara' },
      { city: 'Tongi' },
      { city: 'Tangail' },
      { city: 'Gazipur' },
      { city: 'Savarupazila' },
      { city: 'Paltan' },
      { city: 'Palang' },
      { city: 'Nawabchar' },
      { city: 'Narsingdi' },
      { city: 'Narayanganj' },
      { city: 'Nagarnaluakot' },
      { city: 'Mirzapur' },
      { city: 'Matuail' },
      { city: 'Kishorganj' },
      { city: 'Kafrul' },
      { city: 'Greenmodeltown' },
      { city: 'Gopalganj' },
      { city: 'Azimpur' },
      { city: 'Bhairabbazar' },
    ],
    Sylhetdivision: [
      { city: 'Sylhet(zindabazar)' },
      { city: 'Maulavibazar' },
      { city: 'Jahedpur' },
      { city: 'Sylhetdivision' },
      { city: 'Habiganj' },
      { city: 'Chhatak' },
    ],
    Mymensinghdivision: [
      { city: 'Gafargaon' },
      { city: 'Jamalpur' },
      { city: 'Muktagacha' },
      { city: 'Mymensinghdivision' },
      { city: 'Netrakona' },
      { city: 'Sarishabari' },
      { city: 'Sherpur' },
    ],
    Dhaka: [{ city: 'Dhaka(gulshan)' }, { city: 'Dhaka' }],
    Chittagong: [
      { city: 'Chittagong(bakalia)' },
      { city: 'Ashuganjcity' },
      { city: 'Bibirhat' },
      { city: 'Chhagalnaiya' },
      { city: 'Chittagong' },
      { city: 'Chittagong(15nobagmoniramward)' },
      { city: 'Chittagong(bayazid)' },
      { city: 'Chittagong(khulshiresidentialarea)' },
      { city: 'Comilla' },
      { city: 'Coxsbazar' },
      { city: 'Feni' },
      { city: 'Hajiganj' },
      { city: 'Sandwip' },
      { city: 'Satkania' },
      { city: 'Raojan' },
      { city: 'Rangamati' },
      { city: 'Ramganj' },
      { city: 'Raipur' },
      { city: 'Nabinagar' },
      { city: 'Lakshmipur' },
      { city: 'Shahbazpurtown' },
      { city: 'Laksham' },
      { city: 'Hathazari' },
    ],
    Rangpurdivision: [
      { city: 'Rangpurdivision' },
      { city: 'Saidpur' },
      { city: 'Thakurgaon' },
      { city: 'Rangpurcity' },
      { city: 'Badarganj' },
      { city: 'Dinajpur' },
      { city: 'Gaibandha' },
      { city: 'Nageswari' },
      { city: 'Nilphamari' },
      { city: 'Parbatipur' },
    ],
    Khulnadivision: [
      { city: 'Bagerhat' },
      { city: 'Bhatparaabhaynagar' },
      { city: 'Ujalpur' },
      { city: 'Jessore' },
      { city: 'Satkhira' },
      { city: 'Nowlamary' },
      { city: 'Maguradistrict' },
      { city: 'Kushtia' },
      { city: 'Khulnadivision' },
      { city: 'Kesabpur' },
      { city: 'Kaliganj' },
      { city: 'Kalia' },
    ],
    Rajshahidivision: [
      { city: 'Shahzadpur' },
      { city: 'Bogra' },
      { city: 'Joypurhat' },
      { city: 'Natore' },
      { city: 'Nawabganj' },
      { city: 'Pabna' },
      { city: 'Parnaogaon' },
      { city: 'Puthia' },
      { city: 'Rajshahidivision' },
      { city: 'Shibganj' },
      { city: 'Sirajganj' },
    ],
    Barisaldivision: [
      { city: 'Pirojpur' },
      { city: 'Nalchiti' },
      { city: 'Mathba' },
      { city: 'Bhandaria' },
      { city: 'Barishal(chawckbazar)' },
      { city: 'Barishal' },
      { city: 'Barisaldivision' },
    ],
  },
  BE: {
    Brusselscapital: [
      { city: 'Saintjossetennoode' },
      { city: 'Saintgilles' },
      { city: 'Evere' },
      { city: 'Brusselscapital' },
    ],
    Flanders: [{ city: 'Bruges' }, { city: 'Flanders' }, { city: 'Mechelen' }],
    Wallonia: [{ city: 'Wallonia' }],
  },
  BF: { Centre: [{ city: 'Ouagadougou' }, { city: 'Centre' }] },
  BG: {
    Sofiacapital: [{ city: 'Sofia' }],
    Sofiagrad: [{ city: 'Sofiagrad' }],
    Burgas: [{ city: 'Burgas' }],
    Pazardzhik: [{ city: 'Pazardzhik' }],
    Plovdiv: [{ city: 'Plovdiv' }],
    Gabrovo: [{ city: 'Gabrovo' }],
    Blagoevgrad: [{ city: 'Blagoevgrad' }],
    Varna: [{ city: 'Varna' }],
  },
  BH: {
    Northerngovernorate: [{ city: 'Northerngovernorate' }, { city: 'Hamadtown' }],
    Southerngovernorate: [
      { city: 'Southerngovernorate' },
      { city: 'Riffa' },
      { city: 'Isatown' },
      { city: 'Darkulayb' },
    ],
    Muharraq: [{ city: 'Muharraq' }, { city: 'Almuharraq' }, { city: 'Aladd' }],
    Manama: [{ city: 'Jiddaf' }, { city: 'Manama(alsalmaniya)' }, { city: 'Sitrah' }, { city: 'Manama' }],
  },
  BJ: { Littoral: [{ city: 'Littoral' }] },
  BM: { Hamiltoncity: [{ city: 'Hamiltoncity' }], Saintgeorge: [{ city: 'Saintgeorge' }] },
  BN: { Bruneimuara: [{ city: 'Bandarseribegawan(anggerekdesa)' }, { city: 'Bruneimuara' }] },
  BO: {
    Cochabamba: [{ city: 'Cochabamba' }],
    Lapazdepartment: [{ city: 'Achocalla' }, { city: 'Lapazdepartment' }],
    Oruro: [{ city: 'Oruro' }],
    Santacruzdepartment: [{ city: 'Santacruzdepartment' }],
  },
  BR: {
    Goias: [
      { city: 'Anapolis' },
      { city: 'Goiania' },
      { city: 'Formosa' },
      { city: 'Cidadeocidental' },
      { city: 'Catalao' },
      { city: 'Caldasnovas' },
      { city: 'Aparecidadegoiania' },
      { city: 'Anapolis(conjuntoeldorado)' },
      { city: 'Alexania' },
      { city: 'Senadorcanedo' },
      { city: 'Trindade' },
      { city: 'Goianira' },
      { city: 'Rioverde' },
      { city: 'Planaltina' },
      { city: 'Neropolis' },
      { city: 'Morrinhos' },
      { city: 'Luziania' },
      { city: 'Jatai' },
      { city: 'Itumbiara' },
      { city: 'Goias' },
    ],
    Federaldistrict: [{ city: 'Brasilia' }, { city: 'Planaltina' }, { city: 'Federaldistrict' }],
    Acre: [{ city: 'Riobranco' }, { city: 'Acre' }],
    Espiritosanto: [
      { city: 'Vilavelha' },
      { city: 'Vitoria' },
      { city: 'Linhares' },
      { city: 'Cachoeirodeitapemirim' },
      { city: 'Saomateus' },
      { city: 'Marataizes' },
      { city: 'Serra' },
      { city: 'Guarapari' },
      { city: 'Espiritosanto' },
    ],
    Maranhao: [{ city: 'Imperatriz' }, { city: 'Maranhao' }, { city: 'Pacodolumiar' }, { city: 'Saoluis' }],
    Saopaulo: [
      { city: 'Valinhos' },
      { city: 'Vargemgrandepaulista' },
      { city: 'Maranduba' },
      { city: 'Ubatuba' },
      { city: 'Taubate' },
      { city: 'Votorantim' },
      { city: 'Vinhedo' },
      { city: 'Vilagalvao' },
      { city: 'Varzeapaulista' },
      { city: 'Novaeuropa' },
      { city: 'Maua' },
      { city: 'Mogidascruzes' },
      { city: 'Mogiguacu' },
      { city: 'Mongagua' },
      { city: 'Novaodessa' },
      { city: 'Olimpia' },
      { city: 'Osasco' },
      { city: 'Ourinhos' },
      { city: 'Paulinia' },
      { city: 'Pedreira' },
      { city: 'Peruibe' },
      { city: 'Piedade' },
      { city: 'Pindamonhangaba' },
      { city: 'Piracicaba' },
      { city: 'Poa' },
      { city: 'Praiagrande' },
      { city: 'Saoroque' },
      { city: 'Santanadeparnaiba' },
      { city: 'Presidenteprudente' },
      { city: 'Ribeiraopires' },
      { city: 'Ribeiraopreto' },
      { city: 'Rioclaro' },
      { city: 'Riodaspedras' },
      { city: 'Riograndedaserra' },
      { city: 'Salto' },
      { city: 'Saltodepirapora' },
      { city: 'Santabarbaradoeste' },
      { city: 'Santabranca' },
      { city: 'Santaisabel' },
      { city: 'Santoandre' },
      { city: 'Santos' },
      { city: 'Saobernardodocampo' },
      { city: 'Saocaetanodosul' },
      { city: 'Saocarlos' },
      { city: 'Saojoaodaboavista' },
      { city: 'Saojosedoriopreto' },
      { city: 'Saojosedoscampos' },
      { city: 'Saopaulo' },
      { city: 'Indaiatuba' },
      { city: 'Jandira' },
      { city: 'Jacarei' },
      { city: 'Itupeva' },
      { city: 'Itu' },
      { city: 'Itatiba' },
      { city: 'Itaquaquecetuba' },
      { city: 'Itapira' },
      { city: 'Itapevi' },
      { city: 'Itapetininga' },
      { city: 'Itapecericadaserra' },
      { city: 'Itanhaem' },
      { city: 'Itaimbibi' },
      { city: 'Jarinu' },
      { city: 'Ibiuna' },
      { city: 'Hortolandia' },
      { city: 'Guarulhos' },
      { city: 'Guaruja' },
      { city: 'Francodarocha' },
      { city: 'Franciscomorato' },
      { city: 'Franca' },
      { city: 'Ferrazdevasconcelos' },
      { city: 'Embuguacu' },
      { city: 'Embudasartes' },
      { city: 'Duartina' },
      { city: 'Mairipora' },
      { city: 'Marilia' },
      { city: 'Diadema' },
      { city: 'Mairinque' },
      { city: 'Louveira' },
      { city: 'Lorena' },
      { city: 'Limeira' },
      { city: 'Lencoispaulista(jardimmorumbi)' },
      { city: 'Juquitiba' },
      { city: 'Jundiai' },
      { city: 'Josebonifacio' },
      { city: 'Jau' },
      { city: 'Barretos' },
      { city: 'Badybassitt' },
      { city: 'Atibaia' },
      { city: 'Aruja' },
      { city: 'Araras' },
      { city: 'Araraquara' },
      { city: 'Aracoiabadaserra' },
      { city: 'Aracatuba' },
      { city: 'Andradina' },
      { city: 'Amparo' },
      { city: 'Americana' },
      { city: 'Barueri' },
      { city: 'Cajamar' },
      { city: 'Cubatao' },
      { city: 'Cruzeiro' },
      { city: 'Cravinhos' },
      { city: 'Cotia' },
      { city: 'Catanduva' },
      { city: 'Carapicuiba' },
      { city: 'Caraguatatuba' },
      { city: 'Camposdojordao' },
      { city: 'Campolimpopaulista' },
      { city: 'Campinas' },
      { city: 'Cajuru' },
      { city: 'Caieiras' },
      { city: 'Cacapava' },
      { city: 'Cabreuva' },
      { city: 'Bragancapaulista' },
      { city: 'Botucatu' },
      { city: 'Bomjesusdosperdoes' },
      { city: 'Boituva(vilaginasial)' },
      { city: 'Boituva' },
      { city: 'Birigui' },
      { city: 'Bertioga' },
      { city: 'Bauru' },
      { city: 'Saosebastiao' },
      { city: 'Saovicente' },
      { city: 'Sertaozinho' },
      { city: 'Socorro' },
      { city: 'Sorocaba' },
      { city: 'Sumare' },
      { city: 'Sumare(centro)' },
      { city: 'Suzano' },
      { city: 'Taboaodaserra' },
    ],
    Alagoas: [{ city: 'Satuba' }, { city: 'Maceio' }, { city: 'Arapiraca' }, { city: 'Alagoas' }],
    Parana: [
      { city: 'Ibipora' },
      { city: 'Cambara' },
      { city: 'Cambe' },
      { city: 'Campolargo' },
      { city: 'Campomagro' },
      { city: 'Cascavel' },
      { city: 'Castro' },
      { city: 'Colombo' },
      { city: 'Curitiba' },
      { city: 'Curitiba(centrocivico)' },
      { city: 'Fozdoiguacu' },
      { city: 'Guarapuava' },
      { city: 'Guaratuba' },
      { city: 'Araucaria' },
      { city: 'Londrina' },
      { city: 'Maringa' },
      { city: 'Palmeira' },
      { city: 'Parana' },
      { city: 'Paranagua' },
      { city: 'Pinhais' },
      { city: 'Piraquara' },
      { city: 'Pontagrossa' },
      { city: 'Pontaldoparana' },
      { city: 'Quatrobarras' },
      { city: 'Saojosedospinhais' },
      { city: 'Telemacoborba' },
      { city: 'Almirantetamandare' },
      { city: 'Ampere' },
    ],
    Paraiba: [
      { city: 'Bayeux' },
      { city: 'Cajazeiras' },
      { city: 'Campinagrande' },
      { city: 'Guarabira' },
      { city: 'Joaopessoa' },
      { city: 'Paraiba' },
      { city: 'Patos' },
    ],
    Sergipe: [
      { city: 'Itabaiana' },
      { city: 'Tobiasbarreto' },
      { city: 'Sergipe' },
      { city: 'Nossasenhoradosocorro' },
      { city: 'Lagarto' },
      { city: 'Estancia' },
      { city: 'Aracaju' },
    ],
    Para: [
      { city: 'Ananindeua' },
      { city: 'Belem' },
      { city: 'Braganca' },
      { city: 'Castanhal' },
      { city: 'Maraba' },
      { city: 'Para' },
      { city: 'Parauapebas' },
      { city: 'Redencao' },
      { city: 'Santarem' },
      { city: 'Xinguara' },
    ],
    Minasgerais: [
      { city: 'Mateusleme' },
      { city: 'Pirapora' },
      { city: 'Pedroleopoldo' },
      { city: 'Patrocinio' },
      { city: 'Patosdeminas' },
      { city: 'Passos' },
      { city: 'Parademinas' },
      { city: 'Ouropreto' },
      { city: 'Novalima' },
      { city: 'Muriae' },
      { city: 'Montesclaros' },
      { city: 'Montecarmelo' },
      { city: 'Minasgerais' },
      { city: 'Pitangui' },
      { city: 'Mariana' },
      { city: 'Manhuacu' },
      { city: 'Lavras' },
      { city: 'Lagoasanta' },
      { city: 'Juizdefora' },
      { city: 'Itauna' },
      { city: 'Itajuba' },
      { city: 'Itabirito' },
      { city: 'Itabira' },
      { city: 'Ipatinga' },
      { city: 'Igarape' },
      { city: 'Ibirite' },
      { city: 'Trescoracoes' },
      { city: 'Trespontas' },
      { city: 'Uba' },
      { city: 'Uberaba' },
      { city: 'Uberlandia' },
      { city: 'Unai' },
      { city: 'Varginha' },
      { city: 'Varzeadapalma' },
      { city: 'Vicosa' },
      { city: 'Viscondedoriobranco' },
      { city: 'Governadorvaladares' },
      { city: 'Teofilootoni' },
      { city: 'Setelagoas' },
      { city: 'Sarzedo' },
      { city: 'Saojosedalapa' },
      { city: 'Saojoaquimdebicas' },
      { city: 'Saojoaodelrei' },
      { city: 'Santaluzia' },
      { city: 'Ribeiraodasneves' },
      { city: 'Raposos' },
      { city: 'Pousoalegre' },
      { city: 'Pontenova' },
      { city: 'Pocosdecaldas' },
      { city: 'Araxa' },
      { city: 'Araguari' },
      { city: 'Abaete' },
      { city: 'Baraodecocais' },
      { city: 'Congonhas' },
      { city: 'Formiga' },
      { city: 'Extrema' },
      { city: 'Esmeraldas' },
      { city: 'Divinopolis' },
      { city: 'Diamantina' },
      { city: 'Curvelo' },
      { city: 'Coronelfabriciano' },
      { city: 'Corinto' },
      { city: 'Contagem' },
      { city: 'Conselheirolafaiete' },
      { city: 'Caratinga' },
      { city: 'Carangola' },
      { city: 'Campobelo' },
      { city: 'Caete' },
      { city: 'Buenobrandao' },
      { city: 'Brumadinho' },
      { city: 'Bocaiuva' },
      { city: 'Boaesperanca' },
      { city: 'Betim' },
      { city: 'Belohorizonte' },
      { city: 'Barbacena' },
    ],
    Amapa: [{ city: 'Amapa' }, { city: 'Macapa' }],
    Amazonas: [{ city: 'Manaus' }, { city: 'Amazonas' }],
    Matogrosso: [
      { city: 'Matogrosso' },
      { city: 'Varzeagrande' },
      { city: 'Sinop' },
      { city: 'Rondonopolis' },
      { city: 'Cuiaba' },
    ],
    Tocantins: [{ city: 'Tocantins' }, { city: 'Palmas' }, { city: 'Taguatinga' }],
    Matogrossodosul: [
      { city: 'Treslagoas' },
      { city: 'Matogrossodosul' },
      { city: 'Dourados' },
      { city: 'Campogrande' },
      { city: 'Camapua' },
    ],
    Bahia: [
      { city: 'Curaca' },
      { city: 'Cipo' },
      { city: 'Capimgrosso' },
      { city: 'Camacari' },
      { city: 'Barreiras' },
      { city: 'Barradaestiva' },
      { city: 'Bahia' },
      { city: 'Alagoinhas' },
      { city: 'Eunapolis' },
      { city: 'Feiradesantana' },
      { city: 'Laurodefreitas' },
      { city: 'Vitoriadaconquista' },
      { city: 'Teixeiradefreitas' },
      { city: 'Simoesfilho' },
      { city: 'Serrinha' },
      { city: 'Santoantoniodejesus' },
      { city: 'Salvador' },
      { city: 'Ribeiradopombal' },
      { city: 'Portoseguro' },
      { city: 'Matadesaojoao' },
      { city: 'Jequie' },
      { city: 'Itapetinga' },
      { city: 'Irece' },
      { city: 'Itabuna' },
      { city: 'Ilheus' },
      { city: 'Guanambi' },
    ],
    Santacatarina: [
      { city: 'Imbituba' },
      { city: 'Riodosul' },
      { city: 'Portobelo' },
      { city: 'Penha' },
      { city: 'Palhoca' },
      { city: 'Modelo' },
      { city: 'Maravilha' },
      { city: 'Lages' },
      { city: 'Joinville' },
      { city: 'Itapema' },
      { city: 'Itajai' },
      { city: 'Itacorubi' },
      { city: 'Florianopolis' },
      { city: 'Criciuma' },
      { city: 'Chapeco' },
      { city: 'Campinas' },
      { city: 'Camboriu' },
      { city: 'Cacador' },
      { city: 'Brusque' },
      { city: 'Blumenau' },
      { city: 'Balneariocamboriu' },
      { city: 'Ararangua' },
      { city: 'Santacatarina' },
      { city: 'Xanxere' },
      { city: 'Tubarao' },
      { city: 'Timbo' },
      { city: 'Sombrio' },
      { city: 'Saoludgero' },
      { city: 'Saojose' },
      { city: 'Saofranciscodosul' },
      { city: 'Saobentodosul' },
      { city: 'Santoamarodaimperatriz' },
    ],
    Riograndedosul: [
      { city: 'Santocristo' },
      { city: 'Viamao' },
      { city: 'Uruguaiana' },
      { city: 'Tramandai' },
      { city: 'Torres' },
      { city: 'Sapucaiadosul' },
      { city: 'Sapiranga(centro)' },
      { city: 'Sapiranga' },
      { city: 'Saosebastiaodocai' },
      { city: 'Saoleopoldo' },
      { city: 'Santavitoriadopalmar' },
      { city: 'Santanadolivramento' },
      { city: 'Santamaria(nossasenhoradelourdes)' },
      { city: 'Santamaria' },
      { city: 'Santacruzdosul' },
      { city: 'Rolante' },
      { city: 'Riograndedosul' },
      { city: 'Portoalegre' },
      { city: 'Pelotas' },
      { city: 'Passofundo' },
      { city: 'Osorio' },
      { city: 'Lajeado' },
      { city: 'Novohamburgo' },
      { city: 'Cachoeirinha' },
      { city: 'Cachoeiradosul' },
      { city: 'Bentogoncalves' },
      { city: 'Bage' },
      { city: 'Alvorada' },
      { city: 'Alegrete' },
      { city: 'Ibiruba' },
      { city: 'Guaiba' },
      { city: 'Gravatai' },
      { city: 'Esteio' },
      { city: 'Estanciavelha' },
      { city: 'Erechim' },
      { city: 'Caxiasdosul' },
      { city: 'Capaodacanoa' },
      { city: 'Canoas' },
      { city: 'Canela' },
      { city: 'Camaqua' },
    ],
    Rondonia: [{ city: 'Jiparana' }, { city: 'Ariquemes' }, { city: 'Portovelho' }, { city: 'Rondonia' }],
    Roraima: [{ city: 'Roraima' }],
    Pernambuco: [
      { city: 'Cabodesantoagostinho' },
      { city: 'Vitoriadesantoantao' },
      { city: 'Surubim' },
      { city: 'Saolourencodamata' },
      { city: 'Garanhuns' },
      { city: 'Belemdesaofrancisco' },
      { city: 'Caruaru' },
      { city: 'Cupira' },
      { city: 'Jaboatao' },
      { city: 'Jaboataodosguararapes' },
      { city: 'Olinda' },
      { city: 'Paulista' },
      { city: 'Santacruzdocapibaribe' },
      { city: 'Recife' },
      { city: 'Petrolina' },
      { city: 'Pernambuco' },
    ],
    Ceara: [
      { city: 'Maracanau' },
      { city: 'Caucaia' },
      { city: 'Ceara' },
      { city: 'Eusebio' },
      { city: 'Fortaleza' },
      { city: 'Juazeirodonorte' },
      { city: 'Pereiro' },
      { city: 'Sobral' },
    ],
    Riodejaneiro: [
      { city: 'Angradosreis' },
      { city: 'Resende' },
      { city: 'Riodasostras' },
      { city: 'Riodejaneiro' },
      { city: 'Rocinha' },
      { city: 'Novafriburgo' },
      { city: 'Itaborai' },
      { city: 'Itaguai' },
      { city: 'Itaperuna' },
      { city: 'Macae' },
      { city: 'Mangaratiba' },
      { city: 'Marica' },
      { city: 'Nilopolis' },
      { city: 'Niteroi' },
      { city: 'Iguabagrande' },
      { city: 'Guapimirim' },
      { city: 'Duquedecaxias' },
      { city: 'Cordeiro' },
      { city: 'Carmo' },
      { city: 'Camposdosgoytacazes' },
      { city: 'Novaiguacu' },
      { city: 'Petropolis' },
      { city: 'Pinheiral' },
      { city: 'Cabofrio' },
      { city: 'Belfordroxo' },
      { city: 'Queimados' },
      { city: 'Barramansa' },
      { city: 'Barradopirai' },
      { city: 'Barradatijuca' },
      { city: 'Armacaodosbuzios' },
      { city: 'Areal' },
      { city: 'Araruama' },
      { city: 'Valenca' },
      { city: 'Vilasarapui' },
      { city: 'Saojoaodemeriti' },
      { city: 'Voltaredonda' },
      { city: 'Saogoncalo' },
      { city: 'Universidaderural' },
      { city: 'Saojosedovaledoriopreto' },
      { city: 'Saopedro' },
      { city: 'Saopedrodaaldeia' },
      { city: 'Saquarema' },
      { city: 'Tangua' },
      { city: 'Teresopolis' },
      { city: 'Tresrios' },
    ],
    Piaui: [{ city: 'Teresina' }, { city: 'Saoraimundononato' }, { city: 'Picos' }, { city: 'Piaui' }],
    Riograndedonorte: [
      { city: 'Riograndedonorte' },
      { city: 'Curraisnovos' },
      { city: 'Extremoz' },
      { city: 'Mossoro' },
      { city: 'Natal' },
      { city: 'Parnamirim' },
    ],
  },
  BS: {
    Newprovidence: [
      { city: 'Newprovidence' },
      { city: 'Killarney' },
      { city: 'Nassau' },
      { city: 'Nassau(greaterchippingham)' },
    ],
    Freeport: [{ city: 'Freeport' }, { city: 'Lucaya' }],
  },
  BW: { Gaborone: [{ city: 'Gaborone' }] },
  BY: {
    Minskcity: [{ city: 'Minskcity' }, { city: 'Minsk(centranyrajon)' }],
    Mogilev: [{ city: 'Mogilev' }],
    Minsk: [{ city: 'Minsk' }],
    Brest: [{ city: 'Brest' }],
    Grodnenskaya: [{ city: 'Hrodna' }, { city: 'Lida' }, { city: 'Grodnenskaya' }],
    Homyelvoblasc: [{ city: 'Homyelvoblasc' }],
  },
  BZ: {
    Belizedistrict: [{ city: 'Belizecity' }, { city: 'Belizedistrict' }],
    Cayodistrict: [{ city: 'Cayodistrict' }, { city: 'Belmopan' }],
    Orangewalkdistrict: [{ city: 'Orangewalkdistrict' }],
  },
  CA: {
    Alberta: [
      { city: 'Calgary(northeastcalgary)' },
      { city: 'Calgary(southwestcalgary)' },
      { city: 'Calgary(downtown)' },
      { city: 'Calgary' },
      { city: 'Alberta' },
      { city: 'Reddeer' },
      { city: 'Edmonton(millwoodstowncentre)' },
      { city: 'Edmonton(kildare)' },
      { city: 'Edmonton' },
    ],
    Ontario: [
      { city: 'Toronto' },
      { city: 'Windsor(fontainbleu)' },
      { city: 'Woodstock' },
      { city: 'Windsor(southcentral)' },
      { city: 'Willowdale' },
      { city: 'Hamilton' },
      { city: 'Brantford' },
      { city: 'Burlington' },
      { city: 'Cambridge' },
      { city: 'Concord' },
      { city: 'Cornwall' },
      { city: 'Etobicoke' },
      { city: 'Greatersudbury' },
      { city: 'Greatersudbury(sudbury)' },
      { city: 'Grimsby' },
      { city: 'Guelph' },
      { city: 'Brampton' },
      { city: 'Welland' },
      { city: 'Ajax' },
      { city: 'Barrie' },
      { city: 'Toronto(scarborough)' },
      { city: 'Petawawa' },
      { city: 'Richmondhill' },
      { city: 'Saultstemarie' },
      { city: 'Stcatharines' },
      { city: 'Thornhill' },
      { city: 'Thunderbay' },
      { city: 'Toronto(etobicoke)' },
      { city: 'Toronto(northyork)' },
      { city: 'Toronto(oldtoronto)' },
      { city: 'Kingston' },
      { city: 'Vaughan' },
      { city: 'Vaughan(concord)' },
      { city: 'Vaughan(woodbridge)' },
      { city: 'Waterloo' },
      { city: 'Whitby' },
      { city: 'Oakville' },
      { city: 'London(centrallondon)' },
      { city: 'London' },
      { city: 'Markham' },
      { city: 'Millbrook' },
      { city: 'Milton' },
      { city: 'Mississauga' },
      { city: 'Newmarket' },
      { city: 'Niagarafalls' },
      { city: 'Northyork' },
      { city: 'Ontario' },
      { city: 'Orangeville' },
      { city: 'Oshawa' },
      { city: 'Oshawa(oneill)' },
      { city: 'Ottawa' },
      { city: 'Leamington' },
      { city: 'Pelham' },
      { city: 'Kitchener' },
    ],
    Manitoba: [{ city: 'Manitoba' }, { city: 'Winnipeg' }, { city: 'Brandon' }],
    Quebec: [
      { city: 'Dorval' },
      { city: 'Beauharnois' },
      { city: 'Montreal' },
      { city: 'Montreal(rosemontlapetitepatrie)' },
      { city: 'Montreal(verdun)' },
      { city: 'Montreal(villemarie)' },
      { city: 'Quebec' },
      { city: 'Saintmichel' },
    ],
    Princeedwardisland: [{ city: 'Princeedwardisland' }, { city: 'Charlottetown' }],
    Britishcolumbia: [
      { city: 'Whiterock' },
      { city: 'Victoria' },
      { city: 'Vancouver(eastvancouver)' },
      { city: 'Vancouver(centralvancouver)' },
      { city: 'Vancouver' },
      { city: 'Surrey' },
      { city: 'Kelowna' },
      { city: 'Coquitlam' },
      { city: 'Britishcolumbia' },
    ],
    Saskatchewan: [{ city: 'Regina(downtown)' }, { city: 'Saskatoon' }, { city: 'Saskatchewan' }, { city: 'Regina' }],
    Newbrunswick: [{ city: 'Fredericton' }, { city: 'Moncton' }, { city: 'Newbrunswick' }, { city: 'Stjohn' }],
    Newfoundlandandlabrador: [
      { city: 'Cornerbrook' },
      { city: 'Mountpearl' },
      { city: 'Newfoundlandandlabrador' },
      { city: 'Stjohns' },
    ],
    Novascotia: [
      { city: 'Dartmouth' },
      { city: 'Halifax' },
      { city: 'Halifax(claytonparkwest)' },
      { city: 'Halifax(northend)' },
      { city: 'Novascotia' },
      { city: 'Sydney' },
    ],
  },
  CD: { Kinshasa: [{ city: 'Kinshasa' }] },
  CG: { Pointenoire: [{ city: 'Pointenoire' }], Brazzaville: [{ city: 'Brazzaville' }] },
  CH: {
    Vaud: [{ city: 'Vaud' }, { city: 'Renens' }],
    Zurich: [{ city: 'Opfikon' }, { city: 'Zurich' }],
    Bern: [{ city: 'Bern' }],
  },
  CI: {
    Abidjan: [{ city: 'Abidjan' }, { city: 'Abidjan(leplateau)' }],
    Abidjanautonomousdistrict: [{ city: 'Abidjanautonomousdistrict' }, { city: 'Marcory' }],
    Lacsdistrict: [{ city: 'Yamoussoukro' }, { city: 'Lacsdistrict' }],
  },
  CL: {
    Santiagometropolitan: [
      { city: 'Huechuraba' },
      { city: 'Lapintana' },
      { city: 'Lascondes' },
      { city: 'Loprado' },
      { city: 'Providencia' },
      { city: 'Puentealto' },
      { city: 'Santiagometropolitan' },
    ],
    Biobio: [{ city: 'Concepcion' }, { city: 'Biobio' }],
    Valparaiso: [{ city: 'Valparaiso' }],
    Ohigginsregion: [{ city: 'Ohigginsregion' }],
    Mauleregion: [{ city: 'Mauleregion' }],
  },
  CM: { Littoral: [{ city: 'Littoral' }] },
  CN: {
    Hainan: [{ city: 'Hainan' }],
    Fujian: [{ city: 'Zhangzhou(neimeng)' }, { city: 'Fujian' }],
    Hebei: [{ city: 'Hebei' }],
    Beijing: [{ city: 'Jinrongjie(xichengdistrict)' }, { city: 'Beijing' }],
    Guangdong: [{ city: 'Guangzhou' }, { city: 'Guangdong' }],
    Innermongolia: [{ city: 'Innermongolia' }],
    Jiangsu: [{ city: 'Jiangsu' }],
    Zhejiang: [{ city: 'Zhejiang' }],
    Sichuan: [{ city: 'Muping' }, { city: 'Sichuan' }],
    Gansu: [{ city: 'Gansu' }, { city: 'Zhangyelu' }],
    Chongqing: [{ city: 'Chongqing' }],
    Xinjiang: [{ city: 'Xinjiang' }, { city: 'Xingfulu' }],
  },
  CO: {
    Bogotadc: [
      { city: 'Bogota(fontibon)' },
      { city: 'Bogota(engativa)' },
      { city: 'Bogota(lacandelaria)' },
      { city: 'Bogota(localidaddechapinero)' },
      { city: 'Bogota(suba)' },
      { city: 'Bogotadc' },
      { city: 'Bogota(comunachapinero)' },
    ],
    Bolivar: [{ city: 'Bolivar' }, { city: 'Cartagena' }],
    Valledelcaucadepartment: [{ city: 'Santiagodecali' }, { city: 'Valledelcaucadepartment' }],
    Atlantico: [{ city: 'Atlantico' }],
    Narino: [{ city: 'Narino' }],
    Risaraldadepartment: [{ city: 'Pereira' }, { city: 'Risaraldadepartment' }],
    Huiladepartment: [{ city: 'Pitalito' }, { city: 'Neiva' }, { city: 'Huiladepartment' }],
    Santanderdepartment: [{ city: 'Santanderdepartment' }],
    Tolimadepartment: [{ city: 'Tolimadepartment' }, { city: 'Fresno' }],
    Antioquia: [
      { city: 'Medellin(elpoblado)' },
      { city: 'Antioquia' },
      { city: 'Medellin' },
      { city: 'Envigado(alcala)' },
    ],
    Putumayodepartment: [{ city: 'Putumayodepartment' }],
    Magdalenadepartment: [{ city: 'Magdalenadepartment' }],
    Cundinamarca: [{ city: 'Soacha' }, { city: 'Cundinamarca' }],
  },
  CR: {
    Alajuelaprovince: [{ city: 'Alajuelaprovince' }],
    Sanjose: [{ city: 'Sanjose' }],
    Herediaprovince: [{ city: 'Herediaprovince' }],
    Cartagoprovince: [{ city: 'Cartagoprovince' }],
  },
  CW: { Curacao: [{ city: 'Curacao' }, { city: 'Willemstad' }] },
  CY: {
    Lemesos: [{ city: 'Limassol' }, { city: 'Lemesos' }],
    Nicosia: [{ city: 'Nicosia' }, { city: 'Nicosia(citycentre)' }],
    Pafos: [{ city: 'Pafos' }],
    Larnaka: [{ city: 'Larnaka' }],
    Ammochostos: [{ city: 'Ammochostos' }],
  },
  CZ: {
    Prague: [{ city: 'Prague' }],
    Hlavnimestopraha: [{ city: 'Prague' }, { city: 'Hlavnimestopraha' }],
  },
  DE: {
    Lowersaxony: [{ city: 'Hanover' }, { city: 'Lowersaxony' }],
    Saarland: [{ city: 'Saarland' }],
    Hamburg: [{ city: 'Hamburg' }],
    Schleswigholstein: [
      { city: 'Flensburg' },
      { city: 'Kiel' },
      { city: 'Schleswigholstein' },
      { city: 'Norderstedt' },
    ],
    Rheinlandpfalz: [{ city: 'Rheinlandpfalz' }],
    Landberlin: [{ city: 'Berlin' }],
    Thuringia: [{ city: 'Thuringia' }],
    Hesse: [
      { city: 'Eschborn' },
      { city: 'Frankfurtammain' },
      { city: 'Frankfurtammain(frankfurtammainwest)' },
      { city: 'Frankfurtammain(innenstadti)' },
      { city: 'Hesse' },
      { city: 'Sulzbach' },
    ],
    Bavaria: [
      { city: 'Passau' },
      { city: 'Bavaria' },
      { city: 'Deggendorf' },
      { city: 'Munich' },
      { city: 'Nuremberg' },
      { city: 'Osterhofen' },
      { city: 'Plattling' },
    ],
    Badenwurttemberg: [
      { city: 'Stuttgart' },
      { city: 'Sinsheim' },
      { city: 'Heiligkreuzsteinach' },
      { city: 'Badenwurttemberg' },
    ],
    Brandenburg: [{ city: 'Brandenburg' }],
    Berlin: [{ city: 'Berlin' }, { city: 'Berlin(bezirkmitte)' }, { city: 'Berlin(bezirktempelhofschoneberg)' }],
    Saxony: [{ city: 'Saxony' }],
    Northrhinewestphalia: [
      { city: 'Northrhinewestphalia' },
      { city: 'Dusseldorf(stadtbezirk06)' },
      { city: 'Dusseldorf' },
      { city: 'Dortmund' },
      { city: 'Cologne(lindenthal,cologne)' },
      { city: 'Bielefeld' },
      { city: 'Cologne(ehrenfeld,cologne)' },
      { city: 'Dusseldorf(stadtbezirk4)' },
      { city: 'Essen' },
      { city: 'Cologne' },
      { city: 'Munster' },
    ],
    Saxonyanhalt: [{ city: 'Saxonyanhalt' }],
  },
  DK: {
    Centraljutland: [{ city: 'Centraljutland' }],
    Capitalregion: [{ city: 'Copenhagen' }, { city: 'Capitalregion' }],
  },
  DM: { Saintgeorgeparish: [{ city: 'Roseau' }, { city: 'Saintgeorgeparish' }] },
  DO: {
    Santiagoprovince: [{ city: 'Santiagoprovince' }, { city: 'Santiagodeloscaballeros' }, { city: 'Villabisono' }],
    Santodomingoprovince: [
      { city: 'Santodomingoprovince' },
      { city: 'Santodomingoeste' },
      { city: 'Santodomingooeste' },
    ],
    Laaltagraciaprovince: [{ city: 'Laaltagraciaprovince' }, { city: 'Puntacana' }],
    Nacional: [{ city: 'Santodomingo(bellavista)' }, { city: 'Santodomingo' }, { city: 'Nacional' }],
    Samana: [{ city: 'Samana' }],
  },
  DZ: {
    Oran: [{ city: 'Oran' }],
    Batna: [{ city: 'Batna' }, { city: 'Batnacity' }],
    Chlef: [{ city: 'Chlef' }],
    Tipaza: [{ city: 'Cheraga' }, { city: 'Tipaza' }],
    Constantine: [{ city: 'Constantine' }],
    Tlemcen: [{ city: 'Tlemcen' }],
    Algiers: [
      { city: 'Sidimhamed(algiers)' },
      { city: 'Sidimhamed' },
      { city: 'Kouba' },
      { city: 'Benaknoun' },
      { city: 'Algiers' },
    ],
    Blida: [{ city: 'Blida' }],
    Setif: [{ city: 'Setif' }],
    Annaba: [{ city: 'Annaba' }],
  },
  EC: {
    Imbabura: [{ city: 'Imbabura' }],
    Guayas: [{ city: 'Guayaquil' }, { city: 'Guayas' }, { city: 'Loslojas(enriquebaquerizomoreno)' }],
    Azuay: [{ city: 'Cuenca' }, { city: 'Azuay' }],
    Eloro: [{ city: 'Eloro' }],
    Santodomingodelostsachilas: [{ city: 'Santodomingodeloscolorados' }, { city: 'Santodomingodelostsachilas' }],
    Manabi: [{ city: 'Manabi' }],
    Pichincha: [{ city: 'Quito' }, { city: 'Pichincha' }],
  },
  EE: { Harjumaa: [{ city: 'Harjumaa' }] },
  EG: { Giza: [{ city: 'Ashshaykhzayid' }, { city: 'Giza' }], Cairo: [{ city: 'Cairo' }] },
  ES: {
    Madrid: [
      { city: 'Pozuelodealarcon' },
      { city: 'Chamartin' },
      { city: 'Ciudadlineal' },
      { city: 'Getafe' },
      { city: 'Madrid' },
      { city: 'Madrid(fuencarralelpardo)' },
      { city: 'Alcobendas' },
    ],
    Asturias: [{ city: 'Asturias' }],
    Cantabria: [{ city: 'Cantabria' }],
    Extremadura: [{ city: 'Extremadura' }],
    Aragon: [{ city: 'Zaragoza' }, { city: 'Aragon' }],
    Andalusia: [
      { city: 'Albox' },
      { city: 'Malaga' },
      { city: 'Seville' },
      { city: 'Sanpedro' },
      { city: 'Arboleas' },
      { city: 'Andalusia' },
    ],
    Catalonia: [
      { city: 'Lametllademar' },
      { city: 'Santmarti' },
      { city: 'Torredembarra' },
      { city: 'Barcelona' },
      { city: 'Catalonia' },
    ],
    Castillelamancha: [{ city: 'Castillelamancha' }, { city: 'Albacete' }],
    Castilleandleon: [{ city: 'Castilleandleon' }],
    Larioja: [{ city: 'Larioja' }],
    Galicia: [{ city: 'Vigo' }, { city: 'Galicia' }],
    Balearicislands: [{ city: 'Balearicislands' }],
    Basquecountry: [{ city: 'Basquecountry' }, { city: 'Bilbao' }],
    Canaryislands: [{ city: 'Canaryislands' }],
    Valencia: [
      { city: 'Valencia' },
      { city: 'Alicante' },
      { city: 'Benidorm' },
      { city: 'Castellodelaplana' },
      { city: 'Catral' },
      { city: 'Elche' },
      { city: 'Vinaros' },
      { city: 'Torrevieja' },
      { city: 'Crevillent' },
      { city: 'Guardamardelsegura' },
    ],
    Murcia: [{ city: 'Mazarron' }, { city: 'Murcia' }],
    Navarre: [{ city: 'Navarre' }],
  },
  ET: { Oromiya: [{ city: 'Oromiya' }, { city: 'Burayu' }], Addisababa: [{ city: 'Addisababa' }] },
  FI: { Uusimaa: [{ city: 'Uusimaa' }, { city: 'Helsinki' }] },
  FJ: { Central: [{ city: 'Central' }, { city: 'Suva' }], Western: [{ city: 'Western' }] },
  FR: {
    Nouvelleaquitaine: [{ city: 'Nouvelleaquitaine' }, { city: 'Poitiers' }],
    Grandest: [{ city: 'Strasbourg' }, { city: 'Grandest' }],
    Provence: [{ city: 'Nice' }, { city: 'Provence' }, { city: 'Marseille' }],
    Paysdelaloire: [{ city: 'Paysdelaloire' }, { city: 'Nantes' }],
    Centrevaldeloire: [{ city: 'Centrevaldeloire' }],
    Auvergnerhonealpes: [{ city: 'Auvergnerhonealpes' }, { city: 'Lyon' }],
    Bourgognefranchecomte: [{ city: 'Bourgognefranchecomte' }],
    Brittany: [{ city: 'Brittany' }],
    Iledefrance: [
      { city: 'Nanterre' },
      { city: 'Aubervilliers' },
      { city: 'Bobigny' },
      { city: 'Iledefrance' },
      { city: 'Paris' },
      { city: 'Puteaux' },
      { city: 'Saintdenis' },
    ],
    Hautsdefrance: [{ city: 'Lille' }, { city: 'Hautsdefrance' }, { city: 'Gravelines' }],
    Normandy: [{ city: 'Normandy' }],
    Occitanie: [{ city: 'Occitanie' }],
  },
  GA: { Estuaire: [{ city: 'Estuaire' }, { city: 'Libreville' }] },
  UK: {
    England: [
      { city: 'Stockport' },
      { city: 'London' },
      { city: 'Cottingham' },
      { city: 'Consett' },
      { city: 'Welwyngardencity' },
      { city: 'Westhoughton' },
      { city: 'Westham' },
      { city: 'Westealing' },
      { city: 'Birmingham(smallheath)' },
      { city: 'Birmingham(witton)' },
      { city: 'Westderby' },
      { city: 'Birstall' },
      { city: 'Westbromwich' },
      { city: 'Westbridgford' },
      { city: 'Wembley' },
      { city: 'Westminster' },
      { city: 'Wellington' },
      { city: 'Wellingborough' },
      { city: 'Welling' },
      { city: 'Wednesfield' },
      { city: 'Wednesbury' },
      { city: 'Wawne' },
      { city: 'Watton' },
      { city: 'Wathupondearne' },
      { city: 'Watford' },
      { city: 'Beverley' },
      { city: 'Wigan' },
      { city: 'Widnes' },
      { city: 'Wickford' },
      { city: 'Whitworth' },
      { city: 'Whitleybay' },
      { city: 'Whitehaven' },
      { city: 'Whitchurch' },
      { city: 'Westwickham' },
      { city: 'Benwell' },
      { city: 'Waterorton' },
      { city: 'Bexleyheath' },
      { city: 'Biddulph' },
      { city: 'Billericay' },
      { city: 'Billingham' },
      { city: 'Bilston' },
      { city: 'Birkenhead' },
      { city: 'Birkenhead(bromborough)' },
      { city: 'Birmingham' },
      { city: 'Birmingham(kingsnorton)' },
      { city: 'Bradwell' },
      { city: 'Burtonontrent' },
      { city: 'Bognorregis' },
      { city: 'Bolton' },
      { city: 'Borrowash' },
      { city: 'Botley' },
      { city: 'Bottesford' },
      { city: 'Burnley' },
      { city: 'Bournemouth' },
      { city: 'Bracknell' },
      { city: 'Bradford' },
      { city: 'Bury' },
      { city: 'Bramley' },
      { city: 'Brandon' },
      { city: 'Braunstone' },
      { city: 'Brentwood' },
      { city: 'Bridgwater' },
      { city: 'Bridlington' },
      { city: 'Brierleyhill' },
      { city: 'Brightlingsea' },
      { city: 'Burngreave' },
      { city: 'Blackpool' },
      { city: 'Catcliffe' },
      { city: 'Castlevale' },
      { city: 'Castleford' },
      { city: 'Carshalton' },
      { city: 'Carlisle' },
      { city: 'Bishopsstortford' },
      { city: 'Canterbury' },
      { city: 'Cannock' },
      { city: 'Blackburn' },
      { city: 'Willenhall' },
      { city: 'Canningtown' },
      { city: 'Cambridge' },
      { city: 'Camberwell' },
      { city: 'Blaydon' },
      { city: 'Bloxwich' },
      { city: 'Blyth' },
      { city: 'Camberley' },
      { city: 'Caddington' },
      { city: 'Buxton' },
      { city: 'Newcastleupontyne(benwell)' },
      { city: 'Beckenham' },
      { city: 'Bedford' },
      { city: 'Belgrave' },
      { city: 'Belper' },
      { city: 'Belsizepark' },
      { city: 'Bentley' },
      { city: 'Newburn' },
      { city: 'Newbury' },
      { city: 'Newcastleunderlyme' },
      { city: 'Newcastleupontyne' },
      { city: 'Batley' },
      { city: 'Newmalden' },
      { city: 'Newmilton' },
      { city: 'Newtonabbot' },
      { city: 'Newtonaycliffe' },
      { city: 'Normanton' },
      { city: 'Northampton' },
      { city: 'Northolt' },
      { city: 'Northshields' },
      { city: 'Northwootton' },
      { city: 'Balham' },
      { city: 'Moreton' },
      { city: 'Morley' },
      { city: 'Mossley' },
      { city: 'Mossleyhill' },
      { city: 'Murton' },
      { city: 'Nelson' },
      { city: 'Newarkontrent' },
      { city: 'Newbasford' },
      { city: 'Backworth' },
      { city: 'Nortonhawkfield' },
      { city: 'Banbury' },
      { city: 'Barnsley' },
      { city: 'Barrowinfurness' },
      { city: 'Bartleygreen' },
      { city: 'Basford' },
      { city: 'Basford,stokeontrent' },
      { city: 'Basildon' },
      { city: 'Basingstoke' },
      { city: 'Bath' },
      { city: 'Woodplumpton' },
      { city: 'Yeovil' },
      { city: 'Yeadon' },
      { city: 'Yaxley' },
      { city: 'Wythenshawe(baguley)' },
      { city: 'Worthing' },
      { city: 'Worsley(wardley)' },
      { city: 'Worksop' },
      { city: 'Worcesterpark' },
      { city: 'Worcester' },
      { city: 'Woolton' },
      { city: 'York' },
      { city: 'Wombourne' },
      { city: 'Wolverhampton' },
      { city: 'Woking' },
      { city: 'Wisbech' },
      { city: 'Wingerworth' },
      { city: 'Winchester' },
      { city: 'Wimbledonpark' },
      { city: 'Wilmslow' },
      { city: 'Willesden' },
      { city: 'Chorley' },
      { city: 'Norwich' },
      { city: 'Nottingham' },
      { city: 'Nottingham(mapperley)' },
      { city: 'Nottingham(radford)' },
      { city: 'Nuneaton' },
      { city: 'Nunthorpe' },
      { city: 'Oadby' },
      { city: 'Oakengates' },
      { city: 'Oldbury' },
      { city: 'Burghbysands' },
      { city: 'Christchurch' },
      { city: 'Clactononsea' },
      { city: 'Clayton' },
      { city: 'Claytonlewoods' },
      { city: 'Clerkenwell' },
      { city: 'Clevedon' },
      { city: 'Clubmoor' },
      { city: 'Codsall' },
      { city: 'Yoxall' },
      { city: 'Thornaby' },
      { city: 'Tonbridge' },
      { city: 'Tolworth' },
      { city: 'Titchfield' },
      { city: 'Tipton' },
      { city: 'Timperley' },
      { city: 'Tilehurst' },
      { city: 'Tilbury' },
      { city: 'Tidburygreen' },
      { city: 'Thorntonheath' },
      { city: 'Thorntoncleveleys' },
      { city: 'Torpoint' },
      { city: 'Theboldons' },
      { city: 'Thamesditton' },
      { city: 'Tewkesbury' },
      { city: 'Telford' },
      { city: 'Bristol' },
      { city: 'Taunton' },
      { city: 'Tamworth' },
      { city: 'Syston' },
      { city: 'Swinton' },
      { city: 'Shiremoor' },
      { city: 'Scholes' },
      { city: 'Scunthorpe' },
      { city: 'Shadwell' },
      { city: 'Shaw' },
      { city: 'Sheffield' },
      { city: 'Shepherdsbush' },
      { city: 'Shepshed' },
      { city: 'Shevington' },
      { city: 'Shipley' },
      { city: 'Swindon(cheneymanorindustrialestate)' },
      { city: 'Shirley' },
      { city: 'Shrewsbury' },
      { city: 'Simonstone' },
      { city: 'Sinfin' },
      { city: 'Skelmersdale' },
      { city: 'Slough' },
      { city: 'Smethwick' },
      { city: 'Snaresbrook' },
      { city: 'Solihull' },
      { city: 'Stone' },
      { city: 'Stapleford' },
      { city: 'Staustell' },
      { city: 'Staverton' },
      { city: 'Stepney' },
      { city: 'Stevenage' },
      { city: 'Sthelens' },
      { city: 'Stockport' },
      { city: 'Stocktonontees' },
      { city: 'Stokegifford' },
      { city: 'Stokeontrent' },
      { city: 'Stanley' },
      { city: 'Stourbridge' },
      { city: 'Stratforduponavon' },
      { city: 'Streatham' },
      { city: 'Streatley' },
      { city: 'Streetly' },
      { city: 'Stretford' },
      { city: 'Stretford(oldtrafford)' },
      { city: 'Stretford(traffordpark)' },
      { city: 'Sunderland' },
      { city: 'Southendonsea' },
      { city: 'Swindon' },
      { city: 'Swadlincote' },
      { city: 'Suttoninashfield' },
      { city: 'Suttoncoldfield' },
      { city: 'Sutton' },
      { city: 'Southall' },
      { city: 'Southam' },
      { city: 'Southampton' },
      { city: 'Southbank' },
      { city: 'Scarborough' },
      { city: 'Southgate' },
      { city: 'Southport' },
      { city: 'Southshields' },
      { city: 'Sowerbybridge' },
      { city: 'Spalding(fulney)' },
      { city: 'Speke' },
      { city: 'Spennymoor' },
      { city: 'Stafford' },
      { city: 'Stalybridge' },
      { city: 'Orgreave' },
      { city: 'Wallington' },
      { city: 'Wallasey' },
      { city: 'Wakefield' },
      { city: 'Brighton' },
      { city: 'Ushawmoor' },
      { city: 'Upminster' },
      { city: 'Tyldesley' },
      { city: 'Tunstall' },
      { city: 'Trowbridge' },
      { city: 'Tottenham' },
      { city: 'Wallsend' },
      { city: 'Ormskirk' },
      { city: 'Orpington' },
      { city: 'Oxford' },
      { city: 'Paignton' },
      { city: 'Palmersgreen' },
      { city: 'Parkstone' },
      { city: 'Paull' },
      { city: 'Pelsall' },
      { city: 'Pelton' },
      { city: 'Oldham' },
      { city: 'Brownhills' },
      { city: 'Bromsgrove' },
      { city: 'Bromley' },
      { city: 'Brockley' },
      { city: 'Broadstone' },
      { city: 'Broadblunsdon' },
      { city: 'Brixtonhill' },
      { city: 'Washington' },
      { city: 'Bristol(staplehill)' },
      { city: 'Peterborough' },
      { city: 'Oldswan' },
      { city: 'Warwick' },
      { city: 'Warrington' },
      { city: 'Ware' },
      { city: 'Wanstead' },
      { city: 'Wandsworth' },
      { city: 'Waltononthames' },
      { city: 'Walthamstow' },
      { city: 'Walsall' },
      { city: 'Ruislip' },
      { city: 'Ringway' },
      { city: 'Rochdale' },
      { city: 'Romford' },
      { city: 'Rotherham' },
      { city: 'Rowleyregis' },
      { city: 'Royalleamingtonspa' },
      { city: 'Royaltunbridgewells' },
      { city: 'Royston' },
      { city: 'Royton' },
      { city: 'Rugby' },
      { city: 'Redditch' },
      { city: 'Runcorn' },
      { city: 'Ryde' },
      { city: 'Ryhope' },
      { city: 'Saintandrewsquay' },
      { city: 'Saintleonardsonsea' },
      { city: 'Saintneots' },
      { city: 'Sale' },
      { city: 'Salford' },
      { city: 'Sandbach' },
      { city: 'Potterheigham' },
      { city: 'Pinner' },
      { city: 'Plymouth' },
      { city: 'Plympton' },
      { city: 'Plymstock' },
      { city: 'Polesworth' },
      { city: 'Poole' },
      { city: 'Poplar' },
      { city: 'Portsmouth' },
      { city: 'Portsmouth(cosham)' },
      { city: 'Morden' },
      { city: 'Prenton' },
      { city: 'Preston' },
      { city: 'Prestwich' },
      { city: 'Pudsey' },
      { city: 'Rainham' },
      { city: 'Ramsgate' },
      { city: 'Rastrick' },
      { city: 'Rawtenstall' },
      { city: 'Reading' },
      { city: 'Greatyarmouth' },
      { city: 'Gosforth' },
      { city: 'Gosport' },
      { city: 'Grangehill' },
      { city: 'Grantham' },
      { city: 'Gravesend' },
      { city: 'Grays' },
      { city: 'Greatham' },
      { city: 'Greatmarton' },
      { city: 'Goringbysea' },
      { city: 'Grimsby' },
      { city: 'Groby' },
      { city: 'Guildford' },
      { city: 'Hackney' },
      { city: 'Hadley' },
      { city: 'Halebarns' },
      { city: 'Halesowen' },
      { city: 'Georgegreen' },
      { city: 'Friernbarnet' },
      { city: 'Fulford' },
      { city: 'Fulham' },
      { city: 'Fulwood' },
      { city: 'Garston' },
      { city: 'Garswood' },
      { city: 'Gateshead' },
      { city: 'Gedling' },
      { city: 'Halifax' },
      { city: 'Gildersome' },
      { city: 'Gillingham' },
      { city: 'Glenfield' },
      { city: 'Gloucester' },
      { city: 'Golborne' },
      { city: 'Goodmayes' },
      { city: 'Goosnargh' },
      { city: 'Chelmsford' },
      { city: 'Hendon' },
      { city: 'Hersham' },
      { city: 'Hertford' },
      { city: 'Cheadlehulme' },
      { city: 'Chatham' },
      { city: 'Chapeltown' },
      { city: 'Chapelallerton' },
      { city: 'Chalton' },
      { city: 'Hemelhempstead' },
      { city: 'Chelmsleywood' },
      { city: 'Cheltenham' },
      { city: 'Cheshunt' },
      { city: 'Chester' },
      { city: 'Chesterfield' },
      { city: 'Heslington' },
      { city: 'Hessle' },
      { city: 'Hatfield' },
      { city: 'Halton' },
      { city: 'Hammersmith' },
      { city: 'Hardingstone' },
      { city: 'Harlesden' },
      { city: 'Harlow' },
      { city: 'Harringay' },
      { city: 'Harrogate' },
      { city: 'Hartlepool' },
      { city: 'Findern' },
      { city: 'Havant' },
      { city: 'Hayes' },
      { city: 'Haywardsheath' },
      { city: 'Hazelgrove' },
      { city: 'Hazlerigg' },
      { city: 'Hebburn' },
      { city: 'Heckmondwike' },
      { city: 'Dersingham' },
      { city: 'Dagenham' },
      { city: 'Darlaston' },
      { city: 'Darlington' },
      { city: 'Dartford' },
      { city: 'Darwen' },
      { city: 'Denton' },
      { city: 'Derby' },
      { city: 'Dereham' },
      { city: 'Cudworth' },
      { city: 'Dewsbury' },
      { city: 'Deysbrook' },
      { city: 'Dickensheath' },
      { city: 'Didsbury' },
      { city: 'Dingle' },
      { city: 'Doncaster' },
      { city: 'Drighlington' },
      { city: 'Crawley' },
      { city: 'Colchester' },
      { city: 'Coppull' },
      { city: 'Cosham' },
      { city: 'Coventry' },
      { city: 'Cowes' },
      { city: 'Cowley' },
      { city: 'Cowpen' },
      { city: 'Cramlington' },
      { city: 'Dronfield' },
      { city: 'Credenhill' },
      { city: 'Crewe' },
      { city: 'Cricklewood' },
      { city: 'Crosby' },
      { city: 'Crowthorne' },
      { city: 'Croydon' },
      { city: 'Crystalpalace' },
      { city: 'Exhall' },
      { city: 'England' },
      { city: 'Epsom' },
      { city: 'Erith' },
      { city: 'Essington' },
      { city: 'Eston' },
      { city: 'Etonwick' },
      { city: 'Euxton' },
      { city: 'Exeter' },
      { city: 'Enfield' },
      { city: 'Exmouth' },
      { city: 'Failsworth' },
      { city: 'Farnborough' },
      { city: 'Fazakerley' },
      { city: 'Felling' },
      { city: 'Feltham' },
      { city: 'Finchley' },
      { city: 'Eastbourne' },
      { city: 'Droylsden' },
      { city: 'Dudley' },
      { city: 'Dukinfield' },
      { city: 'Dunstable' },
      { city: 'Dunswell' },
      { city: 'Durham' },
      { city: 'Earlsfield' },
      { city: 'Eastboldon' },
      { city: 'Mitcham' },
      { city: 'Eastham' },
      { city: 'Eastleigh' },
      { city: 'Edgware' },
      { city: 'Elland' },
      { city: 'Ellesmereport' },
      { city: 'Elton' },
      { city: 'Ely' },
      { city: 'Knebworth' },
      { city: 'Acton' },
      { city: 'Aldershot' },
      { city: 'Aldridge' },
      { city: 'Alfreton' },
      { city: 'Kirkby' },
      { city: 'Kirkbyinashfield' },
      { city: 'Kirkleatham' },
      { city: 'Kislingbury' },
      { city: 'Kingswood' },
      { city: 'Knowsley' },
      { city: 'Lamesley' },
      { city: 'Lancaster' },
      { city: 'Lancing' },
      { city: 'Langleypark' },
      { city: 'Lee' },
      { city: 'Leeds' },
      { city: 'Leeds(seacroft)' },
      { city: 'London(finchley)' },
      { city: 'London(holborn)' },
      { city: 'London(nottinghill)' },
      { city: 'London(paddington)' },
      { city: 'London(royalkensingtonandchelsea)' },
      { city: 'London(shadwell)' },
      { city: 'London(southbank)' },
      { city: 'London(walthamstow)' },
      { city: 'Longeaton' },
      { city: 'Kingswinford' },
      { city: 'Kingstonuponthames' },
      { city: 'Kingslynn' },
      { city: 'Kimberley' },
      { city: 'Kidderminster' },
      { city: 'Kettering' },
      { city: 'Aylestone' },
      { city: 'Litherland' },
      { city: 'Arnold' },
      { city: 'Ashford' },
      { city: 'Ashington' },
      { city: 'Ashtoninmakerfield' },
      { city: 'Ashtonunderlyne' },
      { city: 'Auckley' },
      { city: 'Aylesbury' },
      { city: 'Linthwaite' },
      { city: 'Littlehulton' },
      { city: 'Littlelever' },
      { city: 'Liverpool' },
      { city: 'Lofthouse' },
      { city: 'London' },
      { city: 'London(colindale)' },
      { city: 'London(dalston)' },
      { city: 'Kessingland' },
      { city: 'Lincoln' },
      { city: 'Lichfield' },
      { city: 'Leyton' },
      { city: 'Leysdownonsea' },
      { city: 'Leyland' },
      { city: 'Leightonbuzzard' },
      { city: 'Leighonsea' },
      { city: 'Leigh' },
      { city: 'Leicester' },
      { city: 'Leeonthesolent' },
      { city: 'Archway' },
      { city: 'Annfieldplain' },
      { city: 'Andover' },
      { city: 'Allerton' },
      { city: 'Leek' },
      { city: 'Maidstone' },
      { city: 'Hitchin' },
      { city: 'Hinckley' },
      { city: 'Loughborough' },
      { city: 'Lowestoft' },
      { city: 'Chilworth' },
      { city: 'Chingford' },
      { city: 'Chippenham' },
      { city: 'Luton' },
      { city: 'Lythamstannes' },
      { city: 'Macclesfield' },
      { city: 'Madeley' },
      { city: 'Maghull' },
      { city: 'Maidenhead' },
      { city: 'Hillingdon' },
      { city: 'Chesterlestreet' },
      { city: 'Holbeck' },
      { city: 'Maldon' },
      { city: 'Highwycombe' },
      { city: 'Highbury' },
      { city: 'Manchester' },
      { city: 'Mangotsfield' },
      { city: 'Heysham' },
      { city: 'Hethersett' },
      { city: 'Heswall' },
      { city: 'Heston' },
      { city: 'Mansfield' },
      { city: 'Mansfieldwoodhouse' },
      { city: 'Marketharborough' },
      { city: 'Marske' },
      { city: 'Martlesham' },
      { city: 'Martlesham(martleshamheath)' },
      { city: 'Humberstone' },
      { city: 'Keresley' },
      { city: 'Kensington' },
      { city: 'Kennington' },
      { city: 'Kendal' },
      { city: 'Kempston' },
      { city: 'Keighley' },
      { city: 'Jarrow' },
      { city: 'Miltonkeynes' },
      { city: 'Irlam' },
      { city: 'Ipswich' },
      { city: 'Ilkeston' },
      { city: 'Ilford(sevenkings)' },
      { city: 'Ilford' },
      { city: 'Hyde' },
      { city: 'Huyton' },
      { city: 'Hull' },
      { city: 'Huddersfield' },
      { city: 'Hucknall' },
      { city: 'Hove' },
      { city: 'Hounslow' },
      { city: 'Houghtonregis' },
      { city: 'Houghtonlespring' },
      { city: 'Horsham' },
      { city: 'Longton' },
      { city: 'Lostock' },
      { city: 'Middleton' },
      { city: 'Middlesbrough' },
      { city: 'Loudwater' },
      { city: 'Horsforth' },
      { city: 'Hoole' },
    ],
    Northernireland: [
      { city: 'Jordanstown' },
      { city: 'Glengormley' },
      { city: 'Eglinton' },
      { city: 'Antrim' },
      { city: 'Annahilt' },
      { city: 'Bangor' },
      { city: 'Belfast' },
      { city: 'Dundonald' },
      { city: 'Carnmoney' },
      { city: 'Carrickfergus' },
      { city: 'Castlereagh' },
      { city: 'Portadown' },
      { city: 'Northernireland' },
      { city: 'Newtownards' },
      { city: 'Newry' },
      { city: 'Londonderry' },
      { city: 'Lisburn' },
      { city: 'Larne' },
    ],
    Scotland: [
      { city: 'Midcalder' },
      { city: 'Livingston' },
      { city: 'Mayfield' },
      { city: 'Linlithgow' },
      { city: 'Lenzie' },
      { city: 'Kirkintilloch' },
      { city: 'Kirkcaldy' },
      { city: 'Kilmarnock' },
      { city: 'Johnstone' },
      { city: 'Irvine' },
      { city: 'Scotland' },
      { city: 'Wishaw' },
      { city: 'Uddingston' },
      { city: 'Thorntonhall' },
      { city: 'Thornliebank' },
      { city: 'Stepps' },
      { city: 'Howwood' },
      { city: 'Rutherglen' },
      { city: 'Renfrew' },
      { city: 'Perth' },
      { city: 'Paisley' },
      { city: 'Musselburgh' },
      { city: 'Motherwell' },
      { city: 'Milngavie' },
      { city: 'Cults' },
      { city: 'Glasgow' },
      { city: 'Glasgow(broomhill)' },
      { city: 'Giffnock' },
      { city: 'Colinton' },
      { city: 'Coatbridge' },
      { city: 'Clydebank' },
      { city: 'Chapelhall' },
      { city: 'Dumbarton' },
      { city: 'Edinburgh' },
      { city: 'Eastkilbride' },
      { city: 'Erskine' },
      { city: 'Dyce' },
      { city: 'Dundee' },
      { city: 'Dullatur' },
      { city: 'Danderhall' },
      { city: 'Cumbernauld' },
      { city: 'Falkirk' },
      { city: 'Airdrie' },
      { city: 'Aberdeen' },
      { city: 'Alloa' },
      { city: 'Glenrothes' },
      { city: 'Gourock' },
      { city: 'Greenock' },
      { city: 'Hawkhead' },
      { city: 'Highblantyre' },
      { city: 'Edinburgh(southgyle)' },
      { city: 'Cambuslang' },
      { city: 'Glasgow(shettleston)' },
      { city: 'Castlecary' },
      { city: 'Carmunnock' },
      { city: 'Carluke' },
      { city: 'Inverness' },
      { city: 'Bishopbriggs' },
      { city: 'Bellshill' },
      { city: 'Bearsden' },
      { city: 'Barrhead' },
      { city: 'Ayr' },
      { city: 'Ardrossan' },
    ],
    Wales: [
      { city: 'Wrexham' },
      { city: 'Wales' },
      { city: 'Thornhill' },
      { city: 'Swansea' },
      { city: 'Rhoose' },
      { city: 'Radyr' },
      { city: 'Porttalbot' },
      { city: 'Pontypool' },
      { city: 'Penarth' },
      { city: 'Newport' },
      { city: 'Neath' },
      { city: 'Bridgend' },
      { city: 'Cwmbwrla' },
      { city: 'Cwmbran' },
      { city: 'Clydach' },
      { city: 'Cardiff' },
      { city: 'Caerleon' },
      { city: 'Barry' },
      { city: 'Aberkenfig' },
      { city: 'Morriston' },
      { city: 'Marshfield' },
      { city: 'Llanelli' },
      { city: 'Landore' },
      { city: 'Gorseinon' },
    ],
  },
  GD: {
    Saintgeorgeparish: [{ city: 'Saintgeorgeparish' }, { city: 'Stgeorges' }],
    Saintandrewparish: [{ city: 'Grenville' }, { city: 'Saintandrewparish' }],
    Saintgeorge: [{ city: 'Stgeorges' }, { city: 'Saintgeorge' }],
  },
  GE: {
    Kvemokartli: [{ city: 'Kvemokartli' }],
    Tbilisi: [{ city: 'Tbilisi(dzvelitbilisi)' }, { city: 'Tbilisi' }],
    Imereti: [{ city: 'Kulashi' }, { city: 'Imereti' }],
    Mtskhetamtianeti: [{ city: 'Mtskhetamtianeti' }, { city: 'Zahesi(gldaninadzaladevi)' }],
    Samtskhejavakheti: [{ city: 'Samtskhejavakheti' }],
    Adjara: [{ city: 'Adjara' }, { city: 'Batumi' }],
  },
  GH: { Greateraccra: [{ city: 'Greateraccra' }, { city: 'Accra(osuklottey)' }] },
  GR: {
    Attica: [{ city: 'Athens' }, { city: 'Attica' }, { city: 'Chalandri' }, { city: 'Karellas' }, { city: 'Marousi' }],
    Westmacedonia: [{ city: 'Westmacedonia' }],
    Crete: [{ city: 'Limenashersonissou' }, { city: 'Crete' }],
    Southaegean: [{ city: 'Southaegean' }],
    Centralmacedonia: [{ city: 'Centralmacedonia' }, { city: 'Thessaloniki' }],
  },
  GT: {
    Guatemala: [{ city: 'Guatemalacity(zone1)' }, { city: 'Guatemalacity' }, { city: 'Guatemala' }],
    Chiquimula: [{ city: 'Esquipulas' }, { city: 'Chiquimula' }],
  },
  GY: {
    Upperdemeraraberbice: [{ city: 'Linden' }, { city: 'Upperdemeraraberbice' }],
    Demeraramahaica: [{ city: 'Georgetown' }, { city: 'Demeraramahaica' }],
    Essequiboislandswestdemerara: [{ city: 'Essequiboislandswestdemerara' }, { city: 'Vreedenhoop' }],
  },
  HK: {
    Kwaitsing: [{ city: 'Kwaichung' }, { city: 'Kwaitsing' }],
    Kowloon: [{ city: 'Kowloon' }, { city: 'Hongkong' }],
    Centralandwestern: [{ city: 'Centralandwestern' }],
    Tsuenwan: [{ city: 'Tsuenwan' }],
  },
  HN: {
    Franciscomorazandepartment: [{ city: 'Tegucigalpa' }, { city: 'Franciscomorazandepartment' }],
    Cortesdepartment: [{ city: 'Cortesdepartment' }],
  },
  HR: {
    Cityofzagreb: [{ city: 'Cityofzagreb' }],
    Zagreb: [{ city: 'Zagreb' }],
    Splitdalmatia: [{ city: 'Splitdalmatia' }],
  },
  HU: {
    Bacskiskun: [{ city: 'Ersekcsanad' }],
    Pestcounty: [{ city: 'Pestcounty' }],
    Jasznagykunszolnok: [{ city: 'Turkeve' }, { city: 'Szolnok' }],
    Budapest: [{ city: 'Budapest' }],
  },
  ID: {
    Westjava: [
      { city: 'Bekasi' },
      { city: 'Westjava' },
      { city: 'Teluknaga' },
      { city: 'Sukabumi' },
      { city: 'Bandung' },
      { city: 'Karawang' },
      { city: 'Jatibarang' },
      { city: 'Pamanukan' },
      { city: 'Indramayu' },
      { city: 'Cileungsir' },
      { city: 'Depok' },
      { city: 'Cileunyi' },
      { city: 'Cirebon' },
      { city: 'Ciputat' },
      { city: 'Cimahi' },
      { city: 'Bogor' },
      { city: 'Cianjur' },
      { city: 'Cibinong' },
      { city: 'Sawangan' },
      { city: 'Pasarkemis' },
      { city: 'Serpong' },
      { city: 'Cikampek' },
      { city: 'Parung' },
      { city: 'Pamulang' },
      { city: 'Cikarang' },
      { city: 'Cikupa' },
      { city: 'Pameungpeuk' },
    ],
    Banten: [{ city: 'Southtangerang' }, { city: 'Banten' }, { city: 'Tangerang' }],
    Eastjava: [
      { city: 'Malang' },
      { city: 'Magetan' },
      { city: 'Madiun' },
      { city: 'Kumendungsatu' },
      { city: 'Kalibarukulon' },
      { city: 'Jagirsidosermo' },
      { city: 'Gubengairlangga' },
      { city: 'Pacitan' },
      { city: 'Pakiskrajan' },
      { city: 'Munjungan' },
      { city: 'Batu' },
      { city: 'Eastjava' },
      { city: 'Boyolangu' },
      { city: 'Blitar' },
      { city: 'Banyuwangiregency' },
      { city: 'Banyuwangi' },
      { city: 'Bakalan' },
      { city: 'Pare' },
      { city: 'Setro' },
      { city: 'Surabaya' },
      { city: 'Tamanan' },
      { city: 'Tulungagung' },
      { city: 'Wonosari' },
      { city: 'Wonorejo' },
    ],
    Westsumatra: [{ city: 'Westsumatra' }, { city: 'Tabing' }],
    Westnusatenggara: [{ city: 'Westnusatenggara' }],
    Westkalimantan: [{ city: 'Westkalimantan' }, { city: 'Pontianak' }],
    Bangkabelitungislands: [{ city: 'Kepoh' }, { city: 'Bangkabelitungislands' }],
    Riauislands: [
      { city: 'Pancurbirulestariii' },
      { city: 'Batam' },
      { city: 'Riauislands' },
      { city: 'Sekupang' },
      { city: 'Tanjungpinang' },
    ],
    Riau: [
      { city: 'Batam' },
      { city: 'Paritkalipatopah' },
      { city: 'Pekanbaru' },
      { city: 'Riau' },
      { city: 'Tembilahan' },
      { city: 'Dumai' },
    ],
    Centraljava: [
      { city: 'Jatiroto' },
      { city: 'Wonogiri' },
      { city: 'Temanggung' },
      { city: 'Centraljava' },
      { city: 'Banjarnegara' },
      { city: 'Bancakwetan' },
      { city: 'Jepara' },
      { city: 'Kaborongan' },
      { city: 'Kebumen' },
      { city: 'Klaten' },
      { city: 'Krajangrogolan' },
      { city: 'Kubangwaru' },
      { city: 'Kudus' },
      { city: 'Rejoso' },
      { city: 'Semarang' },
      { city: 'Sragen' },
      { city: 'Surakarta' },
    ],
    Bengkulu: [{ city: 'Bengkulu' }],
    Aceh: [{ city: 'Aceh' }],
    Yogyakarta: [{ city: 'Yogyakarta' }, { city: 'Sleman' }],
    Northsulawesi: [{ city: 'Northsulawesi' }, { city: 'Manado' }],
    Bali: [{ city: 'Ubud' }, { city: 'Legian' }, { city: 'Gianyar' }, { city: 'Banjarmedura' }, { city: 'Bali' }],
    Northsumatra: [{ city: 'Northsumatra' }, { city: 'Medan' }, { city: 'Binjai' }],
    Northkalimantan: [{ city: 'Tarakan' }, { city: 'Northkalimantan' }],
    Gorontalo: [{ city: 'Gorontalo' }],
    Southkalimantan: [{ city: 'Southkalimantan' }, { city: 'Banjarmasin' }],
    Centralkalimantan: [{ city: 'Centralkalimantan' }],
    Southsumatra: [{ city: 'Sungaigerong' }, { city: 'Southsumatra' }, { city: 'Palembang' }],
    Southsulawesi: [{ city: 'Southsulawesi' }, { city: 'Pinrang' }, { city: 'Makassar' }],
    Southeastsulawesi: [{ city: 'Southeastsulawesi' }],
    Eastnusatenggara: [{ city: 'Eastnusatenggara' }],
    Lampung: [{ city: 'Lampung' }, { city: 'Kedaton' }],
    Eastkalimantan: [
      { city: 'Samarinda' },
      { city: 'Loajanan' },
      { city: 'Eastkalimantan' },
      { city: 'Kahala' },
      { city: 'Bontang' },
      { city: 'Balikpapan' },
    ],
    Maluku: [{ city: 'Maluku' }, { city: 'Amboncity' }],
    Jakarta: [{ city: 'Jakartapusat' }, { city: 'Jakarta' }, { city: 'Utan' }],
    Jambi: [{ city: 'Jambi' }, { city: 'Jambicity' }],
  },
  IE: {
    Leinster: [
      { city: 'Dundalk' },
      { city: 'Dublin(cooldowncommons)' },
      { city: 'Dublin(tallaght)' },
      { city: 'Dublin(sandyford)' },
      { city: 'Dublin(priorswood)' },
      { city: 'Dublin(kimmage)' },
      { city: 'Dublin(grandcanaldock)' },
      { city: 'Blackrock' },
      { city: 'Dunlaoghaire' },
      { city: 'Edenderry' },
      { city: 'Greystones' },
      { city: 'Hartstown' },
      { city: 'Leinster' },
      { city: 'Mullingar' },
      { city: 'Portlaoise' },
      { city: 'Raheny' },
      { city: 'Rathgar' },
      { city: 'Saggart(nangor)' },
      { city: 'Sandyford(leopardstown)' },
      { city: 'Ballyogan(sandyford)' },
      { city: 'Dublin' },
      { city: 'Dublin(ballycoolen)' },
      { city: 'Ballyogan(leopardstown)' },
      { city: 'Dublin(blanchardstown)' },
      { city: 'Dublin(cherryorchard)' },
      { city: 'Drumcondra' },
      { city: 'Dublin(citywestbusinesscampus)' },
      { city: 'Dublin(citywest)' },
      { city: 'Clondalkin' },
    ],
    Munster: [
      { city: 'Munster' },
      { city: 'Mallow' },
      { city: 'Limerick' },
      { city: 'Cork' },
      { city: 'Castletroy' },
      { city: 'Waterford' },
    ],
    Connacht: [{ city: 'Galway' }, { city: 'Sligo' }, { city: 'Foxford' }, { city: 'Connacht' }],
    Ulster: [{ city: 'Bridgeend(elaghbeg)' }, { city: 'Ulster' }],
  },
  IL: {
    Judeaandsamariaarea: [{ city: 'Givonhahadasha' }, { city: 'Judeaandsamariaarea' }],
    Centraldistrict: [
      { city: 'Petahtikva' },
      { city: 'Roshhaayin' },
      { city: 'Yakum' },
      { city: 'Yaqum' },
      { city: 'Netanya' },
      { city: 'Centraldistrict' },
    ],
    Northerndistrict: [{ city: 'Northerndistrict' }, { city: 'Kafrmanda' }],
    Telaviv: [{ city: 'Telaviv' }, { city: 'Holon' }],
    Haifa: [{ city: 'Haifa' }, { city: 'Tiratcarmel' }, { city: 'Haifa(matam)' }],
    Southerndistrict: [{ city: 'Southerndistrict' }],
    Jerusalem: [{ city: 'Jerusalem' }],
  },
  IM: { Douglas: [{ city: 'Douglas' }] },
  IN: {
    Karnataka: [
      { city: 'Bengaluru' },
      { city: 'Karnataka' },
      { city: 'Bengaluru(industriallayout)' },
      { city: 'Bengaluru(newgurappanapalya)' },
      { city: 'Bengaluru(whitefield)' },
      { city: 'Mangaluru' },
    ],
    Maharashtra: [
      { city: 'Pune' },
      { city: 'Thane' },
      { city: 'Mumbai(rajanpada)' },
      { city: 'Powai' },
      { city: 'Navimumbai(reliancecorporatepark)' },
      { city: 'Nashik' },
      { city: 'Mumbai(sioneast)' },
      { city: 'Mumbai(prabhadevi)' },
      { city: 'Khadki' },
      { city: 'Mumbai(parel)' },
      { city: 'Mumbai(chakalaindustrialarea)' },
      { city: 'Mumbai(centurymills)' },
      { city: 'Mumbai' },
      { city: 'Maharashtra' },
      { city: 'Virar' },
      { city: 'Borivali' },
      { city: 'Bhayandar' },
      { city: 'Dombivali' },
    ],
    Delhi: [
      { city: 'Newdelhi(okhla)' },
      { city: 'Newdelhi(jiasarai)' },
      { city: 'Newdelhi(defencecolony)' },
      { city: 'Newdelhi(connaughtlane)' },
      { city: 'Newdelhi' },
      { city: 'Nangloijat' },
      { city: 'Najafgarh' },
      { city: 'Deoli' },
      { city: 'Delhi' },
      { city: 'Defencecolony' },
      { city: 'Newdelhi(okhlaphaseii)' },
      { city: 'Newdelhi(okhlaphaseiii)' },
      { city: 'Newdelhi(pocketc)' },
      { city: 'Newdelhi(nehruplace)' },
      { city: 'Newdelhi(pocketd)' },
      { city: 'Pitampura' },
      { city: 'Shahdara' },
    ],
    Haryana: [
      { city: 'Sonipat' },
      { city: 'Karnal' },
      { city: 'Haryana' },
      { city: 'Ambala' },
      { city: 'Gurugram(sector44)' },
      { city: 'Gurugram(sector39)' },
      { city: 'Gurugram(phaseiv)' },
      { city: 'Gurugram(phaseiii)' },
      { city: 'Gurugram(durgacolony)' },
      { city: 'Gurugram' },
      { city: 'Faridabad' },
      { city: 'Chandigarh' },
    ],
    Jammuandkashmir: [{ city: 'Jammuandkashmir' }],
    Jharkhand: [{ city: 'Jamshedpur' }, { city: 'Jharkhand' }, { city: 'Ranchi' }],
    Punjab: [
      { city: 'Punjab' },
      { city: 'Sasnagar' },
      { city: 'Ludhiana(aggarnagarbblock)' },
      { city: 'Abohar' },
      { city: 'Amritsar' },
      { city: 'Jalandhar' },
      { city: 'Ludhiana' },
    ],
    Goa: [{ city: 'Goa' }],
    Odisha: [{ city: 'Kendraparha' }, { city: 'Bhubaneswar' }, { city: 'Odisha' }],
    Kerala: [{ city: 'Kerala' }],
    Rajasthan: [{ city: 'Jaipur' }, { city: 'Rajasthan' }, { city: 'Sriganganagar' }, { city: 'Sirohi' }],
    Gujarat: [
      { city: 'Ahmedabad' },
      { city: 'Ahmedabad(shahibaugh)' },
      { city: 'Gujarat' },
      { city: 'Naroda' },
      { city: 'Surat' },
      { city: 'Rajkot' },
      { city: 'Sarkhej' },
    ],
    Madhyapradesh: [
      { city: 'Madhyapradesh' },
      { city: 'Jabalpur' },
      { city: 'Indore' },
      { city: 'Bhopal(bsector)' },
      { city: 'Bhopal' },
    ],
    Bihar: [{ city: 'Bihar' }, { city: 'Patna' }, { city: 'Patna(policecolony)' }],
    Chhattisgarh: [{ city: 'Chhattisgarh' }, { city: 'Durg' }, { city: 'Raipur' }],
    Uttarpradesh: [
      { city: 'Faizabad' },
      { city: 'Loni' },
      { city: 'Etawah' },
      { city: 'Etmadpur' },
      { city: 'Lucknow' },
      { city: 'Bhagwantnagar' },
      { city: 'Bikapur' },
      { city: 'Moth' },
      { city: 'Fatehpur' },
      { city: 'Mathura' },
      { city: 'Khalilabad' },
      { city: 'Jhansi' },
      { city: 'Itaunja' },
      { city: 'Deoband' },
      { city: 'Khatauli' },
      { city: 'Khanpur' },
      { city: 'Bareilly' },
      { city: 'Baragaon' },
      { city: 'Bahraich' },
      { city: 'Aligarh' },
      { city: 'Meerut' },
      { city: 'Akbarpur' },
      { city: 'Moradabad' },
      { city: 'Agra' },
      { city: 'Lar' },
      { city: 'Afzalgarh' },
      { city: 'Dibai' },
      { city: 'Uttarpradesh' },
      { city: 'Raebareli' },
      { city: 'Ramkola' },
      { city: 'Mau' },
      { city: 'Rath' },
      { city: 'Sector' },
      { city: 'Shahganj' },
      { city: 'Suar' },
      { city: 'Utraula' },
      { city: 'Dayalbagh' },
      { city: 'Ramnagar' },
      { city: 'Varanasi' },
      { city: 'Mawana' },
      { city: 'Kanpur' },
      { city: 'Ghaziabad' },
      { city: 'Ghazipur' },
      { city: 'Gorakhpur' },
      { city: 'Goshainganj' },
      { city: 'Hathras' },
      { city: 'Kasganj' },
      { city: 'Najibabad' },
      { city: 'Prayagraj' },
      { city: 'Padrauna' },
      { city: 'Orai' },
      { city: 'Noida' },
      { city: 'Kannauj' },
    ],
    Assam: [{ city: 'Assam' }],
    Uttarakhand: [
      { city: 'Uttarakhand' },
      { city: 'Sitarganj' },
      { city: 'Rishikesh' },
      { city: 'Kashipur' },
      { city: 'Haridwar' },
      { city: 'Roorkee' },
      { city: 'Haldwani' },
      { city: 'Dehradun' },
    ],
    Andhrapradesh: [{ city: 'Andhrapradesh' }, { city: 'Guntur' }],
    Telangana: [
      { city: 'Hyderabad(gachibowli)' },
      { city: 'Mancherial' },
      { city: 'Telangana' },
      { city: 'Hyderabad' },
    ],
    Tamilnadu: [{ city: 'Tamilnadu' }, { city: 'Chennai' }],
    Westbengal: [
      { city: 'Durgapur(bidhannagar)' },
      { city: 'Westbengal' },
      { city: 'Kolkata(epblock)' },
      { city: 'Kolkata(entally)' },
      { city: 'Kolkata' },
      { city: 'Howrah' },
      { city: 'Durgapur' },
    ],
  },
  IQ: {
    Nineveh: [{ city: 'Nineveh' }, { city: 'Mosul' }],
    Basra: [{ city: 'Basra' }],
    Baghdad: [{ city: 'Baghdad' }, { city: 'Baghdad(mansour)' }],
    Erbil: [{ city: 'Erbil' }],
  },
  IR: {
    Tehran: [{ city: 'Tehran(district4)' }, { city: 'Tehran' }],
    Fars: [{ city: 'Shiraz(district6)' }, { city: 'Fars' }],
    Isfahan: [{ city: 'Isfahan' }],
  },
  IS: { Capitalregion: [{ city: 'Capitalregion' }] },
  IT: {
    Lombardy: [
      { city: 'Como' },
      { city: 'Figino' },
      { city: 'Lombardy' },
      { city: 'Milan' },
      { city: 'Rho' },
      { city: 'Pavia' },
    ],
    Campania: [{ city: 'Campania' }, { city: 'Naples' }],
    Liguria: [{ city: 'Liguria' }, { city: 'Genoa' }],
    Emiliaromagna: [{ city: 'Rimini' }, { city: 'Emiliaromagna' }, { city: 'Bologna' }],
    Sardinia: [{ city: 'Elmas' }, { city: 'Sardinia' }],
    Lazio: [{ city: 'Rome' }, { city: 'Lazio' }],
    Abruzzo: [{ city: 'Chieti' }, { city: 'Abruzzo' }],
    Calabria: [{ city: 'Calabria' }],
    Veneto: [{ city: 'Conegliano' }, { city: 'Padova' }, { city: 'Veneto' }],
    Tuscany: [{ city: 'Florence' }, { city: 'Tuscany' }],
    Apulia: [{ city: 'Apulia' }],
    Piedmont: [{ city: 'Piedmont' }, { city: 'Turin' }],
    Umbria: [{ city: 'Umbria' }],
    Friuliveneziagiulia: [{ city: 'Trieste' }, { city: 'Friuliveneziagiulia' }],
    Trentinoaltoadige: [{ city: 'Trentinoaltoadige' }],
    Themarches: [{ city: 'Themarches' }],
    Sicily: [{ city: 'Palermo' }, { city: 'Sicily' }],
  },
  JE: { Sthelier: [{ city: 'Sthelier' }, { city: 'Sainthelier' }] },
  JM: {
    Saintcatherineparish: [
      { city: 'Oldharbour' },
      { city: 'Portmore' },
      { city: 'Saintcatherineparish' },
      { city: 'Spanishtown' },
    ],
    Kingston: [{ city: 'Kingston' }],
    Clarendon: [{ city: 'Clarendon' }],
    Saintjamesparish: [{ city: 'Montegobay' }, { city: 'Saintjamesparish' }],
    Trelawnyparish: [{ city: 'Trelawnyparish' }, { city: 'Falmouth' }],
    Standrew: [{ city: 'Kingston' }, { city: 'Standrew' }],
    Stelizabeth: [{ city: 'Stelizabeth' }],
    Saintannparish: [{ city: 'Saintannparish' }],
    Saintandrewparish: [
      { city: 'Halfwaytree' },
      { city: 'Constantspring' },
      { city: 'Kingston' },
      { city: 'Saintandrewparish' },
    ],
  },
  JO: { Amman: [{ city: 'Amman' }] },
  JP: {
    Tokyo: [{ city: 'Tokyo' }, { city: 'Chiyoda' }, { city: 'Minatoku' }, { city: 'Shibuya' }],
    Osaka: [{ city: 'Osaka' }],
  },
  KE: {
    Kiambucounty: [{ city: 'Kiambucounty' }, { city: 'Ruiru' }],
    Nairobicounty: [
      { city: 'Imaradaimaestate' },
      { city: 'Nairobi(embakasi)' },
      { city: 'Nairobi(springvalley)' },
      { city: 'Nairobi(springvalleyestate)' },
      { city: 'Nairobicounty' },
    ],
    Nairobiarea: [{ city: 'Nairobiarea' }, { city: 'Nairobi(springvalley)' }],
    Mombasacounty: [{ city: 'Mombasacounty' }],
    Machakoscounty: [{ city: 'Githunguri' }, { city: 'Machakoscounty' }],
  },
  KG: { Bishkek: [{ city: 'Bishkek' }], Oshregion: [{ city: 'Oshregion' }] },
  KH: { Phnompenh: [{ city: 'Phnompenh' }] },
  KN: {
    Saintjameswindwa: [{ city: 'Saintjameswindwa' }, { city: 'Newcastle' }],
    Saintgeorgebasseterre: [{ city: 'Saintgeorgebasseterre' }],
    Saintmarycayon: [{ city: 'Saintmarycayon' }],
  },
  KR: {
    Gyeonggido: [{ city: 'Gwangmyeong' }, { city: 'Gyeonggido' }, { city: 'Seongnamsi(buljeongro)' }],
    Seoul: [{ city: 'Yongsandong(hangangdaero)' }, { city: 'Seoul' }, { city: 'Seoul(toegyero)' }],
  },
  KW: {
    Alasimah: [
      { city: 'Kuwaitcity(jibla)' },
      { city: 'Alasimah' },
      { city: 'Kuwaitcity' },
      { city: 'Kuwaitcity(mirqab)' },
    ],
    Alamadi: [{ city: 'Alamadi' }],
    Hawalli: [{ city: 'Hawalli' }],
    Alfarwaniyah: [{ city: 'Alfarwaniyah' }],
  },
  KY: { Georgetown: [{ city: 'Georgetown' }], Northside: [{ city: 'Northside' }] },
  KZ: {
    Karaganda: [{ city: 'Karaganda' }],
    Qostanay: [{ city: 'Kostanay' }, { city: 'Qostanay' }],
    Mangghystau: [{ city: 'Mangghystau' }, { city: 'Aktau' }],
    Aqtobe: [{ city: 'Aktobe' }, { city: 'Aqtobe' }],
    Turkistan: [{ city: 'Turkistan' }],
    Eastkazakhstan: [{ city: 'Eastkazakhstan' }],
    Shymkent: [{ city: 'Shymkent' }],
    Almatyoblysy: [{ city: 'Almatyoblysy' }],
    Almaty: [{ city: 'Almaty(bostandykdistrict)' }, { city: 'Almaty(almalyaudany)' }, { city: 'Almaty' }],
    Astana: [{ city: 'Astana' }],
    Zhambyl: [{ city: 'Zhambyl' }],
    Jetisuregion: [{ city: 'Taldykorgan' }, { city: 'Jetisuregion' }],
    Pavlodarregion: [{ city: 'Pavlodarregion' }],
    Ulytauregion: [{ city: 'Ulytauregion' }, { city: 'Zhezqazghan' }],
  },
  LB: {
    Beyrouth: [{ city: 'Beyrouth' }, { city: 'Beirut' }],
    Beqaa: [{ city: 'Beqaa' }, { city: 'Zahle' }],
    Montliban: [{ city: 'Jdaidetelmatn' }, { city: 'Montliban' }],
  },
  LC: {
    Choiseul: [{ city: 'Choiseul' }],
    Castries: [{ city: 'Castries' }, { city: 'Bisee' }],
    Grosislet: [{ city: 'Grosislet' }],
  },
  LK: {
    Westernprovince: [{ city: 'Westernprovince' }, { city: 'Colombo(colombodivision)' }],
    Western: [{ city: 'Western' }, { city: 'Colombo(colombodivision)' }],
  },
  LT: { Vilnius: [{ city: 'Vilnius' }] },
  LU: { Diekirch: [{ city: 'Diekirch' }, { city: 'Schieren' }] },
  LV: {
    Riga: [
      { city: 'Riga(latgalesuburb)' },
      { city: 'Riga(ziemeurajons)' },
      { city: 'Riga(vidzemespriekpilseta)' },
      { city: 'Riga(latgalespriekpilseta)' },
      { city: 'Riga(centrarajons)' },
      { city: 'Riga' },
    ],
  },
  LY: {
    Miratah: [{ city: 'Miratah' }, { city: 'Zliten' }],
    Banghazi: [{ city: 'Banghazi' }, { city: 'Benghazi' }],
    Tripoli: [{ city: 'Tripoli(souqaljumaa)' }, { city: 'Tripoli' }],
  },
  MA: {
    Marrakeshsafi: [{ city: 'Marrakeshsafi' }, { city: 'Sidiabdallahghiat' }, { city: 'Mechouarkasba' }],
    Rabatsalekenitra: [{ city: 'Tiflet' }, { city: 'Temara' }, { city: 'Rabatsalekenitra' }, { city: 'Rabat(agdal)' }],
    Casablancasettat: [
      { city: 'Bouskoura' },
      { city: 'Darbouazza' },
      { city: 'Eljadida' },
      { city: 'Casablancasettat' },
      { city: 'Casablanca(prefecturedarrondissementsdainchock)' },
      { city: 'Casablanca(maarif)' },
      { city: 'Mohammedia' },
      { city: 'Mechouardecasablanca' },
      { city: 'Lahraouyine' },
      { city: 'Titmellil' },
    ],
    Oriental: [{ city: 'Oujda' }, { city: 'Oriental' }],
    Tangertetouanalhoceima: [
      { city: 'Larache' },
      { city: 'Tangier' },
      { city: 'Tangertetouanalhoceima' },
      { city: 'Boukhalef' },
    ],
    Soussmassa: [{ city: 'Soussmassa' }, { city: 'Sidiifni' }, { city: 'Inezgane' }, { city: 'Agadir' }],
    Guelmimouednoun: [{ city: 'Guelmimouednoun' }],
    Fesmeknes: [{ city: 'Douartoulal' }, { city: 'Fesmeknes' }],
    Draatafilalet: [{ city: 'Draatafilalet' }],
    Benimellalkhenifra: [{ city: 'Benimellalkhenifra' }],
  },
  MD: {
    Chiinaumunicipality: [{ city: 'Chisinau(buiucani)' }, { city: 'Chisinau' }, { city: 'Chiinaumunicipality' }],
    Transnistria: [{ city: 'Transnistria' }, { city: 'Tiraspol' }],
  },
  ME: { Podgorica: [{ city: 'Podgorica' }] },
  MK: { Gradskopje: [{ city: 'Gradskopje' }], Kumanovo: [{ city: 'Kumanovo' }] },
  ML: { Bamako: [{ city: 'Bamako' }], Koulikoro: [{ city: 'Koulikoro' }] },
  MM: {
    Yangon: [{ city: 'Yangon' }, { city: 'Hmawbi' }],
    Naypyitaw: [{ city: 'Naypyitaw' }],
    Rangoon: [{ city: 'Yangon(hlaing)' }, { city: 'Rangoon' }],
    Mandalayregion: [{ city: 'Mandalayregion' }],
  },
  MN: { Ulaanbaatar: [{ city: 'Ulaanbaatar(khoroo8)' }, { city: 'Ulaanbaatar' }] },
  MT: { Ilmarsa: [{ city: 'Ilmarsa' }], Birkirkara: [{ city: 'Birkirkara' }] },
  MU: { Plaineswilhems: [{ city: 'Quatrebornes(ebenecybercity)' }, { city: 'Plaineswilhems' }] },
  MV: {
    Kaafuatoll: [{ city: 'Male(maafannu)' }, { city: 'Male(hulhumale)' }, { city: 'Male' }, { city: 'Kaafuatoll' }],
  },
  MW: { Southernregion: [{ city: 'Southernregion' }] },
  MX: {
    Tamaulipas: [{ city: 'Tamaulipas' }],
    Nayarit: [{ city: 'Nayarit' }],
    Yucatan: [{ city: 'Yucatan' }],
    Bajacalifornia: [{ city: 'Tijuana(zonaeste)' }, { city: 'Tijuana' }, { city: 'Bajacalifornia' }],
    Puebla: [{ city: 'Puebla' }],
    Jalisco: [{ city: 'Jalisco' }, { city: 'Guadalajara(delfresno)' }],
    Coahuila: [{ city: 'Torreon' }, { city: 'Coahuila' }],
    Sanluispotosi: [{ city: 'Sanluispotosi' }],
    Michoacan: [{ city: 'Michoacan' }],
    Tabasco: [{ city: 'Tabasco' }],
    Chiapas: [{ city: 'Chiapas' }],
    Aguascalientes: [{ city: 'Aguascalientes' }],
    Nuevoleon: [{ city: 'Nuevoleon' }, { city: 'Monterrey' }],
    Veracruz: [{ city: 'Veracruz' }],
    Hidalgo: [{ city: 'Hidalgo' }],
    Mexicocity: [
      { city: 'Mexicocity(zedecsantafe)' },
      { city: 'Mexicocity(sanlorenzoatemoaya)' },
      { city: 'Mexicocity(manantialpenapobre)' },
      { city: 'Mexicocity(fuentesdelpedregal)' },
      { city: 'Mexicocity(centro)' },
      { city: 'Mexicocity' },
      { city: 'Magdalenacontreras' },
    ],
    Sonora: [{ city: 'Ciudadobregon' }, { city: 'Sonora' }],
    Mexico: [{ city: 'Mexico' }],
    Queretaro: [{ city: 'Queretaro' }, { city: 'Queretarocity' }],
    Sinaloa: [{ city: 'Sinaloa' }],
    Chihuahua: [{ city: 'Chihuahua' }],
    Guanajuato: [{ city: 'Guanajuato' }],
    Quintanaroo: [{ city: 'Quintanaroo' }],
  },
  MY: {
    Kualalumpur: [
      { city: 'Kualalumpur(kualalumpurcitycentre)' },
      { city: 'Kualalumpur(kampungattap)' },
      { city: 'Kualalumpur(citycentre)' },
      { city: 'Kualalumpur(brickfields)' },
      { city: 'Kualalumpur' },
      { city: 'Goldenfish' },
      { city: 'Ampang' },
      { city: 'Kualalumpur(technologyparkmalaysia)' },
      { city: 'Montkiara' },
      { city: 'Oug' },
      { city: 'Tamanmelati' },
      { city: 'Tamanpetaling' },
      { city: 'Wangsamaju' },
    ],
    Pahang: [{ city: 'Pahang' }],
    Perak: [{ city: 'Bercham' }, { city: 'Ipoh' }, { city: 'Perak' }, { city: 'Taiping' }, { city: 'Telukintan' }],
    Penang: [{ city: 'Relau' }, { city: 'Penang' }],
    Selangor: [
      { city: 'Ladangserikundang' },
      { city: 'Bandarmahkotacheras' },
      { city: 'Kotadamansara' },
      { city: 'Klang' },
      { city: 'Kampungbarubalakong' },
      { city: 'Cyberjaya' },
      { city: 'Shahalam(subanghitechindustrialpark)' },
      { city: 'Petalingjaya' },
      { city: 'Sungaibuloh' },
      { city: 'Shahalam(hicomglenmarieindustrialpark)' },
      { city: 'Shahalam' },
      { city: 'Selayangbaruutara' },
      { city: 'Selangor' },
      { city: 'Rawang' },
      { city: 'Portklang' },
      { city: 'Petalingjaya(seksyen52petalingjaya)' },
    ],
    Melaka: [{ city: 'Melaka' }],
    Kedah: [{ city: 'Kedah' }, { city: 'Sik' }],
    Kelantan: [{ city: 'Kelantan' }],
    Terengganu: [{ city: 'Terengganu' }],
    Negerisembilan: [{ city: 'Seremban' }, { city: 'Negerisembilan' }],
    Sarawak: [{ city: 'Bintulu' }, { city: 'Kuching' }, { city: 'Miri' }, { city: 'Sarawak' }, { city: 'Sibu' }],
    Johor: [
      { city: 'Tamanuniversiti' },
      { city: 'Johorbahru' },
      { city: 'Kampungkangkarteberau' },
      { city: 'Johor' },
      { city: 'Mountaustin' },
      { city: 'Pelentong' },
      { city: 'Bukitindah' },
      { city: 'Perling' },
    ],
    Sabah: [
      { city: 'Sandakan' },
      { city: 'Sabah' },
      { city: 'Ranau' },
      { city: 'Putatan' },
      { city: 'Papar' },
      { city: 'Kotakinabalu' },
      { city: 'Kotabelud' },
      { city: 'Donggongon' },
    ],
  },
  MZ: { Maputocity: [{ city: 'Maputocity' }] },
  NG: { Lagos: [{ city: 'Lagos(victoriaislandannex)' }, { city: 'Lagos(ikoyi)' }, { city: 'Lagos' }] },
  NI: { Managuadepartment: [{ city: 'Managuadepartment' }] },
  NL: {
    Southholland: [{ city: 'Rotterdam' }, { city: 'Thehague(laak)' }, { city: 'Naaldwijk' }, { city: 'Southholland' }],
    Zeeland: [{ city: 'Goes' }],
    Overijssel: [{ city: 'Zwolle' }],
    Northholland: [{ city: 'Northholland' }, { city: 'Hilversum' }, { city: 'Amsterdam' }],
    Northbrabant: [{ city: 'Northbrabant' }, { city: 'Roosendaal' }],
    Limburg: [{ city: 'Limburg' }],
    Gelderland: [{ city: 'Arnhem' }],
    Utrecht: [{ city: 'Utrecht' }],
  },
  NO: { Oslo: [{ city: 'Oslo' }] },
  NP: {
    Bagmatiprovince: [
      { city: 'Bharatpur' },
      { city: 'Bagmatiprovince' },
      { city: 'Hetauda' },
      { city: 'Kathmandu' },
      { city: 'Kathmandu(balaju)' },
      { city: 'Kathmandu(koteshwor)' },
      { city: 'Kathmandu(tripureshwor)' },
      { city: 'Kirtipur' },
      { city: 'Patan(jawalakhel)' },
      { city: 'Patan(thasikhel)' },
    ],
    Province1: [{ city: 'Iahari' }, { city: 'Province1' }],
    Lumbiniprovince: [{ city: 'Butwal' }, { city: 'Lumbiniprovince' }],
    Madhesh: [{ city: 'Birgunj' }, { city: 'Madhesh' }],
    Koshi: [{ city: 'Koshi' }, { city: 'Ilam' }, { city: 'Iahari' }, { city: 'Damak' }, { city: 'Bhadrapur' }],
    Gandakipradesh: [{ city: 'Pokhara(chauthe)' }, { city: 'Gandakipradesh' }, { city: 'Dihi' }],
  },
  NZ: {
    Wellingtonregion: [{ city: 'Wellingtonregion' }],
    Auckland: [
      { city: 'Auckland' },
      { city: 'Auckland(aucklandcbd)' },
      { city: 'Auckland(newmarket)' },
      { city: 'Auckland(takapuna)' },
      { city: 'Newmarket' },
    ],
    Canterbury: [{ city: 'Canterbury' }],
    Bayofplenty: [{ city: 'Bayofplenty' }, { city: 'Tauranga' }],
    Waikatoregion: [{ city: 'Waikatoregion' }],
  },
  OM: { Muscat: [{ city: 'Bawshar' }, { city: 'Muscat' }, { city: 'Muscat(ruwi)' }] },
  PA: { Panama: [{ city: 'Panamacity' }, { city: 'Panama' }] },
  PE: {
    Limaregion: [
      { city: 'Sanisidro' },
      { city: 'Sanfranciscodeborja' },
      { city: 'Limaregion' },
      { city: 'Lamolina' },
      { city: 'Jesusmaria' },
      { city: 'Independencia' },
      { city: 'Huacoy' },
      { city: 'Carabayllo' },
      { city: 'Brena' },
    ],
    Lalibertad: [{ city: 'Lalibertad' }],
    Lambayeque: [{ city: 'Lambayeque' }],
    Ica: [{ city: 'Ica' }],
    Junin: [{ city: 'Junin' }],
    Arequipa: [{ city: 'Arequipa' }],
    Callao: [{ city: 'Callao' }, { city: 'Carmendelaleguareynoso' }, { city: 'Ventanilla' }],
    Limaprovince: [{ city: 'Limaprovince' }],
    Piura: [{ city: 'Piura' }],
  },
  PG: { Nationalcapital: [{ city: 'Nationalcapital' }, { city: 'Portmoresby' }] },
  PH: {
    Calabarzon: [
      { city: 'Imus' },
      { city: 'Naic(sabang)' },
      { city: 'Dasmarinas' },
      { city: 'Calamba' },
      { city: 'Calabarzon' },
      { city: 'Cainta' },
      { city: 'Binangonan' },
      { city: 'Binan' },
      { city: 'Bagongpagasa' },
      { city: 'Bacoor' },
      { city: 'Baclaran' },
      { city: 'Angono' },
      { city: 'Aliang' },
      { city: 'Quezoncity(project6)' },
      { city: 'Sanfranciscodelmonte' },
      { city: 'Sanmateo' },
      { city: 'Mariana' },
      { city: 'Sanpablo' },
      { city: 'Tanza' },
      { city: 'Teresa' },
      { city: 'Tagkawayansabang' },
      { city: 'Silang' },
      { city: 'Taytay' },
    ],
    Westernvisayas: [{ city: 'Pasil' }, { city: 'Westernvisayas' }, { city: 'Bacolodcity' }],
    Cordillera: [{ city: 'Cordillera' }, { city: 'Baguiocity' }],
    Bicolregion: [{ city: 'Bicolregion' }, { city: 'Legazpi' }],
    Zamboangapeninsula: [{ city: 'Zamboangapeninsula' }],
    Caraga: [{ city: 'Tandag' }, { city: 'Caraga' }],
    Davaoregion: [{ city: 'Davaocity' }, { city: 'Davaoregion' }],
    Easternvisayas: [{ city: 'Pawing' }, { city: 'Easternvisayas' }],
    Nationalcapitalregion: [
      { city: 'Pasig' },
      { city: 'Upperbicutan' },
      { city: 'Taguig' },
      { city: 'Sanjuancity' },
      { city: 'Quezoncity(novaliches)' },
      { city: 'Quezoncity' },
      { city: 'Pasig(sanantonio)' },
      { city: 'Makaticity' },
      { city: 'Pasay' },
      { city: 'Paranaquecity' },
      { city: 'Nationalcapitalregion' },
      { city: 'Namayan' },
      { city: 'Marikinacity' },
      { city: 'Manila(sampaloc)' },
      { city: 'Manila' },
      { city: 'Mandaluyongcity' },
      { city: 'Mandaluyong' },
      { city: 'Belair' },
      { city: 'Bagongsilang' },
      { city: 'Bagongsilangan' },
      { city: 'Caloocancity' },
      { city: 'Calumpang' },
      { city: 'Laspinas' },
    ],
    Centralvisayas: [{ city: 'Mandauecity' }, { city: 'Centralvisayas' }, { city: 'Cebucity' }, { city: 'Apas' }],
    Soccsksargen: [{ city: 'Generalsantos' }, { city: 'Soccsksargen' }],
    Mimaropa: [{ city: 'Mimaropa' }, { city: 'Elnido' }],
    Autonomousregioninmuslimmindanao: [{ city: 'Autonomousregioninmuslimmindanao' }],
    Cagayanvalley: [{ city: 'Cagayanvalley' }],
    Centralluzon: [
      { city: 'Meycauayan' },
      { city: 'Olongapocity' },
      { city: 'Plaridel' },
      { city: 'Lomadegato' },
      { city: 'Sanfernandocity' },
      { city: 'Iba' },
      { city: 'Donaremediostrinidad' },
      { city: 'Sanjosedelmonte' },
      { city: 'Centralluzon' },
      { city: 'Balibago' },
      { city: 'Balagtas' },
      { city: 'Angelescity(clarkfreeportzone)' },
      { city: 'Sanmiguel' },
      { city: 'Angelescity(clarkfreeport)' },
      { city: 'Tarlaccity' },
    ],
    Northernmindanao: [{ city: 'Cagayandeoro' }, { city: 'Northernmindanao' }],
    Ilocos: [{ city: 'Ilocos' }, { city: 'Urdaneta' }],
  },
  PK: {
    Punjab: [
      { city: 'Jhelum' },
      { city: 'Kahnanau' },
      { city: 'Faisalabad' },
      { city: 'Gujranwala' },
      { city: 'Lahore' },
      { city: 'Lahore(gulberg)' },
      { city: 'Lahore(nishattown)' },
      { city: 'Multan' },
      { city: 'Punjab' },
      { city: 'Sialkot' },
      { city: 'Rawalpindi' },
      { city: 'Bahawalpur' },
    ],
    Sindh: [
      { city: 'Sindh' },
      { city: 'Gharo' },
      { city: 'Hyderabad' },
      { city: 'Karachi' },
      { city: 'Karachi(sadr)' },
      { city: 'Karachi(civillines)' },
    ],
    Khyberpakhtunkhwa: [{ city: 'Khyberpakhtunkhwa' }],
    Balochistan: [{ city: 'Quetta' }, { city: 'Balochistan' }],
    Islamabad: [{ city: 'Islamabad(h91)' }, { city: 'Islamabad(f7markaz)' }, { city: 'Islamabad' }],
  },
  PL: { Silesia: [{ city: 'Silesia' }], Mazovia: [{ city: 'Mazovia' }, { city: 'Warsaw' }] },
  PR: { Sanjuan: [{ city: 'Sanjuan' }], Ponce: [{ city: 'Ponce' }], Aguadilla: [{ city: 'Aguadilla' }] },
  PS: {
    Westbank: [{ city: 'Nablus' }, { city: 'Ramallah' }, { city: 'Westbank' }],
    Gazastrip: [{ city: 'Gazastrip' }],
  },
  PT: {
    Azores: [{ city: 'Azores' }],
    Porto: [{ city: 'Porto' }],
    Leiria: [{ city: 'Leiria' }],
    Lisbon: [{ city: 'Lisbon' }],
    Faro: [{ city: 'Faro' }],
    Coimbra: [{ city: 'Coimbra' }],
    Braga: [{ city: 'Braga' }],
    Aveiro: [{ city: 'Aveiro' }],
    Setubal: [{ city: 'Setubal' }],
  },
  PY: {
    Canindeyu: [{ city: 'Canindeyu' }, { city: 'Katuete' }],
    Sanpedrodepartment: [{ city: 'Sanpedrodepartment' }],
    Altoparana: [{ city: 'Altoparana' }, { city: 'Ciudaddeleste' }, { city: 'Ciudaddeleste(area3)' }],
    Centraldepartment: [{ city: 'Fernandodelamora(fernandozonasur)' }, { city: 'Centraldepartment' }],
    Asuncion: [{ city: 'Asuncion' }],
  },
  QA: {
    Baladiyatarrayyan: [{ city: 'Muaydhirrawatrashid' }, { city: 'Baladiyatarrayyan' }, { city: 'Alrayyan' }],
    Baladiyataddawah: [{ city: 'Nuayjah' }, { city: 'Doha' }, { city: 'Baladiyataddawah' }],
  },
  RE: { Reunion: [{ city: 'Sainteclotilde' }, { city: 'Reunion' }, { city: 'Saintdenis' }] },
  RO: {
    Ilfov: [{ city: 'Ilfov' }],
    Bucureti: [{ city: 'Bucureti' }, { city: 'Bucharest' }, { city: 'Bucharest(sector1)' }],
    Timicounty: [{ city: 'Timicounty' }],
  },
  RS: {
    Centralserbia: [
      { city: 'Belgrade(newbelgrade)' },
      { city: 'Centralserbia' },
      { city: 'Kragujevac' },
      { city: 'Ni' },
      { city: 'Belgrade' },
    ],
    Vojvodina: [{ city: 'Subotica' }, { city: 'Vojvodina' }, { city: 'Novisad' }],
  },
  RU: {
    Moscow: [{ city: 'Moscow' }],
    Novosibirskoblast: [{ city: 'Novosibirskoblast' }],
    Nizhnynovgorodoblast: [{ city: 'Nizhnynovgorodoblast' }],
    Sverdlovskoblast: [
      { city: 'Yekaterinburg' },
      { city: 'Kamenskuralsky' },
      { city: 'Martyush' },
      { city: 'Sverdlovskoblast' },
    ],
    Adygeyarepublic: [{ city: 'Adygeyarepublic' }, { city: 'Sadovoye' }],
    Crimea: [{ city: 'Simferopol(tsentralnyidistrict)' }, { city: 'Simferopol' }, { city: 'Crimea' }],
    Moscowoblast: [{ city: 'Moscowoblast' }],
    Stpetersburg: [{ city: 'Stpetersburg' }],
    Stavropolkray: [{ city: 'Stavropolkray' }],
    Krasnodarkrai: [{ city: 'Taman' }, { city: 'Sochi' }, { city: 'Krasnodarkrai' }],
    Leningradoblast: [{ city: 'Kudrovo' }],
    Saratovoblast: [{ city: 'Saratovoblast' }],
    Rostov: [{ city: 'Rostov' }, { city: 'Rostovondon' }],
    Samaraoblast: [{ city: 'Samaraoblast' }],
  },
  RW: { Kigali: [{ city: 'Kigali' }, { city: 'Kigali(kimihurura)' }] },
  SA: {
    Easternprovince: [{ city: 'Dammam' }, { city: 'Easternprovince' }],
    Meccaregion: [{ city: 'Meccaregion' }, { city: 'Makkahalmukarramah' }, { city: 'Jeddah' }],
    Jazanregion: [{ city: 'Jazanregion' }],
    Riyadhregion: [{ city: 'Riyadhregion' }, { city: 'Riyadh(gharnaah)' }, { city: 'Riyadh(alolaya)' }],
  },
  SE: { Stockholm: [{ city: 'Stockholm' }] },
  SI: { Ljubljana: [{ city: 'Ljubljana' }] },
  SK: { Bratislava: [{ city: 'Bratislava' }], Bratislavaregion: [{ city: 'Bratislavaregion' }] },
  SN: {
    Dakar: [
      { city: 'Dakar(yoff)' },
      { city: 'Dakar(sicapliberte)' },
      { city: 'Mermozboabab' },
      { city: 'Dakar(mermozsacrecour)' },
      { city: 'Dakar' },
      { city: 'Niakoulrab' },
      { city: 'Pikine' },
    ],
  },
  SO: {
    Banaadir: [{ city: 'Mogadishu' }, { city: 'Banaadir' }],
    Woqooyigalbeed: [{ city: 'Hargeisa' }, { city: 'Woqooyigalbeed' }],
  },
  SR: { Paramaribodistrict: [{ city: 'Paramaribodistrict' }] },
  SV: {
    Lalibertaddepartment: [{ city: 'Antiguocuscatlan' }, { city: 'Lalibertaddepartment' }],
    Sansalvadordepartment: [{ city: 'Sansalvadordepartment' }],
  },
  SX: { Sintmaarten: [{ city: 'Sintmaarten' }, { city: 'Philipsburg' }] },
  SY: {
    Dimashq: [
      { city: 'Damascus(almouhajrin)' },
      { city: 'Damascus(almazzeh)' },
      { city: 'Dimashq' },
      { city: 'Damascus' },
    ],
    Hama: [{ city: 'Hama' }],
    Latakia: [{ city: 'Latakia' }],
    Homs: [{ city: 'Homs' }],
    Rifdimashq: [
      { city: 'Attall' },
      { city: 'Babila' },
      { city: 'Darayya' },
      { city: 'Jaramana' },
      { city: 'Rifdimashq' },
    ],
    Aleppo: [{ city: 'Aleppo' }],
    Alhasakah: [{ city: 'Alhasakah' }, { city: 'Alasakah' }],
  },
  TC: { Caicosislands: [{ city: 'Caicosislands' }, { city: 'Cockburnharbour' }] },
  TG: { Maritime: [{ city: 'Lome' }, { city: 'Maritime' }] },
  TH: {
    Chiangmai: [{ city: 'Chiangmai' }],
    Bangkok: [
      { city: 'Phasicharoen' },
      { city: 'Phayathai' },
      { city: 'Bangkok' },
      { city: 'Bangphlat' },
      { city: 'Bangrak' },
      { city: 'Bangrak(khwaengpathumwan)' },
      { city: 'Dindaeng' },
      { city: 'Dindaeng(khwaengdindaeng)' },
      { city: 'Dindaeng(khwaenghuaikhwang)' },
      { city: 'Khwaengthungsonghong' },
      { city: 'Prakanong' },
      { city: 'Ratchathewi(khwaengsamsennai)' },
    ],
    Chiangrai: [{ city: 'Chiangrai' }],
    Chachoengsao: [{ city: 'Phanomsarakham' }, { city: 'Chachoengsao' }],
    Kanchanaburi: [{ city: 'Kanchanaburi' }],
    Ratchaburi: [{ city: 'Ratchaburi' }],
    Nakhonphanom: [{ city: 'Nakhonphanom' }],
    Nakhonratchasima: [{ city: 'Nakhonratchasima' }],
    Yala: [{ city: 'Yala' }],
    Rayong: [{ city: 'Rayong' }],
    Khonkaen: [{ city: 'Khonkaen' }],
    Chonburi: [{ city: 'Pattaya' }, { city: 'Nakluea' }, { city: 'Chonburi' }],
    Nonthaburi: [{ city: 'Pakkret' }, { city: 'Nonthaburi' }],
    Lopburi: [{ city: 'Lopburi' }],
    Sukhothai: [{ city: 'Sukhothai' }],
    Phuket: [{ city: 'Phuket' }],
    Suratthani: [{ city: 'Kosamui' }, { city: 'Suratthani' }],
    Songkhla: [{ city: 'Songkhla' }],
    Prachuapkhirikhan: [{ city: 'Prachuapkhirikhan' }, { city: 'Huahin' }],
  },
  TJ: { Dushanbe: [{ city: 'Dushanbe' }] },
  TM: { Daoguz: [{ city: 'Daoguz' }, { city: 'Dashoguz' }], Ashgabat: [{ city: 'Ashgabat' }] },
  TN: {
    Monastirgovernorate: [{ city: 'Monastirgovernorate' }],
    Sfaxgovernorate: [{ city: 'Sfaxgovernorate' }, { city: 'Gremda' }],
    Arianagovernorate: [{ city: 'Arianagovernorate' }, { city: 'Aryanah' }],
    Tunisgovernorate: [
      { city: 'Tunisgovernorate' },
      { city: 'Tunis(elmenzah)' },
      { city: 'Lagoulette' },
      { city: 'Tunis(centreurbainnord)' },
    ],
    Bizertegovernorate: [{ city: 'Bizertegovernorate' }],
    Benarousgovernorate: [{ city: 'Benarousgovernorate' }],
    Nabeulgovernorate: [{ city: 'Nabeulgovernorate' }, { city: 'Korba' }],
    Manouba: [{ city: 'Manouba' }],
    Soussegovernorate: [{ city: 'Soussegovernorate' }],
  },
  TR: {
    Bartin: [{ city: 'Bartin' }, { city: 'Ulus' }],
    Bursaprovince: [{ city: 'Bursaprovince' }, { city: 'Yeniehir' }, { city: 'Nilufer' }],
    Hatay: [
      { city: 'Reyhanli' },
      { city: 'Skenderun' },
      { city: 'Kilak' },
      { city: 'Antakya' },
      { city: 'Demirkonak' },
      { city: 'Hatay' },
    ],
    Corum: [{ city: 'Corum' }],
    Trabzon: [{ city: 'Trabzon' }],
    Rizeprovince: [{ city: 'Rizeprovince' }],
    Zmirprovince: [{ city: 'Bayrakli' }, { city: 'Izmir' }, { city: 'Cordaleo' }, { city: 'Zmirprovince' }],
    Bolu: [{ city: 'Bolu' }],
    Canakkale: [{ city: 'Canakkale' }],
    Mula: [{ city: 'Mula' }, { city: 'Marmaris' }],
    Gaziantep: [{ city: 'Yamactepe' }, { city: 'Gaziantep' }],
    Balikesir: [{ city: 'Balikesir' }],
    Mardin: [{ city: 'Yaarkoy' }, { city: 'Mardin' }],
    Mugla: [{ city: 'Fethiye' }],
    Tekirda: [{ city: 'Tekirda' }, { city: 'Corlu' }],
    Konya: [{ city: 'Konya' }, { city: 'Selcuklu' }],
    Diyarbakirprovince: [{ city: 'Diyarbakirprovince' }],
    Zonguldakprovince: [{ city: 'Zonguldakprovince' }],
    Erzurum: [{ city: 'Erzurum' }],
    Eskiehir: [{ city: 'Eskiehir' }],
    Manisa: [{ city: 'Manisa' }],
    Denizli: [{ city: 'Denizli' }],
    Adana: [{ city: 'Adana' }],
    Aydin: [{ city: 'Aydin' }],
    Sakarya: [{ city: 'Adapazari' }, { city: 'Sakarya' }],
    Giresun: [{ city: 'Giresun' }],
    Afyonkarahisarprovince: [{ city: 'Afyonkarahisarprovince' }],
    Samsun: [{ city: 'Samsun' }],
    Istanbul: [
      { city: 'Tuzla' },
      { city: 'Zeytinburnu' },
      { city: 'Umraniye' },
      { city: 'Sultangazi' },
      { city: 'Sultanbeyli' },
      { city: 'Sariyer' },
      { city: 'Maltepe' },
      { city: 'Mahmutbey' },
      { city: 'Kiziltoprak' },
      { city: 'Kartal' },
      { city: 'Pendik' },
      { city: 'Istanbul' },
      { city: 'Atakoy' },
      { city: 'Acibadem' },
      { city: 'Bahcelievler' },
      { city: 'Beyolu' },
      { city: 'Esenler' },
      { city: 'Esenyurt' },
      { city: 'Ili' },
    ],
    Ankara: [{ city: 'Ankara' }, { city: 'Etimesgut' }, { city: 'Mamak' }],
    Kayseri: [{ city: 'Ncesu' }, { city: 'Kayseri' }, { city: 'Hacilar' }],
    Antalya: [{ city: 'Antalya' }],
    Kahramanmara: [{ city: 'Kahramanmara' }],
    Anliurfa: [{ city: 'Akcakale' }, { city: 'Anliurfa' }, { city: 'Sanliurfa' }],
    Kilis: [{ city: 'Kilis' }],
    Mersin: [{ city: 'Mersin' }, { city: 'Silifke' }],
    Kirklareli: [{ city: 'Atakoy' }, { city: 'Kirklareli' }],
    Kocaeli: [{ city: 'Gebze' }, { city: 'Kocaeli' }, { city: 'Zmit' }],
    Kutahya: [{ city: 'Kutahya' }],
    Sivas: [{ city: 'Sivas' }],
    Isparta: [{ city: 'Isparta' }],
    Irnak: [{ city: 'Irnak' }],
    Amasya: [{ city: 'Amasya' }],
    Elazi: [{ city: 'Halkali' }, { city: 'Elazi' }],
  },
  TT: {
    Pointfortin: [{ city: 'Pointfortin' }],
    Diegomartinregionalcorporation: [{ city: 'Petitvalley' }, { city: 'Diegomartinregionalcorporation' }],
    Portofspain: [
      { city: 'Portofspain' },
      { city: 'Portofspain(downtown)' },
      { city: 'Portofspain(mucurapo)' },
      { city: 'Mucurapo' },
    ],
    Boroughofarima: [{ city: 'Boroughofarima' }],
    Chaguanas: [{ city: 'Chaguanas' }],
    Penaldebe: [{ city: 'Penaldebe' }],
    Tobago: [{ city: 'Scarborough' }, { city: 'Tobago' }],
    Sangregranderegionalcorporation: [{ city: 'Sangregranderegionalcorporation' }],
    Sanfernando: [{ city: 'Sanfernando' }, { city: 'Monrepos' }],
    Couvatabaquitetalparo: [{ city: 'Couvatabaquitetalparo' }],
    Princestown: [{ city: 'Princestown' }],
    Tunapunapiarco: [{ city: 'Tunapunapiarco' }, { city: 'Arouca' }],
    Sanjuanlaventille: [{ city: 'Sanjuanlaventille' }],
    Mayaro: [{ city: 'Mayaro' }, { city: 'Rioclaro' }],
  },
  TW: {
    Taipei: [{ city: 'Taipei' }, { city: 'Yinggedistrict' }],
    Taiwan: [{ city: 'Taiwan' }, { city: 'Taipei' }, { city: 'Taichung' }, { city: 'Neihudistrict' }],
  },
  TZ: { Daressalaamregion: [{ city: 'Daressalaamregion' }] },
  UA: {
    Zhytomyr: [{ city: 'Zhytomyr' }],
    Volyn: [{ city: 'Volyn' }, { city: 'Kovel' }],
    Zakarpattia: [{ city: 'Zakarpattia' }],
    Zaporizhzhia: [{ city: 'Zaporizhzhia' }, { city: 'Zaporizhzhya' }],
    Mykolaiv: [{ city: 'Kalynivka' }, { city: 'Mykolaiv' }],
    Mykolayivoblast: [{ city: 'Mykolayivoblast' }],
    Ivanofrankivsk: [{ city: 'Ivanofrankivsk' }],
    Chernihiv: [{ city: 'Chernihiv' }],
    Chernivtsi: [{ city: 'Chernivtsi' }, { city: 'Chernivtsi(leninskyidistrict)' }],
    Kirovohrad: [{ city: 'Kirovohrad' }],
    Khmelnytskyioblast: [{ city: 'Starasyniava' }],
    Khmelnytskyi: [{ city: 'Khmelnytskyi' }],
    Kharkivskaoblast: [{ city: 'Kharkivskaoblast' }],
    Kharkiv: [{ city: 'Kharkiv' }],
    Ivanofrankivskoblast: [{ city: 'Ivanofrankivskoblast' }],
    Cherkasy: [{ city: 'Cherkasy' }],
    Rivne: [{ city: 'Rivne' }],
    Odessa: [{ city: 'Odessa' }],
    Odesa: [{ city: 'Odesa' }],
    Dnipropetrovsk: [{ city: 'Dnipropetrovsk' }, { city: 'Kamianske' }],
    Donetsk: [{ city: 'Donetsk' }],
    Vinnytsia: [{ city: 'Vinnytsia' }],
    Kyiv: [{ city: 'Kyiv' }],
    Poltava: [{ city: 'Kremenchuk(avtozavodskyidistrict)' }, { city: 'Poltava' }],
    Poltavaoblast: [{ city: 'Kremenchuk(avtozavodskyidistrict)' }, { city: 'Poltavaoblast' }],
    Ternopil: [{ city: 'Ternopil' }],
    Lviv: [
      { city: 'Turka' },
      { city: 'Sambir' },
      { city: 'Lviv(halytskyidistrict)' },
      { city: 'Lviv' },
      { city: 'Drohobych' },
    ],
    Sumy: [{ city: 'Sumy' }],
    Kyivcity: [{ city: 'Kyivcity' }, { city: 'Kyiv(solomyanskyidistrict)' }, { city: 'Kyiv(shevchenkivskyidistrict)' }],
  },
  UG: { Centralregion: [{ city: 'Centralregion' }, { city: 'Kampalacentraldivision' }] },
  US: {
    Florida: [
      { city: 'Melbourne' },
      { city: 'Hollywood' },
      { city: 'Homestead' },
      { city: 'Hudson' },
      { city: 'Hialeah' },
      { city: 'Jacksonville' },
      { city: 'Jacksonville(southsideestates)' },
      { city: 'Jacksonville(southwestjacksonville)' },
      { city: 'Kissimmee' },
      { city: 'Lakeland' },
      { city: 'Largo' },
      { city: 'Lauderdalelakes' },
      { city: 'Lecanto' },
      { city: 'Leesburg' },
      { city: 'Liveoak' },
      { city: 'Margate' },
      { city: 'Tallahassee' },
      { city: 'Alafaya' },
      { city: 'Brandon' },
      { city: 'Bradenton' },
      { city: 'Boyntonbeach' },
      { city: 'Bonitasprings' },
      { city: 'Bocaraton' },
      { city: 'Baylake' },
      { city: 'Auburndale' },
      { city: 'Apopka' },
      { city: 'Aloma' },
      { city: 'Brooksville' },
      { city: 'Deltona' },
      { city: 'Grantvalkaria' },
      { city: 'Gainesville' },
      { city: 'Ftpierce' },
      { city: 'Fruitlandpark' },
      { city: 'Fortwaltonbeach' },
      { city: 'Fortmyers' },
      { city: 'Fortlauderdale(northfortlauderdale)' },
      { city: 'Florida' },
      { city: 'Englewood' },
      { city: 'Doral' },
      { city: 'Hainescity' },
      { city: 'Delraybeach' },
      { city: 'Deland' },
      { city: 'Davie' },
      { city: 'Davenport' },
      { city: 'Coralsprings(r&dpark)' },
      { city: 'Coralsprings' },
      { city: 'Coopercity' },
      { city: 'Cocoa' },
      { city: 'Clermont' },
      { city: 'Buenaventuralakes' },
      { city: 'Miami' },
      { city: 'Stpetersburg' },
      { city: 'Portorange' },
      { city: 'Portsaintlucie' },
      { city: 'Royalpalmbeach' },
      { city: 'Ruskin' },
      { city: 'Sanford' },
      { city: 'Sarasota' },
      { city: 'Sebring' },
      { city: 'Springfield' },
      { city: 'Stcloud' },
      { city: 'Pompanobeach' },
      { city: 'Stuart' },
      { city: 'Tamarac' },
      { city: 'Tampa' },
      { city: 'Thevillages' },
      { city: 'Unionpark' },
      { city: 'Venice' },
      { city: 'Verobeach' },
      { city: 'Ocoee' },
      { city: 'Miamigardens' },
      { city: 'Miramar' },
      { city: 'Newportrichey' },
      { city: 'Nokomis' },
      { city: 'Northfortmyers(hancock)' },
      { city: 'Northmiami' },
      { city: 'Northmiamibeach' },
      { city: 'Northport' },
      { city: 'Ocala' },
      { city: 'Wesleychapel' },
      { city: 'Orlando' },
      { city: 'Orlando(southwestorlando)' },
      { city: 'Ormondbeach' },
      { city: 'Palmcoast' },
      { city: 'Panamacity' },
      { city: 'Pembrokepines' },
      { city: 'Pensacola' },
      { city: 'Plantcity' },
      { city: 'Westperrine(perrine)' },
      { city: 'Wildwood' },
      { city: 'Wimauma' },
      { city: 'Zephyrhills' },
    ],
    Arizona: [
      { city: 'Tucson' },
      { city: 'Arizona' },
      { city: 'Phoenix' },
      { city: 'Sells' },
      { city: 'Surprise' },
      { city: 'Tempe' },
      { city: 'Yuma' },
    ],
    Arkansas: [
      { city: 'Pinebluff' },
      { city: 'Littlerock(rivermountain)' },
      { city: 'Littlerock' },
      { city: 'Fayetteville' },
      { city: 'Arkansas' },
    ],
    Alabama: [
      { city: 'Leeds' },
      { city: 'Tuscaloosa' },
      { city: 'Rainsville' },
      { city: 'Phenixcity' },
      { city: 'Opelika' },
      { city: 'Montgomery' },
      { city: 'Montevallo' },
      { city: 'Huntsville' },
      { city: 'Gardendale' },
      { city: 'Dothan' },
      { city: 'Decatur' },
      { city: 'Chickasaw' },
      { city: 'Brighton' },
      { city: 'Birmingham' },
      { city: 'Anniston' },
      { city: 'Alexandercity' },
      { city: 'Alabama' },
    ],
    Texas: [
      { city: 'Ysletadelsurpueblo' },
      { city: 'Wylie' },
      { city: 'Spring' },
      { city: 'Pearland' },
      { city: 'Pflugerville' },
      { city: 'Pharr' },
      { city: 'Plainview' },
      { city: 'Plano' },
      { city: 'Plano(originaldonation)' },
      { city: 'Prosper' },
      { city: 'Rosenberg' },
      { city: 'Roundrock' },
      { city: 'Sanantonio' },
      { city: 'Sanantonio(northeastside)' },
      { city: 'Sanantonio(southside)' },
      { city: 'Sugarland' },
      { city: 'Temple' },
      { city: 'Texarkana' },
      { city: 'Texas' },
      { city: 'Thewoodlands(researchforest)' },
      { city: 'Tyler' },
      { city: 'Universalcity(northeastside)' },
      { city: 'Waco' },
      { city: 'Wallisville' },
      { city: 'Waxahachie' },
      { city: 'Weslaco' },
      { city: 'Wichitafalls' },
      { city: 'Orange' },
      { city: 'Aldine' },
      { city: 'Allen' },
      { city: 'Amarillo' },
      { city: 'Arlington' },
      { city: 'Austin' },
      { city: 'Bearcreek' },
      { city: 'Beaumont' },
      { city: 'Bluemound' },
      { city: 'Lancaster' },
      { city: 'Harlingen' },
      { city: 'Houston' },
      { city: 'Houston(alief)' },
      { city: 'Houston(greatergreenspoint)' },
      { city: 'Houston(northshore)' },
      { city: 'Irving' },
      { city: 'Katy' },
      { city: 'Keller' },
      { city: 'Killeen' },
      { city: 'Klein' },
      { city: 'Kyle' },
      { city: 'Lamarque' },
      { city: 'Harkerheights' },
      { city: 'Laredo' },
      { city: 'Lewisville' },
      { city: 'Longview' },
      { city: 'Losfresnos' },
      { city: 'Lubbock' },
      { city: 'Mansfield' },
      { city: 'Mckinney' },
      { city: 'Mesquite' },
      { city: 'Midland' },
      { city: 'Mission' },
      { city: 'Odessa' },
      { city: 'Olton' },
      { city: 'Dumas' },
      { city: 'Borger' },
      { city: 'Brownsville' },
      { city: 'Cactus' },
      { city: 'Canyon' },
      { city: 'Canyonlake' },
      { city: 'Cedarhill' },
      { city: 'Corpuschristi' },
      { city: 'Cypress' },
      { city: 'Dallas' },
      { city: 'Dallas(northdallas)' },
      { city: 'Denton' },
      { city: 'Desoto' },
      { city: 'Duncanville' },
      { city: 'Edinburg' },
      { city: 'Elpaso' },
      { city: 'Elpaso(missionvalley)' },
      { city: 'Euless' },
      { city: 'Forney' },
      { city: 'Fortworth' },
      { city: 'Frisco' },
      { city: 'Fritch' },
      { city: 'Garland' },
      { city: 'Grandprairie' },
    ],
    Utah: [{ city: 'Ogden' }, { city: 'Saltlakecity' }, { city: 'Utah' }],
    Vermont: [{ city: 'Vermont' }],
    Alaska: [{ city: 'Alaska' }],
    Virginia: [
      { city: 'Alexandria' },
      { city: 'Ashburn' },
      { city: 'Blacksburg' },
      { city: 'Chantilly' },
      { city: 'Charlottesville' },
      { city: 'Chesterfieldcourthouse' },
      { city: 'Culpeper' },
      { city: 'Danville' },
      { city: 'Dulles' },
      { city: 'Suffolk' },
      { city: 'Fairfax' },
      { city: 'Fortlee' },
      { city: 'Greenville' },
      { city: 'Manassas' },
      { city: 'Martinsville' },
      { city: 'Norfolk' },
      { city: 'Reston' },
      { city: 'Richmond' },
      { city: 'Richmond(southrichmond)' },
      { city: 'Richmond(thewestend)' },
      { city: 'Roanoke' },
      { city: 'Sterling' },
      { city: 'Virginia' },
      { city: 'Virginiabeach' },
    ],
    Wisconsin: [
      { city: 'Greenbay' },
      { city: 'Browndeer' },
      { city: 'Cedarburg' },
      { city: 'Fonddulac' },
      { city: 'Germantown' },
      { city: 'Jackson' },
      { city: 'Lomira' },
      { city: 'Madison' },
      { city: 'Menomoneefalls' },
      { city: 'Milton' },
      { city: 'Milwaukee' },
      { city: 'Oconomowoc' },
      { city: 'Racine' },
      { city: 'Westbend' },
      { city: 'Wisconsin' },
    ],
    Washington: [
      { city: 'Tacoma' },
      { city: 'Tukwila' },
      { city: 'Vancouver' },
      { city: 'Washington' },
      { city: 'Yakima' },
      { city: 'Seattle' },
      { city: 'Burien' },
      { city: 'Everett' },
      { city: 'Federalway' },
      { city: 'Kennewick' },
    ],
    Westvirginia: [{ city: 'Charleston' }, { city: 'Clarksburg' }, { city: 'Westvirginia' }],
    Wyoming: [{ city: 'Cheyenne' }, { city: 'Wyoming' }],
    Tennessee: [
      { city: 'Maryville' },
      { city: 'Algood' },
      { city: 'Bartlett' },
      { city: 'Blountville' },
      { city: 'Chattanooga' },
      { city: 'Chattanooga(citycenter)' },
      { city: 'Clarksville' },
      { city: 'Cleveland' },
      { city: 'Columbia' },
      { city: 'Germantown' },
      { city: 'Greeneville' },
      { city: 'Jackson' },
      { city: 'Knoxville' },
      { city: 'Mckenzie' },
      { city: 'Memphis' },
      { city: 'Murfreesboro' },
      { city: 'Nashville' },
      { city: 'Tennessee' },
    ],
    Southdakota: [{ city: 'Southdakota' }],
    Southcarolina: [
      { city: 'Kingstree' },
      { city: 'Beaufort' },
      { city: 'Bluffton' },
      { city: 'Charleston' },
      { city: 'Columbia' },
      { city: 'Conway' },
      { city: 'Darlington' },
      { city: 'Florence' },
      { city: 'Forestbrook' },
      { city: 'Greenville' },
      { city: 'Hartsville' },
      { city: 'Hiltonheadisland' },
      { city: 'Irmo' },
      { city: 'Ladson' },
      { city: 'Lexington' },
      { city: 'Littleriver' },
      { city: 'Monckscorner' },
      { city: 'Murrellsinlet' },
      { city: 'Myrtlebeach' },
      { city: 'Orangeburg' },
      { city: 'Rockhill' },
      { city: 'Simpsonville' },
      { city: 'Southcarolina' },
      { city: 'Spartanburg' },
      { city: 'Summerville' },
      { city: 'Anderson' },
    ],
    Colorado: [
      { city: 'Arvada' },
      { city: 'Aurora' },
      { city: 'Colorado' },
      { city: 'Coloradosprings' },
      { city: 'Denver' },
      { city: 'Englewood(denvertechcenter)' },
      { city: 'Fortcollins' },
      { city: 'Greeley' },
      { city: 'Highlandsranch' },
      { city: 'Littleton' },
      { city: 'Pueblo' },
      { city: 'Stratmoor' },
    ],
    Oregon: [
      { city: 'Eugene' },
      { city: 'Beaverton' },
      { city: 'Oregon' },
      { city: 'Troutdale' },
      { city: 'Portland' },
    ],
    Michigan: [
      { city: 'Southfield' },
      { city: 'Taylor' },
      { city: 'Troy' },
      { city: 'Ubly' },
      { city: 'Unionville' },
      { city: 'Vassar' },
      { city: 'Walledlake' },
      { city: 'Wayne' },
      { city: 'Yale' },
      { city: 'Muskegon' },
      { city: 'Grandrapids' },
      { city: 'Holland' },
      { city: 'Kalamazoo' },
      { city: 'Kingston' },
      { city: 'Livonia' },
      { city: 'Marlette' },
      { city: 'Mayville' },
      { city: 'Melvindale' },
      { city: 'Michigan' },
      { city: 'Millington' },
      { city: 'Mtmorris' },
      { city: 'Northbranch' },
      { city: 'Pigeon' },
      { city: 'Pontiac' },
      { city: 'Roseville' },
      { city: 'Ruth' },
      { city: 'Saginaw' },
      { city: 'Sandusky' },
      { city: 'Sebewaing' },
      { city: 'Frankenmuth' },
      { city: 'Caro' },
      { city: 'Badaxe' },
      { city: 'Baldwin' },
      { city: 'Baycity' },
      { city: 'Bessemer' },
      { city: 'Browncity' },
      { city: 'Casscity' },
      { city: 'Chelsea' },
      { city: 'Clintontownship' },
      { city: 'Columbiaville' },
      { city: 'Croswell' },
      { city: 'Dearborn' },
      { city: 'Detroit' },
      { city: 'Detroit(airportsub)' },
      { city: 'Fairgrove' },
      { city: 'Flint' },
    ],
    Ohio: [
      { city: 'Northolmsted' },
      { city: 'Youngstown' },
      { city: 'Warren' },
      { city: 'Wapakoneta' },
      { city: 'Valleyview' },
      { city: 'Trotwood' },
      { city: 'Toledo' },
      { city: 'Springfield' },
      { city: 'Portsmouth' },
      { city: 'Ohio' },
      { city: 'Obetz' },
      { city: 'Norwalk' },
      { city: 'Zanesville' },
      { city: 'Mounthealthy' },
      { city: 'Mentor' },
      { city: 'Massillon' },
      { city: 'Mapleheights' },
      { city: 'Mansfield' },
      { city: 'Lyndhurst' },
      { city: 'Louisville' },
      { city: 'Lorain' },
      { city: 'Lima' },
      { city: 'Kent' },
      { city: 'Hudson' },
      { city: 'Berea' },
      { city: 'Elyria' },
      { city: 'Eaton' },
      { city: 'Eastcleveland' },
      { city: 'Dayton' },
      { city: 'Columbus(sharonheights)' },
      { city: 'Columbus' },
      { city: 'Cleveland' },
      { city: 'Cincinnati(centralbusinessdistrict)' },
      { city: 'Cincinnati' },
      { city: 'Canton' },
      { city: 'Bridgetownnorth' },
      { city: 'Akron' },
    ],
    Connecticut: [
      { city: 'Stamford' },
      { city: 'Bloomfield' },
      { city: 'Branford' },
      { city: 'Bridgeport' },
      { city: 'Connecticut' },
      { city: 'Hamden' },
      { city: 'Hartford' },
      { city: 'Killingworth' },
      { city: 'Meriden' },
      { city: 'Newhaven' },
      { city: 'Newtown' },
      { city: 'Norwalk' },
      { city: 'Wallingford' },
      { city: 'Waterbury' },
    ],
    Oklahoma: [
      { city: 'Weatherford' },
      { city: 'Tulsa' },
      { city: 'Oklahomacity' },
      { city: 'Oklahoma' },
      { city: 'Norman' },
      { city: 'Kingfisher' },
      { city: 'Idabel' },
      { city: 'Edmond' },
    ],
    Massachusetts: [
      { city: 'Brockton' },
      { city: 'Amherst' },
      { city: 'Boston' },
      { city: 'Boston(roxbury)' },
      { city: 'Boston(westroxbury)' },
      { city: 'Chicopee' },
      { city: 'Dalton' },
      { city: 'Foxborough' },
      { city: 'Greenfield' },
      { city: 'Groveland' },
      { city: 'Holyoke' },
      { city: 'Lawrence' },
      { city: 'Mashpee' },
      { city: 'Massachusetts' },
      { city: 'Maynard' },
      { city: 'Quincy' },
      { city: 'Sheffield' },
      { city: 'Springfield' },
      { city: 'Westfield' },
      { city: 'Woburn' },
      { city: 'Worcester' },
      { city: 'Plymouth' },
      { city: 'Northampton' },
      { city: 'Pittsfield' },
    ],
    Maryland: [
      { city: 'Maryland' },
      { city: 'Monkton' },
      { city: 'Rockville' },
      { city: 'Salisbury' },
      { city: 'Sudlersville' },
      { city: 'Whitemarsh' },
      { city: 'Worton' },
      { city: 'Lanham' },
      { city: 'Baltimore' },
      { city: 'Baltimore(reisterstownstation)' },
      { city: 'Capitolheights' },
      { city: 'Centreville' },
      { city: 'Frederick' },
      { city: 'Gaithersburg' },
      { city: 'Gambrills' },
      { city: 'Germantown' },
      { city: 'Hagerstown' },
    ],
    Louisiana: [
      { city: 'Slidell' },
      { city: 'Batonrouge' },
      { city: 'Gonzales' },
      { city: 'Hammond' },
      { city: 'Lafayette' },
      { city: 'Lakecharles' },
      { city: 'Louisiana' },
      { city: 'Marrero' },
      { city: 'Monroe' },
      { city: 'Neworleans' },
      { city: 'Opelousas' },
      { city: 'Shreveport' },
    ],
    Pennsylvania: [
      { city: 'Stroudsburg' },
      { city: 'Erie' },
      { city: 'Scranton' },
      { city: 'Rosstownship' },
      { city: 'Pittsburgh' },
      { city: 'Philadelphia(northeastphiladelphia)' },
      { city: 'Philadelphia' },
      { city: 'Pennsylvania' },
      { city: 'Palmerton' },
      { city: 'Linglestown' },
      { city: 'Lebanon' },
      { city: 'Lancaster' },
      { city: 'Harrisburg' },
      { city: 'Eddington' },
      { city: 'Easton' },
      { city: 'Coatesville' },
      { city: 'Bushkill' },
      { city: 'Blairsville' },
      { city: 'Bethlehem' },
      { city: 'Bethelpark' },
      { city: 'Allentown' },
    ],
    Maine: [{ city: 'Maine' }],
    Newyork: [
      { city: 'Yonkers' },
      { city: 'Williamsville' },
      { city: 'Whiteplains' },
      { city: 'Wappingersfalls' },
      { city: 'Utica' },
      { city: 'Syracuse' },
      { city: 'Statenisland' },
      { city: 'Schenectady' },
      { city: 'Ryebrook' },
      { city: 'Rochester(downtown)' },
      { city: 'Rochester' },
      { city: 'Queensvillage' },
      { city: 'Albany' },
      { city: 'Jamaica' },
      { city: 'Queens' },
      { city: 'Poughkeepsie' },
      { city: 'Niagarafalls' },
      { city: 'Newyork(brooklyn)' },
      { city: 'Newyork' },
      { city: 'Newburgh' },
      { city: 'Mountvernon' },
      { city: 'Middletown' },
      { city: 'Mamaroneck' },
      { city: 'Lynbrook' },
      { city: 'Loudonville' },
      { city: 'Islip' },
      { city: 'Hicksville' },
      { city: 'Freeport' },
      { city: 'Flushing' },
      { city: 'Farmingville' },
      { city: 'Coram' },
      { city: 'Buffalo' },
      { city: 'Brooklyn' },
      { city: 'Binghamton' },
      { city: 'Babylon' },
    ],
    Northcarolina: [
      { city: 'Asheboro' },
      { city: 'Asheville' },
      { city: 'Goldsboro' },
      { city: 'Knightdale' },
      { city: 'Kingsmountain' },
      { city: 'Kernersville' },
      { city: 'Kannapolis' },
      { city: 'Hopemills' },
      { city: 'Hollyridge' },
      { city: 'Highpoint' },
      { city: 'Hickory' },
      { city: 'Harrisburg' },
      { city: 'Halfmoon' },
      { city: 'Greensboro' },
      { city: 'Gastonia' },
      { city: 'Garner' },
      { city: 'Fayetteville' },
      { city: 'Elizabethcity' },
      { city: 'Durham' },
      { city: 'Concord' },
      { city: 'Charlotte' },
      { city: 'Cary(highmeadows)' },
      { city: 'Burlington' },
      { city: 'Buiescreek' },
      { city: 'Laurinburg' },
      { city: 'Raeford' },
      { city: 'Wendell' },
      { city: 'Wakeforest' },
      { city: 'Thomasville' },
      { city: 'Statesville' },
      { city: 'Springlake' },
      { city: 'Shelby' },
      { city: 'Selma' },
      { city: 'Salisbury' },
      { city: 'Rockymount' },
      { city: 'Rockingham' },
      { city: 'Raleigh' },
      { city: 'Wentworth' },
      { city: 'Pfafftown' },
      { city: 'Northcarolina' },
      { city: 'Morrisville' },
      { city: 'Mooresville' },
      { city: 'Monroe' },
      { city: 'Mebane' },
      { city: 'Lumberton' },
      { city: 'Lincolnton' },
      { city: 'Lexington' },
      { city: 'Leland' },
      { city: 'Winstonsalem' },
      { city: 'Wilson' },
      { city: 'Wilmington' },
    ],
    Newjersey: [
      { city: 'Eastwindsor' },
      { city: 'Eatontown' },
      { city: 'Edison' },
      { city: 'Elizabeth' },
      { city: 'Elmwoodpark' },
      { city: 'Fortlee' },
      { city: 'Hackensack' },
      { city: 'Harrison' },
      { city: 'Jacksontownship' },
      { city: 'Lyndhurst' },
      { city: 'Morristown' },
      { city: 'Mountlaureltownship' },
      { city: 'Newark' },
      { city: 'Newjersey' },
      { city: 'Northbergen' },
      { city: 'Parsippanytroyhillstownship' },
      { city: 'Paterson' },
      { city: 'Piscataway' },
      { city: 'Randolphtownship' },
      { city: 'Sayreville' },
      { city: 'Secaucus' },
      { city: 'Trenton' },
      { city: 'Union' },
      { city: 'Vineland' },
      { city: 'Washingtontownship(turnersville)' },
      { city: 'Absecon' },
      { city: 'Audubon' },
      { city: 'Bloomingdale' },
      { city: 'Camden' },
      { city: 'Clifton' },
    ],
    Delaware: [{ city: 'Dover' }, { city: 'Delaware' }, { city: 'Millsboro' }, { city: 'Newcastle' }],
    Newmexico: [{ city: 'Albuquerque' }, { city: 'Clovis' }, { city: 'Lascruces' }, { city: 'Newmexico' }],
    Missouri: [
      { city: 'Charlack' },
      { city: 'Chesterfield' },
      { city: 'Eldoradosprings' },
      { city: 'Florissant' },
      { city: 'Independence' },
      { city: 'Kansascity' },
      { city: 'Missouri' },
      { city: 'Ofallon' },
      { city: 'Smithville' },
      { city: 'Stlouis' },
    ],
    Mississippi: [
      { city: 'Hattiesburg' },
      { city: 'Hornlake' },
      { city: 'Jackson' },
      { city: 'Longbeach' },
      { city: 'Meridian' },
      { city: 'Mississippi' },
      { city: 'Oxford' },
      { city: 'Pascagoula' },
      { city: 'Ridgeland' },
      { city: 'Tupelo' },
      { city: 'Vicksburg' },
      { city: 'Gulfport' },
      { city: 'Biloxi' },
      { city: 'Brookhaven' },
      { city: 'Clarksdale' },
      { city: 'Columbus' },
      { city: 'Corinth' },
    ],
    Districtofcolumbia: [
      { city: 'Washingtondc(capitolhill)' },
      { city: 'Washingtondc' },
      { city: 'Washington' },
      { city: 'Districtofcolumbia' },
    ],
    Minnesota: [{ city: 'Maplegrove' }, { city: 'Minneapolis' }, { city: 'Minnesota' }, { city: 'Roseville' }],
    Newhampshire: [{ city: 'Londonderry' }, { city: 'Exeter' }, { city: 'Newhampshire' }],
    Montana: [{ city: 'Montana' }],
    Nebraska: [{ city: 'Lincoln' }, { city: 'Nebraska' }, { city: 'Omaha' }],
    Nevada: [{ city: 'Lasvegas' }, { city: 'Nevada' }, { city: 'Northlasvegas' }, { city: 'Reno' }],
    Northdakota: [{ city: 'Northdakota' }],
    Illinois: [
      { city: 'Eastpeoria' },
      { city: 'Bellwood' },
      { city: 'Champaign' },
      { city: 'Chicago' },
      { city: 'Chicago(nearsouthside)' },
      { city: 'Chicago(southside)' },
      { city: 'Algonquin' },
      { city: 'Elmhurst' },
      { city: 'Westchicago' },
      { city: 'Swansea' },
      { city: 'Springfield' },
      { city: 'Roxana' },
      { city: 'Romeoville' },
      { city: 'Rockford' },
      { city: 'Homewood' },
      { city: 'Peoria' },
      { city: 'Oakpark' },
      { city: 'Northchicago' },
      { city: 'Mountprospect' },
      { city: 'Moline' },
      { city: 'Riverforest' },
      { city: 'Mchenry' },
      { city: 'Illinois' },
    ],
    California: [
      { city: 'Anaheim' },
      { city: 'Bakersfield' },
      { city: 'Baldwinhills' },
      { city: 'Bellflower' },
      { city: 'Brea' },
      { city: 'California' },
      { city: 'Carlsbad' },
      { city: 'Chiriacosummit' },
      { city: 'Corona' },
      { city: 'Covina' },
      { city: 'Dalycity' },
      { city: 'Delano' },
      { city: 'Aguadulce' },
      { city: 'Losangeles(southlosangeles)' },
      { city: 'Lamesa' },
      { city: 'Lamirada' },
      { city: 'Lamont' },
      { city: 'Longbeach' },
      { city: 'Longbeach(eastranchodominguez)' },
      { city: 'Losangeles' },
      { city: 'Losangeles(canogapark)' },
      { city: 'Losangeles(downtownlosangeles)' },
      { city: 'Losangeles(sherwoodforest)' },
      { city: 'Lakewood' },
      { city: 'Losangeles(tujunga)' },
      { city: 'Losangeles(valleyglen)' },
      { city: 'Madera' },
      { city: 'Modesto' },
      { city: 'Monrovia' },
      { city: 'Montereypark' },
      { city: 'Morenovalley' },
      { city: 'Norwalk' },
      { city: 'Haciendaheights' },
      { city: 'Devoreheights' },
      { city: 'Eastranchodominguez' },
      { city: 'Fairfield' },
      { city: 'Florin' },
      { city: 'Fontana' },
      { city: 'Fresno' },
      { city: 'Fullerton' },
      { city: 'Gardena' },
      { city: 'Gardengrove' },
      { city: 'Hawthorne' },
      { city: 'Hayward' },
      { city: 'Hemet' },
      { city: 'Hesperia' },
      { city: 'Huntingtonbeach' },
      { city: 'Inglewood' },
      { city: 'Irvine' },
      { city: 'Lakeelsinore' },
      { city: 'Azusa' },
      { city: 'Oakland' },
      { city: 'Sanjose(edenvale)' },
      { city: 'Riverside' },
      { city: 'Ranchocucamonga' },
      { city: 'Porterville' },
      { city: 'Pomona' },
      { city: 'Pleasanton' },
      { city: 'Pittsburg' },
      { city: 'Picorivera' },
      { city: 'Rohnertpark' },
      { city: 'Pasadena' },
      { city: 'Palmdesert' },
      { city: 'Palmdale' },
      { city: 'Oxnard' },
      { city: 'Sanjose(downtownsanjose)' },
      { city: 'Ontario' },
      { city: 'Sanjose' },
      { city: 'Vista' },
      { city: 'Santamonica' },
      { city: 'Scottsvalley' },
      { city: 'Stockton' },
      { city: 'Santamaria' },
      { city: 'Temecula' },
      { city: 'Turlock' },
      { city: 'Santaclara' },
      { city: 'Victorville' },
      { city: 'Perris' },
      { city: 'Watsonville' },
      { city: 'Westcovina' },
      { city: 'Westsacramento' },
      { city: 'Whittier' },
      { city: 'Wilmington' },
      { city: 'Sacramento' },
      { city: 'Santaana' },
      { city: 'Oakglen' },
      { city: 'Sanfrancisco' },
      { city: 'Sanfernando' },
      { city: 'Sandiego' },
      { city: 'Sanbernardino' },
    ],
    Georgia: [
      { city: 'Suwanee' },
      { city: 'Pooler' },
      { city: 'Powdersprings' },
      { city: 'Riverdale' },
      { city: 'Rome' },
      { city: 'Savannah' },
      { city: 'Smyrna' },
      { city: 'Snellville' },
      { city: 'Stockbridge' },
      { city: 'Stonemountain' },
      { city: 'Thomasville' },
      { city: 'Valdosta' },
      { city: 'Woodstock' },
      { city: 'Newnan' },
      { city: 'Jonesboro' },
      { city: 'Decatur' },
      { city: 'Douglas' },
      { city: 'Douglasville' },
      { city: 'Fairburn' },
      { city: 'Fayetteville' },
      { city: 'Fitzgerald' },
      { city: 'Georgia' },
      { city: 'Griffin' },
      { city: 'Hinesville' },
      { city: 'Dalton' },
      { city: 'Lagrange' },
      { city: 'Lawrenceville' },
      { city: 'Lilburn' },
      { city: 'Lithonia' },
      { city: 'Macon' },
      { city: 'Marietta' },
      { city: 'Mcdonough' },
      { city: 'Milledgeville' },
      { city: 'Atlanta(perimetercenter)' },
      { city: 'Albany' },
      { city: 'Alpharetta' },
      { city: 'Athens' },
      { city: 'Atlanta' },
      { city: 'Atlanta(downtownatlanta)' },
      { city: 'Augusta' },
      { city: 'Austell' },
      { city: 'Buford' },
      { city: 'Carrollton' },
      { city: 'Columbus' },
      { city: 'Commerce' },
      { city: 'Conyers' },
      { city: 'Covington' },
    ],
    Hawaii: [{ city: 'Hawaii' }, { city: 'Honolulu' }],
    Idaho: [{ city: 'Boise' }, { city: 'Idaho' }],
    Indiana: [
      { city: 'Evansville' },
      { city: 'Newalbany' },
      { city: 'Mishawaka' },
      { city: 'Lawrence' },
      { city: 'Indianapolis' },
      { city: 'Indiana' },
      { city: 'Hammond' },
      { city: 'Hagerstown' },
      { city: 'Griffith' },
      { city: 'Gaston' },
      { city: 'Fortwayne' },
      { city: 'Noblesville' },
      { city: 'Elwood' },
      { city: 'Economy' },
      { city: 'Daleville' },
      { city: 'Connersville' },
      { city: 'Bloomington' },
      { city: 'Attica' },
      { city: 'Anderson' },
      { city: 'Yorktown' },
      { city: 'Watson' },
      { city: 'Terrehaute' },
      { city: 'Sheridan' },
      { city: 'Richmond' },
    ],
    Rhodeisland: [{ city: 'Eastprovidence' }, { city: 'Providence' }, { city: 'Rhodeisland' }],
    Kentucky: [
      { city: 'Ashland' },
      { city: 'Paris' },
      { city: 'Owensboro' },
      { city: 'Louisville' },
      { city: 'Lexingtonfayette' },
      { city: 'Kentucky' },
      { city: 'Hopkinsville' },
      { city: 'Heritagecreek' },
      { city: 'Hadley' },
      { city: 'Flemingsburg' },
      { city: 'Ewing' },
      { city: 'Edgewood' },
      { city: 'Brooksville' },
      { city: 'Barbourville' },
    ],
    Kansas: [
      { city: 'Wichita' },
      { city: 'Topeka' },
      { city: 'Kansascity' },
      { city: 'Kansas' },
      { city: 'Coffeyville' },
    ],
    Iowa: [{ city: 'Iowa' }, { city: 'Desmoines' }, { city: 'Davenport' }],
  },
  UY: {
    Canelones: [{ city: 'Canelones' }, { city: 'Pasodecarrasco' }],
    Montevideodepartment: [{ city: 'Montevideodepartment' }],
    Maldonado: [{ city: 'Puntadeleste' }, { city: 'Maldonado' }],
  },
  UZ: {
    Samarqandregion: [{ city: 'Samarqandregion' }],
    Tashkent: [{ city: 'Tashkent(shayxontohurtumani)' }, { city: 'Tashkent' }],
  },
  VC: { Saintgeorgeparish: [{ city: 'Saintgeorgeparish' }, { city: 'Kingstown' }] },
  VE: {
    Aragua: [{ city: 'Maracay' }, { city: 'Aragua' }],
    Zulia: [{ city: 'Sancarlosdelzulia' }, { city: 'Zulia' }, { city: 'Sanfrancisco' }, { city: 'Maracaibo' }],
    Merida: [{ city: 'Merida' }, { city: 'Elvigia' }],
    Anzoategui: [{ city: 'Anzoategui' }],
    Carabobo: [{ city: 'Carabobo' }, { city: 'Valencia' }],
    Miranda: [{ city: 'Miranda' }, { city: 'Caracas(lospalosgrandes)' }],
    Estadolaguaira: [{ city: 'Estadolaguaira' }],
    Distritofederal: [{ city: 'Caracas(zonaindustriallatrinidad)' }, { city: 'Distritofederal' }, { city: 'Caracas' }],
    Bolivar: [{ city: 'Bolivar' }],
    Lara: [{ city: 'Barquisimeto' }, { city: 'Barquisimeto(urbanizacionfundalara)' }, { city: 'Lara' }],
    Portuguesa: [{ city: 'Portuguesa' }],
    Falcon: [{ city: 'Falcon' }, { city: 'Coro' }],
  },
  VG: { Tortola: [{ city: 'Roadtown' }, { city: 'Tortola' }] },
  VI: { Saintthomasisland: [{ city: 'Charlotteamalie' }, { city: 'Saintthomasisland' }] },
  VN: {
    Lngsnprovince: [{ city: 'Lngsnprovince' }, { city: 'Langson' }],
    Dngnaiprovince: [{ city: 'Dngnaiprovince' }, { city: 'Bienhoa' }],
    Binhphcprovince: [{ city: 'Binhphcprovince' }],
    Binhdnhprovince: [{ city: 'Quinhon' }, { city: 'Binhdnhprovince' }],
    Binhthunprovince: [{ city: 'Binhthunprovince' }],
    Dngthapprovince: [{ city: 'Dngthapprovince' }],
    Qungnamprovince: [{ city: 'Qungnamprovince' }],
    Lamdngprovince: [{ city: 'Lamdngprovince' }, { city: 'Dalat' }],
    Danang: [{ city: 'Danang' }],
    Daknongprovince: [{ city: 'Daknongprovince' }],
    Kiengiangprovince: [{ city: 'Kiengiangprovince' }],
    Khanhhoaprovince: [{ city: 'Nhatrang' }, { city: 'Khanhhoaprovince' }],
    Baravungtauprovince: [{ city: 'Baravungtauprovince' }],
    Binhdngprovince: [{ city: 'Thunan' }, { city: 'Binhdngprovince' }, { city: 'Thudaumot' }],
    Qungninh: [{ city: 'Qungninh' }],
    Phuthprovince: [{ city: 'Phuthprovince' }],
    Bcgiangprovince: [{ city: 'Bcgiangprovince' }],
    Bcninhprovince: [{ city: 'Bcninhprovince' }],
    Angiangprovince: [{ city: 'Angiangprovince' }],
    Nghanprovince: [{ city: 'Nghanprovince' }],
    Hatinhprovince: [{ city: 'Hatinhprovince' }],
    Hochiminh: [
      { city: 'Qunbinhthnh' },
      { city: 'Qunba' },
      { city: 'Hocmon' },
      { city: 'Hochiminhcity' },
      { city: 'Hochiminh' },
      { city: 'Qunbn' },
      { city: 'Quntanphu' },
      { city: 'Thdc' },
      { city: 'Tantuc' },
      { city: 'Tandinh' },
      { city: 'Qunsau' },
      { city: 'Qunphunhun' },
      { city: 'Qunmt' },
      { city: 'Qunmi' },
      { city: 'Qunhai' },
      { city: 'Qunchin' },
    ],
    Hoabinhprovince: [{ city: 'Hoabinhprovince' }],
    Hngyenprovince: [{ city: 'Hngyenprovince' }],
    Hidngprovince: [{ city: 'Hidngprovince' }],
    Hanoi: [
      { city: 'Thanhxuan' },
      { city: 'Langthng' },
      { city: 'Hoankim' },
      { city: 'Hanoi' },
      { city: 'Haibatrng' },
      { city: 'Hadong' },
      { city: 'Cugiy' },
      { city: 'Cudin' },
    ],
    Longanpovince: [{ city: 'Longanpovince' }],
    Camauprovince: [{ city: 'Camauprovince' }],
    Dklk: [{ city: 'Dklk' }, { city: 'Buonmathuot' }],
    Cantho: [{ city: 'Cairang' }, { city: 'Cantho' }],
    Vinhphucprovince: [{ city: 'Vinhphucprovince' }],
    Tayninhprovince: [{ city: 'Tayninhprovince' }],
    Haiphong: [{ city: 'Haiphong' }],
    Namdnhprovince: [{ city: 'Namdnhprovince' }],
    Gialaiprovince: [{ city: 'Gialaiprovince' }],
    Snlaprovince: [{ city: 'Snlaprovince' }],
    Soctrangprovince: [{ city: 'Soctrangprovince' }],
    Tingiang: [{ city: 'Tingiang' }],
    Thanhhoaprovince: [{ city: 'Thanhhoaprovince' }],
    Thainguyenprovince: [{ city: 'Thainguyenprovince' }],
    Thaibinhprovince: [{ city: 'Thaibinhprovince' }],
    Bntreprovince: [{ city: 'Bntreprovince' }],
  },
  XK: {
    Pristina: [{ city: 'Pristina' }, { city: 'Lipljan' }],
    Prizren: [{ city: 'Pagarua' }, { city: 'Prizren' }],
    Pec: [{ city: 'Pec' }],
    Ferizaj: [{ city: 'Ferizaj' }],
    Gjakova: [{ city: 'Gjakova' }],
    Gjilan: [{ city: 'Gjilan' }],
    Mitrovica: [{ city: 'Mitrovica' }, { city: 'Vushtrri' }],
  },
  YE: { Amanatalasimah: [{ city: 'Amanatalasimah' }, { city: 'Sanaa' }] },
  ZA: {
    Gauteng: [
      { city: 'Randburg(blackheath)' },
      { city: 'Pretoria' },
      { city: 'Randburg' },
      { city: 'Randburg(fairland)' },
      { city: 'Randburg(newlands)' },
      { city: 'Sandton(bryanston)' },
      { city: 'Sandton(edenburg)' },
      { city: 'Sandton(sandhurst)' },
      { city: 'Soweto' },
      { city: 'Westonaria' },
      { city: 'Centurion' },
      { city: 'Centurion(highveld)' },
      { city: 'Centurion(highveldtechnopark)' },
      { city: 'Gauteng' },
      { city: 'Johannesburg' },
      { city: 'Johannesburg(waverley)' },
      { city: 'Kemptonpark(isando)' },
      { city: 'Kemptonpark(serengetigolfandwildlifeestate)' },
      { city: 'Midrand(halfwayhouseestate)' },
      { city: 'Midrand(midridgepark)' },
      { city: 'Nigel' },
    ],
    Westerncape: [
      { city: 'Rondebosch' },
      { city: 'Retreat' },
      { city: 'Kraaifontein' },
      { city: 'Capetown(greenpoint)' },
      { city: 'Capetown(golfcourse)' },
      { city: 'Capetown(gardens)' },
      { city: 'Capetown(claremont)' },
      { city: 'Rosebank' },
      { city: 'Capetown(capetowncitycentre)' },
      { city: 'Capetown' },
      { city: 'Worcester' },
      { city: 'Westerncape' },
      { city: 'Stellenbosch(stellenboschcentral)' },
      { city: 'Stellenbosch' },
    ],
    Kwazulunatal: [{ city: 'Berea' }, { city: 'Durban' }, { city: 'Kwazulunatal' }],
    Limpopo: [{ city: 'Limpopo' }, { city: 'Thohoyandou' }],
    Easterncape: [{ city: 'Easterncape' }, { city: 'Portelizabeth(millpark)' }, { city: 'Portelizabeth' }],
    Northerncape: [{ city: 'Northerncape' }],
    Freestate: [{ city: 'Freestate' }],
    Mpumalanga: [{ city: 'Mpumalanga' }],
    Northwest: [{ city: 'Letlhabile' }, { city: 'Northwest' }, { city: 'Rustenburg' }],
  },
  ZM: { Lusakaprovince: [{ city: 'Lusakaprovince' }] },
  ZW: { Harare: [{ city: 'Harare' }] },
};

export const NETNUT_COUNTRIES = [
  { value: 'AE', label: 'countriesregions:countries.AE' },
  { value: 'AG', label: 'countriesregions:countries.AG' },
  { value: 'AI', label: 'countriesregions:countries.AI' },
  { value: 'AL', label: 'countriesregions:countries.AL' },
  { value: 'AM', label: 'countriesregions:countries.AM' },
  { value: 'AO', label: 'countriesregions:countries.AO' },
  { value: 'AR', label: 'countriesregions:countries.AR' },
  { value: 'AT', label: 'countriesregions:countries.AT' },
  { value: 'AU', label: 'countriesregions:countries.AU' },
  { value: 'AZ', label: 'countriesregions:countries.AZ' },
  { value: 'BA', label: 'countriesregions:countries.BA' },
  { value: 'BB', label: 'countriesregions:countries.BB' },
  { value: 'BD', label: 'countriesregions:countries.BD' },
  { value: 'BE', label: 'countriesregions:countries.BE' },
  { value: 'BF', label: 'countriesregions:countries.BF' },
  { value: 'BG', label: 'countriesregions:countries.BG' },
  { value: 'BH', label: 'countriesregions:countries.BH' },
  { value: 'BJ', label: 'countriesregions:countries.BJ' },
  { value: 'BM', label: 'countriesregions:countries.BM' },
  { value: 'BN', label: 'countriesregions:countries.BN' },
  { value: 'BO', label: 'countriesregions:countries.BO' },
  { value: 'BR', label: 'countriesregions:countries.BR' },
  { value: 'BS', label: 'countriesregions:countries.BS' },
  { value: 'BW', label: 'countriesregions:countries.BW' },
  { value: 'BY', label: 'countriesregions:countries.BY' },
  { value: 'BZ', label: 'countriesregions:countries.BZ' },
  { value: 'CA', label: 'countriesregions:countries.CA' },
  { value: 'CD', label: 'countriesregions:countries.CD' },
  { value: 'CG', label: 'countriesregions:countries.CG' },
  { value: 'CH', label: 'countriesregions:countries.CH' },
  { value: 'CI', label: 'countriesregions:countries.CI' },
  { value: 'CL', label: 'countriesregions:countries.CL' },
  { value: 'CM', label: 'countriesregions:countries.CM' },
  { value: 'CN', label: 'countriesregions:countries.CN' },
  { value: 'CO', label: 'countriesregions:countries.CO' },
  { value: 'CR', label: 'countriesregions:countries.CR' },
  { value: 'CW', label: 'countriesregions:countries.CW' },
  { value: 'CY', label: 'countriesregions:countries.CY' },
  { value: 'CZ', label: 'countriesregions:countries.CZ' },
  { value: 'DE', label: 'countriesregions:countries.DE' },
  { value: 'DK', label: 'countriesregions:countries.DK' },
  { value: 'DM', label: 'countriesregions:countries.DM' },
  { value: 'DO', label: 'countriesregions:countries.DO' },
  { value: 'DZ', label: 'countriesregions:countries.DZ' },
  { value: 'EC', label: 'countriesregions:countries.EC' },
  { value: 'EE', label: 'countriesregions:countries.EE' },
  { value: 'EG', label: 'countriesregions:countries.EG' },
  { value: 'ES', label: 'countriesregions:countries.ES' },
  { value: 'ET', label: 'countriesregions:countries.ET' },
  { value: 'FI', label: 'countriesregions:countries.FI' },
  { value: 'FJ', label: 'countriesregions:countries.FJ' },
  { value: 'FR', label: 'countriesregions:countries.FR' },
  { value: 'GA', label: 'countriesregions:countries.GA' },
  { value: 'UK', label: 'countriesregions:countries.GB' },
  { value: 'GD', label: 'countriesregions:countries.GD' },
  { value: 'GE', label: 'countriesregions:countries.GE' },
  { value: 'GH', label: 'countriesregions:countries.GH' },
  { value: 'GR', label: 'countriesregions:countries.GR' },
  { value: 'GT', label: 'countriesregions:countries.GT' },
  { value: 'GY', label: 'countriesregions:countries.GY' },
  { value: 'HK', label: 'countriesregions:countries.HK' },
  { value: 'HN', label: 'countriesregions:countries.HN' },
  { value: 'HR', label: 'countriesregions:countries.HR' },
  { value: 'HU', label: 'countriesregions:countries.HU' },
  { value: 'ID', label: 'countriesregions:countries.ID' },
  { value: 'IE', label: 'countriesregions:countries.IE' },
  { value: 'IL', label: 'countriesregions:countries.IL' },
  { value: 'IM', label: 'countriesregions:countries.IM' },
  { value: 'IN', label: 'countriesregions:countries.IN' },
  { value: 'IQ', label: 'countriesregions:countries.IQ' },
  { value: 'IR', label: 'countriesregions:countries.IR' },
  { value: 'IS', label: 'countriesregions:countries.IS' },
  { value: 'IT', label: 'countriesregions:countries.IT' },
  { value: 'JE', label: 'countriesregions:countries.JE' },
  { value: 'JM', label: 'countriesregions:countries.JM' },
  { value: 'JO', label: 'countriesregions:countries.JO' },
  { value: 'JP', label: 'countriesregions:countries.JP' },
  { value: 'KE', label: 'countriesregions:countries.KE' },
  { value: 'KG', label: 'countriesregions:countries.KG' },
  { value: 'KH', label: 'countriesregions:countries.KH' },
  { value: 'KN', label: 'countriesregions:countries.KN' },
  { value: 'KR', label: 'countriesregions:countries.KR' },
  { value: 'KW', label: 'countriesregions:countries.KW' },
  { value: 'KY', label: 'countriesregions:countries.KY' },
  { value: 'KZ', label: 'countriesregions:countries.KZ' },
  { value: 'LB', label: 'countriesregions:countries.LB' },
  { value: 'LC', label: 'countriesregions:countries.LC' },
  { value: 'LK', label: 'countriesregions:countries.LK' },
  { value: 'LT', label: 'countriesregions:countries.LT' },
  { value: 'LU', label: 'countriesregions:countries.LU' },
  { value: 'LV', label: 'countriesregions:countries.LV' },
  { value: 'LY', label: 'countriesregions:countries.LY' },
  { value: 'MA', label: 'countriesregions:countries.MA' },
  { value: 'MD', label: 'countriesregions:countries.MD' },
  { value: 'ME', label: 'countriesregions:countries.ME' },
  { value: 'MK', label: 'countriesregions:countries.MK' },
  { value: 'ML', label: 'countriesregions:countries.ML' },
  { value: 'MM', label: 'countriesregions:countries.MM' },
  { value: 'MN', label: 'countriesregions:countries.MN' },
  { value: 'MT', label: 'countriesregions:countries.MT' },
  { value: 'MU', label: 'countriesregions:countries.MU' },
  { value: 'MV', label: 'countriesregions:countries.MV' },
  { value: 'MW', label: 'countriesregions:countries.MW' },
  { value: 'MX', label: 'countriesregions:countries.MX' },
  { value: 'MY', label: 'countriesregions:countries.MY' },
  { value: 'MZ', label: 'countriesregions:countries.MZ' },
  { value: 'NG', label: 'countriesregions:countries.NG' },
  { value: 'NI', label: 'countriesregions:countries.NI' },
  { value: 'NL', label: 'countriesregions:countries.NL' },
  { value: 'NO', label: 'countriesregions:countries.NO' },
  { value: 'NP', label: 'countriesregions:countries.NP' },
  { value: 'NZ', label: 'countriesregions:countries.NZ' },
  { value: 'OM', label: 'countriesregions:countries.OM' },
  { value: 'PA', label: 'countriesregions:countries.PA' },
  { value: 'PE', label: 'countriesregions:countries.PE' },
  { value: 'PG', label: 'countriesregions:countries.PG' },
  { value: 'PH', label: 'countriesregions:countries.PH' },
  { value: 'PK', label: 'countriesregions:countries.PK' },
  { value: 'PL', label: 'countriesregions:countries.PL' },
  { value: 'PR', label: 'countriesregions:countries.PR' },
  { value: 'PS', label: 'countriesregions:countries.PS' },
  { value: 'PT', label: 'countriesregions:countries.PT' },
  { value: 'PY', label: 'countriesregions:countries.PY' },
  { value: 'QA', label: 'countriesregions:countries.QA' },
  { value: 'RE', label: 'countriesregions:countries.RE' },
  { value: 'RO', label: 'countriesregions:countries.RO' },
  { value: 'RS', label: 'countriesregions:countries.RS' },
  { value: 'RU', label: 'countriesregions:countries.RU' },
  { value: 'RW', label: 'countriesregions:countries.RW' },
  { value: 'SA', label: 'countriesregions:countries.SA' },
  { value: 'SE', label: 'countriesregions:countries.SE' },
  { value: 'SI', label: 'countriesregions:countries.SI' },
  { value: 'SK', label: 'countriesregions:countries.SK' },
  { value: 'SN', label: 'countriesregions:countries.SN' },
  { value: 'SO', label: 'countriesregions:countries.SO' },
  { value: 'SR', label: 'countriesregions:countries.SR' },
  { value: 'SV', label: 'countriesregions:countries.SV' },
  { value: 'SX', label: 'countriesregions:countries.SX' },
  { value: 'SY', label: 'countriesregions:countries.SY' },
  { value: 'TC', label: 'countriesregions:countries.TC' },
  { value: 'TG', label: 'countriesregions:countries.TG' },
  { value: 'TH', label: 'countriesregions:countries.TH' },
  { value: 'TJ', label: 'countriesregions:countries.TJ' },
  { value: 'TM', label: 'countriesregions:countries.TM' },
  { value: 'TN', label: 'countriesregions:countries.TN' },
  { value: 'TR', label: 'countriesregions:countries.TR' },
  { value: 'TT', label: 'countriesregions:countries.TT' },
  { value: 'TW', label: 'countriesregions:countries.TW' },
  { value: 'TZ', label: 'countriesregions:countries.TZ' },
  { value: 'UA', label: 'countriesregions:countries.UA' },
  { value: 'UG', label: 'countriesregions:countries.UG' },
  { value: 'US', label: 'countriesregions:countries.US' },
  { value: 'UY', label: 'countriesregions:countries.UY' },
  { value: 'UZ', label: 'countriesregions:countries.UZ' },
  { value: 'VC', label: 'countriesregions:countries.VC' },
  { value: 'VE', label: 'countriesregions:countries.VE' },
  { value: 'VG', label: 'countriesregions:countries.VG' },
  { value: 'VI', label: 'countriesregions:countries.VI' },
  { value: 'VN', label: 'countriesregions:countries.VN' },
  { value: 'XK', label: 'countriesregions:countries.XK' },
  { value: 'YE', label: 'countriesregions:countries.YE' },
  { value: 'ZA', label: 'countriesregions:countries.ZA' },
  { value: 'ZM', label: 'countriesregions:countries.ZM' },
  { value: 'ZW', label: 'countriesregions:countries.ZW' },
];
