import { useCallback, useEffect, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'modules/common/Modal';
import { useChangeThreadsMutation, useGetChangeThreadsOptionsQuery } from 'store/api';
import type { Option } from 'types';
import { ToastManager } from 'utils/toast';

import { ChangeThreadsForm, type ChangeThreadsValues } from './ChangeThreadsForm';

type HandleSubmit = FormikConfig<ChangeThreadsValues>['onSubmit'];

export type ProxyChangeThreadsModalProps = {
  proxyIds: number[];
};

export function ProxyChangeThreadsModal({ proxyIds }: ProxyChangeThreadsModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const [changeThreads] = useChangeThreadsMutation();
  const {
    data: threadsOptions,
    isFetching: isOptionsLoading,
    refetch,
  } = useGetChangeThreadsOptionsQuery(proxyIds[0], { skip: proxyIds.length > 1 });

  const initialValues = useMemo<ChangeThreadsValues>(() => {
    return { threads: threadsOptions?.currentThreads ?? 100 };
  }, [threadsOptions?.currentThreads]);

  const options = useMemo<Array<Option<number>>>(() => {
    return threadsOptions?.options.map((i) => ({ value: i, label: i === 0 ? t('common:unlimited') : String(i) })) ?? [];
  }, [t, threadsOptions?.options]);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ threads }) => {
      try {
        await changeThreads({ proxyId: proxyIds[0], threads }).unwrap();

        ToastManager.success(t('proxies.modals.upgradeThreads.success'));

        hideModal();
      } catch {
        // noop
      }
    },
    [changeThreads, hideModal, proxyIds, t],
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Formik<ChangeThreadsValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <ChangeThreadsForm options={options} isLoading={isOptionsLoading} />
    </Formik>
  );
}
