import { isRejectedWithValue, type Middleware } from '@reduxjs/toolkit';
import { t } from 'i18next';
import isObject from 'lodash/isObject';

import type { MutationThunkArg } from 'store/types';
import { isAxiosBaseQueryError } from 'store/utils/errors';
import { parseError } from 'utils/error';
import { ToastManager } from 'utils/toast';

function isMutationThunkArg(arg: unknown): arg is MutationThunkArg {
  return isObject(arg) && 'type' in arg && arg.type === 'mutation';
}

const whitelistedMutations = ['getOrderConfiguration', 'testProxy'];

export const errorMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (!isAxiosBaseQueryError(action.payload)) {
      return next(action);
    }

    if (!isMutationThunkArg(action.meta.arg) || whitelistedMutations.includes(action.meta.arg.endpointName)) {
      return next(action);
    }

    const isNotValid = [422].includes(action.payload.status);

    if (isNotValid) {
      return next(action);
    }

    const isRateLimitError = action.payload.status === 429;

    if (isRateLimitError) {
      ToastManager.error(t('errors:RATE_LIMIT_EXCEEDED'));
    } else {
      ToastManager.error(t(`errors:${parseError(action.payload)}`));
    }
  }

  return next(action);
};
