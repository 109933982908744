import { useMemo } from 'react';

import { isOrderStateMalformed } from 'modules/orders/helpers';
import type { ServiceID } from 'store/orders/types';
import { PersistService } from 'utils/persist';

import { useOrderState } from './useOrderState';

export function useOrderFlow() {
  const selectedPlanId = useOrderState((state) => state.selectedPlanId);
  const selectedServiceId = useOrderState((state) => state.selectedServiceId);
  const selectedSetup = useOrderState((state) => state.selectedSetup);

  const isMarketplaceFlow = useMemo(() => !!PersistService.get('marketplace-flow'), []);

  const isSameSubnetFlow = useMemo(() => !!PersistService.get<number>('order-origin-proxy-id'), []);

  const originProxyId = useMemo(() => PersistService.get<number>('order-origin-proxy-id'), []);

  const isStateMalformed = useMemo(
    () => isOrderStateMalformed(selectedServiceId, selectedPlanId, selectedSetup),
    [selectedPlanId, selectedServiceId, selectedSetup],
  );

  const isValidServiceId = useMemo(() => {
    const knownServiceIds: ServiceID[] = [
      'rotating-mobile',
      'rotating-residential',
      'static-datacenter-ipv4',
      'static-datacenter-ipv6',
      'static-residential-ipv4',
      'vpn',
    ];

    return knownServiceIds.includes(selectedServiceId);
  }, [selectedServiceId]);

  return useMemo(() => {
    return { isMarketplaceFlow, isSameSubnetFlow, isStateMalformed, isValidServiceId, originProxyId };
  }, [isMarketplaceFlow, isSameSubnetFlow, isStateMalformed, isValidServiceId, originProxyId]);
}
