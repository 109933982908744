import { useMemo } from 'react';

import { matchPath, useLocation, useParams } from 'react-router-dom';

import { paths } from 'paths';
import type { Nullable } from 'types';

type CustomCloseType = 'credentials-generator' | 'operation' | 'referrals';

export function useCustomClose() {
  const { proxyId, operationId } = useParams();
  const { pathname } = useLocation();

  const closeType = useMemo<Nullable<CustomCloseType>>(() => {
    if (matchPath(`${paths.proxies.index}/:proxyId/credentials-generator`, pathname) && !!proxyId)
      return 'credentials-generator';

    if (matchPath(`${paths.proxies.operation.index}/:operationId`, pathname) && !!operationId) return 'operation';

    if (matchPath(paths.referrals.bank, pathname)) return 'referrals';

    return null;
  }, [operationId, pathname, proxyId]);

  return useMemo(() => {
    if (!closeType) return paths.index;

    if (closeType === 'credentials-generator' && !!proxyId) {
      return paths.proxies.overview(proxyId);
    }

    if (closeType === 'referrals') {
      return paths.referrals.index;
    }

    return paths.proxies.index;
  }, [closeType, proxyId]);
}
