import type { Components, Theme } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

export const MuiTableRow: Components<Theme>['MuiTableRow'] = {
  styleOverrides: {
    root: {
      '--TableRow-hover-background': 'rgba(var(--mui-palette-primary-mainChannel) / 0.04)',

      [`& .${tableCellClasses.root}`]: {
        whiteSpace: 'nowrap',
      },
    },
  },
};
