import type { ReactNode } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography, { type TypographyProps } from '@mui/material/Typography';

type SummaryItemSlotProps = {
  label?: Partial<TypographyProps>;
  value?: Partial<TypographyProps>;
};

type SummaryItemProps = {
  label: string;
  value: ReactNode;
  isLoading?: boolean;

  slotProps?: SummaryItemSlotProps;
};

export function SummaryItem({ label, value, isLoading, slotProps }: SummaryItemProps) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="body-2" {...slotProps?.label}>
        {label}
      </Typography>

      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <Typography variant="body-2" {...slotProps?.value}>
          {value}
        </Typography>
      )}
    </Stack>
  );
}
