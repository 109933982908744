import Stack from '@mui/material/Stack';

import { Outlet } from 'react-router-dom';

import { BaseLayoutNavigation } from './BaseLayoutNavigation';

export function BaseLayout() {
  return (
    <Stack
      component="main"
      flex="1 1 auto"
      minHeight="100%"
      bgcolor={{ xs: 'var(--mui-palette-background-paper)', md: 'var(--mui-palette-background-default)' }}
      sx={(theme) => ({ transition: theme.transitions.create('all', { duration: 150 }) })}
    >
      <BaseLayoutNavigation />

      <Stack
        flex="1 1 auto"
        sx={{
          '--BaseLayout-paddingBlock': {
            xs: 'calc(var(--BaseLayoutNavigation-height) + 32px)',
            md: 'calc(var(--BaseLayoutNavigation-height) + 64px)',
          },
          '--BaseLayout-paddingInline': { xs: '16px', lg: '0px' },
          '--BaseLayout-padding':
            'var(--BaseLayout-paddingBlock) var(--BaseLayout-paddingInline) calc(var(--BaseLayout-paddingBlock) + 64px) var(--BaseLayout-paddingInline)',
          '--BaseLayout-maxWidth': '976px',
        }}
      >
        <Stack
          flex="1 1 auto"
          p="var(--BaseLayout-padding)"
          m="var(--BaseLayout-margin)"
          width="var(--BaseLayout-width)"
          maxWidth="var(--BaseLayout-maxWidth)"
        >
          <Outlet />
        </Stack>
      </Stack>
    </Stack>
  );
}
