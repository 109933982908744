import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { posthogEvents } from 'constants/posthogEvents';
import { useHideModal } from 'modules/common/Modal';
import type { ProxyBulkActionModel } from 'modules/proxies/types';
import { useUpdateAuthenticationTypeMutation } from 'store/api';
import type { UpdateAuthenticationTypePayload } from 'store/proxies/payloads';
import { AuthenticationType } from 'store/proxies/types';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import {
  ChangeAuthenticationMethodForm,
  type ChangeAuthenticationMethodValues,
} from './ChangeAuthenticationMethodForm';

type HandleSubmit = FormikConfig<ChangeAuthenticationMethodValues>['onSubmit'];

export type ProxyChangeAuthenticationMethodModalProps = {
  proxy: ProxyBulkActionModel;
};

export function ProxyChangeAuthenticationMethodModal({ proxy }: ProxyChangeAuthenticationMethodModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [updateAuthenticationType] = useUpdateAuthenticationTypeMutation();

  const hideModal = useHideModal();

  const initialValues = useMemo<ChangeAuthenticationMethodValues>(() => {
    const { authenticationType, whitelistedIps = [] } = proxy;

    return {
      type: authenticationType,
      ipWhitelist: whitelistedIps?.length > 0 ? whitelistedIps : [''],
    };
  }, [proxy]);

  const validate = useCallback(
    ({ ipWhitelist, type }: ChangeAuthenticationMethodValues) => {
      const errors: Record<string, string | string[] | undefined> = {};

      const filteredWhitelist = ipWhitelist.filter((i) => Boolean(i.length));

      if (type === AuthenticationType.IP_WHITELIST && filteredWhitelist.length === 0) {
        errors.globalError = t('errors:minItems', { field: t('common:form.ipWhitelist'), min: 1 }) ?? '';
      }

      return errors;
    },
    [t],
  );

  const mapResponseKeyIntoFormKey = useCallback((key: keyof UpdateAuthenticationTypePayload) => {
    if (key === 'ips') return 'ipWhitelist';
    if (key === 'newAuthType') return 'type';

    throw new Error('Unsupported key');
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ type, ipWhitelist }, { setErrors, setSubmitting }) => {
      try {
        const ips = type === AuthenticationType.IP_WHITELIST ? ipWhitelist.filter((v) => v.length) : [];

        await updateAuthenticationType({ proxyId: proxy.id, newAuthType: type, ips }).unwrap();

        posthog?.capture(posthogEvents.proxy.authenticationType.success, {
          proxyId: proxy.id,
          authenticationType: type,
        });

        ToastManager.success(t('proxies.modals.changeAuthMethod.success'));
        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error, mapResponseKeyIntoFormKey);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        setSubmitting(false);

        posthog?.capture(posthogEvents.proxy.authenticationType.failed, {
          proxyId: proxy.id,
          authenticationType: type,
        });
      }
    },
    [hideModal, mapResponseKeyIntoFormKey, posthog, proxy.id, t, updateAuthenticationType],
  );

  return (
    <Formik<ChangeAuthenticationMethodValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      <ChangeAuthenticationMethodForm proxyId={proxy.id} />
    </Formik>
  );
}
