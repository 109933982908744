import { useCallback, useEffect, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { posthogEvents } from 'constants/posthogEvents';
import { useHideModal } from 'modules/common/Modal';
import { withMultiStepModalContext } from 'modules/proxies/components';
import type { ProxyBulkActionModel } from 'modules/proxies/types';
import { useBulkChangeProtocolMutation, useGetChangeProtocolOptionsQuery } from 'store/api';
import { Protocol } from 'store/proxies/types';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import { BulkChangeProtocolForm, type BulkChangeProtocolValues } from './BulkChangeProtocolForm';

type HandleSubmit = FormikConfig<BulkChangeProtocolValues>['onSubmit'];

export type ProxyBulkChangeProtocolModalProps = {
  // TODO: Uncomment me to enable all proxies
  // isAllSelected: boolean;
  proxies: ProxyBulkActionModel[];
};

function ProxyBulkChangeProtocolModalComponent({ proxies = [] }: ProxyBulkChangeProtocolModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const posthog = usePostHog();
  // TODO: Uncomment me
  // const { setOperationId, setStep } = useMultiStepStatusModalContext();

  const [bulkUpdateProtocol] = useBulkChangeProtocolMutation();

  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);
  const { data, refetch, isUninitialized } = useGetChangeProtocolOptionsQuery(proxyIds[0]);

  const initialValues = useMemo<BulkChangeProtocolValues>(() => {
    return { protocol: null };
  }, []);

  const validationSchema = useMemo<Yup.Schema<BulkChangeProtocolValues>>(() => {
    return Yup.object().shape({
      protocol: Yup.string().oneOf(Object.values(Protocol)).required(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ protocol }, { setErrors, setSubmitting }) => {
      if (!protocol) return;

      try {
        // TODO: Add operationId, and send an empty array on `isAllSelected`
        await bulkUpdateProtocol({ ids: proxyIds, protocol }).unwrap();

        posthog?.capture(posthogEvents.proxy.bulk.changeProtocol.success, {
          proxyIds,
          total: proxyIds.length,
        });

        // TODO: Add setter for operationId and step from context
        ToastManager.success(t('proxies.modals.changeProtocol.success'));
        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        posthog?.capture(posthogEvents.proxy.bulk.changeProtocol.failed, {
          proxyIds,
          total: proxyIds.length,
        });

        setSubmitting(false);
      }
    },
    [bulkUpdateProtocol, hideModal, posthog, proxyIds, t],
  );

  useEffect(() => {
    if (isUninitialized) return;

    refetch();
  }, [isUninitialized, refetch]);

  return (
    <Formik<BulkChangeProtocolValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      validateOnChange
    >
      <BulkChangeProtocolForm proxyIds={proxyIds} availableTypes={data?.availableTypes} />
    </Formik>
  );
}

export const ProxyBulkChangeProtocolModal = withMultiStepModalContext<ProxyBulkChangeProtocolModalProps>(
  ProxyBulkChangeProtocolModalComponent,
  'changeProtocol',
);
