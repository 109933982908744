import { createContext, Fragment, useCallback, useContext, useEffect, useMemo, type ReactNode } from 'react';

import { useQueryState } from 'nuqs';
import qs from 'qs';

import type { Optional } from 'types';

import type { ModalContextValue, Modal, ModalRenderFn, ShowModalFn } from './types';
import { useModalState } from './useModalState';

type ModalProvider = {
  children: ReactNode;
};

export function createModalManager<ModalMap extends Modal<string, Record<string, unknown>>>(
  renderFn: ModalRenderFn<ModalMap>,
) {
  const ModalContext = createContext<Optional<ModalContextValue<ModalMap>>>(undefined);

  function Provider({ children }: ModalProvider) {
    const modals = useModalState((state) => state.modals);
    const hideModalFromState = useModalState((state) => state.hideModal);
    const showModalFromState = useModalState((state) => state.showModal);

    const [modalId, setModalId] = useQueryState('modal', { defaultValue: '' });
    const [stringifiedModalParams, setStringifiedModalParams] = useQueryState('modalParams');

    const modalParams = useMemo(() => {
      if (!stringifiedModalParams) return;

      return qs.parse(stringifiedModalParams) as Record<string, unknown>;
    }, [stringifiedModalParams]);

    const showModal = useCallback<ShowModalFn<ModalMap>>(
      (id, ...args) => {
        const params = args[0] ?? undefined;

        showModalFromState(id, params);
      },
      [showModalFromState],
    );

    const hideModal = useCallback(
      <ID extends ModalMap['id']>(id?: ID) => {
        setModalId(null);
        setStringifiedModalParams(null);

        hideModalFromState(id);
      },
      [hideModalFromState, setModalId, setStringifiedModalParams],
    );

    const renderModals = useCallback(() => {
      return modals.map((modal, index) => <Fragment key={`modal-${index}`}>{renderFn(modal as ModalMap)}</Fragment>);
    }, [modals]);

    const value = useMemo<ModalContextValue<ModalMap>>(() => {
      return { modals, showModal, hideModal };
    }, [hideModal, modals, showModal]);

    useEffect(() => {
      if (!modalId.length) return;

      const modalFromURL = { id: modalId, params: modalParams };

      if (!modals.some((modal) => modal.id === modalId)) {
        showModalFromState(modalFromURL.id, modalFromURL.params);
      }
    }, [modalId, modalParams, modals, showModalFromState]);

    useEffect(() => {
      const topModal = modals[modals.length - 1];

      if (topModal) {
        setModalId(topModal.id);
      } else {
        setModalId(null);
        setStringifiedModalParams(null);
      }
    }, [modals, setModalId, setStringifiedModalParams]);

    return (
      <ModalContext.Provider value={value}>
        {children}

        {renderModals()}
      </ModalContext.Provider>
    );
  }

  function useModalManager() {
    const context = useContext(ModalContext);

    if (context === undefined) {
      throw new Error('useModalManager must be used within ModalProvider');
    }

    return context;
  }

  function useShowModal() {
    const { showModal } = useModalManager();

    return showModal;
  }

  function useHideModal() {
    const { hideModal } = useModalManager();

    return hideModal;
  }

  function useModalStack() {
    const { modals } = useModalManager();

    return modals;
  }

  function useModalURLControl() {
    const [modalId, setModalId] = useQueryState('modal', { defaultValue: '' });
    const [stringifiedModalParams, setStringifiedModalParams] = useQueryState('modalParams');

    const showModal = useCallback<ShowModalFn<ModalMap>>(
      (id, ...args) => {
        const params = args[0] ?? {};

        setModalId(id);
        setStringifiedModalParams(qs.stringify(params));
      },
      [setModalId, setStringifiedModalParams],
    );

    const hideModal = useCallback(() => {
      setModalId(null);
      setStringifiedModalParams(null);
    }, [setModalId, setStringifiedModalParams]);

    return useMemo(() => {
      return {
        currentModalId: modalId,
        currentModalParams: stringifiedModalParams,
        showModalByURL: showModal,
        hideModalByURL: hideModal,
      };
    }, [hideModal, modalId, showModal, stringifiedModalParams]);
  }

  return { Provider, useShowModal, useHideModal, useModalStack, useModalManager, useModalURLControl };
}
