// #region Common types
/**
 * Literal union of available pricing units
 *
 * Example: $1.99/month
 */
export type OrderPricingUnit = 'month' | 'proxy';

/**
 * Literal union of available order flow steps
 *
 * Represents the available steps in the order flow
 *
 * - setup - Setup form
 * - details - Personal Details form
 * - checkout - Checkout form
 */
export type OrderStep = 'setup' | 'details' | 'checkout';

/**
 * Literal union of available plan ids
 *
 * Represents the unique identifier of each available plan under the service
 */
export type PlanID = 'basic' | 'standard' | 'premium' | 'vpn-1m' | 'vpn-12m';

/**
 * Literal union of available service ids
 *
 * Represents the unique identifier of each available service
 *
 * ! VPN services is not accessible from the API side - it needs to be added manually via mapper
 */
export type ServiceID =
  | 'static-residential-ipv4'
  | 'static-datacenter-ipv4'
  | 'static-datacenter-ipv6'
  | 'rotating-residential'
  | 'rotating-mobile'
  | 'dedicated-mobile'
  | 'vpn';

export type SpecialOfferProperties = 'quantity' | 'months' | 'days' | 'traffic';

export type PriceLabel = 'service' | 'user' | 'marketplace' | 'coupon';
// #endregion Common types

export enum OrderStatus {
  WAITING = 'WAITING',
  PAYING = 'PAYING',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  FAILED = 'FAILED',
}
