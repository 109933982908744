import { useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { DateTimePicker, dateTimePickerToolbarClasses } from '@mui/x-date-pickers';

import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikInput, FormikNumberInput, FormikSwitch } from 'components/form-partials/formik';
import { Icon } from 'components/Icon';
import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';

export type RefundValues = {
  reason: string;
  refundSince: string;
  isCustomAmount: boolean;
  refundAmount?: number;
};

type IconProps = {
  className: string;
};

function OpenPickerIcon(props: IconProps) {
  return (
    <IconButton {...props}>
      <Icon name="calendar-edit" />
    </IconButton>
  );
}

function NextIconButton(props: IconProps) {
  return (
    <IconButton {...props}>
      <Icon name="chevron-right" size="small" />
    </IconButton>
  );
}

function PrevIconButton(props: IconProps) {
  return (
    <IconButton {...props}>
      <Icon name="chevron-left" size="small" />
    </IconButton>
  );
}

function SwitchViewIcon(props: IconProps) {
  return (
    <IconButton {...props}>
      <Icon name="chevron-down" size="small" />
    </IconButton>
  );
}

export function RefundForm() {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { handleSubmit, isSubmitting, isValid, values, setFieldValue } = useFormikContext<RefundValues>();

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="confirm" type="submit" fullWidth loading={isSubmitting} disabled={!isValid}>
        {t('common:buttons.confirm')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.overview.admin.modals.refund.title')}
      actions={actions}
    >
      <Stack spacing={4}>
        <FormikInput name="reason" multiline rows={4} label={t('common:form.reason')} />

        <Stack spacing={1}>
          <InputLabel htmlFor="refundSince">{t('common:form.refundSince')}</InputLabel>

          <DateTimePicker
            name="refundSince"
            format="YYYY-MM-DD HH:mm:ss"
            value={dayjs(values.refundSince)}
            onChange={(date) => setFieldValue('refundSince', date?.toISOString())}
            slotProps={{
              toolbar: {
                hidden: false,
                sx: {
                  [`& .${dateTimePickerToolbarClasses.separator}`]: {
                    lineHeight: '48px',
                  },
                },
              },
            }}
            slots={{
              switchViewIcon: SwitchViewIcon,
              openPickerIcon: OpenPickerIcon,
              nextIconButton: NextIconButton,
              previousIconButton: PrevIconButton,
            }}
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            ampm={false}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <InputLabel>{t('common:form.isCustomAmount')}</InputLabel>

          <FormikSwitch name="isCustomAmount" />
        </Stack>

        <Collapse in={values.isCustomAmount} unmountOnExit>
          <FormikNumberInput name="refundAmount" fullWidth label={t('common:form.refundAmount')} />
        </Collapse>
      </Stack>
    </ModalContainer>
  );
}
