import { useCallback, useEffect } from 'react';

import { NuqsAdapter } from 'nuqs/adapters/react-router';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'translations';
import 'utils/yup';

import { useLoadTranslations } from 'hooks/useLoadTranslations';
import { useSession } from 'modules/accounts/hooks';
import { router } from 'routes';
import { useAppSelector } from 'store';
import { toLanguageDTO } from 'store/accounts/helpers';
import { Language } from 'store/accounts/models';
import { useChangeLanguageMutation } from 'store/api';
import { PersistService } from 'utils/persist';

import 'globals.css';
import 'swiper/css';
import 'swiper/css/virtual';

export function App() {
  const posthog = usePostHog();

  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const { i18n } = useTranslation();

  const { session } = useSession({ skip: !isAuthenticated });

  const [changeLanguage] = useChangeLanguageMutation();

  useLoadTranslations();

  const updateLanguage = useCallback(() => {
    if (!session || !isAuthenticated) return;

    // No need to update language when it's already set
    if (session.language) return;

    try {
      changeLanguage({ language: toLanguageDTO(i18n.language as Language) });
    } catch {
      // noop
    }
  }, [changeLanguage, i18n.language, isAuthenticated, session]);

  useEffect(() => {
    if (!session?.userId) return;

    posthog?.identify(session.userId.toString());
  }, [posthog, session?.userId]);

  useEffect(() => {
    if (!session) return;

    i18n.changeLanguage(session.language ?? i18n.language ?? PersistService.get('language') ?? Language.EN);
  }, [i18n, session]);

  useEffect(() => {
    updateLanguage();
  }, [updateLanguage]);

  return (
    <NuqsAdapter>
      <RouterProvider router={router} />

      <ToastContainer
        draggable={false}
        position="bottom-center"
        closeButton={false}
        closeOnClick={false}
        icon={false}
        limit={3}
        hideProgressBar
      />
    </NuqsAdapter>
  );
}
