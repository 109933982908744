/* eslint-disable @typescript-eslint/unified-signatures */
import type { ProxyBulkOperationTaskModel, ProxyModel } from 'store/proxies/models';
import { AuthenticationType } from 'store/proxies/types';

import type { ProxyBulkActionModel } from './types';

function isProxyModel(from: ProxyModel | ProxyBulkOperationTaskModel): from is ProxyModel {
  return 'authentication' in from;
}

export function toProxyBulkActionModel(from: ProxyModel): ProxyBulkActionModel;
export function toProxyBulkActionModel(from: ProxyBulkOperationTaskModel): ProxyBulkActionModel;
export function toProxyBulkActionModel(from: ProxyModel[]): ProxyBulkActionModel[];
export function toProxyBulkActionModel(from: ProxyBulkOperationTaskModel[]): ProxyBulkActionModel[];

export function toProxyBulkActionModel(
  from: ProxyModel | ProxyBulkOperationTaskModel | ProxyModel[] | ProxyBulkOperationTaskModel[],
): ProxyBulkActionModel | ProxyBulkActionModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => {
      if (isProxyModel(item)) return toProxyBulkActionModel(item);

      return toProxyBulkActionModel(item);
    });
  }

  if (isProxyModel(from)) {
    const {
      id,
      networkType,
      authentication: { whitelistedIps },
    } = from;

    return {
      id,
      networkType,
      authenticationType:
        whitelistedIps.length > 0 ? AuthenticationType.IP_WHITELIST : AuthenticationType.USERNAME_PASSWORD,
      whitelistedIps,
    };
  }

  const { id, networkType, authType } = from;

  return { id, networkType, authenticationType: authType };
}
