import { useCallback, useMemo, useState } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useSession } from 'modules/accounts/hooks';
import { useHideModal } from 'modules/common/Modal';
import type { ProxyBulkActionModel } from 'modules/proxies/types';
import { useExtendProxyPeriodMutation } from 'store/api';
import { ToastManager } from 'utils/toast';

import { ExtendProxyPeriodForm, type ExtendProxyPeriodValues } from './ExtendProxyPeriodForm';

type HandleSubmit = FormikConfig<ExtendProxyPeriodValues>['onSubmit'];

export type ProxyExtendPeriodModalProps = {
  proxy: ProxyBulkActionModel;
};

export function ProxyExtendPeriodModal({ proxy }: ProxyExtendPeriodModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const hideModal = useHideModal();
  const { session } = useSession();
  const [singleItemPrice, setSingleItemPrice] = useState(0);

  const [extendProxyPeriod] = useExtendProxyPeriodMutation();

  const initialValues = useMemo<ExtendProxyPeriodValues>(() => {
    return { periodInMonths: 1, couponCode: null };
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ periodInMonths, couponCode }) => {
      try {
        await extendProxyPeriod({
          proxyId: proxy.id,
          periodInMonths,
          couponCode: couponCode ?? undefined,
        }).unwrap();

        GA_EVENTS.proxyPeriodExtended({
          userId: session?.userId,
          value: singleItemPrice,
          networkType: proxy.networkType,
          proxy_id: proxy.id,
          period: periodInMonths,
        });

        posthog?.capture(posthogEvents.proxy.extendPeriod.success, { proxyId: proxy.id, periodInMonths });

        ToastManager.success(t('proxies.modals.extendPeriod.success'));
        hideModal();
      } catch {
        posthog?.capture(posthogEvents.proxy.extendPeriod.failed, { proxyId: proxy.id, periodInMonths });
      }
    },
    [extendProxyPeriod, hideModal, posthog, proxy.id, proxy.networkType, session?.userId, singleItemPrice, t],
  );

  return (
    <Formik<ExtendProxyPeriodValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <ExtendProxyPeriodForm proxy={proxy} setPriceForSingle={setSingleItemPrice} />
    </Formik>
  );
}
