import { useCallback, useMemo, useState } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useSession } from 'modules/accounts/hooks';
import { useHideModal } from 'modules/common/Modal';
import { useUpgradeUplinkSpeedMutation } from 'store/api';
import { ToastManager } from 'utils/toast';

import { UpgradeUplinkSpeedForm, type UpgradeUplinkSpeedValues } from './UpgradeUplinkSpeedForm';

type HandleSubmit = FormikConfig<UpgradeUplinkSpeedValues>['onSubmit'];

export type ProxyUpgradeUplinkSpeedModalProps = {
  proxyIds: number[];
};

export function ProxyUpgradeUplinkSpeedModal({ proxyIds }: ProxyUpgradeUplinkSpeedModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [upgradeUplinkSpeed] = useUpgradeUplinkSpeedMutation();
  const hideModal = useHideModal();
  const { session } = useSession();
  const [singleItemPrice, setSingleItemPrice] = useState(0);

  const initialValues = useMemo<UpgradeUplinkSpeedValues>(() => {
    return { uplinkSpeed: 100 };
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ uplinkSpeed }) => {
      try {
        await upgradeUplinkSpeed({ proxyId: proxyIds[0], uplinkSpeed }).unwrap();

        GA_EVENTS.proxyBandwidthSpeedUpgraded({
          userId: session?.userId,
          value: singleItemPrice,
          proxy_id: proxyIds[0],
          bandwidthSpeed: uplinkSpeed,
        });

        posthog?.capture(posthogEvents.proxy.upgradeBandwidthSpeed.success, {
          proxyId: proxyIds[0],
          uplinkSpeed,
        });

        ToastManager.success(t('proxies.modals.upgradeBandwidthSpeed.success'));
        hideModal();
      } catch {
        posthog?.capture(posthogEvents.proxy.upgradeBandwidthSpeed.failed, {
          proxyId: proxyIds[0],
          uplinkSpeed,
        });
      }
    },
    [hideModal, posthog, proxyIds, session?.userId, singleItemPrice, t, upgradeUplinkSpeed],
  );

  return (
    <Formik<UpgradeUplinkSpeedValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <UpgradeUplinkSpeedForm proxyIds={proxyIds} price={singleItemPrice} setPriceForSingle={setSingleItemPrice} />
    </Formik>
  );
}
