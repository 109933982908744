import { useCallback, useMemo, useState } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import * as Yup from 'yup';

import { posthogEvents } from 'constants/posthogEvents';
import { useRequestCountryMutation } from 'store/api';
import type { OrderSetupCountriesField } from 'store/orders/models';
import type { NetworkType } from 'store/proxies/types';
import { getValidationErrors } from 'utils/error';

import { CountryRequestForm, type CountryRequestStep, type CountryRequestValues } from './CountryRequestForm';

type HandleSubmit = FormikConfig<CountryRequestValues>['onSubmit'];

export type CountryRequestModalProps = {
  networkType: NetworkType;
  setup: OrderSetupCountriesField;
};

export function CountryRequestModal({ networkType, setup }: CountryRequestModalProps) {
  const [requestCountry] = useRequestCountryMutation();
  const posthog = usePostHog();

  const [step, setStep] = useState<CountryRequestStep>('form');

  const initialValues = useMemo<CountryRequestValues>(() => {
    return { codes: null, comment: '' };
  }, []);

  const validationSchema = useMemo<Yup.Schema<CountryRequestValues>>(() => {
    return Yup.object().shape({
      codes: Yup.string().required(),
      comment: Yup.string().optional(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ codes, comment }, { setErrors, setSubmitting }) => {
      if (!codes) return;

      try {
        await requestCountry({ codes: [codes], networkType, comment }).unwrap();

        posthog.capture(posthogEvents.order.countryRequest.success, { country: codes?.[0] });

        setStep('success');
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        posthog.capture(posthogEvents.order.countryRequest.failure, { country: codes?.[0] });
      } finally {
        setSubmitting(false);
      }
    },
    [networkType, posthog, requestCountry],
  );

  return (
    <Formik<CountryRequestValues> initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      <CountryRequestForm step={step} setup={setup} />
    </Formik>
  );
}
