/* eslint-disable react/no-unescaped-entities */
import { useEffect, useMemo } from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { ModalContainer } from 'components/modals';
import { useEmailVerification } from 'modules/accounts/hooks';
import { useHideModal } from 'modules/common/Modal';
import { ToastManager } from 'utils/toast';

export function VerifyEmailModal() {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const { session, handleResendVerificationEmail } = useEmailVerification(true);

  const actions = useMemo(
    () => [
      <Button fullWidth onClick={() => hideModal()}>
        {t('common:buttons.gotIt')}
      </Button>,
    ],
    [hideModal, t],
  );

  useEffect(() => {
    if (!session?.isActive) return;

    ToastManager.success(t('account.modals.emailVerification.success'));
    hideModal();
  }, [hideModal, session?.isActive, t]);

  return (
    <ModalContainer
      title={t('account.modals.emailVerification.title')}
      subtitle={
        <>
          <Trans i18nKey="account.modals.emailVerification.subtitle">We've sent a verification link to</Trans>
          <Typography component="span" variant="body-1" color="text.primary">
            {session?.email}
          </Typography>
        </>
      }
      actions={actions}
    >
      <Typography variant="body-1" color="var(--mui-palette-neutral-500)" textAlign="center">
        <Trans i18nKey="account.modals.emailVerification.content">
          Didn't receive an email?
          <Link variant="body-1" color="primary.main" onClick={handleResendVerificationEmail}>
            Resend
          </Link>
        </Trans>
      </Typography>
    </ModalContainer>
  );
}
