import { useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';

import { AccountSuspendedBanner, EmailVerificationBanner, IdentityVerificationBanner } from 'modules/accounts/banners';
import { useSession, useEmailVerification, useIdentityVerificationStatus } from 'modules/accounts/hooks';

export function SidebarBanners() {
  const { isSuspended } = useSession();
  const { shouldDisplayIdentityNotification } = useIdentityVerificationStatus();
  const { isEmailVerificationRequired } = useEmailVerification();

  const isAnyBannerVisible = useMemo(() => {
    return !!isSuspended || !!shouldDisplayIdentityNotification || !!isEmailVerificationRequired;
  }, [isEmailVerificationRequired, isSuspended, shouldDisplayIdentityNotification]);

  if (!isAnyBannerVisible) return null;

  return (
    <Stack spacing={2}>
      <Collapse in={isSuspended} unmountOnExit>
        <AccountSuspendedBanner />
      </Collapse>

      <Collapse in={shouldDisplayIdentityNotification} unmountOnExit>
        <IdentityVerificationBanner />
      </Collapse>

      <Collapse in={isEmailVerificationRequired} unmountOnExit>
        <EmailVerificationBanner />
      </Collapse>
    </Stack>
  );
}
