import { useCallback, useMemo } from 'react';

import { ProxyActionModel, type ProxyOverviewModel } from 'store/proxies/models';
import { uppercaseTypeToEnumKey } from 'utils/string';

export function useProxyActionPermissions(proxy?: ProxyOverviewModel) {
  const canProxy = useCallback(
    (action: Lowercase<keyof typeof ProxyActionModel>) => {
      if (!proxy) return false;

      return proxy.actions.includes(ProxyActionModel[uppercaseTypeToEnumKey(action)]);
    },
    [proxy],
  );

  return useMemo(() => {
    return { canProxy };
  }, [canProxy]);
}
