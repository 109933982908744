import { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikAutocomplete } from 'components/form-partials/formik';
import { ModalContainer } from 'components/modals';
import { useHideModal } from 'modules/common/Modal';
import type { Option } from 'types';

export type ChangeUplinkSpeedValues = {
  uplinkSpeed: number;
};

type ChangeUplinkSpeedFormProps = {
  options: Array<Option<number>>;
  isLoading?: boolean;
};

export function ChangeUplinkSpeedForm({ options, isLoading }: ChangeUplinkSpeedFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { handleSubmit, isSubmitting, isValid } = useFormikContext<ChangeUplinkSpeedValues>();

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isLoading}>
        {t('common:buttons.change')}
      </Button>,
    ];
  }, [hideModal, isLoading, isSubmitting, isValid, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.changeBandwidthSpeed.title')}
      subtitle={t('proxies.modals.upgradeBandwidthSpeed.subtitle')}
      actions={actions}
    >
      <FormikAutocomplete size="small" disableClearable options={options} name="uplinkSpeed" />
    </ModalContainer>
  );
}
