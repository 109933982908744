import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import type { Nullable, Optional } from 'types';

export async function copyToClipboard(value: string) {
  if (!navigator.clipboard) return;

  try {
    await navigator.clipboard.writeText(value);

    return true;
  } catch {
    return false;
  }
}

export function createBlobLink(data: string, fileName: string, type = 'text/plain') {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  link.click();
  link.remove();
}

export function downloadConfigFile(data: string, fileName: string) {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(data)}`;

  const downloadAnchorNode = document.createElement('a');

  downloadAnchorNode.setAttribute('download', fileName);
  downloadAnchorNode.setAttribute('href', dataStr);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function downloadFile(data: string | ArrayBuffer | ArrayBufferView | Blob, fileName: string): void {
  const blob = new Blob([data]);

  const blobURL = window.URL.createObjectURL
    ? window.URL.createObjectURL(blob)
    : window.webkitURL.createObjectURL(blob);

  const tempLink = document.createElement('a');

  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fileName);

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  setTimeout(() => {
    document.body.removeChild(tempLink);
    URL.revokeObjectURL(blobURL);
  }, 200);
}

type ParsedTimePeriod = {
  value: number;
  format: 'days' | 'months';
};

export function parseTimePeriod(from?: Nullable<string>): Nullable<ParsedTimePeriod> {
  if (!from) return null;

  const match = /^(?<value>-?(?:\d+)?\.?\d+) *(?<format>m?|d?)?$/i.exec(from);

  const groups = match?.groups as Optional<{ value: string; format: string }>;

  if (!groups) return null;

  const { value, format } = groups;

  const parsedValue = Number.isNaN(parseFloat(value)) ? 1 : parseFloat(value);

  return { value: parsedValue, format: format === 'd' ? 'days' : 'months' };
}

export function isObjectDeepEmpty<T extends object>(value: Optional<Nullable<T>>): boolean {
  if (!value) return true;

  return Object.values(value).every((val) => {
    if (typeof val === 'object') {
      return isObjectDeepEmpty(val);
    }

    return isEmpty(val) || !isNil(val);
  });
}

export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0B';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.max(0, Math.floor(Math.log(bytes) / Math.log(k)));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function getBytesFromGigabytes(gbs: number) {
  return gbs * 1_024 * 1_024 * 1_024;
}
