import { useCallback } from 'react';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { posthogEvents } from 'constants/posthogEvents';
import { useRouter } from 'hooks/useRouter';
import { useIdentityVerificationStatus } from 'modules/accounts/hooks';
import { useShowModal } from 'modules/common/Modal';
import { toProxyBulkActionModel } from 'modules/proxies/helpers';
import { paths } from 'paths';
import { ProxyActionModel, type ProxyModel } from 'store/proxies/models';
import type { PartialRecord, Action } from 'types';
import { ToastManager } from 'utils/toast';
import { buildGrafanaURL } from 'utils/url';
import { isAdminUser } from 'utils/user';

export function useGetAvailableProxyActions() {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const showModal = useShowModal();
  const router = useRouter();
  const { shouldForceIDVerification } = useIdentityVerificationStatus();

  return useCallback(
    (proxies: ProxyModel[], isAllSelected = false) => {
      const isBulk = proxies.length > 1;
      const actions: Action[] = [];

      const proxyIdsPerAction = Object.keys(ProxyActionModel).reduce<PartialRecord<ProxyActionModel, number[]>>(
        (acc, key) => {
          return {
            ...acc,
            [key]: proxies.filter((proxy) => proxy.actions.includes(key as ProxyActionModel)).map(({ id }) => id),
          };
        },
        {},
      );

      // #region SHOW_DETAILS
      if (!isBulk && proxyIdsPerAction[ProxyActionModel.SHOW_DETAILS]?.length) {
        actions.push({
          icon: 'information-circle',
          label: t('common:buttons.showDetails'),
          onClick: (e) => {
            e.stopPropagation();

            posthog.capture(posthogEvents.proxy.actions.details);

            router.push(`${paths.proxies.index}/${proxyIdsPerAction[ProxyActionModel.SHOW_DETAILS]?.[0]}`);
          },
        });
      }
      // #endregion SHOW_DETAILS

      // #region AUTO_EXTEND
      if (proxyIdsPerAction[ProxyActionModel.AUTO_EXTEND]?.length) {
        const counter = isBulk && !isAllSelected ? ` (${proxyIdsPerAction[ProxyActionModel.AUTO_EXTEND].length})` : '';

        const label = `${t('common:form.isAutoExtendEnabled')}${counter}`;

        actions.push({
          icon: 'refresh',
          label,
          onClick: (e) => {
            e.stopPropagation();

            posthog.capture(posthogEvents.proxy.actions.autoExtend, { isBulk });

            if (isBulk || isAllSelected) {
              const allowedProxies = proxies.filter(({ id }) =>
                proxyIdsPerAction[ProxyActionModel.AUTO_EXTEND]?.includes(id),
              );

              return showModal('proxies.period.auto-extend.bulk', {
                isAllSelected,
                proxies: toProxyBulkActionModel(allowedProxies),
              });
            }

            const proxyId = proxyIdsPerAction[ProxyActionModel.AUTO_EXTEND]?.[0];

            if (!proxyId) return;

            return showModal('proxies.period.auto-extend', { proxyId });
          },
        });
      }
      // #endregion AUTO_EXTEND

      // #region REACTIVATE
      if (!isBulk && proxyIdsPerAction[ProxyActionModel.REACTIVATE]?.length) {
        actions.push({
          icon: 'refresh',
          label: t('common:buttons.reactivate'),
          onClick: (e) => {
            e.stopPropagation();

            const proxyId = proxyIdsPerAction[ProxyActionModel.REACTIVATE]?.[0];

            if (!proxyId) return;

            if (shouldForceIDVerification()) {
              ToastManager.error(t('common:idVerification.title'));

              return router.push(paths.account.identityVerification);
            }

            e.preventDefault();

            posthog.capture(posthogEvents.proxy.actions.reactivate);

            return showModal('proxies.reactivate', { proxyId });
          },
        });
      }
      // #endregion REACTIVATE

      // #region EXTEND_PERIOD
      if (proxyIdsPerAction[ProxyActionModel.EXTEND_PERIOD]?.length) {
        const counter =
          isBulk && !isAllSelected ? ` (${proxyIdsPerAction[ProxyActionModel.EXTEND_PERIOD].length})` : '';

        const label = `${t('common:buttons.extendProxy')} ${counter}`;

        actions.push({
          icon: 'refresh',
          label,
          onClick: (e) => {
            e.stopPropagation();

            posthog.capture(posthogEvents.proxy.actions.autoExtend, { isBulk });

            if (isBulk || isAllSelected) {
              const allowedProxies = proxies.filter(({ id }) =>
                proxyIdsPerAction[ProxyActionModel.EXTEND_PERIOD]?.includes(id),
              );

              return showModal('proxies.period.extend.bulk', {
                isAllSelected,
                proxies: toProxyBulkActionModel(allowedProxies),
              });
            }

            const proxyId = proxyIdsPerAction[ProxyActionModel.EXTEND_PERIOD]?.[0];
            const proxy = proxies.find(({ id }) => id === proxyId);

            if (!proxy) return;

            return showModal('proxies.period.extend', { proxy: toProxyBulkActionModel(proxy) });
          },
        });
      }
      // #endregion EXTEND_PERIOD

      // #region BUY_TRAFFIC
      if (proxyIdsPerAction[ProxyActionModel.BUY_TRAFFIC]?.length) {
        const counter = isBulk && !isAllSelected ? ` (${proxyIdsPerAction[ProxyActionModel.BUY_TRAFFIC].length})` : '';

        const label = `${t('common:buttons.extendBandwidth')} ${counter}`;

        actions.push({
          icon: 'trend-up',
          label,
          onClick: (e) => {
            e.stopPropagation();

            posthog.capture(posthogEvents.proxy.actions.extendBandwidth, { isBulk });

            if (isBulk || isAllSelected) {
              const allowedProxies = proxies.filter(({ id }) =>
                proxyIdsPerAction[ProxyActionModel.BUY_TRAFFIC]?.includes(id),
              );

              return showModal('proxies.bandwidth.extend.bulk', {
                isAllSelected,
                proxies: toProxyBulkActionModel(allowedProxies),
              });
            }

            const proxyId = proxyIdsPerAction[ProxyActionModel.BUY_TRAFFIC]?.[0];
            const proxy = proxies.find(({ id }) => id === proxyId);

            if (!proxy) return;

            return showModal('proxies.bandwidth.extend', { proxy: toProxyBulkActionModel(proxy) });
          },
        });
      }
      // #endregion BUY_TRAFFIC

      // #region CHANGE_PROTOCOL
      if (proxyIdsPerAction[ProxyActionModel.CHANGE_PROTOCOL]?.length) {
        const counter =
          isBulk && !isAllSelected ? ` (${proxyIdsPerAction[ProxyActionModel.CHANGE_PROTOCOL].length})` : '';

        const label = `${t('common:buttons.changeProtocol')} ${counter}`;

        actions.push({
          icon: 'trend-up',
          label,
          onClick: (e) => {
            e.stopPropagation();

            posthog.capture(posthogEvents.proxy.actions.extendBandwidth, { isBulk });

            if (isBulk || isAllSelected) {
              const allowedProxies = proxies.filter(({ id }) =>
                proxyIdsPerAction[ProxyActionModel.CHANGE_PROTOCOL]?.includes(id),
              );

              return showModal('proxies.protocol.change.bulk', {
                isAllSelected,
                proxies: toProxyBulkActionModel(allowedProxies),
              });
            }

            const proxyId = proxyIdsPerAction[ProxyActionModel.CHANGE_PROTOCOL]?.[0];
            const proxy = proxies.find(({ id }) => id === proxyId);

            if (!proxy) return;

            return showModal('proxies.protocol.change', { proxy: toProxyBulkActionModel(proxy) });
          },
        });
      }
      // #endregion CHANGE_PROTOCOL

      // #region CHANGE_AUTHENTICATION_METHOD
      if (proxyIdsPerAction[ProxyActionModel.CHANGE_AUTHENTICATION_METHOD]?.length) {
        const counter =
          isBulk && !isAllSelected
            ? ` (${proxyIdsPerAction[ProxyActionModel.CHANGE_AUTHENTICATION_METHOD].length})`
            : '';

        const label = `${t('common:buttons.changeAuthentication')} ${counter}`;

        actions.push({
          icon: 'trend-up',
          label,
          onClick: (e) => {
            e.stopPropagation();

            posthog.capture(posthogEvents.proxy.actions.extendBandwidth, { isBulk });

            if (isBulk || isAllSelected) {
              const allowedProxies = proxies.filter(({ id }) =>
                proxyIdsPerAction[ProxyActionModel.CHANGE_AUTHENTICATION_METHOD]?.includes(id),
              );

              return showModal('proxies.authentication-method.change.bulk', {
                isAllSelected,
                proxies: toProxyBulkActionModel(allowedProxies),
              });
            }

            const proxyId = proxyIdsPerAction[ProxyActionModel.CHANGE_AUTHENTICATION_METHOD]?.[0];
            const proxy = proxies.find(({ id }) => id === proxyId);

            if (!proxy) return;

            return showModal('proxies.authentication-method.change', { proxy: toProxyBulkActionModel(proxy) });
          },
        });
      }
      // #endregion CHANGE_AUTHENTICATION_METHOD

      // #region DOWNLOAD_CREDENTIALS
      if (proxyIdsPerAction[ProxyActionModel.DOWNLOAD_CREDENTIALS]?.length) {
        const counter =
          isBulk && !isAllSelected ? ` (${proxyIdsPerAction[ProxyActionModel.DOWNLOAD_CREDENTIALS].length})` : '';

        const label = `${t('common:buttons.downloadCredentials')} ${counter}`;

        actions.push({
          icon: 'trend-up',
          label,
          onClick: (e) => {
            e.stopPropagation();

            posthog.capture(posthogEvents.proxy.actions.extendBandwidth, { isBulk });

            const allowedProxies = proxies.filter(({ id }) =>
              proxyIdsPerAction[ProxyActionModel.DOWNLOAD_CREDENTIALS]?.includes(id),
            );

            return showModal('proxies.credentials.download', {
              isAllSelected,
              proxies: toProxyBulkActionModel(allowedProxies),
            });
          },
        });
      }
      // #endregion DOWNLOAD_CREDENTIALS

      if (!isBulk && isAdminUser()) {
        actions.push({
          icon: 'grafana',
          label: t('common:buttons.grafana'),
          onClick: (e) => {
            e.stopPropagation();

            window.open(buildGrafanaURL(proxies[0].id), '_blank');
          },
        });
      }

      return actions;
    },
    [posthog, router, shouldForceIDVerification, showModal, t],
  );
}
