import { type MouseEvent, useCallback, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { ModalContainer } from 'components/modals';
import { RouterLink } from 'components/RouterLink';
import { useRouter } from 'hooks/useRouter';
import { useIdentityVerificationStatus } from 'modules/accounts/hooks';
import { useHideModal } from 'modules/common/Modal';
import { paths } from 'paths';
import { useGetBankDetailsQuery, useGetReferralsDetailsQuery, useReferralsPayoutMutation } from 'store/api';
import { isAxiosBaseQueryError } from 'store/utils/errors';
import { CurrencyFormatter } from 'utils/currency';
import { ToastManager } from 'utils/toast';

import { SummaryItem } from './SummaryItem';

export function PayoutModal() {
  const { t } = useTranslation();
  const router = useRouter();
  const hideModal = useHideModal();

  const { refetch } = useIdentityVerificationStatus();
  const { data, isLoading } = useGetReferralsDetailsQuery();
  const { data: bankDetails, isLoading: areBankDetailsLoading } = useGetBankDetailsQuery();
  const [payout, { isLoading: isPayoutLoading }] = useReferralsPayoutMutation();

  const onPayoutClick = useCallback(async () => {
    try {
      await payout().unwrap();

      ToastManager.success(t('referrals.modals.payout.success'));
    } catch (error) {
      if (!isAxiosBaseQueryError(error)) {
        return;
      }

      if (error.message === 'VERIFICATION_REQUIRED') {
        await refetch();

        router.push(paths.account.identityVerification);
      }
    } finally {
      hideModal();
    }
  }, [hideModal, payout, refetch, router, t]);

  const actions = useMemo(
    () => [
      <Button fullWidth key="cancel" color="secondary" onClick={() => hideModal()}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button
        fullWidth
        key="payout"
        color="success"
        disabled={(data && data.finalEarnings < 0) || isPayoutLoading}
        onClick={onPayoutClick}
      >
        {t('common:buttons.payout')}
      </Button>,
    ],
    [data, hideModal, isPayoutLoading, onPayoutClick, t],
  );

  const footer = useMemo(
    () => (
      <Typography variant="caption" color="var(--mui-palette-neutral-500)" textAlign="center">
        {t('referrals.modals.payout.footer')}
      </Typography>
    ),
    [t],
  );

  const handleEditClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      hideModal();

      return router.push(paths.referrals.bank);
    },
    [hideModal, router],
  );

  return (
    <ModalContainer
      title={t('referrals.modals.payout.title')}
      subtitle={t('referrals.modals.payout.subtitle')}
      actions={actions}
      footer={footer}
    >
      <Stack spacing={2} divider={<Divider />}>
        <Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="title">{t('referrals.modals.payout.bankAccount')}</Typography>

            <Link component={RouterLink} href={paths.referrals.bank} variant="body-1" onClick={handleEditClick}>
              {t('common:buttons.edit')}
            </Link>
          </Stack>

          <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
            {areBankDetailsLoading ? <Skeleton width="100%" height={20} /> : bankDetails?.accountNumber}
          </Typography>
        </Stack>

        <>
          <SummaryItem
            label={t('referrals.modals.payout.earnings')}
            value={CurrencyFormatter.format(data?.earnings ?? 0)}
            isLoading={isLoading}
          />

          <SummaryItem
            label={t('referrals.modals.payout.taxFee', { amount: data?.transactionTaxInPercent })}
            value={CurrencyFormatter.format(data?.earningsTax ?? 0)}
            isLoading={isLoading}
          />

          <SummaryItem
            label={t('referrals.modals.payout.bankFee')}
            value={t('common:minus', { amount: CurrencyFormatter.format(data?.transactionFee ?? 0) })}
            isLoading={isLoading}
          />
        </>

        <SummaryItem
          label={t('referrals.modals.payout.total')}
          value={CurrencyFormatter.format(data?.finalEarnings ?? 0)}
          isLoading={isLoading}
          slotProps={{ label: { variant: 'title' }, value: { variant: 'headline-2' } }}
        />
      </Stack>
    </ModalContainer>
  );
}
