import { useCallback, useMemo, useRef } from 'react';

import { Formik, type FormikConfig } from 'formik';
import isNil from 'lodash/isNil';
import { usePostHog } from 'posthog-js/react';
import * as Yup from 'yup';

import { posthogEvents } from 'constants/posthogEvents';
import type { NetNutAccessConfig, NetNutCredentialsGeneratorValues } from 'modules/proxies/generators/types';
import type { HostnameModel, ProxyOverviewModel } from 'store/proxies/models';
import { Protocol } from 'store/proxies/types';

import { CredentialsGenerator } from './CredentialsGenerator';
import { GeneratorForm } from './GeneratorForm';
import { getCredentialsGeneratorPostHogEventValues } from '../helpers';

type NetNutGeneratorProps = {
  proxy?: ProxyOverviewModel;
  host?: HostnameModel[];

  onGenerate: (credentials: NetNutAccessConfig[]) => void;
};

type HandleSubmit = FormikConfig<NetNutCredentialsGeneratorValues>['onSubmit'];

export function NetNutGenerator({ proxy, host, onGenerate }: NetNutGeneratorProps) {
  const posthog = usePostHog();
  const generator = useRef(new CredentialsGenerator()).current;

  const initialValues = useMemo<NetNutCredentialsGeneratorValues>(() => {
    return {
      connection: Protocol.HTTP,
      country: 'random',
      state: 'random',
      city: 'random',
      session: 'random',
      region: 'US',
      hostname: 'dns',
      asn: '',

      proxyIp: 'proxy-us.proxy-cheap.com',
      port: 5959,
      username: proxy?.authentication.username ?? '',
      password: proxy?.authentication.password ?? '',

      count: 50,
    };
  }, [proxy?.authentication.password, proxy?.authentication.username]);

  const validationSchema = useMemo<Yup.Schema>(() => {
    return Yup.object().shape({
      count: Yup.number().max(100).required(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    (values, { setSubmitting }) => {
      if (!proxy) return;

      const { asn, city, connection, count, country, hostname, password, region, session, state, username } = values;

      const hostnameIp = host?.find((o) => o.region === region)?.hostnameIp ?? null;

      posthog.capture(
        posthogEvents.proxy.credentialsGenerator.generated,
        getCredentialsGeneratorPostHogEventValues({ networkType: proxy.networkType, type: 'netnut', values }),
      );

      generator.setOptions({
        asn,
        city,
        connection,
        country,
        hostname,
        region,
        password,
        session,
        state,
        username,
        hostnameIp,
      });

      const credentials = Array.from(Array(count).keys())
        .map(() => generator.build())
        .filter((v) => !isNil(v));

      onGenerate(credentials);

      setSubmitting(false);
    },
    [generator, host, onGenerate, posthog, proxy],
  );

  return (
    <Formik<NetNutCredentialsGeneratorValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <GeneratorForm networkType={proxy?.networkType} host={host} generator={generator} />
    </Formik>
  );
}
