import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { ModalItem } from './types';

type State = {
  /**
   * Determines opened modals
   */
  modals: ModalItem[];
};

type Actions = {
  hideModal: (modalId?: string) => void;
  showModal: (modalId: string, params?: Record<string, unknown>) => void;
};

type ModalState = State & Actions;

const defaultState: State = {
  modals: [],
};

export const useModalState = create<ModalState>()(
  persist(
    (set) => ({
      ...defaultState,

      hideModal: (modalId) => {
        return set((prev) => {
          if (modalId) {
            return { ...prev, modals: prev.modals.filter((modal) => modal.id !== modalId) };
          }

          return { ...prev, modals: prev.modals.slice(0, -1) };
        });
      },

      showModal: (id, params) => {
        return set((prev) => ({ ...prev, modals: [...prev.modals, { id, params }] }));
      },
    }),
    {
      name: 'app.modal',
      version: 0,
    },
  ),
);
